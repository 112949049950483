import React from "react";

function NumberToWordsFr({ number }) {
    const words1To19 = ['', 'Un', 'Deux', 'Trois', 'Quatre', 'Cinq', 'Six', 'Sept', 'Huit', 'Neuf', 'Dix', 'Onze', 'Douze', 'Treize', 'Quatorze', 'Quinze', 'Seize', 'Dix-sept', 'Dix-huit', 'Dix-neuf'];

    const wordsTens = ['', '', 'Vingt', 'Trente', 'Quarante', 'Cinquante', 'Soixante', 'Soixante', 'Quatre-vingt', 'Quatre-vingt'];

    const wordsThousands = ['', 'Mille', 'Million', 'Milliard', 'Billion', 'Billiard', 'Trillion', 'Trilliard'];

    function convertNumberToWordsLessThanOneThousand(num) {
        if (num === 0) {
            return '';
        }
        if (num < 20) {
            return words1To19[num] + ' ';
        }
        if (num < 100) {
            return wordsTens[Math.floor(num / 10)] + ' ' + convertNumberToWordsLessThanOneThousand(num % 10);
        }
        if (num < 200) {
            return 'Cent ' + convertNumberToWordsLessThanOneThousand(num % 100);
        }
        return words1To19[Math.floor(num / 100)] + ' Cent ' + convertNumberToWordsLessThanOneThousand(num % 100);
    }

    function convertNumberToWords(num) {
        if (num === 0) {
            return 'Zéro';
        }

        let words = '';
        let i = 0;
        while (num > 0) {
            if (num % 1000 !== 0) {
                words = convertNumberToWordsLessThanOneThousand(num % 1000) + wordsThousands[i] + ' ' + words;
            }
            num = Math.floor(num / 1000);
            i++;
        }
        return words.trim();
    }

    return <>{convertNumberToWords(number)}</>;
}

export default NumberToWordsFr;