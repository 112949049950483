import React from "react";

function NumberToWordsEn({ number }) {
    const words1To19 = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];

    const wordsTens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    const wordsThousands = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];

    function convertNumberToWordsLessThanOneThousand(num) {
        if (num === 0) {
            return '';
        }
        if (num < 20) {
            return words1To19[num] + ' ';
        }
        if (num < 100) {
            return wordsTens[Math.floor(num / 10)] + ' ' + convertNumberToWordsLessThanOneThousand(num % 10);
        }
        return words1To19[Math.floor(num / 100)] + ' Hundred ' + convertNumberToWordsLessThanOneThousand(num % 100);
    }

    function convertNumberToWords(num) {
        if (num === 0) {
            return 'Zero';
        }

        let words = '';
        let i = 0;
        while (num > 0) {
            if (num % 1000 !== 0) {
                words = convertNumberToWordsLessThanOneThousand(num % 1000) + wordsThousands[i] + ' ' + words;
            }
            num = Math.floor(num / 1000);
            i++;
        }
        return words.trim();
    }

    return <>{convertNumberToWords(number)}</>;
}

export default NumberToWordsEn;