import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { usePDF } from 'react-to-pdf';
import logoCamtel from '../assets/img/icons/favicon/favicon-camtel.png';
import logosFtth from '../assets/img/logo/logos-ftth.png';
import { BASE_URL } from '../apiConfig';
import FormatDate from './admin/components/FormatDate';
import SignatureCanvas from 'react-signature-canvas';

const Fiche = () => {
    const { id } = useParams();

    const [orders, setOrders] = useState([]);
    const [signature, setSignature] = useState({ customerSignature: null });
    const sigCustomerCanvas = useRef({});

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const { toPDF, targetRef } = usePDF({ filename: `fiche_des_travaux_${id}.pdf` });

    const saveCustomerSignature = () => {
        const customerSignature = sigCustomerCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        setSignature({ ...signature, customerSignature: customerSignature });
    }

    useEffect(() => {
        if (token) {
            const fetchOrders = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/installation/final/order/` + id,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const allSheetData = Object.values(response.data);
                            setOrders(allSheetData);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [token]);

    const [signatures, setSignatures] = useState(null);

    useEffect(() => {
        if (token) {
            const fetchSignatures = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/installation/signature/` + id,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            setSignatures(response.data.signatures[0]);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchSignatures();
        }
    }, [token])

    const handleSave = async () => {
        if ((!signature.customerSignature)) {
            alert('La signature du client doit être apposée.');
            return;
        }

        if (token) {
            try {
                const response = await axios.post(`${BASE_URL}/api/installation/handle-signature`, {
                    idSheet: id,
                    customerSignature: signature.customerSignature
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                alert(response.data.message);
            } catch (error) {
                console.error('Erreur lors de l\'envoi des signatures', error);
            }
        }
    }

    return (
        <div>
            <div className='flex float-right'>
                {signature.customerSignature ? (
                    <button
                        onClick={handleSave}
                        className='m-4 mr-2 px-10 py-2 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-lg shadow-md'
                    >
                        Enregistrer
                    </button>
                ) : null}

                {/* {localStorage.getItem('userRole') === 'super_admin' || localStorage.getItem('userRole') === 'admin_camtel' && ( */}
                <button
                    className='m-4 mr-14 px-10 py-2 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-md'
                    onClick={() => toPDF()}
                >
                    Télécharger la fiche
                </button>
                {/* )} */}
            </div>

            <br /><br /><br />

            <div className='fiche pl-10 pr-10 pt-3 pb-3' ref={targetRef} style={{ fontSize: 14 }}>

                <div className="mb-5 mt-2 flex items-center justify-between">
                    <div className="w-28 h-23 mr-4 ">
                        <img src={logoCamtel} alt="Logo CAMTEL" className="w-full h-full" />
                    </div>

                    <div className="w-full flex flex-col items-center">
                        <div className="w-100 h-10 mb-1">
                            <img src={logosFtth} alt="icônes FTTH" className="w-full h-full" style={{ width: 250, height: 60 }} />
                        </div>
                        <h2 className="text-sky-400 text-2xl mt-4 font-bold sm:text-4xl text-center whitespace-nowrap">
                            Fiche des travaux
                        </h2>
                    </div>

                    <div className="flex items-center mb-2">
                        {Array.isArray(orders) && orders.length > 0 ? (
                            orders.map((sheetData, sheetIndex) => (
                                <div className="w-20 h-20 ml-4">
                                    <img src={orders[0].ordersData[0].customerPhoto} alt="Photo client" className="w-full h-full rounded-full" />
                                </div>
                            ))
                        ) : (null)}
                    </div>
                </div>
                <div className="border border-black mb-4">
                    <div className="bg-sky-500 text-2xl font-bold border-b border-black text-center text-white pb-4">INFORMATIONS CLIENT</div>
                    <div className='font-montserrat py-2 px-2 border-b border-black'>
                        {
                            Array.isArray(orders) && orders.length > 0 ? (
                                orders.map((sheetData) => (
                                    <div key={sheetData.id}>
                                        {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                            sheetData.ordersData.map((order) => (
                                                <>
                                                    <div className="mb-2">N° ID Souscription: <span className='pl-20 pr-20 border-b border-black' style={{ paddingBottom: 6 }}>{order.idSubscription}{order.idSheet}</span></div>
                                                    <div className="mb-2">Nom et Prénoms / Raison Sociale: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.nameOrSocialName}</span></div>
                                                    <div className="mb-2 flex">
                                                        <div className="w-1/2 pr-2">Région:  <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>
                                                            {
                                                                order.region === 'CE' ? 'Centre' :
                                                                    order.region === 'LT' ? 'Littoral' :
                                                                        order.region === 'NO' ? 'Nord' :
                                                                            order.region === 'EN' ? 'Extrême-Nord' :
                                                                                order.region === 'AD' ? 'Adamaoua' :
                                                                                    order.region === 'ES' ? 'Est' :
                                                                                        order.region === 'SU' ? 'SUD' :
                                                                                            order.region === 'OU' ? 'Ouest' :
                                                                                                order.region === 'NW' ? 'Nord-Ouest' :
                                                                                                    'Sud-Ouest'
                                                            }
                                                        </span></div>
                                                        <div className="w-1/2 pl-2">Ville: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.city}</span></div>
                                                    </div>
                                                    <div className="mb-2">Type de Client: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.customerType}</span></div>
                                                    <div className="mb-2">Localisation Précise: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.localisation}</span></div>
                                                    <div className="mb-2">Adresse mail: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.email}</span></div>
                                                    <div className="mb-2">N° Mobile: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.mobile}</span></div>
                                                    {order.idCardNumber !== null && (
                                                        <div className="mb-2">N°CNI (+ Espace Upload Recto Verso): <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.idCardNumber}</span></div>
                                                    )}
                                                    {order.idPassportNumber !== null && (
                                                        <div className="mb-2">N°Passeport : <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.idPassportNumber}</span></div>
                                                    )}
                                                    {order.idResidencePermitNumber !== null && (
                                                        <div className="mb-2">N° Carte de séjour : <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.idResidencePermitNumber}</span></div>
                                                    )}
                                                    {order.commerceRegNumber !== null || order.commerceRegNumber !== '' && (
                                                        <div className="mb-2">N°Régistre Commerce (si Entreprise): <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.commerceRegNumber}</span></div>
                                                    )}
                                                </>
                                            ))
                                        ) : (
                                            null
                                        )}
                                    </div>
                                ))
                            ) : (
                                null
                            )
                        }
                    </div>
                    <div className="bg-sky-500 font-bold border-b border-black text-center" style={{ paddingBottom: 10 }}>TYPE D’OPERATION</div>
                    <div className="flex">
                        <div className="w-3/5 border-r border-b">
                            <p className="bg-gray-300 font-bold text-lg text-center" style={{ paddingBottom: 4 }}>Formule d’abonnement</p>
                            <table className="table-fixed w-full">
                                <thead>
                                    <tr>
                                        <th className="w-1/6 border-t border-r border-black" style={{ paddingBottom: 4 }}></th>
                                        <th className="w-1/6 border border-black" style={{ paddingBottom: 4 }}>Vitesse(*)</th>
                                        <th className="w-1/6 border border-black" style={{ paddingBottom: 4 }}>Mins Fixes</th>
                                        <th className="w-1/6 border border-black" style={{ paddingBottom: 4 }}>Mins Mobile</th>
                                        <th className="w-1/6 border border-black" style={{ paddingBottom: 4 }}>Prix</th>
                                        <th className="w-1/6 border-t border-black" style={{ paddingBottom: 4 }}>Offre Choisie</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    <tr>
                                        <th className="border-t border-r border-black" style={{ paddingBottom: 6 }}>Blue Max S</th>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>10 Mbps</td>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>200 mins</td>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>90 mins</td>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>25 000</td>

                                        {
                                            Array.isArray(orders) && orders.length > 0 ? (
                                                orders.map((sheetData) => (
                                                    <td className="border-t border-black text-center" style={{ paddingBottom: 6 }}>
                                                        {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                            sheetData.ordersData.map((order) => (
                                                                <>
                                                                    {
                                                                        order.offerBlueMaxSelected === 'Blue Max S' ? '✅' : ''
                                                                    }
                                                                </>
                                                            ))
                                                        ) : (
                                                            null
                                                        )}
                                                    </td>
                                                ))
                                            ) : (
                                                null
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <th className="border-t border-r border-black" style={{ paddingBottom: 6 }}>Blue Max M</th>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>16 Mbps</td>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>300 mins</td>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>120 mins</td>
                                        <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>35 000</td>
                                        {
                                            Array.isArray(orders) && orders.length > 0 ? (
                                                orders.map((sheetData) => (
                                                    <td className="border-t border-black text-center" style={{ paddingBottom: 6 }}>
                                                        {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                            sheetData.ordersData.map((order) => (
                                                                <span>
                                                                    {
                                                                        order.offerBlueMaxSelected === 'Blue Max M' ? '✅' : ''
                                                                    }
                                                                </span>
                                                            ))
                                                        ) : (
                                                            null
                                                        )}
                                                    </td>
                                                ))
                                            ) : (
                                                null
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <th className="border-t border-r border-black" style={{ paddingBottom: 6 }}>Blue Max L</th>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>25 Mbps</td>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>400 mins</td>
                                        <td className="border border-black" style={{ paddingBottom: 6 }}>150 mins</td>
                                        <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>50 000</td>

                                        {
                                            Array.isArray(orders) && orders.length > 0 ? (
                                                orders.map((sheetData) => (
                                                    <td className="border-t border-black text-center" style={{ paddingBottom: 6 }}>
                                                        {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                            sheetData.ordersData.map((order) => (
                                                                <>
                                                                    {
                                                                        order.offerBlueMaxSelected === 'Blue Max L' ? '✅' : ''
                                                                    }
                                                                </>
                                                            ))
                                                        ) : (
                                                            null
                                                        )}
                                                    </td>
                                                ))
                                            ) : (
                                                null
                                            )
                                        }
                                    </tr>
                                    <tr>
                                        <th className="border-t border-r border-black" style={{ paddingBottom: 6 }}>Blue Max XL</th>
                                        <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>35 Mbps</td>
                                        <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>500 mins</td>
                                        <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>180 mins</td>
                                        <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>60 000</td>
                                        {
                                            Array.isArray(orders) && orders.length > 0 ? (
                                                orders.map((sheetData) => (
                                                    <td className="border-t border-black text-center" style={{ paddingBottom: 6 }}>
                                                        {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                            sheetData.ordersData.map((order) => (
                                                                <>
                                                                    {
                                                                        order.offerBlueMaxSelected === 'Blue Max XL' ? '✅' : ''
                                                                    }
                                                                </>
                                                            ))
                                                        ) : (
                                                            null
                                                        )}
                                                    </td>
                                                ))
                                            ) : (
                                                null
                                            )
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="w-2/5 border-l border-black">
                            <div className="bg-gray-300 font-bold text-lg text-center border-b border-black" style={{ paddingBottom: 6 }}>Signature du client</div>
                            {signatures && signatures.customerSignature ? (
                                <img src={signatures.customerSignature} key={signatures.customerSignature} />
                            ) : (
                                <div>
                                    {localStorage.getItem('userRole') === 'technicien_partner' || localStorage.getItem('userRole') === 'super_admin' ? (
                                        <SignatureCanvas
                                            penColor='black'
                                            canvasProps={{ width: 360, height: 170, className: 'sigCustomerCanvas' }}
                                            ref={sigCustomerCanvas}
                                            onEnd={saveCustomerSignature}
                                        />
                                    ) : null}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg">
                        <div className="bg-sky-500 font-bold border-t border-black text-center" style={{ paddingBottom: 10 }}>PRE-ETUDE PRESTATAIRE</div>
                        <table className="table-auto w-full">
                            <tbody>
                                {Array.isArray(orders) && orders.length > 0 ? (
                                    orders.map((sheetData, sheetIndex) => (
                                        <>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                    sheetData.ordersData.map((order, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Nom du prestataire: {order.nameEntity}</td>
                                                            <td className="border border-black" style={{ paddingBottom: 6 }}>Référence du FDT: {order.fdtRef}</td>
                                                            <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Port FAT: {order.portFat}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                    sheetData.ordersData.map((order, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Tel : {order.telephoneEntity}</td>
                                                            <td className="border border-black" style={{ paddingBottom: 6 }}>Référence du FAT : {order.fatRef}</td>
                                                            <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Coordonnée GPS du FAT : {order.fatGpsCoordinates}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                    sheetData.ordersData.map((order, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Email : {order.emailEntity}</td>
                                                            <td className="border border-black" style={{ paddingBottom: 6 }}>Distance FAT-Client (m) : {order.dFatCustomer}</td>
                                                            <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Coordonnée GPS Client : {order.customerGpsCoordinates}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                    sheetData.ordersData.map((order, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Zone d’intervention : {order.interventionZone}</td>
                                                            <td className="border border-b-0 border-black" style={{ paddingBottom: 6 }}>OLT - F/S/P : {order.oltSfp}</td>
                                                            <td className="border border-r-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Nombre de poteau à installer : {order.nbPoToInstall}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                        </>
                                    ))
                                ) : (
                                    <>
                                        {null}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <table className="table-auto w-full">
                        <tbody>
                            <tr>
                                <td className='w-1/2 border border-l-0 border-b-0 border-black'>
                                    <div className="bg-sky-500 font-bold border-b border-t-0 border-l-0 border-l-0 border-black text-center" style={{ paddingBottom: 10 }}>
                                        RESULTAT PRE-ETUDE PRESTATAIRE
                                    </div>
                                    {
                                        Array.isArray(orders) && orders.length > 0 ? (
                                            orders.map((sheetData) => (
                                                <div key={sheetData.id} className='text-center'>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order) => (
                                                            <>
                                                                <label className="inline-flex items-center" style={{ paddingBottom: 6 }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Concluante"
                                                                        checked={order.result === 'Concluante'}
                                                                    />
                                                                    <span className="ml-2">Concluante</span>
                                                                </label>

                                                                <label className="inline-flex items-center ml-2" style={{ paddingBottom: 6 }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Non concluante"
                                                                        checked={order.result === 'Non concluante'}
                                                                    />
                                                                    <span className="ml-2">Non concluante</span>
                                                                </label>
                                                            </>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            null
                                        )
                                    }
                                </td>
                                <td className='w-1/2 border border-r-0 border-black'>
                                    <div className="bg-sky-500 font-bold border-b border-t-0 border-black text-center" style={{ paddingBottom: 10 }}>
                                        VALIDATION DE L’ETUDE
                                    </div>
                                    {
                                        Array.isArray(orders) && orders.length > 0 ? (
                                            orders.map((sheetData) => (
                                                <div key={sheetData.id} className='text-center'>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order) => (
                                                            <>
                                                                <label className="inline-flex items-center" style={{ paddingBottom: 6 }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Favorable"
                                                                        checked={order.validation === 'Favorable'}
                                                                    />
                                                                    <span className="ml-2">Favorable</span>
                                                                </label>
                                                                <label className="inline-flex items-center ml-2" style={{ paddingBottom: 6 }}>
                                                                    <input
                                                                        type="checkbox"
                                                                        value="Défavorable"
                                                                        checked={order.validation === 'Défavorable'}
                                                                    />
                                                                    <span className="ml-2">Défavorable</span>
                                                                </label>
                                                            </>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            null
                                        )
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className='w-1/2 border border-l-0 border-b-0 border-black'>
                                    <div className="bg-sky-500 font-bold border-b border-black text-center" style={{ paddingBottom: 10 }}>RESULTAT PRE-ETUDE PRESTATAIRE</div>
                                    {
                                        Array.isArray(orders) && orders.length > 0 ? (
                                            orders.map((sheetData) => (
                                                <>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order) => (
                                                            <div className='text-center' style={{ paddingBottom: 6 }}>{order.preEtudeObs === '' ? '---' : order.preEtudeObs}</div>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </>
                                            ))
                                        ) : (
                                            null
                                        )
                                    }
                                </td>
                                <td className='w-1/2 border border-r-0 border-b-0 border-black'>
                                    <div className="bg-sky-500 font-bold border-b border-black text-center" style={{ paddingBottom: 10 }}>OBSERVATIONS DE L’ETUDE</div>
                                    {
                                        Array.isArray(orders) && orders.length > 0 ? (
                                            orders.map((sheetData) => (
                                                <div>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order) => (
                                                            <div className='text-center p-1' style={{ paddingBottom: 6 }}>{order.etudeObs === '' ? '---' : order.etudeObs}</div>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            null
                                        )
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="bg-white shadow-md rounded-lg">
                        <div className="bg-sky-500 font-bold border-t border-black text-center" style={{ paddingBottom: 10 }}>ACTIVATION DU SERVICE</div>
                        <table className="table-auto w-full">
                            <tbody>
                                {Array.isArray(orders) && orders.length > 0 ? (
                                    orders.map((sheetData, sheetIndex) => (
                                        <>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                    sheetData.installationData.map((installation, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Nom du conseiller : {installation.nom} {installation.prenom} </td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                                {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                    sheetData.ordersData.map((order, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-black" style={{ paddingBottom: 6 }}>Code Client : {order.crmCustomerCode}</td>
                                                            <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Référence paiement : {order.paymentRef}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                    sheetData.ordersData.map((order, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Tel : {orders[0].installationData[0].mobile} </td>
                                                            <td className="border border-black" style={{ paddingBottom: 6 }}>Compte de facturation : {order.billingAccount}</td>
                                                            <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Date de paiement : {order.paymentDate}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                    sheetData.ordersData.map((order, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Email : {orders[0].installationData[0].email} </td>
                                                            <td className="border border-black" style={{ paddingBottom: 6 }}>Numéro de Telephone : {order.phoneNumberActivated}</td>
                                                            <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Numéro STB/IP Stick : {order.stbIpStickNumber}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                    sheetData.ordersData.map((order, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Agence Commerciale : {order.bindedEntity}</td>
                                                            <td className="border border-b-0 border-black" style={{ paddingBottom: 6 }}>Numéro ONT : {order.ontNumber}</td>
                                                            <td className="border border-r-0 border-b-0 border-black" style={{ paddingBottom: 6 }}></td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                        </>
                                    ))
                                ) : (
                                    <>
                                        {null}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="bg-white shadow-md rounded-lg">
                        <div className="bg-sky-500 font-bold border-t border-black text-center" style={{ paddingBottom: 10 }}>INSTALLATION PRESTATAIRE</div>
                        <table className="table-auto w-full">
                            <tbody>
                                {Array.isArray(orders) && orders.length > 0 ? (
                                    orders.map((sheetData, sheetIndex) => (
                                        <>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                    sheetData.installationData.map((installation, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Nombre de Balancelle : {installation.nbBalancel}</td>
                                                            <td className="border border-black" style={{ paddingBottom: 6 }}>Nombre Crochet d’Arrêt : {installation.nbCrochetArret}</td>
                                                            <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Nombre de STB/ Stick : {installation.stbStickNumber}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                    sheetData.installationData.map((installation, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Métrage câble entrée FAT : {installation.cableMeterEntryFat}</td>
                                                            <td className="border border-black" style={{ paddingBottom: 6 }}>Métrage câble chez l’abonné : {installation.clientCableMeter}</td>
                                                            <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Nombre de TB : {installation.tbNumber}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                    sheetData.installationData.map((installation, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Longueur Câble utilisée (m) : {installation.usedCableLong}</td>
                                                            <td className="border border-black" style={{ paddingBottom: 6 }}>Nombre de poteau armé installé : {installation.nbArmedInstalledPoto}</td>
                                                            <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Nombre de Jarretière : {installation.nbGarter}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                            <tr key={sheetIndex}>
                                                {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                    sheetData.installationData.map((installation, orderIndex) => (
                                                        <React.Fragment key={orderIndex}>
                                                            <td className="border border-l-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Nombre d’ATB : {installation.atbNumber}</td>
                                                            <td className="border border-b-0 border-black" style={{ paddingBottom: 6 }}>Numéro ONT : {installation.ontNumber}</td>
                                                            <td className="border border-r-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Nombre de Smoove {installation.nbSmoove}</td>
                                                        </React.Fragment>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </tr>
                                        </>
                                    ))
                                ) : (
                                    <>
                                        {null}
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* INTERLOCUTEUR EN CHARGE */}
                    <div className="bg-white shadow-md rounded-lg">
                        <div className="bg-sky-500 font-bold text-lg text-center border-b border-black" style={{ paddingBottom: 10 }}>INTERLOCUTEUR EN CHARGE</div>
                        {Array.isArray(orders) && orders.length > 0 ? (
                            orders.map((sheetData, sheetIndex) => (
                                <>
                                    <div key={sheetIndex}>
                                        <div className="flex text-center border-b border-black">
                                            {Array.isArray(sheetData.commercialPartnerInfos) && sheetData.commercialPartnerInfos.length > 0 ? (
                                                sheetData.commercialPartnerInfos.map((commercialPartnerInfo, orderIndex) => (
                                                    <div className="border-r border-black flex-1">
                                                        <div className="font-bold">Prospecteur</div>
                                                        <div style={{ paddingBottom: 6 }}>Nom /Tél : </div> <div> {commercialPartnerInfo.nom} {commercialPartnerInfo.prenom} / {commercialPartnerInfo.mobile} </div>
                                                        {Array.isArray(sheetData.signatureComPartner) && sheetData.signatureComPartner.length > 0 ? (
                                                            sheetData.signatureComPartner.map((comaPrtnerSignature, orderIndex) => (
                                                                <div>
                                                                    <img src={comaPrtnerSignature.signature} key={comaPrtnerSignature.signature} />
                                                                </div>
                                                            ))) : null
                                                        }
                                                    </div>
                                                ))
                                            ) : (
                                                null
                                            )}
                                            {Array.isArray(sheetData.techPartnerInfos) && sheetData.techPartnerInfos.length > 0 ? (
                                                sheetData.techPartnerInfos.map((techPartnerInfo, orderIndex) => (
                                                    <div className="border-r border-black flex-1">
                                                        <div className="font-bold">Technicien Installateur</div>
                                                        <div style={{ paddingBottom: 6 }}>Nom /Tél : </div> <div> {techPartnerInfo.nom} {techPartnerInfo.prenom} / {techPartnerInfo.mobile} </div>
                                                        <div>
                                                            {Array.isArray(sheetData.signatureTechnicianPartner) && sheetData.signatureTechnicianPartner.length > 0 ? (
                                                                sheetData.signatureTechnicianPartner.map((techPartnerSignature, orderIndex) => (
                                                                    <div key={orderIndex}>
                                                                        <img src={techPartnerSignature.signature} alt={`Signature ${orderIndex}`} />
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                signatures && signatures.techPartnerSignature ? (
                                                                    <img src={signatures.techPartnerSignature} alt="Signature" />
                                                                ) : null
                                                            )}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : null}
                                            {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                sheetData.installationData.map((installation, orderIndex) => (

                                                    <div className="border-r border-black flex-1">
                                                        <div className="font-bold">Conseiller Client</div>
                                                        <div style={{ paddingBottom: 6 }}>Nom /Tél : </div> <div> {installation.nom} {installation.prenom} / {installation.mobile} </div>
                                                        {Array.isArray(sheetData.signatureComCamtel) && sheetData.signatureComCamtel.length > 0 ? (
                                                            sheetData.signatureComCamtel.map((comCamtelSignature, orderIndex) => (
                                                                <div>
                                                                    <img src={comCamtelSignature.signature} key={comCamtelSignature.signature} />
                                                                </div>
                                                            ))) : null
                                                        }
                                                    </div>

                                                ))
                                            ) : (
                                                null
                                            )}
                                            <div className="border-r border-black flex-1">
                                                <div className="font-bold">Chef d’Agence</div>
                                            </div>
                                            {Array.isArray(sheetData.techCamtelInfos) && sheetData.techCamtelInfos.length > 0 ? (
                                                sheetData.techCamtelInfos.map((techCamtelInfo, orderIndex) => (
                                                    <div className='flex-1'>
                                                        <div className="font-bold">Technicien CAMTEL</div>
                                                        {techCamtelInfo.nom && techCamtelInfo.prenom && (<><div style={{ paddingBottom: 6 }}>Nom /Tél : </div> <div> {techCamtelInfo.nom} {techCamtelInfo.prenom} / {techCamtelInfo.mobile} </div></>)}
                                                        {Array.isArray(sheetData.signatureTechCamtel) && sheetData.signatureTechCamtel.length > 0 ? (
                                                            sheetData.signatureTechCamtel.map((techCamtelSignature, orderIndex) => (
                                                                <div>
                                                                    <img src={techCamtelSignature.signature} key={techCamtelSignature.signature} />
                                                                </div>
                                                            ))) : null
                                                        }
                                                    </div>
                                                ))
                                            ) : (
                                                null
                                            )}
                                        </div>
                                    </div>
                                </>
                            ))
                        ) : (
                            <>
                                {null}
                            </>
                        )}
                    </div>
                    {/* EVALUATION DU CLIENT */}
                    <div className="bg-white shadow-md rounded-lg">
                        <div className="bg-gray-300 font-bold text-lg mb-1 text-center border-b border-black" style={{ paddingBottom: 6 }}>EVALUATION DU CLIENT</div>
                        <div className="flex text-center border-b border-black">
                            <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                <p className='text-5xl pb-2'>😡</p>
                                <p style={{ paddingBottom: 1, paddingTop: 1 }}>Très insatisfait</p>
                            </div>
                            <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                <p className='text-5xl pb-2'>😢</p>
                                <p style={{ paddingBottom: 1, paddingTop: 1 }}>Insatisfait/déçu</p>
                            </div>
                            <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                <p className='text-5xl pb-2'>😐</p>
                                <p style={{ paddingBottom: 1, paddingTop: 1 }}>Indifférent</p>
                            </div>
                            <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                <p className='text-5xl pb-2'>😊</p>
                                <p style={{ paddingBottom: 1, paddingTop: 1 }}>Satisfait</p>
                            </div>
                            <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                <p className='text-5xl pb-2'>😁</p>
                                <p style={{ paddingBottom: 1, paddingTop: 1 }}>Très satisfait</p>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white shadow-md rounded-lg">
                        <div className="text-center">
                            {Array.isArray(orders) && orders.length > 0 ? (
                                orders.map((sheetData, sheetIndex) => (
                                    <>
                                        <div className="flex text-center border-b border-black">
                                            {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                sheetData.ordersData.map((orderData, orderIndex) => (
                                                    <>
                                                        <div className="pb-8 border-r border-black flex-1">
                                                            <strong>Date de souscription</strong>
                                                            <p><FormatDate dateStr={orderData.sheetCreatedAt} /></p>
                                                        </div>
                                                        <div className="pb-8 border-r border-black flex-1">
                                                            <strong>Date de Pré-étude</strong>
                                                            <p><FormatDate dateStr={orderData.preEtudeCreatedAt} /></p>
                                                        </div>
                                                        <div className="pb-8 border-r border-black flex-1">
                                                            <strong>Date d’Etude</strong>
                                                            <p>
                                                                {orderData.preEtudeUpdateByTechCamtelAt ? (
                                                                    <FormatDate dateStr={orderData.preEtudeUpdateByTechCamtelAt} />
                                                                ) : (
                                                                    <FormatDate dateStr={orderData.preEtudeCreatedAt} />
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="pb-8 border-r border-black flex-1">
                                                            <strong>Date de paiement</strong>
                                                            <p><FormatDate dateStr={orderData.paymentDate === null ? '' : orderData.paymentDate} /></p>
                                                        </div>
                                                        {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                            sheetData.installationData.map((iData, installationIndex) => (
                                                                <div className="flex-1 pb-8">
                                                                    <strong>Date d’Installation</strong>
                                                                    <p><FormatDate dateStr={iData.installationCreatedAt} /></p>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            null
                                                        )}
                                                    </>
                                                ))
                                            ) : (
                                                null
                                            )}
                                        </div>
                                    </>
                                ))
                            ) : (
                                <>
                                    {null}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <p className='text-center text-2xl mt-2'>Merci pour votre confiance, nous travaillons à la mériter !</p>
            </div>
        </div>
    )
}

export default Fiche;