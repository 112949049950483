import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';
import DatePicker from 'react-datepicker';
import WhatsAppButton from '../components/WhatsAppButton';

const Rdv = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [slotsCache, setSlotsCache] = useState({});

    const [idSubscription, setIdSubscription] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/api/appointment/${localStorage.getItem('techId')}`);
                setAppointments(res.data.appointments);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [localStorage.getItem('techId')]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setSelectedTime(null);
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };

    const getBookedHoursForDate = useCallback((date) => {
        return appointments
            .filter(app => {
                const appDate = new Date(app.appointmentDateTime);
                return appDate.getFullYear() === date.getFullYear() &&
                    appDate.getMonth() === date.getMonth() &&
                    appDate.getDate() === date.getDate();
            })
            .map(app => new Date(app.appointmentDateTime).getHours());
    }, [appointments]);

    const isDateFullyBooked = (date) => {
        const bookedHours = getBookedHoursForDate(date);
        const allHours = [10, 11, 12, 13, 14];
        return allHours.every(hour => bookedHours.includes(hour));
    };

    const generateTimeSlots = useCallback((date) => {
        if (slotsCache[date.toDateString()]) {
            return slotsCache[date.toDateString()];
        }

        const bookedHours = getBookedHoursForDate(date);
        const slots = [];
        for (let hour = 10; hour <= 14; hour++) {
            if (!bookedHours.includes(hour) &&
                (hour === 10 ? !bookedHours.includes(hour + 1) :
                    hour === 11 ? !bookedHours.includes(hour - 1) && !bookedHours.includes(hour + 1) :
                        hour === 12 ? !bookedHours.includes(hour - 1) && !bookedHours.includes(hour + 1) :
                            hour === 13 ? !bookedHours.includes(hour - 1) && !bookedHours.includes(hour + 1) :
                                !bookedHours.includes(hour - 1))) {
                slots.push(`${hour}:00`);
            }
        }

        setSlotsCache(prevCache => ({
            ...prevCache,
            [date.toDateString()]: slots
        }));

        return slots;
    }, [getBookedHoursForDate, slotsCache]);

    const availableTimeSlots = useMemo(() => {
        if (selectedDate) {
            return generateTimeSlots(selectedDate);
        }
        return [];
    }, [selectedDate, generateTimeSlots]);

    const isWeekday = (date) => {
        if (!date) return false;

        const day = date.getDay();
        const today = new Date();
        let daysToAdd = 3;
        while (daysToAdd > 0) {
            today.setDate(today.getDate() + 1);
            if (today.getDay() !== 0 && today.getDay() !== 6) {
                daysToAdd--;
            }
        }
        return day !== 0 && day !== 6 && date >= today;
    };

    const filterDate = (date) => {
        return isWeekday(date) && !isDateFullyBooked(date) && generateTimeSlots(date).length > 0;
    };

    useEffect(() => {
        if (id) {
            const fetchOrder = async () => {
                try {

                    const paymentCheckResponse = await axios.get(`${BASE_URL}/api/payment/check/${id}`);
                    if (paymentCheckResponse.data.isPaymentExist === 0) {
                        setErrorMessage('Vous ne pouvez pas programmer le Rendez-vous d\'installation avant d\'avoir payé les frais d\'installation.');
                        return;
                    }

                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/pdf/${id}`,
                        headers: {}
                    };

                    const response = await axios.request(config);

                    if (response.data && response.data.sheet[0].idSheet) {
                        setIdSubscription(response.data.sheet[0].idSheet);

                        let idSubscription = response.data.sheet[0].idSubscription;
                        if (idSubscription.endsWith('-')) {
                            idSubscription = idSubscription.slice(0, -1);
                        }

                        localStorage.setItem('idPartner', response.data.sheet[0].idEntity);

                        const res = await axios.get(`${BASE_URL}/api/user/${response.data.sheet[0].idEntity}`);
                        let userData;
                        if (res.data && res.data.techs) {
                            userData = res.data.techs;
                        }

                        async function fetchInstallationData(techs) {
                            if (techs.length === 0) {
                                return;
                            }

                            let minElements = Infinity;
                            let minTech = null;
                            let latestAppointment = null;

                            for (let tech of techs) {
                                try {
                                    const response = await axios.get(`${BASE_URL}/api/appointment/${tech.id}`);

                                    const currentMonthAppointments = response.data.appointments.filter(appointment => {
                                        const appointmentDate = new Date(appointment.appointmentDateTime);
                                        const now = new Date();
                                        return appointmentDate.getMonth() === now.getMonth() && appointmentDate.getFullYear() === now.getFullYear();
                                    });

                                    if (currentMonthAppointments.length === 0) {
                                        localStorage.setItem('techId', tech.id);
                                        localStorage.setItem('idPartner', tech.idEntity);
                                    } else {
                                        const currentLatestAppointment = currentMonthAppointments.reduce((latest, appointment) => {
                                            const latestDate = new Date(latest.appointmentDateTime);
                                            const currentAppointmentDate = new Date(appointment.appointmentDateTime);
                                            return currentAppointmentDate > latestDate ? appointment : latest;
                                        }, currentMonthAppointments[0]);

                                        if (currentMonthAppointments.length < minElements) {
                                            minElements = currentMonthAppointments.length;
                                            minTech = tech;
                                            latestAppointment = currentLatestAppointment;

                                            localStorage.setItem('latestAppointment', JSON.stringify(latestAppointment));
                                        }
                                    }

                                    if (latestAppointment) {
                                        const appointmentDate = new Date(latestAppointment.appointmentDateTime);

                                        const localDate = appointmentDate.toLocaleString();

                                        const endDateHour = new Date(latestAppointment.appointmentDateTime);
                                        endDateHour.setHours(endDateHour.getHours() + 1);

                                        localStorage.setItem('startAppointmentDate', localDate);

                                        localStorage.setItem('techId', minTech.id);
                                        localStorage.setItem('idPartner', minTech.idEntity);
                                    } else {
                                        const todayDate = new Date();
                                        const localDate = todayDate.toLocaleString();

                                        localStorage.setItem('startAppointmentDate', localDate);
                                        localStorage.setItem('techId', tech.id);
                                        localStorage.setItem('idPartner', tech.idEntity);
                                    }
                                } catch (error) {
                                    console.error(`Erreur lors de la récupération des données d'installation pour ${tech.nom}:`, error);
                                }
                            }
                        }

                        await fetchInstallationData(userData);
                    } else {
                        alert('Commande inexistante.');
                    }

                } catch (error) {
                    console.error('Erreur lors de la récupération de la commande: ', error);
                }
            }

            fetchOrder();
        }
    }, [id]);

    const handleRDVSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (!selectedDate || !selectedTime) {
            alert('Aucune date ou heure sélectionnée');
            return;
        }

        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
        const day = String(selectedDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        const dateOfRdv = `${formattedDate} ${selectedTime}:00`;

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const idPartner = localStorage.getItem('idPartner');
            const techId = localStorage.getItem('techId');

            const res = await axios.get(`${BASE_URL}/api/payment/${idSubscription}`, config);
            let payToken;
            if (res.data && res.data.payment[0].payToken) {
                payToken = res.data.payment[0].payToken;
            }

            const data = {
                appointmentDateTime: dateOfRdv,
                idSheet: idSubscription,
                idEntity: idPartner,
                idPartnerTechnician: techId,
                returnedPayToken: payToken
            };

            const response = await axios.post(`${BASE_URL}/api/appointment`, data, config);

            const showAlert = (message) => {
                return new Promise((resolve) => {
                    alert(message);
                    resolve();
                });
            };

            if (response.data && response.data.message) {
                localStorage.removeItem('idPartner');
                localStorage.removeItem('techId');

                await showAlert(response.data.message);

                setLoading(false);

                navigate(`/receipt/${payToken}`);
            } else if (response.data && response.data.error) {
                alert(response.data.error);
            } else {
                alert('Une erreur inconnue est survenue lors du traitement de cette requête. Veuillez vérifier votre connexion internet et réesayer plus tard.');
                setLoading(false);
            }
        } catch (error) {
            console.error("Erreur  : ", error);
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../assets/img/icons/gif/loading-points.webp')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <main className="w-full flex">
                    <div
                        className="relative flex-1 hidden items-center justify-center h-screen bg-gray-900 lg:flex bg-contain bg-no-repeat bg-center"
                        style={{ backgroundImage: `url(${require('../assets/img/bg/animiertes-gif-von-online-umwandeln-de.gif')})` }}
                    ></div>
                    <div className="flex-1 flex items-center justify-center h-screen">
                        <div className="w-full space-y-8 px-4 bg-white text-gray-600 sm:px-0">
                            <div className="w-full text-center">
                                <img src={require('../assets/img/icons/favicon/favicon-camtel.png')} width={220} className="mx-auto mb-5" />

                                {errorMessage ? (
                                    <h3 className="text-gray-800 text-2xl font-bold sm:text-4xl text-center">{errorMessage}</h3>
                                ) : (
                                    <>
                                        <div className="space-y-2">
                                            <h3 className="text-gray-800 text-2xl font-bold sm:text-4xl text-center">Merci d'avoir payé votre souscription!</h3>
                                        </div>
                                        <form onSubmit={handleRDVSubmit} className="bg-white px-8 pt-6 pb-8 mb-4">
                                            <div className="mb-2">
                                                <label className="block text-gray-700 font-bold mb-2" htmlFor="date">
                                                    Quel jour souhaiteriez-vous être installé ?
                                                </label>
                                                <DatePicker
                                                    placeholderText='Date du RDV ici'
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    filterDate={filterDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                />
                                            </div>
                                            {selectedDate && (
                                                <div className="mb-2 mt-4">
                                                    <label className="block text-gray-700 font-bold mb-2" htmlFor="time">
                                                        A quelle heure souhaiteriez-vous être installée ?
                                                    </label>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        {availableTimeSlots.map((time, index) => (
                                                            <button
                                                                key={index}
                                                                type="button"
                                                                onClick={() => handleTimeChange(time)}
                                                                className={`bg-sky-200 hover:bg-sky-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline} ${selectedTime === time ? 'bg-sky-700 font-bold' : 'bg-sky-200'}`}
                                                            >
                                                                {time}
                                                            </button>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="flex items-center justify-center">
                                                <button
                                                    type="submit"
                                                    className="bottom-0 right-0 mb-3 bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded"
                                                >
                                                    Réserver
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </main>
            )}

            <WhatsAppButton />
        </>
    );
}

export default Rdv;
