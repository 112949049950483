import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { BASE_URL } from '../../apiConfig';
import Header from '../admin/components/Header';
import Sidebar from '../admin/components/Sidebar';

const VerifPayment = () => {
    const [loading, setLoading] = useState(false);
    const [file1Data, setFile1Data] = useState([]);
    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const handleFileUpload = (event, setFileData) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
            setFileData(jsonData);
        };

        reader.readAsArrayBuffer(file);
    };

    const findCommonRows = async () => {
        if (token && localStorage.getItem('userRole') === 'super_admin') {
            try {
                setLoading(true);
                const response = await axios.patch(`${BASE_URL}/api/payment/cross`, file1Data, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (response.data.length > 0) {
                    generateExcelFile(response.data);
                    setLoading(false);
                } else {
                    alert('Aucune ligne commune trouvée.');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error during cross payments:', error);
                setLoading(false);
            }
        }
    };

    const generateExcelFile = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Common Rows");

        XLSX.writeFile(workbook, "common_rows.xlsx");
    };

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../../assets/img/icons/gif/loading-points.webp')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <>
                        <Header />

                        <Sidebar setHideText={setHideText} hideText={hideText} />

                        <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[75%] 2xl:w-[73%]'}`}>


                            <div className="min-h-screen bg-gray-100 flex items-center justify-center">
                                <div className="bg-white p-8 rounded-lg shadow-lg">
                                    <h2 className="text-2xl font-bold mb-6">Upload des fichiers Excel</h2>

                                    <div className="mb-4">
                                        <label className="block mb-2 text-gray-700">Upload du fichier excel de la DCF:</label>
                                        <input
                                            type="file"
                                            accept=".xlsx, .xls"
                                            onChange={(e) => handleFileUpload(e, setFile1Data)}
                                            className="p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    <button
                                        onClick={findCommonRows}
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                    >
                                        Effectuer le croisement
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default VerifPayment;