import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';
import WhatsAppButton from '../components/WhatsAppButton';

const FollowUp = () => {
    const [loading, setLoading] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [showProgression, setShowProgression] = useState(false);
    const [steps, setSteps] = useState({
        stepsItems: ["Demande", "Survey", "Activation", "Installation"],
        currentStep: 1
    });

    const statusToStepIndex = (status) => {
        switch (status) {
            case 'existence':
                return 1;
            case 'survey':
                return 2;
            case 'activation':
                return 3;
            case 'installation':
                return 4;
            default:
                return 0;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        localStorage.setItem('subscriptionId', subscriptionId);

        const parts = subscriptionId.split("-");

        const part1 = parts[0];
        const part2 = parts[1];

        try {
            const response = await axios.post(`${BASE_URL}/api/orders/follow-up-sheet/${part1}-/${part2}`);
            if (response.data && response.data.error) {
                alert(response.data.error);
            } else {
                setSteps(prevSteps => ({ ...prevSteps, currentStep: statusToStepIndex(response.data.status) }));
                setStatusMessage(response.data.message);
                setShowProgression(true);
            }
            setLoading(false);
        } catch (error) {
            console.error('Erreur lors de l\'envoi des données', error);
            setLoading(false);
        }
    };

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../assets/img/icons/gif/loading-points.webp')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <div className='w-full h-screen flex items-center justify-center'>
                        {showProgression ? (
                            <>
                                <div className="flex-1 max-w-2xl mx-auto px-4 md:px-0">
                                    {statusMessage && <h3 className='text-gray-800 text-2xl font-bold sm:text-4xl text-center mb-7'>{statusMessage}</h3>}
                                    <ul aria-label="Steps" className="items-center text-gray-600 font-medium md:flex">
                                        {steps.stepsItems.map((item, idx) => (
                                            <li aria-current={steps.currentStep == idx + 1 ? "step" : false} className="flex-1 last:flex-none flex gap-x-2 md:items-center">
                                                <div className="flex items-center flex-col gap-x-2">
                                                    <div className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${steps.currentStep > idx + 1 ? "bg-sky-600 border-sky-600" : "" || steps.currentStep == idx + 1 ? "border-sky-600" : ""}`}>
                                                        <span className={` ${steps.currentStep > idx + 1 ? "hidden" : "" || steps.currentStep == idx + 1 ? "text-sky-600" : ""}`}>
                                                            {idx + 1}
                                                        </span>
                                                        {
                                                            steps.currentStep > idx + 1 ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                                </svg>
                                                            ) : ""
                                                        }
                                                    </div>
                                                    <hr className={`h-12 border md:hidden ${idx + 1 == steps.stepsItems.length ? "hidden" : "" || steps.currentStep > idx + 1 ? "border-sky-600" : ""}`} />
                                                </div>
                                                <div className="h-8 flex items-center md:h-auto">
                                                    <h3 className={`text-sm ${steps.currentStep == idx + 1 ? "text-sky-600" : ""}`}>
                                                        {item}
                                                    </h3>
                                                </div>
                                                <hr className={`hidden mr-2 w-full border md:block ${idx + 1 == steps.stepsItems.length ? "hidden" : "" || steps.currentStep > idx + 1 ? "border-sky-600" : ""}`} />
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="mt-7 flex items-center justify-center">
                                        <button
                                            onClick={() => { setShowProgression(false) }}
                                            className="px-4 py-2 text-white font-medium bg-sky-500 hover:bg-sky-600 active:bg-sky-600 rounded-lg duration-150"
                                        >
                                            Précédent
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <main className="w-full flex">
                                <div
                                    className="relative flex-1 hidden items-center justify-center h-screen bg-gray-900 lg:flex bg-contain bg-no-repeat bg-center"
                                    style={{ backgroundImage: `url(${require('../assets/img/bg/animiertes-gif-von-online-umwandeln-de.gif')})` }}
                                ></div>
                                <div className="flex-1 flex items-center justify-center h-screen">
                                    <div className="w-full max-w-md space-y-3 px-4 bg-white text-gray-600 sm:px-0">
                                        <div className="text-center">
                                            <img src={require('../assets/img/icons/favicon/favicon-camtel.png')} width={220} className="mx-auto" />
                                            <div className="space-y-2">
                                                <h3 className="text-gray-800 text-2xl font-bold sm:text-4xl text-center">Vérifier l'état d'avancement de votre dossier.</h3>
                                            </div>
                                        </div>
                                        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
                                            <div className="mb-4">
                                                <label className="text-center block text-gray-700 font-bold mb-2" htmlFor="subscriptionId">
                                                    Identifiant de souscription (subscription ID):
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    id="subscriptionId"
                                                    value={subscriptionId}
                                                    onChange={e => setSubscriptionId(e.target.value)}
                                                />
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <button
                                                    className="w-full px-4 py-2 text-white font-medium bg-sky-500 hover:bg-sky-600 active:bg-sky-600 rounded-lg duration-150"
                                                >
                                                    Valider
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </main>
                        )}
                    </div>
                )
            }

            <WhatsAppButton />
        </>
    );
}

export default FollowUp;
