import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../apiConfig";

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: "",
        role: ""
    });

    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${BASE_URL}/api/user/forgotpassword`, formData);
            alert(response.data.message);
            setLoading(false);
            navigate('/login');
        } catch (error) {
            console.error("Erreur de connexion :", error);
            setErrorMessage(error);
            setLoading(false);
        }
    };

    const MySelect = () => {
        const [selectedOption, setSelectedOption] = useState(formData.role || "");

        const handleSelectChange = (e) => {
            const newValue = e.target.value;
            setSelectedOption(newValue);
            // Mettez à jour le formulaire avec la valeur sélectionnée
            setFormData({ ...formData, role: newValue });
        };

        return (
            <div className="my-2">
                <select
                    id="role"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    required
                >
                    <option value="">Connectez-vous en tant que...</option>
                    <option value="super_admin">Super Admin</option>
                    <option value="admin_camtel">Chef d'Agence - CAMTEL</option>
                    <option value="admin_partner">Partenaire (Admin Partenaire)</option>
                    <option value="technicien_camtel">Pool Technique (CAMTEL)</option>
                    <option value="commercial_camtel">Pool Commercial (CAMTEL)</option>
                    <option value="technicien_partner">Technicien (Partenaire)</option>
                    <option value="commercial_partner">Commercial (Partenaire)</option>
                </select>
            </div>
        );
    };

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../assets/img/icons/gif/loading-points.webp')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    <main className="w-full flex">
                        <div
                            className="relative flex-1 hidden items-center justify-center h-screen bg-gray-900 lg:flex bg-contain bg-no-repeat bg-center"
                            style={{ backgroundImage: `url(${require('../assets/img/bg/animiertes-gif-von-online-umwandeln-de.gif')})` }}
                        ></div>
                        <div className="flex-1 flex items-center justify-center h-screen">
                            <div className="w-full max-w-md space-y-3 px-4 bg-white text-gray-600 sm:px-0">
                                <div className="text-center">
                                    <img src={require('../assets/img/icons/favicon/favicon-camtel.png')} width={220} className="mx-auto" />
                                    <div className="space-y-2">
                                        <h3 className="text-gray-800 text-2xl font-bold sm:text-4xl text-center">Réinitialisez votre mot de passe.</h3>
                                    </div>
                                </div>
                                <form
                                    onSubmit={handleSubmit}
                                    className="space-y-3"
                                >
                                    <div>
                                        <label className="font-medium" htmlFor="role">
                                            Type de compte
                                        </label>
                                        <MySelect />
                                    </div>
                                    <div>
                                        <label className="font-medium">
                                            E-mail
                                        </label>
                                        <input
                                            type="email"
                                            value={formData.email}
                                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                            required
                                            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                                        />
                                    </div>
                                    <button
                                        className="w-full px-4 py-2 text-white font-medium bg-sky-500 hover:bg-sky-600 active:bg-sky-600 rounded-lg duration-150"
                                    >
                                        Réinitialiser
                                    </button>

                                    {errorMessage && (
                                        <div className="text-red-500 text-center">{errorMessage}</div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </main>
                </>
            )}
        </>
    )
}

export default ForgotPassword;
