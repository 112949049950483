import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import axios from 'axios';
import Chart from 'react-apexcharts';
import Header from './components/Header';
import { BASE_URL } from '../../apiConfig';
import '../../style.css';

const AdminHome = () => {
    const [totalNbOfPartners, setTotalNbOfPartners] = useState([]);
    const [totalNbOfCustomers, setTotalNbOfCustomers] = useState([]);
    const [totalNbOfPreStudies, setTotalNbOfPreStudies] = useState([]);
    const [totalNbOfPendingPreStudies, setTotalNbOfPendingPreStudies] = useState([]);
    const [totalNbOfNotConcludedPreStudies, setTotalNbOfNotConcludedPreStudies] = useState([]);
    const [totalNbOfConcludedPreStudies, setTotalNbOfConcludedPreStudies] = useState([]);
    const [totalNbOfStudies, setTotalNbOfStudies] = useState([]);
    const [totalNbOfPendingStudies, setTotalNbOfPendingStudies] = useState([]);
    const [totalNbOfNotFavorableStudies, setTotalNbOfNotFavorableStudies] = useState([]);
    const [totalNbOfFavorableStudies, setTotalNbOfFavorableStudies] = useState([]);
    const [totalNbOfPendingPayment, setTotalNbOfPendingPayment] = useState([]);
    const [totalNbOfPendingInstallation, setTotalNbOfPendingInstallation] = useState([]);
    const [totalNbOfInstalledOrders, setTotalNbOfInstalledOrders] = useState([]);
    const [delayInstallation, setDelayInstallation] = useState([]);

    const [nbCamtelPartner, setNbCamtelPartner] = useState([]);
    const [nbCamtelPartnersCustomers, setNbCamtelPartnersCustomers] = useState([]);
    const [nbCamtelPreStudiedOrders, setNbCamtelPreStudiedOrders] = useState([]);
    const [nbCamtelPendingOrders, setNbCamtelPendingOrders] = useState([]);
    const [nbCamtelInconclusiveOrders, setNbCamtelInconclusiveOrders] = useState([]);
    const [nbCamtelConclusiveOrders, setNbCamtelConclusiveOrders] = useState([]);
    const [nbCamtelPendingPayment, setNbCamtelPendingPayment] = useState([]);
    const [nbCamtelPendingInstallation, setNbCamtelPendingInstallation] = useState([]);
    const [nbCamtelInstalledOrders, setNbCamtelInstalledOrders] = useState([]);
    const [delayCamtelTreatmentOrders, setDelayCamtelTreatmentOrders] = useState([]);

    const [nbCamtelStudiedOrders, setNbCamteltudiedOrders] = useState([]);
    const [nbCamtelStudiedPendingOrders, setNbCamtelStudiedPendingOrders] = useState([]);
    const [nbCamtelStudiedUnfavorableOrders, setNbCamtelStudiedUnfavorableOrders] = useState([]);
    const [nbCamtelStudiedFavorableOrders, setNbCamtelStudiedFavorableOrders] = useState([]);

    const [partners, setPartners] = useState([]);
    const [entity, setEntity] = useState(null);
    const [years, setYears] = useState(null);
    const [year, setYear] = useState(null);

    const [isVisible, setIsVisible] = useState(true);
    const [nbPartnerCustomers, setNbPartnerCustomers] = useState([]);
    const [nbPartnerPendingPreStudy, setNbPartnerPendingPreStudy] = useState([]);
    const [nbPartnerDonePreStudy, setNbPartnerDonePreStudy] = useState([]);
    const [nbPartnerNotConcludedPreStudy, setNbPartnerNotConcludedPreStudy] = useState([]);
    const [nbPartnerConcludedPreStudy, setNbPartnerConcludedPreStudy] = useState([]);
    const [nbPartnerPendingStudy, setNbPartnerPendingStudy] = useState([]);
    const [nbPartnerDoneStudy, setNbPartnerDoneStudy] = useState([]);
    const [nbPartnerNotFavorableStudy, setNbPartnerNotFavorableStudy] = useState([]);
    const [nbPartnerFavorableStudy, setNbPartnerFavorableStudy] = useState([]);
    const [nbPartnerOrdersPendingPayment, setNbPartnerOrdersPendingPayment] = useState([]);
    const [nbPartnerOrdersPendingInstallation, setNbPartnerOrdersPendingInstallation] = useState([]);
    const [nbPartnerOrdersInstalled, setNbPartnerOrdersInstalled] = useState([]);
    const [partnerDelayInstallation, setPartnerDelayInstallation] = useState([]);

    const [ordersPerMonths, setOrdersPerMonths] = useState([]);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    // SUPER ADMIN
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'super_admin') {
            const getTotalNumberOfPartners = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/partners`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfPartners(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfCustomers = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/customers`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfCustomers(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfPreStudies = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/prestudies`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfPreStudies(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfPendingPreStudies = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/pending/prestudies`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfPendingPreStudies(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfNotConcludedPreStudies = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/notconcluded/prestudies`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfNotConcludedPreStudies(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfConcludedPreStudies = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/concluded/prestudies`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfConcludedPreStudies(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfStudies = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/studies`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfStudies(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfPendingStudies = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/pending/studies`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfPendingStudies(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfNotFavorableStudies = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/notfavorable/studies`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfNotFavorableStudies(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfFavorableStudies = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/favorable/studies`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfFavorableStudies(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfPendingPayment = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/pending/payment`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfPendingPayment(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfPendingInstallation = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/pending/installation`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfPendingInstallation(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalNumberOfInstalledOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/installed/installation`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setTotalNbOfInstalledOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalDelayInstallation = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/delay/installation`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setDelayInstallation(response.data.averageDelayInDays);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            getTotalNumberOfPartners();
            getTotalNumberOfCustomers();
            getTotalNumberOfPreStudies();
            getTotalNumberOfPendingPreStudies();
            getTotalNumberOfNotConcludedPreStudies();
            getTotalNumberOfConcludedPreStudies();
            getTotalNumberOfStudies();
            getTotalNumberOfPendingStudies();
            getTotalNumberOfNotFavorableStudies();
            getTotalNumberOfFavorableStudies();
            getTotalNumberOfPendingPayment();
            getTotalNumberOfPendingInstallation();
            getTotalNumberOfInstalledOrders();
            getTotalDelayInstallation();
        }
    }, [token]);

    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'super_admin') {

            const fetchData = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/entities/superadmin/`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setPartners(response.data.entities);
                } catch (error) {
                    console.error("Erreur lors de la récupération des partenaires :", error);
                }
            };

            const fetchDataPerMonths = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/number/orders/months`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setOrdersPerMonths(response.data.series[0].data);
                } catch (error) {
                    console.error("Erreur lors de la récupération des données mensuelles :", error);
                }
            };

            const fetchYears = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/years`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setYears(response.data.years);
                } catch (error) {
                    console.error("Erreur lors de la récupération des partenaires :", error);
                }
            };

            fetchData();
            fetchDataPerMonths();
            fetchYears();
        }
    }, [token]);

    useEffect(() => {
        if (token && (localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' || localStorage.getItem('userRole') === 'commercial_camtel')) {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/entities/admincamtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setPartners(response.data.entities);
                } catch (error) {
                    console.error("Erreur lors de la récupération des partenaires :", error);
                }
            };

            const fetchTotalPartnersDataPerMonths = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/admincamtel/number/orders/months/partners/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setOrdersPerMonths(response.data.series[0].data);
                } catch (error) {
                    console.error("Erreur lors de la récupération des données mensuelles :", error);
                }
            };

            fetchData();
            fetchTotalPartnersDataPerMonths();
        }
    }, [token]);

    const handleSelectChangeYear = async (e) => {
        const newValue = e.target.value;
        setYear(newValue);

        if (newValue === '') {
            if (entity === 'all' || entity === '' || entity === null) {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/number/orders/curyear/nullentity/chart`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setOrdersPerMonths(response.data.series[0].data);
                } catch (error) {
                    console.error("Erreur lors de la récupération des données mensuelles :", error);
                }
            } else {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/number/orders/year/chart/` + entity + `/` + currentYear, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setOrdersPerMonths(response.data.series[0].data);
                } catch (error) {
                    console.error("Erreur lors de la récupération des données mensuelles :", error);
                }
            }
        } else {
            if (entity === 'all' || entity === '' || entity === null) {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/number/orders/nullentity/chart/` + newValue, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setOrdersPerMonths(response.data.series[0].data);
                } catch (error) {
                    console.error("Erreur lors de la récupération des données mensuelles :", error);
                }
            } else {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/number/orders/year/chart/` + entity + `/` + newValue, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setOrdersPerMonths(response.data.series[0].data);
                } catch (error) {
                    console.error("Erreur lors de la récupération des données mensuelles :", error);
                }
            }
        }
    }

    const handleSelectChangeEntity = async (e) => {
        const newValue = e.target.value;
        setEntity(newValue);
        setYear(currentYear);

        if (newValue === 'all' || newValue === '') {
            setIsVisible(true);

            if (token && (localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' || localStorage.getItem('userRole') === 'commercial_camtel')) {
                const fetchTotalPartnersDataPerMonths = async () => {
                    try {
                        const response = await axios.get(`${BASE_URL}/api/stat/admincamtel/number/orders/months/partners/` + localStorage.getItem('userId'), {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setOrdersPerMonths(response.data.series[0].data);
                    } catch (error) {
                        console.error("Erreur lors de la récupération des données mensuelles :", error);
                    }
                };

                fetchTotalPartnersDataPerMonths();
            }
        } else {
            setIsVisible(false);

            if (token) {
                // NB OF CUSTOMERS BY PARTNER
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/customers/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerCustomers(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF PENDING PRESTUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/pending/prestudy/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerPendingPreStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF DONE PRESTUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/done/prestudy/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerDonePreStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF NOT CONCLUDED PRESTUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/notconcluded/prestudy/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerNotConcludedPreStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF CONCLUDED PRESTUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/concluded/prestudy/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerConcludedPreStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF PENDING STUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/pending/study/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerPendingStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF DONE STUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/done/study/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerDoneStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF NOT FAVORABLE STUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/notfavorable/study/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerNotFavorableStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF FAVORABLE STUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/favorable/study/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerFavorableStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF PENDING ORDERS PAYMENT
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/order/pending/payment/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerOrdersPendingPayment(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF PENDING INSTALLATION
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/order/pending/installation/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerOrdersPendingInstallation(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF INSTALLED ORDERS
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/order/installed/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerOrdersInstalled(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // PARTNER DELAY TREATMENT
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/delay/installation/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setPartnerDelayInstallation(response.data.averageDelayInDays);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // PARTNER DIAGRAM
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/number/orders/months/camtel/${newValue}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setOrdersPerMonths(response.data.series[0].data);
                } catch (error) {
                    console.error("Erreur lors de la récupération des données mensuelles :", error);
                }
            }
        }
    }

    // ADMIN CAMTEL
    useEffect(() => {
        if (token && localStorage.getItem('userRole') !== 'super_admin') {

            const getNumberOfPartnerByAgency = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelPartner(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfPartnersCustomers = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/customers/partners/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelPartnersCustomers(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfPartnersPreStudiedOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/partners/prestudied/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelPreStudiedOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfPartnersPendingOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/partners/pending/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelPendingOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfInconclusivePartnersOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/inconclusive/partners/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelInconclusiveOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfConclusivePartnersOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/conclusive/partners/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelConclusiveOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfPartnersStudiedOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/partners/studied/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamteltudiedOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfPartnersStudiedPendingOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/partners/pending/study/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelStudiedPendingOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfUnfavorableStudiedPartnersOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/unfavorable/partners/study/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelStudiedUnfavorableOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfFavorableStudiedPartnersOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/favorable/partners/study/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelStudiedFavorableOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfPendingPaymentOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/pending/payment/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelPendingPayment(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfPendingInstallationOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/pending/installation/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelPendingInstallation(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getNumberOfInstalledOrders = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/installed/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbCamtelInstalledOrders(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            const getTotalDelayInstallation = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/delay/installation/orders/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setDelayCamtelTreatmentOrders(response.data.averageDelayInDays);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }
            };

            getNumberOfPartnerByAgency();
            getNumberOfPartnersCustomers();
            getNumberOfPartnersPreStudiedOrders();
            getNumberOfPartnersPendingOrders();
            getNumberOfInconclusivePartnersOrders();
            getNumberOfConclusivePartnersOrders();
            getNumberOfPartnersStudiedOrders();
            getNumberOfPartnersStudiedPendingOrders();
            getNumberOfUnfavorableStudiedPartnersOrders();
            getNumberOfFavorableStudiedPartnersOrders();
            getNumberOfPendingPaymentOrders();
            getNumberOfPendingInstallationOrders();
            getNumberOfInstalledOrders();
            getTotalDelayInstallation();
        }
    }, [token]);

    // PARTNER
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'admin_partner') {
            const getNumberOfElementsByPartner = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/customers/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerCustomers(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF PENDING PRESTUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/pending/prestudy/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerPendingPreStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF DONE PRESTUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/done/prestudy/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerDonePreStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF NOT CONCLUDED PRESTUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/notconcluded/prestudy/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerNotConcludedPreStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF CONCLUDED PRESTUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/concluded/prestudy/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerConcludedPreStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF PENDING STUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/pending/study/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerPendingStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF DONE STUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/done/study/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerDoneStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF NOT FAVORABLE STUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/notfavorable/study/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerNotFavorableStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF FAVORABLE STUDY
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/favorable/study/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerFavorableStudy(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF PENDING ORDERS PAYMENT
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/order/pending/payment/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerOrdersPendingPayment(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF PENDING INSTALLATION
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/order/pending/installation/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerOrdersPendingInstallation(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // NB OF INSTALLED ORDERS
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/order/installed/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setNbPartnerOrdersInstalled(response.data);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // PARTNER DELAY TREATMENT
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/superadmin/single/partner/delay/installation/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setPartnerDelayInstallation(response.data.averageDelayInDays);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la stat :", error);
                }

                // PARTNER DIAGRAM
                try {
                    const response = await axios.get(`${BASE_URL}/api/stat/number/orders/months/camtel/` + localStorage.getItem('userId'), {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setOrdersPerMonths(response.data.series[0].data);
                } catch (error) {
                    console.error("Erreur lors de la récupération des données mensuelles :", error);
                }
            }

            getNumberOfElementsByPartner();
        }
    }, [token]);

    const options = {
        chart: {
            id: 'orders-chart',
            type: 'bar',
            height: 350,
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
    };

    const series = [
        {
            name: 'Commandes',
            data: ordersPerMonths
        },
    ];

    const currentYear = new Date().getFullYear();

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <div>
            <Header />

            <Sidebar setHideText={setHideText} hideText={hideText} />

            {(localStorage.getItem('userRole') === 'super_admin') || (localStorage.getItem('userRole') === 'admin_camtel') || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' || (localStorage.getItem('userRole') === 'admin_partner') ? (

                <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 flex justify-center ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                    <div className='w-full'>
                        <h1 className="text-3xl font-semibold mb-6">Tableau de bord</h1>

                        <div className="bg-white shadow-md rounded-md p-4 mb-4">
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-activity bg-sky"><path d="M22 12h-4l-3 9L9 3l-3 9H2" /></svg>
                                <h2 className="text-xl font-semibold mb-2 ml-4">Situation Globale</h2>
                            </div>
                        </div>

                        {(localStorage.getItem('userRole') === 'super_admin') || (localStorage.getItem('userRole') === 'admin_camtel') || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? (
                            <div className='flex justify-end select-bloc'>
                                <div>
                                    <select
                                        id="nameEntity"
                                        name="nameEntity"
                                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        value={entity}
                                        onChange={handleSelectChangeEntity}
                                    >
                                        <option value="">Sélectionnez un partenaire ici</option>
                                        <option value="all">Tout</option>
                                        {Array.isArray(partners) && partners.length > 0 ? (
                                            partners.map((partner) => (
                                                <option value={`${partner.idEntity}`}>
                                                    {partner.name}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">Aucun partenaire trouvé.</option>
                                        )}
                                    </select>
                                    {/* <p>{entity}</p> */}
                                </div>
                            </div>
                        ) : null}

                        <h2 className="text-xl font-semibold mb-4">Instances</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
                            {isVisible && ((localStorage.getItem('userRole') === 'super_admin') || (localStorage.getItem('userRole') === 'admin_camtel') || (localStorage.getItem('userRole') === 'commercial_camtel') || (localStorage.getItem('userRole') === 'technicien_camtel')) ? (
                                <div className="bg-white p-4 rounded-lg shadow-md">
                                    <div className="flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-building-2"><path d="M6 22V4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v18Z" /><path d="M6 12H4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h2" /><path d="M18 9h2a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2" /><path d="M10 6h4" /><path d="M10 10h4" /><path d="M10 14h4" /><path d="M10 18h4" /></svg>
                                        <div className="ml-4">
                                            <p className="text-1xl font-semibold">Nombre de Partenaires</p>
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') !== 'super_admin' ? nbCamtelPartner.count : totalNbOfPartners.count
                                                }
                                                &nbsp;partenaires
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (null)}

                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clipboard-list"><rect width="8" height="4" x="8" y="2" rx="1" ry="1" /><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><path d="M12 11h4" /><path d="M12 16h4" /><path d="M8 11h.01" /><path d="M8 16h.01" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Nombre total de Clients Prospectés</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelPartnersCustomers.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerCustomers.count : totalNbOfCustomers.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerCustomers.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className="text-xl font-semibold mb-4">Pré-étude</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clipboard-signature"><rect width="8" height="4" x="8" y="2" rx="1" ry="1" /><path d="M8 4H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-.5" /><path d="M16 4h2a2 2 0 0 1 1.73 1" /><path d="M18.42 9.61a2.1 2.1 0 1 1 2.97 2.97L16.95 17 13 18l.99-3.95 4.43-4.44Z" /><path d="M8 18h1" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes en attente de pré-étude</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelPreStudiedOrders.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerPendingPreStudy.count : totalNbOfPendingPreStudies.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerPendingPreStudy.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clipboard-edit"><rect width="8" height="4" x="8" y="2" rx="1" ry="1" /><path d="M10.42 12.61a2.1 2.1 0 1 1 2.97 2.97L7.95 21 4 22l.99-3.95 5.43-5.44Z" /><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-5.5" /><path d="M4 13.5V6a2 2 0 0 1 2-2h2" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes pré-études réalisées</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelPendingOrders.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerDonePreStudy.count : totalNbOfPreStudies.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerDonePreStudy.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-x-2"><path d="M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4" /><path d="M14 2v6h6" /><path d="m3 12.5 5 5" /><path d="m8 12.5-5 5" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes pré-études non concluantes</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelInconclusiveOrders.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerNotConcludedPreStudy.count : totalNbOfNotConcludedPreStudies.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerNotConcludedPreStudy.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-check-2"><path d="M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4" /><polyline points="14 2 14 8 20 8" /><path d="m3 15 2 2 4-4" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes pré-étude concluantes</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelConclusiveOrders.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerConcludedPreStudy.count : totalNbOfConcludedPreStudies.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerConcludedPreStudy.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className="text-xl font-semibold mb-4">Études</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-clock"><path d="M16 22h2c.5 0 1-.2 1.4-.6.4-.4.6-.9.6-1.4V7.5L14.5 2H6c-.5 0-1 .2-1.4.6C4.2 3 4 3.5 4 4v3" /><polyline points="14 2 14 8 20 8" /><circle cx="8" cy="16" r="6" /><path d="M9.5 17.5 8 16.25V14" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes en attente d'étude</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelStudiedOrders.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerPendingStudy.count : totalNbOfPendingStudies.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerPendingStudy.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clipboard-check"><rect width="8" height="4" x="8" y="2" rx="1" ry="1" /><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" /><path d="m9 14 2 2 4-4" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes etudes réalisées</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelStudiedPendingOrders.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerDoneStudy.count : totalNbOfStudies.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerDoneStudy.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-x"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" /><polyline points="14 2 14 8 20 8" /><line x1="9.5" x2="14.5" y1="12.5" y2="17.5" /><line x1="14.5" x2="9.5" y1="12.5" y2="17.5" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes études défavorables</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelStudiedUnfavorableOrders.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerNotFavorableStudy.count : totalNbOfNotFavorableStudies.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerNotFavorableStudy.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-check"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" /><polyline points="14 2 14 8 20 8" /><path d="m9 15 2 2 4-4" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes études favorables</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelStudiedFavorableOrders.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerFavorableStudy.count : totalNbOfFavorableStudies.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerFavorableStudy.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className="text-xl font-semibold mb-4">Paiement</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-credit-card"><rect width="20" height="14" x="2" y="5" rx="2" /><line x1="2" x2="22" y1="10" y2="10" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes en attente de paiement</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelPendingPayment.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerOrdersPendingPayment.count : totalNbOfPendingPayment.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerOrdersPendingPayment.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className="text-xl font-semibold mb-4">Installation</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-list-restart"><path d="M21 6H3" /><path d="M7 12H3" /><path d="M7 18H3" /><path d="M12 18a5 5 0 0 0 9-3 4.5 4.5 0 0 0-4.5-4.5c-1.33 0-2.54.54-3.41 1.41L11 14" /><path d="M11 10v4h4" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes payés mais en attente d'installation</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelPendingInstallation.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerOrdersPendingInstallation.count : totalNbOfPendingInstallation.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerOrdersPendingInstallation.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-list-checks"><path d="m3 17 2 2 4-4" /><path d="m3 7 2 2 4-4" /><path d="M13 6h8" /><path d="M13 12h8" /><path d="M13 18h8" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Commandes installées</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? nbCamtelInstalledOrders.count : localStorage.getItem('userRole') === 'admin_partner' ? nbPartnerOrdersInstalled.count : totalNbOfInstalledOrders.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    nbPartnerOrdersInstalled.count
                                                }
                                                &nbsp;commandes
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h2 className="text-xl font-semibold mb-4">Clôtures</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
                            <div className="bg-white p-4 rounded-lg shadow-md">
                                <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-hourglass"><path d="M5 22h14" /><path d="M5 2h14" /><path d="M17 22v-4.172a2 2 0 0 0-.586-1.414L12 12l-4.414 4.414A2 2 0 0 0 7 17.828V22" /><path d="M7 2v4.172a2 2 0 0 0 .586 1.414L12 12l4.414-4.414A2 2 0 0 0 17 6.172V2" /></svg>
                                    <div className="ml-4">
                                        <p className="text-1xl font-semibold">Délai moyen de traitement des commandes</p>
                                        {isVisible ? (
                                            <p className="text-gray-500">
                                                {
                                                    localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') === 'commercial_camtel' || localStorage.getItem('userRole') === 'technicien_camtel' ? Math.round(delayCamtelTreatmentOrders) : localStorage.getItem('userRole') === 'admin_partner' ? Math.round(partnerDelayInstallation) : Math.round(delayInstallation)
                                                }
                                                &nbsp;jours
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {
                                                    Math.round(partnerDelayInstallation)
                                                }
                                                &nbsp;jours
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-4 mt-6 rounded-lg shadow-md">
                            <h2 className="text-xl font-semibold mb-4">Statistiques des commandes par mois</h2>
                            <div className='flex justify-end items-center mb-2'>
                                <div className='mr-2'>Filtrer par année</div>
                                <select
                                    id="year"
                                    name="year"
                                    className="appearance-none border rounded w-32 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    value={year}
                                    onChange={handleSelectChangeYear}
                                >
                                    {Array.isArray(years) && years.length > 0 ? (
                                        years.map((y, index) => (
                                            <option value={`${y.year}`}>
                                                {y.year}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="">Aucune année trouvé.</option>
                                    )}
                                </select>
                            </div>
                            <Chart options={options} series={series} type="bar" />
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{ height: '100vh' }} className={`container flex items-center justify-center container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                    <p className='text-3xl font-bold mb-2 uppercase text-center'>Seuls les administrateurs ont accès aux informations du dashboard. Veuillez naviguer vers d'autres rubriques en cliquant sur le menu !</p>
                </div>
            )}
        </div>
    );
}

export default AdminHome;