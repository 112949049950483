import React from "react";

function FormatDate({ dateStr }) {
  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);

    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  };

  return <>{formatDate(dateStr)}</>;
}

export default FormatDate;