import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { BASE_URL } from "../../apiConfig";
import FormatDate from "./components/FormatDate";
import Modal from 'react-modal';

const OrderDetails = () => {
    const { orderId } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    const [selectedAppointmentOrder, setSelectedAppointmentOrder] = useState(null);
    const [selectedPreStudyOrder, setSelectedPreStudyOrder] = useState(null);
    const [selectedInstallationData, setSelectedInstallationData] = useState(null);
    const [selectedActivationData, setSelectedActivationData] = useState(null);
    const [selectedPaymentData, setSelectedPaymentData] = useState(null);
    const [techCamtelName, setTechCamtelName] = useState([]);
    const [techPartnerName, setTechPartnerName] = useState([]);
    const [comCamtelName, setComCamtelName] = useState([]);
    const [comPartnerName, setComPartnerName] = useState([]);
    const [details, setDetails] = useState(null);
    const [token, setToken] = useState(null);
    const [partners, setPartners] = useState([]);

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }
        setToken(token);
    }, [navigate]);

    // Get Only Partners name
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'super_admin') {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/entities/superadmin/partners/names/`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setPartners(response.data.entities);
                } catch (error) {
                    console.error("Erreur lors de la récupération des partenaires :", error);
                }
            };

            fetchData();
        }
    }, [token]);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            border: '1px solid #ccc',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            borderRadius: '8px',
        },
        overlay: {
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
        },
    };

    const CustomModal = ({ isOpen, closeModal, partners, selectedOrderId }) => {
        const [entity, setEntity] = useState(null);

        const [formData, setFormData] = useState({
            idEntity: null,
            nameEntity: "",
        });

        const handleSelectChangeEntity = (e) => {
            const newValue = e.target.value;
            setEntity(newValue);

            if (newValue && typeof newValue === 'string') {
                const [idEntity, name] = newValue.split(':');

                setFormData({ ...formData, idEntity: idEntity, nameEntity: name });
            }
        }

        const handleFormSubmit = async (e) => {
            e.preventDefault();
            setLoading(true);

            try {
                await axios.patch(`${BASE_URL}/api/orders/${selectedOrderId}`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                setIsOpen(false);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la soumission du formulaire :', error);
                setLoading(false);
            }
        };

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customModalStyles}
                contentLabel="Liste des partenaires"
            >
                <input
                    type="hidden"
                    id="orderId"
                    name="orderId"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={selectedOrderId}
                    onChange={selectedOrderId}
                    readOnly
                />
                <h2 className="text-lg font-semibold mb-4">Liste des partenaires</h2>
                <select
                    id="nameEntity"
                    name="nameEntity"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={entity}
                    onChange={handleSelectChangeEntity}
                >
                    <option value="">Choisissez une entité</option>
                    {Array.isArray(partners) && partners.length > 0 ? (
                        partners.map((partner) => (
                            <option value={`${partner.idEntity}:${partner.name || ''}`}>
                                {partner.name}
                            </option>
                        ))
                    ) : (
                        <option value="">Aucun partenaire trouvé.</option>
                    )}
                </select>
                <button
                    onClick={handleFormSubmit}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-4 rounded-full"
                >
                    Valider
                </button>
            </Modal>
        );
    };

    const handleSelectChange = (e) => {
        const newValue = e.target.value;
        setEditedRegion(newValue);
    }

    const handleSelectChangeOffer = (e) => {
        const newValue = e.target.value;
        setEditedOffer(newValue);
    }

    const [oldCustomerPhotoFileName, setOldCustomerPhotoFileName] = useState("");
    const [oldImageLocalisationFileName, setOldImageLocalisationFileName] = useState("");
    const [oldRectoCniFileName, setOldRectoCniFileName] = useState("");
    const [oldVersoCniFileName, setOldVersoCniFileName] = useState("");
    const [oldIdPassportFrontFileName, setOldIdPassportFrontFileName] = useState("");
    const [oldIdResidencePermitFrontFileName, setOldIdResidencePermitFrontFileName] = useState("");
    const [oldRecotCommerceRegFileName, setOldRectoCommerceRegFileName] = useState("");

    const [displayCustomerPhoto, setDisplayCustomerPhoto] = useState("");
    const [displayImageLocalisation, setDisplayImageLocalisation] = useState("");
    const [displayRectoCni, setDisplayRectoCni] = useState("");
    const [displayVersoCni, setDisplayVersoCni] = useState("");
    const [displayIdPassportFront, setDisplayIdPassportFront] = useState("");
    const [displayIdResidencePermitFront, setDisplayIdResidencePermitFront] = useState("");
    const [displayRecotCommerceReg, setDisplayRectoCommerceReg] = useState("");

    const [editingOrder, setEditingOrder] = useState(null);
    const [editedCustomerPhoto, setEditedCustomerPhoto] = useState("");
    const [editedNameOrSocialName, setEditedNameOrSocialName] = useState("");
    const [editedCustomerType, setEditedCustomerType] = useState("");
    const [editedRegion, setEditedRegion] = useState("");
    const [editedCity, setEditedCity] = useState("");
    const [editedLocalisation, setEditedLocalisation] = useState("");
    const [editedLieuDit, setEditedLieuDit] = useState("");
    const [editedCustomerGpsCoordinates, setEditedCustomerGpsCoordinates] = useState("");
    const [editedImageLocalisation, setEditedImageLocalisation] = useState("");
    const [editedEmail, setEditedEmail] = useState("");
    const [editedMobile, setEditedMobile] = useState("");
    const [editedIdCardNumber, setEditedIdCardNumber] = useState("");
    const [editedIdPassportNumber, setEditedIdPassportNumber] = useState("");
    const [editedIdResidencePermitNumber, setEditedIdResidencePermitNumber] = useState("");
    const [editedRectoCni, setEditedRectoCni] = useState("");
    const [editedVersoCni, setEditedVersoCni] = useState("");
    const [editedIdPassportFront, setEditedIdPassportFront] = useState("");
    const [editedIdResidencePermitFront, setEditedIdResidencePermitFront] = useState("");
    const [editedCommerceRegNumber, setEditedCommerceRegNumber] = useState("");
    const [editedRectoCommerceReg, setEditedRectoCommerceReg] = useState("");
    const [editedOffer, setEditedOffer] = useState("");

    const [isEditVisible, setIsEditVisible] = useState(false);

    const handleEdit = (order) => {
        setEditingOrder(order);
        setEditedCustomerPhoto(order.customerPhoto);
        setOldCustomerPhotoFileName(order.customerPhoto);

        setEditedNameOrSocialName(order.nameOrSocialName);
        setEditedCustomerType(order.customerType);
        setEditedRegion(order.region);
        setEditedCity(order.city);
        setEditedLocalisation(order.localisation);
        setEditedLieuDit(order.lieuDit);
        setEditedCustomerGpsCoordinates(order.customerGpsCoordinates);
        setEditedImageLocalisation(order.imageLocalisation);
        setOldImageLocalisationFileName(order.imageLocalisation);

        setEditedEmail(order.email);
        setEditedMobile(order.mobile);
        setEditedIdCardNumber(order.idCardNumber);
        setEditedIdPassportNumber(order.idPassportNumber);
        setEditedIdResidencePermitNumber(order.idResidencePermitNumber);
        setEditedIdResidencePermitFront(order.idResidencePermitFront);
        setOldIdResidencePermitFrontFileName(order.idResidencePermitFront);

        setEditedRectoCni(order.rectoCni);
        setEditedVersoCni(order.versoCni);
        setOldRectoCniFileName(order.rectoCni);
        setOldVersoCniFileName(order.versoCni);

        setEditedIdPassportFront(order.idPasseportFront);
        setOldIdPassportFrontFileName(order.idPasseportFront);

        setEditedCommerceRegNumber(order.commerceRegNumber);
        setEditedRectoCommerceReg(order.rectoCommerceReg);
        setOldRectoCommerceRegFileName(order.rectoCommerceReg);

        setEditedOffer(order.offerBlueMaxSelected);

        setIsEditVisible(true);
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setDisplayCustomerPhoto(imageUrl);
            setEditedCustomerPhoto(file);
        }
    }

    const handleFileChangeLocalisation = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayImageLocalisation(fileToDisplay);
            setEditedImageLocalisation(file);
        }
    }

    const handleFileChangeRectoCni = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayRectoCni(fileToDisplay);
            setEditedRectoCni(file);
        }
    }

    const handleFileChangeVersoCni = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayVersoCni(fileToDisplay);
            setEditedVersoCni(file);
        }
    }

    const handleFileChangePassport = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayIdPassportFront(fileToDisplay);
            setEditedIdPassportFront(file);
        }
    };

    const handleFileChangeIdResidencePermit = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayIdResidencePermitFront(fileToDisplay);
            setEditedIdResidencePermitFront(file);
        }
    };

    const handleFileChangeReg = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayRectoCommerceReg(fileToDisplay);
            setEditedRectoCommerceReg(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = {
            oldCustomerPhoto: oldCustomerPhotoFileName,
            customerPhoto: editedCustomerPhoto,
            nameOrSocialName: editedNameOrSocialName,
            customerType: editedCustomerType,
            region: editedRegion,
            city: editedCity,
            editedLocalisation: editedLocalisation,
            lieuDit: editedLieuDit,
            customerGpsCoordinates: editedCustomerGpsCoordinates,
            oldPreciseLocationFile: oldImageLocalisationFileName,
            preciseLocationFile: editedImageLocalisation,
            email: editedEmail,
            mobile: editedMobile,
            idCardNumber: editedIdCardNumber,
            idPassportNumber: editedIdPassportNumber,
            idResidencePermitNumber: editedIdResidencePermitNumber,
            oldIdCardFront: oldRectoCniFileName,
            oldIdCardBack: oldVersoCniFileName,
            idCardFront: editedRectoCni,
            idCardBack: editedVersoCni,
            idPasseportFront: editedIdPassportFront,
            oldIdPasseportFront: oldIdPassportFrontFileName,
            idResidencePermitFront: editedIdResidencePermitFront,
            oldIdResidencePermitFront: oldIdResidencePermitFrontFileName,
            regComNumber: editedCommerceRegNumber,
            oldCommerceRegistryFront: oldRecotCommerceRegFileName,
            commerceRegistryFront: editedRectoCommerceReg,
            offerBlueMaxSelected: editedOffer
        }

        if (token) {
            try {
                const response = await axios.patch(`${BASE_URL}/api/orders/superadmin/${editingOrder.idSheet}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setLoading(false);
                alert('Commande mis à jour avec succès.');
                setIsEditVisible(false);
            } catch (error) {
                setLoading(false);
                alert('Erreur lors de la mise à jour de la commande.');
                console.error("Erreur lors de la mise à jour de la commande: ", error);
            }
        }
    }

    useEffect(() => {
        if (token) {
            const fetchOrderDetails = async () => {
                console.log('orderId: ', orderId);

                try {
                    const response = await axios.get(`${BASE_URL}/api/orders/details/${orderId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    const orderData = response.data[orderId];

                    setOrderDetails(orderData.orderData[0]);
                    setSelectedAppointmentOrder(orderData.appointmentData[0]);
                    setSelectedPreStudyOrder(orderData.preStudyOrderData[0]);
                    setSelectedInstallationData(orderData.installationData[0]);
                    setSelectedActivationData(orderData.activationData[0]);
                    setSelectedPaymentData(orderData.paymentData[0]);

                    if (orderData.preStudyOrderData[0].idTechnicienCamtel) {
                        const techCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/${orderData.preStudyOrderData[0].idTechnicienCamtel}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setTechCamtelName(techCamtelProfile.data.profile[0]);
                    }

                    if (orderData.activationData[0]?.idCommercialCamtel) {
                        const comCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/${orderData.activationData[0].idCommercialCamtel}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setComCamtelName(comCamtelProfile.data.profile[0]);
                    }

                    if (orderData.preStudyOrderData[0].idBindedEntity) {
                        const entityResponse = await axios.get(`${BASE_URL}/api/entities/details/${orderData.preStudyOrderData[0].idBindedEntity}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        setDetails(entityResponse.data.entities[0]);
                    }

                    setLoading(false);
                } catch (error) {
                    console.error("Erreur lors de la récupération des détails de la commande", error);
                    setLoading(false);
                }
            };

            fetchOrderDetails();
        }
    }, [token, orderId]);

    const deleteSurvey = (idSheet) => {
        setLoading(true);

        const superAdminId = localStorage.getItem('userId');

        if (token && window.confirm('Êtes-vous sûr de vouloir supprimer le survey de cette commande ?')) {
            axios.delete(`${BASE_URL}/api/prestudy/delete/survey/${idSheet}/${superAdminId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response) {
                        if (response.data) {
                            if (response.data.message) {
                                alert(response.data.message)
                            } else {
                                alert(response.data.error)
                            }
                        }
                    }
                    setLoading(false);
                })
                .catch(error => {
                    alert('Erreur survenue lors de la suppression du survey. Veuillez réessayer s\'il vous plait.');

                    setLoading(false);
                });
        }
    }

    const redoSurvey = (idSheet, customerGpsCoordinates) => {
        setLoading(true);

        if (token) {
            axios.post(`${BASE_URL}/api/orders/redo/survey/${idSheet}`, { customerGpsCoordinates }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    let data = response.data;

                    if (data && data.message) {
                        window.open(`/location/${idSheet}`, '_blank');
                    } else {
                        alert(data.error);
                    }

                    setLoading(false);
                })
                .catch(error => {
                    alert('Erreur survenue lors de la réalisation du nouveau survey. Veuillez réessayer s\'il vous plait.');

                    setLoading(false);
                });
        }
    }

    if (loading) {
        return (
            <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                <img
                    src={require('../../assets/img/icons/gif/loading-icon-animated.jpeg')}
                    alt='Loader'
                    className="mx-auto"
                    style={{ maxWidth: '300px', maxHeight: '300px' }}
                />
            </div>
        );
    }

    return (
        <>
            <Header />
            <Sidebar setHideText={setHideText} hideText={hideText} />
            <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[75%] 2xl:w-[73%]'}`}>
                {orderDetails && !isEditVisible && (
                    <>
                        <div className="flex justify-end">
                            <div className="flex flex-col md:flex-row">
                                <div>
                                    {selectedPaymentData && selectedPaymentData.idPaidOrder && selectedPaymentData.status === 'SUCCESSFULL' && (
                                        <button
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 m-1"
                                            onClick={() => window.open(`/receipt/${selectedPaymentData.payToken}`, '_blank', 'noopener,noreferrer')}
                                        >
                                            Visualiser le reçu de paiement
                                        </button>
                                    )}
                                </div>
                                <div>
                                    <button onClick={() => openModal(orderDetails.idSheet)} className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 m-1">{!orderDetails.name ? 'Assigner à un partenaire' : 'Ré-assigner à un partenaire'}</button>
                                    <CustomModal
                                        isOpen={isOpen}
                                        closeModal={closeModal}
                                        partners={partners}
                                        selectedOrderId={orderDetails.idSheet}
                                    />
                                </div>
                                <button
                                    onClick={() => handleEdit(orderDetails)}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 m-1"
                                >
                                    Modifier
                                </button>
                                {(selectedPreStudyOrder && selectedPreStudyOrder.idPreEtude) && (selectedInstallationData && !selectedInstallationData.idInstallation) && (
                                    <button
                                        onClick={() => deleteSurvey(orderDetails.idSheet)}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 m-1"
                                    >
                                        Supprimer manuellement le survey
                                    </button>
                                )}
                                {(selectedPreStudyOrder && selectedPreStudyOrder.idPreEtude) && (selectedInstallationData && !selectedInstallationData.idInstallation) && (
                                    <button
                                        onClick={() => redoSurvey(orderDetails.idSheet, orderDetails.customerGpsCoordinates)}
                                        className="bg-sky-500 hover:bg-sky-700 text-white font-bold p-2 m-1"
                                    >
                                        Refaire le survey
                                    </button>
                                )}
                                <button
                                    className="close-button bg-yellow-500 hover:bg-yellow-700 p-2 m-1 text-white"
                                    onClick={() => window.close()}
                                >
                                    <span className="close-icon text-white">✖</span> Fermer
                                </button>
                            </div>
                        </div>
                        <div className="mt-4 border p-4">
                            <h2 className="text-3xl font-bold mb-2 uppercase text-center">Détails de la commande</h2>

                            {comPartnerName.nom ? <p><strong>Commercial du partenaire ayant prospecté la commande: </strong> {comPartnerName.nom + ' ' + comPartnerName.prenom}</p> : <p><strong>Client assignée au partenaire par CAMTEL.</strong></p>}
                            {techPartnerName.nom ? <p><strong>Technicien du partenaire en charge de la pré-étude et de l'installation de la commande :</strong> {techPartnerName.nom + ' ' + techPartnerName.prenom}</p> : <p><strong>Commande non assignée à un technicien (Partenaire) pour pré-étude.</strong></p>}
                            {techCamtelName.nom ? <p><strong>Pool Technique CAMTEL en charge de l'étude: </strong> {techCamtelName.nom + ' ' + techCamtelName.prenom}</p> : <p><strong>Étude pas encore validée par le Pool Technique CAMTEL</strong></p>}
                            {comCamtelName.nom ? <p><strong>Pool Commercial CAMTEL en charge de l'activation de cette commande: </strong> {comCamtelName.nom + ' ' + comCamtelName.prenom}</p> : <p><strong>Commande non activée par le Pool Commercial CAMTEL.</strong></p>}

                            <div className="mt-3 mb-3">
                                <p><strong>Date de souscription du client: </strong><FormatDate dateStr={orderDetails.sheetCreatedAt} /></p>
                                <p><strong>Date de RDV d'installation du client: </strong>{selectedAppointmentOrder ? <FormatDate dateStr={selectedAppointmentOrder.appointmentDateTime} /> : 'Installation non programmée par le client.'}</p>
                            </div>

                            {orderDetails.customerPhoto && (
                                <div className="flex justify-between w-full">
                                    <div>
                                        <strong>Photo du client :</strong>
                                        <img
                                            src={orderDetails.customerPhoto}
                                            alt="Photo du client"
                                            className="block w-32 h-auto mt-2 rounded"
                                            style={{ height: 200, width: 200 }}
                                        />
                                    </div>
                                </div>

                            )}
                            <p><strong>N°ID Souscription :</strong> {orderDetails.idSubscription + orderDetails.idSheet}</p>
                            <p><strong>Nom et Prénoms / Raison Sociale :</strong> {orderDetails.nameOrSocialName}</p>
                            <p><strong>Type de Client:</strong> {orderDetails.customerType}</p>
                            <p>
                                <strong>Région: </strong>
                                {
                                    orderDetails.region === 'CE' ? 'Centre' :
                                        orderDetails.region === 'LT' ? 'Littoral' :
                                            orderDetails.region === 'NO' ? 'Nord' :
                                                orderDetails.region === 'EN' ? 'Extrême-Nord' :
                                                    orderDetails.region === 'AD' ? 'Adamaoua' :
                                                        orderDetails.region === 'ES' ? 'Est' :
                                                            orderDetails.region === 'SU' ? 'SUD' :
                                                                orderDetails.region === 'OU' ? 'Ouest' :
                                                                    orderDetails.region === 'NW' ? 'Nord-Ouest' :
                                                                        'Sud-Ouest'
                                }
                            </p>
                            <p><strong>Ville:</strong> {orderDetails.city}</p>
                            <p><strong>Localisation Précise:</strong> {orderDetails.localisation}</p>
                            <p><strong>Lieu-dit:</strong> {orderDetails.lieuDit}</p>
                            {orderDetails.customerGpsCoordinates && (<p><strong>Coordonnées GPS: </strong>{orderDetails.customerGpsCoordinates}</p>)}
                            <p>
                                <strong>Plan de localisation:</strong>
                                {orderDetails.imageLocalisation && orderDetails.imageLocalisation.endsWith('.jpg') || orderDetails.imageLocalisation && orderDetails.imageLocalisation.endsWith('.png') ? (
                                    <img
                                        src={orderDetails.imageLocalisation}
                                        alt="Plan de localisation"
                                        className="block w-32 h-auto mt-2 rounded"
                                        style={{ height: 500, width: '100%' }}
                                    />
                                ) : (
                                    <iframe
                                        src={orderDetails.imageLocalisation}
                                        width="100%"
                                        height="500"
                                        title="PDF Viewer"
                                    ></iframe>
                                )}
                            </p>
                            <p><strong>Email:</strong> {orderDetails.email}</p>
                            <p><strong>N° Mobile:</strong> {orderDetails.mobile}</p>
                            {orderDetails.idCardNumber !== null && (
                                <>
                                    <p><strong>Numéro de la CNI:</strong> {orderDetails.idCardNumber}</p>
                                    <p>
                                        <strong>Carte Nationnale d'identité:</strong>
                                        <div className="flex">
                                            <div className="flex flex-col md:flex-row">
                                                {orderDetails.rectoCni && orderDetails.rectoCni.endsWith('.jpg') || orderDetails.rectoCni && orderDetails.rectoCni.endsWith('.png') ? (
                                                    <img
                                                        src={orderDetails.rectoCni}
                                                        alt="Image Recto de la CNI"
                                                        className="w-full h-auto mt-2 rounded md:w-1/2"
                                                        style={{ height: 250 }}
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={orderDetails.rectoCni}
                                                        className="w-full md:w-1/2"
                                                        height="300"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                )}

                                                {orderDetails.versoCni && orderDetails.versoCni.endsWith('.jpg') || orderDetails.versoCni && orderDetails.versoCni.endsWith('.png') ? (
                                                    <img
                                                        src={orderDetails.versoCni}
                                                        alt="Image Verso de la CNI"
                                                        className="w-full h-auto mt-2 rounded md:w-1/2"
                                                        style={{ height: 250 }}
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={orderDetails.versoCni}
                                                        className="w-full md:w-1/2"
                                                        height="300"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                )}
                                            </div>
                                        </div>
                                    </p>
                                </>
                            )}
                            {orderDetails.idPassportNumber !== null && (
                                <>
                                    <p><strong>Numéro du passeport:</strong> {orderDetails.idPassportNumber}</p>
                                    <p>
                                        <strong>Passeport:</strong>
                                        {orderDetails.idPasseportFront && orderDetails.idPasseportFront.endsWith('.jpg') || orderDetails.idPasseportFront && orderDetails.idPasseportFront.endsWith('.png') ? (
                                            <img
                                                src={orderDetails.idPasseportFront}
                                                alt="Image Recto du passeport"
                                                className="block w-32 h-auto mt-2 rounded"
                                                style={{ height: 250, width: '50%' }}
                                            />
                                        ) : (
                                            <iframe
                                                src={orderDetails.idPasseportFront}
                                                width="100%"
                                                height="300"
                                                title="PDF Viewer"
                                            ></iframe>
                                        )}
                                    </p>
                                </>
                            )}
                            {orderDetails.idResidencePermitNumber && (
                                <>
                                    <p><strong>Numéro dr la carte de séjour:</strong> {orderDetails.idResidencePermitNumber}</p>
                                    <p>
                                        <strong>Carte de séjour:</strong>
                                        <div className="flex">
                                            {orderDetails.idResidencePermitFront && orderDetails.idResidencePermitFront.endsWith('.jpg') || orderDetails.idResidencePermitFront && orderDetails.idResidencePermitFront.endsWith('.png') ? (
                                                <img
                                                    src={orderDetails.idResidencePermitFront}
                                                    alt="Image Recto de la carte de séjour"
                                                    className="block w-32 h-auto mt-2 rounded"
                                                    style={{ height: 250, width: '50%' }}
                                                />
                                            ) : (
                                                <iframe
                                                    src={orderDetails.idResidencePermitFront}
                                                    width="100%"
                                                    height="300"
                                                    title="PDF Viewer"
                                                ></iframe>
                                            )}
                                        </div>
                                    </p>
                                </>
                            )}
                            {orderDetails.commerceRegNumber && (
                                <>
                                    <p><strong>N°Régistre Commerce :</strong> {orderDetails.commerceRegNumber}</p>
                                    <p>
                                        <strong>Image du registre de commerce:</strong>
                                        <div className="flex">
                                            {orderDetails.rectoCommerceReg && orderDetails.rectoCommerceReg.endsWith('.jpg') || orderDetails.rectoCommerceReg && orderDetails.rectoCommerceReg.endsWith('.png') ? (
                                                <img
                                                    src={orderDetails.rectoCommerceReg}
                                                    alt="Image du registre de commerce"
                                                    className="block w-32 h-auto mt-2 rounded"
                                                    style={{ height: 'auto', width: '100%' }}
                                                />
                                            ) : (
                                                <iframe
                                                    src={orderDetails.rectoCommerceReg}
                                                    width="100%"
                                                    height="500"
                                                    title="PDF Viewer"
                                                ></iframe>
                                            )}
                                        </div>
                                    </p>
                                </>
                            )}

                            <h3 className="text-2xl font-bold mt-2">TYPE D’OPERATION</h3>
                            <p><strong>Formule d’abonnement choisie:</strong> {orderDetails.offerBlueMaxSelected}</p>
                        </div>
                    </>
                )}

                {selectedPreStudyOrder && !isEditVisible && (
                    <>
                        <div className="mt-4 border p-4">
                            <h3 className="text-3xl font-bold mb-2 text-center">PRE-ETUDE PRESTATAIRE</h3>
                            <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedPreStudyOrder.preEtudeCreatedAt} /></p>
                            <p><strong>Nom du prestataire :</strong> {selectedPreStudyOrder.name}</p>
                            <p><strong>Tel :</strong> {selectedPreStudyOrder.telephoneEntity}</p>
                            <p><strong>Email :</strong> {selectedPreStudyOrder.emailEntity}</p>
                            {
                                details && (
                                    <>
                                        <p><strong>Zone d’intervention :</strong> {details.interventionZone}</p>
                                        <p><strong>Agence Commerciale :</strong> {details.name}</p>
                                    </>
                                )
                            }
                            <p><strong>Référence du FDT :</strong> {selectedPreStudyOrder.fdtRef}</p>
                            <p><strong>Référence du FAT :</strong> {selectedPreStudyOrder.fatRef}</p>
                            <p><strong>Distance FAT-Client (m) :</strong> {selectedPreStudyOrder.dFatCustomer}</p>
                            <p><strong>Nombre de poteau à installer :</strong> {selectedPreStudyOrder.nbPoToInstall}</p>
                        </div>
                        <div className="mt-4 border p-4">
                            <h3 className="text-3xl font-bold mb-2 text-center">RESULTAT ET OBSERVATIONS DE LA PRE-ETUDE</h3>
                            <p><strong>Date de la réalisation de la pré-étude: </strong><FormatDate dateStr={selectedPreStudyOrder.preEtudeCreatedAt} /></p>
                            <p><strong>Statut de la validation par le technicien du prestataire:</strong> {selectedPreStudyOrder.result}</p>
                            <p><strong>Observations fait par le technicien du prestataire:</strong> {selectedPreStudyOrder.preEtudeObs}</p>
                        </div>
                        {
                            (selectedPreStudyOrder.validation !== '' || selectedPreStudyOrder.etudeObs !== '') && (
                                <div className="mt-4 border p-4">
                                    <h3 className="text-3xl font-bold mb-2 mt-2 text-center">VALIDATION ET OBSERVATIONS DE L’ETUDE</h3>
                                    <p><strong>Date de validation de la pré-étude: </strong><FormatDate dateStr={selectedPreStudyOrder.preEtudeCreatedAt} /></p>
                                    {selectedPreStudyOrder.validation !== '' && (
                                        <p><strong>Statut de la validation par le technicien de CAMTEL:</strong> {selectedPreStudyOrder.validation}</p>
                                    )}
                                    {selectedPreStudyOrder.etudeObs !== '' && (
                                        <p><strong>Observations de l'étude du technicien de CAMTEL:</strong> {selectedPreStudyOrder.etudeObs}</p>
                                    )}
                                </div>
                            )
                        }
                    </>
                )}

                {selectedActivationData && selectedActivationData.crmCustomerCode !== null && selectedActivationData.crmCustomerCode !== '' && !isEditVisible && (
                    <div className="mt-4 border p-4">
                        <h3 className="text-2xl font-bold mt-2 mb-2 text-center ">ACTIVATION</h3>
                        <p><strong>Date de l'activation: </strong><FormatDate dateStr={selectedActivationData.activationCreatedAt} /></p>
                        <p><strong>ID de la commande (CBS) :</strong> {selectedActivationData.cbsOrderId}</p>
                        <p><strong>Code Client CRM :</strong> {selectedActivationData.crmCustomerCode}</p>
                        <p><strong>Compte de facturation :</strong> {selectedActivationData.billingAccount}</p>
                        <p><strong>Numéro de Telephone :</strong> {selectedActivationData.phoneNumberActivated}</p>
                        <p><strong>Numéro ONT :</strong> {selectedActivationData.ontNumber}</p>
                        <p><strong>Référence paiement :</strong> {selectedActivationData.paymentRef}</p>
                        <p><strong>Date de paiement :</strong><FormatDate dateStr={selectedActivationData.paymentDate} /></p>
                        <p><strong>Numéro STB/IP Stick :</strong> {selectedActivationData.stbIpStickNumber}</p>
                    </div>
                )}

                {selectedInstallationData && selectedInstallationData.idInstallation !== null && !isEditVisible && (
                    <>
                        {selectedInstallationData.idTechPartner !== null && (
                            <div className="mt-4 border p-4">
                                <h3 className="text-3xl font-bold mb-2 mt-2 text-center">INSTALLATION</h3>
                                <p><strong>Date de l'installation: </strong><FormatDate dateStr={selectedInstallationData.installationCreatedAt} /></p>
                                <p><strong>Nombre de Balancelle :</strong> {selectedInstallationData.nbBalancel}</p>
                                <p><strong>Métrage câble entrée FAT :</strong> {selectedInstallationData.cableMeterEntryFat}</p>
                                <p><strong>Longueur Câble utilisée (m) :</strong> {selectedInstallationData.usedCableLong}</p>
                                <p><strong>Nombre d’ATB :</strong> {selectedInstallationData.atbNumber}</p>
                                <p><strong>Nombre Crochet d’Arrêt :</strong> {selectedInstallationData.nbCrochetArret}</p>
                                <p><strong>Métrage câble chez l’abonné :</strong> {selectedInstallationData.clientCableMeter}</p>
                                <p><strong>Nombre de poteau armé installé :</strong> {selectedInstallationData.nbArmedInstalledPoto}</p>
                                <p><strong>Numéro ONT :</strong> {selectedInstallationData.ontNumber}</p>
                                <p><strong>Nombre de STB/ Stick :</strong> {selectedInstallationData.stbStickNumber}</p>
                                <p><strong>Nombre de TB :</strong> {selectedInstallationData.tbNumber}</p>
                                <p><strong>Nombre de Jarretière :</strong> {selectedInstallationData.nbGarter}</p>
                                <p><strong>Nombre de Smoove :</strong> {selectedInstallationData.nbSmoove}</p>
                            </div>
                        )}
                    </>
                )}

                {editingOrder && isEditVisible && (
                    <div className="mt-4 border p-4">
                        <div className="flex justify-end">
                            <div>
                                <button
                                    className="close-button bg-yellow-500 hover:bg-yellow-700 p-2 text-white"
                                    onClick={() => setIsEditVisible(false)}
                                >
                                    <span className="close-icon text-white">✖</span> Fermer
                                </button>
                            </div>
                        </div>

                        <h2 className="text-4xl font-bold mb-4 mt-4 text-center">Modifier la commande</h2>
                        <form enctype="multipart/form-data">
                            <div className="mb-2">
                                <div>
                                    <strong>Photo du client :</strong>
                                    {displayCustomerPhoto ? (
                                        <img
                                            src={displayCustomerPhoto}
                                            alt="Photo du client"
                                            className="block w-32 h-auto mt-2 rounded"
                                            style={{ height: 200, width: 200 }}
                                        />
                                    ) : (
                                        <img
                                            src={editedCustomerPhoto}
                                            alt="Photo du client"
                                            className="block w-32 h-auto mt-2 rounded"
                                            style={{ height: 200, width: 200 }}
                                        />
                                    )}
                                </div>
                                <input
                                    type="file"
                                    accept="image/jpeg, image/jpg, image/png"
                                    id="customerPhoto"
                                    name="customerPhoto"
                                    onChange={handleFileChange}
                                    className="rounded w-full py-2 px-3"
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="editedNameOrSocialName" className="block text-gray-700 font-bold">
                                    Nom et Prénoms / Raison Sociale
                                </label>
                                <input
                                    type="text"
                                    id="editedNameOrSocialName"
                                    name="editedNameOrSocialName"
                                    value={editedNameOrSocialName}
                                    onChange={(e) => setEditedNameOrSocialName(e.target.value)}
                                    className="border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div>
                                <div className="mb-2">
                                    <label htmlFor="editedCustomerType" className="block text-gray-700 font-bold">
                                        Type de Client
                                    </label>
                                    <select
                                        id="editedCustomerType"
                                        name="editedCustomerType"
                                        className="border rounded w-full py-2 px-3"
                                        value={editedCustomerType}
                                        onChange={(e) => { setEditedCustomerType(e.target.value); }}
                                    >
                                        <option value="">Selectionnez le type de client</option>
                                        <option value="Particulier">Particulier</option>
                                        <option value="Entreprise">Entreprise</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div>
                                    <label htmlFor="editedRegion" className="block text-gray-700 font-bold">
                                        Région
                                    </label>
                                </div>
                                <select
                                    id="region"
                                    name="region"
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    value={editedRegion}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Choisissez la region</option>
                                    <option value="EN">Extrême-Nord</option>
                                    <option value="NO">Nord</option>
                                    <option value="AD">Adamaoua</option>
                                    <option value="CE">Centre</option>
                                    <option value="LT">Littoral</option>
                                    <option value="SU">Sud</option>
                                    <option value="NW">Nord-Ouest</option>
                                    <option value="SW">Sud-Ouest</option>
                                    <option value="ES">Est</option>
                                </select>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="editedCity" className="block text-gray-700 font-bold">
                                    Ville
                                </label>
                                <input
                                    type="email"
                                    id="editedCity"
                                    name="editedCity"
                                    value={editedCity}
                                    onChange={(e) => setEditedCity(e.target.value)}
                                    className="border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="editedLocalisation" className="block text-gray-700 font-bold">
                                    Localisation Précise
                                </label>
                                <input
                                    type="text"
                                    id="editedLocalisation"
                                    name="editedLocalisation"
                                    value={editedLocalisation}
                                    onChange={(e) => setEditedLocalisation(e.target.value)}
                                    className="border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="editedLieuDit" className="block text-gray-700 font-bold">
                                    Lieu-dit
                                </label>
                                <input
                                    type="text"
                                    id="editedLieuDit"
                                    name="editedLieuDit"
                                    value={editedLieuDit}
                                    onChange={(e) => setEditedLieuDit(e.target.value)}
                                    className="border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="editedCustomerGpsCoordinates" className="block text-gray-700 font-bold">
                                    Coordonnées GPS
                                </label>
                                <input
                                    type="text"
                                    id="editedCustomerGpsCoordinates"
                                    name="editedCustomerGpsCoordinates"
                                    value={editedCustomerGpsCoordinates}
                                    onChange={(e) => setEditedCustomerGpsCoordinates(e.target.value)}
                                    className="border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="editedImageLocalisation" className="block text-gray-700 font-bold">
                                    Plan de localisation
                                </label>
                                <div className="mb-2">
                                    <div>
                                        {displayImageLocalisation ? (
                                            <img
                                                src={displayImageLocalisation}
                                                alt="Plan de localisation"
                                                className="block w-32 h-auto mt-2 rounded"
                                                style={{ height: 500, width: '100%' }}
                                            />
                                        ) : (
                                            <>
                                                {editedImageLocalisation && editedImageLocalisation.endsWith('.jpg') || editedImageLocalisation && editedImageLocalisation.endsWith('.png') ? (
                                                    <img
                                                        src={editedImageLocalisation}
                                                        alt="Plan de localisation"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 500, width: '100%' }}
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={editedImageLocalisation}
                                                        width="100%"
                                                        height="500"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="flex m-2">
                                        <strong className="whitespace-nowrap my-2">Modifier le plan de localisation:</strong>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/jpg, image/png, application/pdf"
                                            id="preciseLocationFile"
                                            name="preciseLocationFile"
                                            onChange={handleFileChangeLocalisation}
                                            className="rounded w-full py-2 px-3"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="editedEmail" className="block text-gray-700 font-bold">
                                    Email
                                </label>
                                <input
                                    type="text"
                                    id="editedEmail"
                                    name="editedEmail"
                                    value={editedEmail}
                                    onChange={(e) => setEditedEmail(e.target.value)}
                                    className="border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="editedMobile" className="block text-gray-700 font-bold">
                                    N° Mobile
                                </label>
                                <input
                                    type="text"
                                    id="editedMobile"
                                    name="editedMobile"
                                    value={editedMobile}
                                    onChange={(e) => setEditedMobile(e.target.value)}
                                    className="border rounded w-full py-2 px-3"
                                />
                            </div>
                            {editedIdCardNumber && (
                                <>
                                    <div className="mb-2">
                                        <label htmlFor="editedIdCardNumber" className="block text-gray-700 font-bold">
                                            Numéro de la CNI
                                        </label>
                                        <input
                                            type="text"
                                            id="editedIdCardNumber"
                                            name="editedIdCardNumber"
                                            value={editedIdCardNumber}
                                            onChange={(e) => setEditedIdCardNumber(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="cni" className="block text-gray-700 font-bold">
                                            Carte Nationnale d'identité (Recto & Verso)
                                        </label>
                                        <div className="flex">
                                            {displayRectoCni ? (
                                                <img
                                                    src={displayRectoCni}
                                                    alt="Image Recto de la CNI"
                                                    className="block w-32 h-auto mt-2 rounded"
                                                    style={{ height: 250, width: '50%' }}
                                                />
                                            ) : (
                                                <>
                                                    {editedRectoCni && editedRectoCni.endsWith('.jpg') || editedRectoCni && editedRectoCni.endsWith('.png') ? (
                                                        <img
                                                            src={editedRectoCni}
                                                            alt="Image Recto de la CNI"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={editedRectoCni}
                                                            width="50%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </>
                                            )}

                                            {displayVersoCni ? (
                                                <img
                                                    src={displayVersoCni}
                                                    alt="Image Verso de la CNI"
                                                    className="block w-32 h-auto mt-2 rounded"
                                                    style={{ height: 250, width: '50%' }}
                                                />
                                            ) : (
                                                <>
                                                    {editedVersoCni && editedVersoCni.endsWith('.jpg') || editedVersoCni && editedVersoCni.endsWith('.png') ? (
                                                        <img
                                                            src={editedVersoCni}
                                                            alt="Image Verso de la CNI"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={editedVersoCni}
                                                            width="50%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <strong className="whitespace-nowrap my-2">Modifier le recto du CNI:</strong>
                                        <input
                                            type="file"
                                            accept=".jpg, .jpeg, .png, .pdf"
                                            id="idCardFront"
                                            name="idCardFront"
                                            onChange={handleFileChangeRectoCni}
                                            className="rounded w-full py-2 px-3"
                                        />
                                    </div>

                                    <div className="flex">
                                        <strong className="whitespace-nowrap my-2">Modifier le verso du CNI:</strong>
                                        <input
                                            type="file"
                                            accept=".jpg, .jpeg, .png, .pdf"
                                            id="idCardBack"
                                            name="idCardBack"
                                            onChange={handleFileChangeVersoCni}
                                            className="rounded w-full py-2 px-3"
                                        />
                                    </div>
                                </>
                            )}
                            {editedIdPassportNumber && (
                                <>
                                    <div className="mb-2">
                                        <label htmlFor="editedIdPassportNumber" className="block text-gray-700 font-bold">
                                            Numéro du passeport
                                        </label>
                                        <input
                                            type="text"
                                            id="editedIdPassportNumber"
                                            name="editedIdPassportNumber"
                                            value={editedIdPassportNumber}
                                            onChange={(e) => setEditedIdPassportNumber(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                        <p className="mt-2 bold">Passeport:</p>
                                        {displayIdPassportFront ? (
                                            <img
                                                src={displayIdPassportFront}
                                                alt="Image Recto du passeport"
                                                className="block w-32 h-auto mt-2 rounded"
                                                style={{ height: 250, width: '50%' }}
                                            />
                                        ) : (
                                            <>
                                                {editedIdPassportFront && editedIdPassportFront.endsWith('.jpg') || editedIdPassportFront && editedIdPassportFront.endsWith('.png') ? (
                                                    <img
                                                        src={editedIdPassportFront}
                                                        alt="Image Recto du passeport"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 250, width: '50%' }}
                                                    />
                                                ) : (
                                                    <iframe
                                                        src={editedIdPassportFront}
                                                        width="100%"
                                                        height="300"
                                                        title="PDF Viewer"
                                                    ></iframe>
                                                )}
                                            </>
                                        )}
                                        <div className="flex m-2">
                                            <strong className="whitespace-nowrap my-2">Modifier le passeport :</strong>
                                            <input
                                                type="file"
                                                accept="image/jpeg, image/jpg, image/png"
                                                id="idPasseportFront"
                                                name="idPasseportFront"
                                                onChange={handleFileChangePassport}
                                                className="rounded w-full py-2 px-3"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {editedIdResidencePermitNumber && (
                                <>
                                    <div className="mb-2">
                                        <label htmlFor="editedIdResidencePermitNumber" className="block text-gray-700 font-bold">
                                            Numéro de la carte de séjour
                                        </label>
                                        <input
                                            type="text"
                                            id="editedIdResidencePermitNumber"
                                            name="editedIdResidencePermitNumber"
                                            value={editedIdResidencePermitNumber}
                                            onChange={(e) => setEditedIdResidencePermitNumber(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div>
                                        <strong>Carte de séjour:</strong>
                                        <div className="flex">
                                            {displayIdResidencePermitFront ? (
                                                <img
                                                    src={displayIdResidencePermitFront}
                                                    alt="Image Recto de la carte de séjour"
                                                    className="block w-32 h-auto mt-2 rounded"
                                                    style={{ height: 250, width: '50%' }}
                                                />
                                            ) : (
                                                <>
                                                    {editedIdResidencePermitFront && editedIdResidencePermitFront.endsWith('.jpg') || editedIdResidencePermitFront && editedIdResidencePermitFront.endsWith('.png') ? (
                                                        <img
                                                            src={editedIdResidencePermitFront}
                                                            alt="Image Recto de la carte de séjour"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={editedIdResidencePermitFront}
                                                            width="100%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="flex m-2">
                                            <strong className="whitespace-nowrap my-2">Modifier la carte de séjour :</strong>
                                            <input
                                                type="file"
                                                accept="image/jpeg, image/jpg, image/png"
                                                id="idResidencePermitFront"
                                                name="idResidencePermitFront"
                                                onChange={handleFileChangeIdResidencePermit}
                                                className="rounded w-full py-2 px-3"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {editedCommerceRegNumber && (
                                <>
                                    <div className="mb-2">
                                        <label htmlFor="editedCommerceRegNumber" className="block text-gray-700 font-bold">
                                            N° Régistre Commerce
                                        </label>
                                        <input
                                            type="text"
                                            id="editedCommerceRegNumber"
                                            name="editedCommerceRegNumber"
                                            value={editedCommerceRegNumber}
                                            onChange={(e) => setEditedCommerceRegNumber(e.target.value)}
                                            className="border rounded w-full py-2 px-3"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="editedRectoCommerceReg" className="block text-gray-700 font-bold">
                                            Image du registre de commerce
                                        </label>
                                        <div className="flex">
                                            {displayRecotCommerceReg ? (
                                                <img
                                                    src={displayRecotCommerceReg}
                                                    alt="Image du registre de commerce"
                                                    className="block w-32 h-auto mt-2 rounded"
                                                    style={{ height: 'auto', width: '100%' }}
                                                />
                                            ) : (
                                                <>
                                                    {editedRectoCommerceReg && editedRectoCommerceReg.endsWith('.jpg') || editedRectoCommerceReg && editedRectoCommerceReg.endsWith('.png') ? (
                                                        <img
                                                            src={editedRectoCommerceReg}
                                                            alt="Image du registre de commerce"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 'auto', width: '100%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={editedRectoCommerceReg}
                                                            width="100%"
                                                            height="500"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex m-2">
                                        <strong className="whitespace-nowrap my-2">Modifier l'image du registre de commerce :</strong>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/jpg, image/png"
                                            id="commerceRegistryFront"
                                            name="commerceRegistryFront"
                                            onChange={handleFileChangeReg}
                                            className="rounded w-full py-2 px-3"
                                        />
                                    </div>
                                </>
                            )}

                            <div className="mb-2">
                                <label htmlFor="editedOffer" className="block text-gray-700 font-bold mt-2">
                                    Formule d’abonnement choisie
                                </label>

                                <select
                                    id="editedOffer"
                                    name="editedOffer"
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    value={editedOffer}
                                    onChange={handleSelectChangeOffer}
                                >
                                    <option value="">Sélectionnez une offre pour modifier</option>
                                    <option value="Blue Max S">Blue Max S</option>
                                    <option value="Blue Max M">Blue Max M</option>
                                    <option value="Blue Max L">Blue Max L</option>
                                    <option value="Blue Max XL">Blue Max XL</option>
                                </select>
                            </div>

                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                            >
                                Enregistrer
                            </button>
                        </form>
                    </div>
                )}
            </div >
        </>
    );
};

export default OrderDetails;