import React, { useState } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';

import pdf1 from '../../assets/pdfs/CRM_Tutoriel_SurveyAvecCoordoneesGPS_v02_20240805.pdf';
import pdf2 from '../../assets/pdfs/FiberConnect_2_0_Tutoriel_PFComm_fr_v01_20240809.pdf';
import pdf3 from '../../assets/pdfs/FiberConnect_2_0_Tutoriel_PFTech_fr_v01_20240816.pdf';
import pdf4 from '../../assets/pdfs/FiberConnect_2_0_Tutoriel_TechInstall_fr_v01_20240818.pdf';

const pdfs = [
    { id: 1, title: 'Réalisation d\'un Survey dans l\'OSS en utilisant des coordonées GPS par le Point Focal Commercial FIBERCONNECT', path: pdf1 },
    { id: 2, title: 'Guide d\'utilisation du Point Focal Commercial dans FIBERCONNECT', path: pdf2 },    
    { id: 3, title: 'Guide d\'utilisation du Point Focal Technique dans FIBERCONNECT', path: pdf3 },
    { id: 4, title: 'Guide d\'utilisation du Technicien Installateur dans FIBERCONNECT', path: pdf4 },
];

const Help = () => {
    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    const handlePdfClick = (pdfPath) => {
        window.open(pdfPath, '_blank');
    };

    return (
        <>
            <Header />
            <Sidebar setHideText={setHideText} hideText={hideText} />

            <div className={`container mx-auto ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                <div className="min-h-screen bg-gray-100 p-6">
                    <header className="bg-sky-500 text-white py-4 px-6 rounded-lg shadow-md">
                        <h1 className="text-3xl font-bold">Aide et Documentation</h1>
                    </header>

                    <main className="mt-8 max-w-4xl mx-auto">
                        <div className="bg-white shadow-lg rounded-lg p-6">
                            <h2 className="text-2xl font-semibold mb-4">Documents PDF</h2>
                            <ul className="space-y-4">
                                {pdfs.map((pdf) => (
                                    <li key={pdf.id} className="border border-gray-300 rounded-lg shadow-sm">
                                        <button
                                            onClick={() => handlePdfClick(pdf.path)}
                                            className="block p-4 w-full text-left hover:bg-gray-50"
                                        >
                                            <h3 className="text-xl font-medium text-blue-600">{pdf.title}</h3>
                                            <p className="text-gray-500">Cliquez pour ouvrir</p>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default Help;
