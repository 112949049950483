import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../apiConfig";

const Login = () => {
    const navigate = useNavigate();

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        role: ""
    });

    const [errorMessage, setErrorMessage] = useState("");

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));

        if (!token) {
            navigate('/login');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.password === 'camtel123') {
            navigate('/forgot');
        } else {
            setLoading(true);
            try {
                const response = await axios.post(`${BASE_URL}/api/user/login`, formData);

                setLoading(false);

                const userId = response.data.userId;
                const profileId = response.data.profileId;
                const userEntity = response.data.userEntity;
                const userRole = response.data.userRole;
                const email = response.data.email;
                const region = response.data.region;

                localStorage.setItem('userId', userId);
                localStorage.setItem('profileId', profileId);
                localStorage.setItem('userEntity', userEntity);
                localStorage.setItem('userRole', userRole);
                localStorage.setItem('email', email);
                localStorage.setItem('region', region);

                const res = response.data;
                localStorage.setItem('userInfos', res);

                const token = response.data.token;
                localStorage.setItem('token', JSON.stringify(token));

                setIsUserLoggedIn(true);

                if (response.data && response.data.messageProfil) {
                    setErrorMessage(response.data.messageProfil);
                } else if (response.data && response.data.messageVisible) {
                    setErrorMessage(response.data.messageVisible);
                } else if (response.data && response.data.message) {
                    setErrorMessage(response.data.message);
                }
                else {                    
                    navigate('/dashboard');
                }
            } catch (error) {
                setLoading(false);
                console.log("Erreur de connexion :", error);
                setErrorMessage("Email ou mot de passe incorrect !!!");
            }
        }
    };

    const MySelect = () => {
        const [selectedOption, setSelectedOption] = useState(formData.role || "");

        const handleSelectChange = (e) => {
            const newValue = e.target.value;
            setSelectedOption(newValue);
            setFormData({ ...formData, role: newValue });
        };

        return (
            <div className="my-2">
                <select
                    id="role"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    required
                >
                    <option value="">Connectez-vous en tant que...</option>
                    <option value="super_admin">Super Admin</option>
                    <option value="admin_camtel">Chef d'Agence - CAMTEL</option>
                    <option value="admin_partner">Partenaire (Admin Partenaire)</option>
                    <option value="technicien_camtel">Point Focal Technique (CAMTEL)</option>
                    <option value="commercial_camtel">Point Focal Commercial (CAMTEL)</option>
                    <option value="technicien_partner">Technicien (Partenaire)</option>
                    <option value="commercial_partner">Commercial (Partenaire)</option>
                    <option value="commercial_manager">Manager Commercial</option>
                    <option value="infra_admin">Infra-Admin</option>
                </select>
            </div>
        );
    };

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../assets/img/icons/gif/loading-points.webp')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <main className="w-full flex">
                        <div
                            className="relative flex-1 hidden items-center justify-center h-screen bg-gray-900 lg:flex bg-contain bg-no-repeat bg-center"
                            style={{ backgroundImage: `url(${require('../assets/img/bg/animiertes-gif-von-online-umwandeln-de.gif')})` }}
                        ></div>
                        <div className="flex-1 flex items-center justify-center h-screen">
                            <div className="w-full max-w-md space-y-3 px-4 bg-white text-gray-600 sm:px-0">
                                <div className="text-center">
                                    <img src={require('../assets/img/icons/favicon/favicon-camtel.png')} width={220} className="mx-auto" />
                                    <div className="space-y-2">
                                        <h3 className="text-gray-800 text-2xl font-bold sm:text-4xl text-center">Connectez-vous pour commencer !</h3>
                                    </div>
                                </div>
                                <form
                                    onSubmit={handleSubmit}
                                    className="space-y-3"
                                >
                                    <div>
                                        <label className="font-medium" htmlFor="role">
                                            Type de compte
                                        </label>
                                        <MySelect />
                                    </div>
                                    <div>
                                        <label className="font-medium">
                                            E-mail
                                        </label>
                                        <input
                                            type="email"
                                            value={formData.email}
                                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                            required
                                            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                                        />
                                    </div>
                                    <div>
                                        <div className="relative">
                                            <label className="font-medium">Mot de passe</label>
                                            <div className="flex items-center mt-2">
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    value={formData.password}
                                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                                    required
                                                    className="w-full px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg pr-12"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    className="absolute right-0 top-0 mt-10 mr-3 focus:outline-none"
                                                >
                                                    {showPassword ? (
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-eye-off"><path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"/><path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"/><path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"/><line x1="2" x2="22" y1="2" y2="22"/></svg>
                                                    ) : (                                                        
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-eye"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"/><circle cx="12" cy="12" r="3"/></svg>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        className="w-full px-4 py-2 text-white font-medium bg-sky-500 hover:bg-sky-600 active:bg-sky-600 rounded-lg duration-150"
                                    >
                                        Se connecter
                                    </button>
                                    <p className="text-center text-gray-600 text-sm mt-4">
                                        Mot de passe oublié ?
                                        <a
                                            href="/forgot"
                                            className="text-sky-500 hover:underline ml-1"
                                        >
                                            Cliquez ici
                                        </a>
                                    </p>

                                    {errorMessage && (
                                        <div className="text-red-500 text-center">{errorMessage}</div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </main>
                )
            }
        </>
    )
}

export default Login;
