// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-container {
    height: 80vh;
    width: 95vw;
}

@media (max-width: 640px) {
    .leaflet-container {
        height: 50vh;
        width: 90vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/location.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":[".leaflet-container {\n    height: 80vh;\n    width: 95vw;\n}\n\n@media (max-width: 640px) {\n    .leaflet-container {\n        height: 50vh;\n        width: 90vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
