import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../admin/components/Sidebar";
import Header from "../admin/components/Header";
import { BASE_URL } from "../../apiConfig";
import FormatDate from "../admin/components/FormatDate";
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import '../../style.css';

const SuperOrder = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [allSheetData, setAllSheetData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);

    const [selectedAppointmentOrder, setSelectedAppointmentOrder] = useState(null);
    const [selectedPreStudyOrder, setSelectedPreStudyOrder] = useState(null);
    const [selectedInstalationData, setSelectedInstallationData] = useState(null);
    const [selectedActivationData, setSelectedActivationData] = useState(null);
    const [selectedPaymentData, setSelectedPaymentData] = useState(null);


    const [partners, setPartners] = useState([]);

    const [details, setDetails] = useState(null);

    const [oldCustomerPhotoFileName, setOldCustomerPhotoFileName] = useState("");
    const [oldImageLocalisationFileName, setOldImageLocalisationFileName] = useState("");
    const [oldRectoCniFileName, setOldRectoCniFileName] = useState("");
    const [oldVersoCniFileName, setOldVersoCniFileName] = useState("");
    const [oldIdPassportFrontFileName, setOldIdPassportFrontFileName] = useState("");
    const [oldIdResidencePermitFrontFileName, setOldIdResidencePermitFrontFileName] = useState("");
    const [oldRecotCommerceRegFileName, setOldRectoCommerceRegFileName] = useState("");

    const [displayCustomerPhoto, setDisplayCustomerPhoto] = useState("");
    const [displayImageLocalisation, setDisplayImageLocalisation] = useState("");
    const [displayRectoCni, setDisplayRectoCni] = useState("");
    const [displayVersoCni, setDisplayVersoCni] = useState("");
    const [displayIdPassportFront, setDisplayIdPassportFront] = useState("");
    const [displayIdResidencePermitFront, setDisplayIdResidencePermitFront] = useState("");
    const [displayRecotCommerceReg, setDisplayRectoCommerceReg] = useState("");

    const [editingOrder, setEditingOrder] = useState(null);
    const [editedCustomerPhoto, setEditedCustomerPhoto] = useState("");
    const [editedNameOrSocialName, setEditedNameOrSocialName] = useState("");
    const [editedCustomerType, setEditedCustomerType] = useState("");
    const [editedRegion, setEditedRegion] = useState("");
    const [editedCity, setEditedCity] = useState("");
    const [editedLocalisation, setEditedLocalisation] = useState("");
    const [editedLieuDit, setEditedLieuDit] = useState("");
    const [editedCustomerGpsCoordinates, setEditedCustomerGpsCoordinates] = useState("");
    const [editedImageLocalisation, setEditedImageLocalisation] = useState("");
    const [editedEmail, setEditedEmail] = useState("");
    const [editedMobile, setEditedMobile] = useState("");
    const [editedIdCardNumber, setEditedIdCardNumber] = useState("");
    const [editedIdPassportNumber, setEditedIdPassportNumber] = useState("");
    const [editedIdResidencePermitNumber, setEditedIdResidencePermitNumber] = useState("");
    const [editedRectoCni, setEditedRectoCni] = useState("");
    const [editedVersoCni, setEditedVersoCni] = useState("");
    const [editedIdPassportFront, setEditedIdPassportFront] = useState("");
    const [editedIdResidencePermitFront, setEditedIdResidencePermitFront] = useState("");
    const [editedCommerceRegNumber, setEditedCommerceRegNumber] = useState("");
    const [editedRectoCommerceReg, setEditedRectoCommerceReg] = useState("");
    const [editedOffer, setEditedOffer] = useState("");

    const [isDetailsVisible, setIsDetailsVisible] = useState(true);
    const [isEditVisible, setIsEditVisible] = useState(false);

    const [comPartnerName, setComPartnerName] = useState([]);
    const [techPartnerName, setTechPartnerName] = useState([]);
    const [techCamtelName, setTechCamtelName] = useState([]);
    const [comCamtelName, setComCamtelName] = useState([]);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const [loading, setLoading] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // Get Only Partners name
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'super_admin') {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/entities/superadmin/partners/names/`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setPartners(response.data.entities);
                } catch (error) {
                    console.error("Erreur lors de la récupération des partenaires :", error);
                }
            };

            fetchData();
        }
    }, [token]);

    // SUPER ADMIN CAMTEL
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'super_admin') {
            const fetchOrders = async () => {
                setLoading(true);

                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/superadmin/`,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const allSheetData = Object.values(response.data);
                            setFilteredData(allSheetData);
                            setAllSheetData(allSheetData);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [isOpen, selectedOrder, token]);

    useEffect(() => {
        filterData();
    }, [searchTerm, selectedStatus]);

    const searchCustomer = (e) => {
        setSearchTerm(e.target.value);
    };

    // const handleStatusChange = (e) => {
    //     setSelectedStatus(e.target.value);
    // };

    const filterData = () => {
        let filtered = allSheetData;

        if (searchTerm) {
            filtered = filtered.filter(sheet =>
                sheet.preStudyOrder.some(order =>
                    order.nameOrSocialName?.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
        // const filtered = allSheetData.filter(sheet => {
        //     const hasInstallationData = sheet.installationData && sheet.installationData.length > 0;

        //     return sheet.installationData.some(order => {
        //         // Filtre par nom ou raison sociale (recherché) et statut sélectionné
        //         // const matchesSearchTerm = order.nameOrSocialName?.toLowerCase().includes(searchTerm.toLowerCase());
        //         const matchesStatus = (
        //             (order.result === selectedStatus) || (!hasInstallationData && selectedStatus === "Pré-étude non réalisée")
        //         );

        //         // return matchesSearchTerm && matchesStatus;
        //         return  matchesStatus;
        //     });
        // });
        setFilteredData(filtered);
    };

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            border: '1px solid #ccc',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            padding: '20px',
            borderRadius: '8px',
        },
        overlay: {
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
        },
    };

    const CustomModal = ({ isOpen, closeModal, partners, selectedOrderId }) => {
        const [entity, setEntity] = useState(null);

        const [formData, setFormData] = useState({
            idEntity: null,
            nameEntity: "",
        });

        const handleSelectChangeEntity = (e) => {
            const newValue = e.target.value;
            setEntity(newValue);

            if (newValue && typeof newValue === 'string') {
                const [idEntity, name] = newValue.split(':');

                setFormData({ ...formData, idEntity: idEntity, nameEntity: name });
            }
        }

        const handleFormSubmit = async (e) => {
            e.preventDefault();
            setLoading(true);

            try {
                await axios.patch(`${BASE_URL}/api/orders/${selectedOrderId}`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                setIsOpen(false);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la soumission du formulaire :', error);
                setLoading(false);
            }
        };

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customModalStyles}
                contentLabel="Liste des partenaires"
            >
                <input
                    type="hidden"
                    id="orderId"
                    name="orderId"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={selectedOrderId}
                    onChange={selectedOrderId}
                    readOnly
                />
                <h2 className="text-lg font-semibold mb-4">Liste des partenaires</h2>
                <select
                    id="nameEntity"
                    name="nameEntity"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={entity}
                    onChange={handleSelectChangeEntity}
                >
                    <option value="">Choisissez une entité</option>
                    {Array.isArray(partners) && partners.length > 0 ? (
                        partners.map((partner) => (
                            <option value={`${partner.idEntity}:${partner.name || ''}`}>
                                {partner.name}
                            </option>
                        ))
                    ) : (
                        <option value="">Aucun partenaire trouvé.</option>
                    )}
                </select>
                <button
                    onClick={handleFormSubmit}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-4 rounded-full"
                >
                    Valider
                </button>
            </Modal>
        );
    };

    const handleSelectChange = (e) => {
        const newValue = e.target.value;
        setEditedRegion(newValue);
    }

    const handleSelectChangeOffer = (e) => {
        const newValue = e.target.value;
        setEditedOffer(newValue);
    }

    const showOrderDetails = async (order) => {
        setSelectedAppointmentOrder(null);
        setSelectedPreStudyOrder(null);
        setSelectedActivationData(null);
        setSelectedInstallationData(null);
        setSelectedPaymentData(null);

        setTechCamtelName([]);
        setTechPartnerName([]);
        setComCamtelName([]);
        setComPartnerName([]);

        setSelectedOrder(order);

        setIsDetailsVisible(false);

        if (token && order.idPartnerTechnician && order.idPartnerTechnician !== 0) {
            const techPartnerProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + order.idPartnerTechnician, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            setTechPartnerName(techPartnerProfile.data.profile[0]);
        } else {
            console.log('Pas de technicien assigné à cette commande.');
        }

        if (token && order && order.idPartnerCommercial && order.idPartnerCommercial !== 0) {
            const comPartnerProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + order.idPartnerCommercial, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            setComPartnerName(comPartnerProfile.data.profile[0]);
        } else {
            console.log('Commande autoprospectée ou par CAMTEL.');
        }

        if (token) {
            try {
                const response = await axios.get(`${BASE_URL}/api/orders/details/` + order.idSheet, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const appointmentData = response.data[order.idSheet].appointmentData[0];
                setSelectedAppointmentOrder(appointmentData);

                const orderData = response.data[order.idSheet].preStudyOrderData[0];
                setSelectedPreStudyOrder(orderData);

                if (orderData.idTechnicienCamtel && orderData.idTechnicienCamtel !== 0) {
                    const techCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + orderData.idTechnicienCamtel, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });
                    setTechCamtelName(techCamtelProfile.data.profile[0]);
                } else {
                    console.log('Pré-étude de la commande non validée par le Pool Technique de CAMTEL.');
                }

                setSelectedInstallationData(response.data[order.idSheet].installationData[0]);
                setSelectedActivationData(response.data[order.idSheet].activationData[0]);
                setSelectedPaymentData(response.data[order.idSheet].paymentData[0]);

                if (response.data[order.idSheet].activationData[0] && response.data[order.idSheet].activationData[0].idCommercialCamtel !== 0 && response.data[order.idSheet].activationData[0].idCommercialCamtel !== null) {
                    const comCamtelProfile = await axios.get(`${BASE_URL}/api/profile/getprofile/` + response.data[order.idSheet].activationData[0].idCommercialCamtel, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                    });
                    setComCamtelName(comCamtelProfile.data.profile[0]);
                } else {
                    console.log('Commande non activée par le Pool Commercial de CAMTEL.');
                }

                const idBindedEntity = orderData.idBindedEntity;
                const entityResponse = await axios.get(`${BASE_URL}/api/entities/details/` + idBindedEntity, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });
                const entityData = entityResponse.data.entities;
                setDetails(entityData[0]);

            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
            }
        }
    };

    const handleEdit = (order) => {
        setEditingOrder(order);
        setEditedCustomerPhoto(order.customerPhoto);
        setOldCustomerPhotoFileName(order.customerPhoto);

        setEditedNameOrSocialName(order.nameOrSocialName);
        setEditedCustomerType(order.customerType);
        setEditedRegion(order.region);
        setEditedCity(order.city);
        setEditedLocalisation(order.localisation);
        setEditedLieuDit(order.lieuDit);
        setEditedCustomerGpsCoordinates(order.customerGpsCoordinates);
        setEditedImageLocalisation(order.imageLocalisation);
        setOldImageLocalisationFileName(order.imageLocalisation);

        setEditedEmail(order.email);
        setEditedMobile(order.mobile);
        setEditedIdCardNumber(order.idCardNumber);
        setEditedIdPassportNumber(order.idPassportNumber);
        setEditedIdResidencePermitNumber(order.idResidencePermitNumber);
        setEditedIdResidencePermitFront(order.idResidencePermitFront);
        setOldIdResidencePermitFrontFileName(order.idResidencePermitFront);

        setEditedRectoCni(order.rectoCni);
        setEditedVersoCni(order.versoCni);
        setOldRectoCniFileName(order.rectoCni);
        setOldVersoCniFileName(order.versoCni);

        setEditedIdPassportFront(order.idPasseportFront);
        setOldIdPassportFrontFileName(order.idPasseportFront);

        setEditedCommerceRegNumber(order.commerceRegNumber);
        setEditedRectoCommerceReg(order.rectoCommerceReg);
        setOldRectoCommerceRegFileName(order.rectoCommerceReg);

        setEditedOffer(order.offerBlueMaxSelected);

        setIsDetailsVisible(true);
        setIsEditVisible(true);
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setDisplayCustomerPhoto(imageUrl);
            setEditedCustomerPhoto(file);
        }
    }

    const handleFileChangeLocalisation = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayImageLocalisation(fileToDisplay);
            setEditedImageLocalisation(file);
        }
    }

    const handleFileChangeRectoCni = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayRectoCni(fileToDisplay);
            setEditedRectoCni(file);
        }
    }

    const handleFileChangeVersoCni = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayVersoCni(fileToDisplay);
            setEditedVersoCni(file);
        }
    }

    const handleFileChangePassport = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayIdPassportFront(fileToDisplay);
            setEditedIdPassportFront(file);
        }
    };

    const handleFileChangeIdResidencePermit = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayIdResidencePermitFront(fileToDisplay);
            setEditedIdResidencePermitFront(file);
        }
    };

    const handleFileChangeReg = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileToDisplay = URL.createObjectURL(file);
            setDisplayRectoCommerceReg(fileToDisplay);
            setEditedRectoCommerceReg(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = {
            oldCustomerPhoto: oldCustomerPhotoFileName,
            customerPhoto: editedCustomerPhoto,
            nameOrSocialName: editedNameOrSocialName,
            customerType: editedCustomerType,
            region: editedRegion,
            city: editedCity,
            editedLocalisation: editedLocalisation,
            lieuDit: editedLieuDit,
            customerGpsCoordinates: editedCustomerGpsCoordinates,
            oldPreciseLocationFile: oldImageLocalisationFileName,
            preciseLocationFile: editedImageLocalisation,
            email: editedEmail,
            mobile: editedMobile,
            idCardNumber: editedIdCardNumber,
            idPassportNumber: editedIdPassportNumber,
            idResidencePermitNumber: editedIdResidencePermitNumber,
            oldIdCardFront: oldRectoCniFileName,
            oldIdCardBack: oldVersoCniFileName,
            idCardFront: editedRectoCni,
            idCardBack: editedVersoCni,
            idPasseportFront: editedIdPassportFront,
            oldIdPasseportFront: oldIdPassportFrontFileName,
            idResidencePermitFront: editedIdResidencePermitFront,
            oldIdResidencePermitFront: oldIdResidencePermitFrontFileName,
            regComNumber: editedCommerceRegNumber,
            oldCommerceRegistryFront: oldRecotCommerceRegFileName,
            commerceRegistryFront: editedRectoCommerceReg,
            offerBlueMaxSelected: editedOffer
        }

        if (token) {
            try {
                const response = await axios.patch(`${BASE_URL}/api/orders/superadmin/${editingOrder.idSheet}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                });
                setLoading(false);
                alert('Commande mis à jour avec succès.');
                setIsEditVisible(false);
                setSelectedOrder(null);
                setIsDetailsVisible(true);
            } catch (error) {
                setLoading(false);
                alert('Erreur lors de la mise à jour de la commande.');
                console.error("Erreur lors de la mise à jour de la commande: ", error);
            }
        }
    }

    const handleDelete = (order) => {
        setLoading(true);

        if (token && window.confirm('Êtes-vous sûr de vouloir supprimer cette commande ? Elle disparaîtra définitivement après cette action.')) {
            axios.delete(`${BASE_URL}/api/orders/` + order.idSheet + '?name=' + order.nameOrSocialName + '&email=' + order.email + '&idSuperAdmin=' + localStorage.getItem('userId') + '&mobile=' + order.mobile + '&sheetCreatedAt=' + order.sheetCreatedAt, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    setLoading(false);
                    if (response.data && response.data.errorMessage) {
                        alert(response.data.errorMessage);
                    } else {
                        alert('Commande supprimée avec succès!');
                        setSelectedOrder(null);
                        setIsDetailsVisible(true);
                    }
                })
                .catch((error) => {
                    alert('Erreur lors de la suppression de la commande.');
                    console.error("Erreur lors de la suppression de la commande : ", error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }

    const redoSurvey = (idSheet, customerGpsCoordinates) => {
        setLoading(true);

        if (token) {
            axios.post(`${BASE_URL}/api/orders/redo/survey/${idSheet}`, { customerGpsCoordinates }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    let data = response.data;

                    if (data && data.message) {
                        window.open(`/location/${idSheet}`, '_blank');
                    } else {
                        alert(data.error);
                    }

                    setLoading(false);
                })
                .catch(error => {
                    alert('Erreur survenue lors de la réalisation du nouveau survey. Veuillez réessayer s\'il vous plait.');

                    setLoading(false);
                });
        }
    }

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    // DOWNLOAD ORDERS
    const handleDownload = () => {
        setLoading(true);

        if (token) {
            axios.get(`${BASE_URL}/api/orders/download/orders/by/super/admin`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    let data = response.data;

                    data = data.map(item => ({
                        subscription_ID: item.idSubscription + item.idSheet,
                        photo_du_client: item.customerPhoto,
                        nom_du_client: item.nameOrSocialName,
                        type_de_client: item.customerType,
                        region: item.region,
                        ville: item.city,
                        localisation: item.localisation,
                        lieuDit: item.lieuDit,
                        coordonnees_gps_du_client: item.customerGpsCoordinates,
                        email: item.email,
                        numero_telephone_du_client: item.mobile,
                        numero_cni_du_client: item.idCardNumber,
                        numero_passeport_du_client: item.idPassportNumber,
                        numero_carte_de_sejour_du_client: item.idResidencePermitNumber,
                        image_recto_cni_du_client: item.rectoCni,
                        image_verso_cni_du_client: item.versoCni,
                        image_passeport_du_client: item.idPasseportFront,
                        image_carte_de_sejour_du_client: item.idResidencePermitFront,
                        numero_registre_de_commerce: item.commerceRegNumber,
                        image_registre_de_commerce: item.rectoCommerceReg,
                        offre_choisie: item.offerBlueMaxSelected,
                        date_de_souscription: item.sheetCreatedAt,
                        date_realisation_pre_etude: item.preEtudeCreatedAt,
                        nom_du_prestataire: item.nameEntity,
                        numero_telephone_prestataire: item.telephoneEntity,
                        email_prestataire: item.emailEntity,
                        reference_fdt: item.fdtRef,
                        reference_fat: item.fatRef,
                        distance_fat_client: item.dFatCustomer,
                        distance_fat_client: item.dFatCustomer,
                        nb_poteau_a_installer: item.nbPoToInstall,
                        nb_poteau_a_installer: item.nbPoToInstall,
                        statut_pre_etude: item.result,
                        observations_pre_etude: item.preEtudeObs,
                        date_realisation_etude: item.preEtudeUpdateByTechCamtelAt,
                        statut_etude: item.validation,
                        observations_etude: item.etudeObs,
                        crm_customer_code: item.crmCustomerCode,
                        numero_facturation_client: item.billingAccount,
                        numero_ligne_client: item.phoneNumberActivated,
                        reference_de_paiement: item.paymentRef,
                        date_de_paiement_client: item.paymentDate,
                        date_installation: item.installationCreatedAt,
                        nombre_balancelle: item.nbBalancel,
                        metrage_cable_entree_fat: item.cableMeterEntryFat,
                        nb_poteau_arme_installe: item.nbArmedInstalledPoto,
                        numero_ONT: item.ontNumber,
                        numero_de_STB_Stick: item.stbStickNumber,
                        numero_de_TB: item.tbNumber,
                        numero_de_jarretiere: item.nbGarter,
                        numero_de_smoove: item.nbSmoove,
                    }));

                    const worksheet = XLSX.utils.json_to_sheet(data);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille1');

                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                    const url = window.URL.createObjectURL(dataBlob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'fiberconnect_orders.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    setLoading(false);
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des données ou de la création du fichier Excel :', error);
                    alert('Erreur lors de la récupération des données ou de la création du fichier Excel ');

                    setLoading(false);
                });
        }
    };

    const relaunchPendingOrders = async (e) => {
        e.preventDefault();

        const userConfirmed = window.confirm("Êtes-vous sûr de vouloir relancer les commandes en attente de survey ?");

        if (token && userConfirmed) {
            setLoading(true);

            try {
                const response = await axios.post(`${BASE_URL}/api/orders/relaunch/pending`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                alert(response.data.message);
            } catch (error) {
                console.error('Erreur lors de la relance :', error);
                setLoading(false);
            }
        } else {
            console.log('Action annulée par l\'utilisateur.');
        }
    };

    const relaunchUnPaidOrders = async (e) => {
        e.preventDefault();

        const userConfirmed = window.confirm("Êtes-vous sûr de vouloir relancer les commandes en attente de paiement ?");

        if (token && userConfirmed) {
            setLoading(true);

            try {
                const response = await axios.post(`${BASE_URL}/api/orders/relaunch/unpaid`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                alert(response.data.message);
            } catch (error) {
                console.error('Erreur lors de la relance :', error);
                setLoading(false);
            }
        } else {
            console.log('Action annulée par l\'utilisateur.');
        }
    }

    const relaunchUnFavorableOrders = async (e) => {
        e.preventDefault();

        const userConfirmed = window.confirm("Êtes-vous sûr de vouloir relancer les commandes défavorables ?");

        if (token && userConfirmed) {
            setLoading(true);

            try {
                const response = await axios.post(`${BASE_URL}/api/orders/relaunch/unfavorable`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                alert(response.data.message);
            } catch (error) {
                console.error('Erreur lors de la relance :', error);
                setLoading(false);
            }
        } else {
            console.log('Action annulée par l\'utilisateur.');
        }
    }

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../../assets/img/icons/gif/loading-points.webp')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <>
                        <Header />

                        <Sidebar setHideText={setHideText} hideText={hideText} />

                        <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[75%] 2xl:w-[73%]'}`}>
                            {isDetailsVisible && !isEditVisible && (
                                <div>
                                    <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des commandes</h1>

                                    <div className="flex justify-end">
                                        <div className="flex flex-col md:flex-row">
                                            <Link to="/super-sheet">
                                                <button
                                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-4"
                                                >
                                                    Prospecter un client
                                                </button>
                                            </Link>

                                            <button
                                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 ml-2 mb-4"
                                                onClick={handleDownload}>Télécharger (Excel)
                                            </button>
                                        </div>
                                    </div>

                                    <div className="flex justify-end">
                                        <div className="flex flex-col md:flex-row">
                                            <button
                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 ml-2 mb-4"
                                                onClick={relaunchPendingOrders}
                                            >
                                                Rélancer les commandes non traitées
                                            </button>

                                            <button
                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 ml-2 mb-4"
                                                onClick={relaunchUnPaidOrders}
                                            >
                                                Rélancer les commandes non payées
                                            </button>

                                            <button
                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 ml-2 mb-4"
                                                onClick={relaunchUnFavorableOrders}
                                            >
                                                Rélancer les commandes défavorables
                                            </button>
                                        </div>
                                    </div>

                                    <div className="sm:w-[100%] md:w-[100%] lg:w-[50%] xl:w-[50%] 2xl:w-[50%] float-right">
                                        <label className="relative block">
                                            <span className="sr-only">Search</span>
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search">
                                                    <circle cx="11" cy="11" r="8" />
                                                    <path d="m21 21-4.3-4.3" />
                                                </svg>
                                            </span>
                                            <input
                                                className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                                placeholder="Rechercher un client par son nom"
                                                type="text"
                                                value={searchTerm}
                                                onChange={searchCustomer}
                                            />
                                        </label>
                                    </div>

                                    {/* <div>
                                        <select
                                            className="block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                            value={selectedStatus}
                                            onChange={handleStatusChange}
                                        >
                                            <option value="">Tous les statuts</option>
                                            <option value="Pré-étude non réalisée">Pré-étude non réalisée</option>
                                            <option value="Concluante">Concluante</option>
                                            <option value="Non concluante">Non concluante</option>
                                        </select>
                                    </div> */}

                                    <div className="w-full mt-12 shadow-sm overflow-x-auto">
                                        <table className="w-full table-auto">
                                            <thead className="w-full">
                                                <tr>
                                                    <th className="px-2 py-2 text-center">ID de la souscription</th>
                                                    <th className="px-2 py-2 text-center">Nom du partenaire</th>
                                                    <th className="px-2 py-2 text-center">Nom du client</th>
                                                    <th className="px-2 py-2 text-center">Statut de la pré-étude</th>
                                                    <th className="px-2 py-2 text-center">Statut de l'étude</th>
                                                    <th className="px-2 py-2 text-center">Statut du paiement</th>
                                                    <th className="px-2 py-2 text-center">Statut de l'activation</th>
                                                    <th className="px-2 py-2 text-center">Statut de l'installation</th>
                                                    <th className="px-4 py-2 text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="w-full text-gray-600 divide-y">
                                                {Array.isArray(filteredData) && filteredData.length > 0 ? (
                                                    filteredData.map((sheetData) => (
                                                        <tr key={sheetData.idSheet} className="border-b hover:bg-gray-100">
                                                            {Array.isArray(sheetData.preStudyOrder) && sheetData.preStudyOrder.length > 0 ? (
                                                                sheetData.preStudyOrder.map((order) => (
                                                                    <>
                                                                        <td className="gap-x-3 py-2 px-2 whitespace-nowrap lg:px-6">
                                                                            <div>{order.idSubscription + order.idSheet}</div>
                                                                        </td>
                                                                        <td className="px-2 py-2 text-center lg:px-6">
                                                                            {order.name === '' || order.name === null ? 'Client autoprospecté' : order.name}
                                                                        </td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{order.nameOrSocialName}</td>
                                                                    </>
                                                                ))
                                                            ) : (
                                                                <p>Aucune donnée de preStudyOrder disponible</p>
                                                            )}

                                                            {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                                sheetData.installationData.map((installation) => (
                                                                    <>
                                                                        <td className="px-2 lg:px-6 py-2 text-center">{installation.result === '' || installation.result === null ? 'Pré-étude non réalisée' : installation.result}</td>
                                                                        <td className="px-2 lg:px-6 py-2 text-center">
                                                                            {installation.validation === '' || installation.validation === null ? 'Étude non réalisée' : installation.validation}
                                                                        </td>
                                                                    </>
                                                                ))
                                                            ) : (
                                                                <>
                                                                    <td className="px-2 py-2 lg:px-6 text-center">Pré-étude non réalisée</td>
                                                                    <td className="px-2 py-2 lg:px-6 text-center">Étude non réalisée</td>
                                                                </>
                                                            )}

                                                            {Array.isArray(sheetData.paymentData) && sheetData.paymentData.length > 0 ? (
                                                                sheetData.paymentData.map((payment) => (
                                                                    <td className="px-2 lg:px-6 py-2 text-center">
                                                                        {
                                                                            sheetData.activationData && sheetData.activationData.length > 0 && sheetData.activationData[0].idActivation
                                                                                ? 'Commande payée'
                                                                                : payment.status === 'SUCCESSFULL' ? 'Commande payée' : 'Commande non payée'
                                                                        }
                                                                    </td>
                                                                ))
                                                            ) : (
                                                                <td className="px-2 py-2 lg:px-6 text-center">Commande non payée</td>
                                                            )}

                                                            {Array.isArray(sheetData.activationData) && sheetData.activationData.length > 0 ? (
                                                                sheetData.activationData.map((activation) => (
                                                                    <td className="px-2 py-2 lg:px-6 text-center">
                                                                        {activation.idActivation === '' || activation.idActivation === null ? 'Service non activé' : 'Service activé'}
                                                                    </td>
                                                                ))
                                                            ) : (
                                                                <td className="px-2 py-2 lg:px-6 text-center">Service non activé</td>
                                                            )}

                                                            {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                                sheetData.installationData.map((installation) => (
                                                                    <td className="px-2 py-2 lg:px-6 text-center">
                                                                        {installation.idTechPartner !== null ? 'Installé' : 'Non installé'}
                                                                    </td>
                                                                ))
                                                            ) : (
                                                                <td className="px-2 py-2 lg:px-6 text-center">Non installé</td>
                                                            )}

                                                            <td className="lg:px-6 text-center p-1">
                                                                <div className="flex items-center justify-center">
                                                                    {Array.isArray(sheetData.preStudyOrder) && sheetData.preStudyOrder.length > 0 ? (
                                                                        sheetData.preStudyOrder.map((order) => (
                                                                            <button
                                                                                onClick={() => { showOrderDetails(order) }}
                                                                                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold m-1 py-1 px-2"
                                                                            >
                                                                                Détails
                                                                            </button>
                                                                        ))
                                                                    ) : (
                                                                        null
                                                                    )}

                                                                    {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                                        sheetData.installationData.map((installation) => (
                                                                            <div className="">
                                                                                {installation.idInstallation !== null && installation.idInstallation !== '' && (
                                                                                    <Link
                                                                                        to={`/fiche/${installation.idSheet}`}
                                                                                        className="bg-green-500 hover:bg-green-700 text-white font-bold m-1 py-2 px-2 whitespace-nowrap"
                                                                                    >
                                                                                        Prévisualiser la fiche
                                                                                    </Link>
                                                                                )}
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        null
                                                                    )}

                                                                    {Array.isArray(sheetData.preStudyOrder) && sheetData.preStudyOrder.length > 0 && (
                                                                        sheetData.preStudyOrder.map((order) => (
                                                                            <Link
                                                                                to={`/contract/${order.idSheet}`}
                                                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-1 px-2 m-3 whitespace-nowrap"
                                                                            >
                                                                                Visualiser le contrat
                                                                            </Link>
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <td colSpan={9} className="text-center">Aucune commande disponible</td>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}

                            {selectedOrder && !isDetailsVisible && (
                                <>
                                    <div className="flex justify-end">
                                        <div className="flex flex-col md:flex-row">
                                            <div>
                                                {selectedPaymentData && selectedPaymentData.idPaidOrder && selectedPaymentData.status === 'SUCCESSFULL' && (
                                                    <button
                                                        className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 m-1"
                                                        onClick={() => window.open(`/receipt/${selectedPaymentData.payToken}`, '_blank', 'noopener,noreferrer')}
                                                    >
                                                        Visualiser le reçu de paiement
                                                    </button>
                                                )}
                                            </div>
                                            <div>
                                                <button onClick={() => openModal(selectedOrder.idSheet)} className="bg-green-500 hover:bg-green-700 text-white font-bold p-2 m-1">{!selectedOrder.name ? 'Assigner à un partenaire' : 'Ré-assigner à un partenaire'}</button>
                                                <CustomModal
                                                    isOpen={isOpen}
                                                    closeModal={closeModal}
                                                    partners={partners}
                                                    selectedOrderId={selectedOrder.idSheet}
                                                />
                                            </div>
                                            <button
                                                onClick={() => handleEdit(selectedOrder)}
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 m-1"
                                            >
                                                Modifier
                                            </button>
                                            {(selectedPreStudyOrder && selectedPreStudyOrder.idPreEtude) && (selectedInstalationData && !selectedInstalationData.idInstallation) && (
                                                <button
                                                    onClick={() => redoSurvey(selectedOrder.idSheet, selectedOrder.customerGpsCoordinates)}
                                                    className="bg-sky-500 hover:bg-sky-700 text-white font-bold p-2 m-1"
                                                >
                                                    Refaire le survey
                                                </button>
                                            )}
                                            <button
                                                onClick={() => handleDelete(selectedOrder)}
                                                className="bg-red-500 hover:bg-red-700 text-white font-bold p-2 m-1"
                                            >
                                                Supprimer
                                            </button>
                                            <button
                                                className="close-button bg-yellow-500 hover:bg-yellow-700 p-2 m-1 text-white"
                                                onClick={() => setIsDetailsVisible(true)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-4 border p-4">
                                        <h2 className="text-3xl font-bold mb-2 uppercase text-center">Détails de la commande</h2>

                                        {comPartnerName.nom ? <p><strong>Commercial du partenaire ayant prospecté la commande: </strong> {comPartnerName.nom + ' ' + comPartnerName.prenom}</p> : <p><strong>Client assignée au partenaire par CAMTEL.</strong></p>}
                                        {techPartnerName.nom ? <p><strong>Technicien du partenaire en charge de la pré-étude et de l'installation de la commande :</strong> {techPartnerName.nom + ' ' + techPartnerName.prenom}</p> : <p><strong>Commande non assignée à un technicien (Partenaire) pour pré-étude.</strong></p>}
                                        {techCamtelName.nom ? <p><strong>Pool Technique CAMTEL en charge de l'étude: </strong> {techCamtelName.nom + ' ' + techCamtelName.prenom}</p> : <p><strong>Étude pas encore validée par le Pool Technique CAMTEL</strong></p>}
                                        {comCamtelName.nom ? <p><strong>Pool Commercial CAMTEL en charge de l'activation de cette commande: </strong> {comCamtelName.nom + ' ' + comCamtelName.prenom}</p> : <p><strong>Commande non activée par le Pool Commercial CAMTEL.</strong></p>}

                                        <div className="mt-3 mb-3">
                                            <p><strong>Date de souscription du client: </strong><FormatDate dateStr={selectedOrder.sheetCreatedAt} /></p>
                                            <p><strong>Date de RDV d'installation du client: </strong>{selectedAppointmentOrder ? <FormatDate dateStr={selectedAppointmentOrder.appointmentDateTime} /> : 'Installation non programmée par le client.'}</p>
                                        </div>

                                        {selectedOrder.customerPhoto && (
                                            <div className="flex justify-between w-full">
                                                <div>
                                                    <strong>Photo du client :</strong>
                                                    <img
                                                        src={selectedOrder.customerPhoto}
                                                        alt="Photo du client"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 200, width: 200 }}
                                                    />
                                                </div>
                                            </div>

                                        )}
                                        <p><strong>N°ID Souscription :</strong> {selectedOrder.idSubscription + selectedOrder.idSheet}</p>
                                        <p><strong>Nom et Prénoms / Raison Sociale :</strong> {selectedOrder.nameOrSocialName}</p>
                                        <p><strong>Type de Client:</strong> {selectedOrder.customerType}</p>
                                        <p>
                                            <strong>Région: </strong>
                                            {
                                                selectedOrder.region === 'CE' ? 'Centre' :
                                                    selectedOrder.region === 'LT' ? 'Littoral' :
                                                        selectedOrder.region === 'NO' ? 'Nord' :
                                                            selectedOrder.region === 'EN' ? 'Extrême-Nord' :
                                                                selectedOrder.region === 'AD' ? 'Adamaoua' :
                                                                    selectedOrder.region === 'ES' ? 'Est' :
                                                                        selectedOrder.region === 'SU' ? 'SUD' :
                                                                            selectedOrder.region === 'OU' ? 'Ouest' :
                                                                                selectedOrder.region === 'NW' ? 'Nord-Ouest' :
                                                                                    'Sud-Ouest'
                                            }
                                        </p>
                                        <p><strong>Ville:</strong> {selectedOrder.city}</p>
                                        <p><strong>Localisation Précise:</strong> {selectedOrder.localisation}</p>
                                        <p><strong>Lieu-dit:</strong> {selectedOrder.lieuDit}</p>
                                        {selectedOrder.customerGpsCoordinates && (<p><strong>Coordonnées GPS: </strong>{selectedOrder.customerGpsCoordinates}</p>)}
                                        <p>
                                            <strong>Plan de localisation:</strong>
                                            {selectedOrder.imageLocalisation && selectedOrder.imageLocalisation.endsWith('.jpg') || selectedOrder.imageLocalisation && selectedOrder.imageLocalisation.endsWith('.png') ? (
                                                <img
                                                    src={selectedOrder.imageLocalisation}
                                                    alt="Plan de localisation"
                                                    className="block w-32 h-auto mt-2 rounded"
                                                    style={{ height: 500, width: '100%' }}
                                                />
                                            ) : (
                                                <iframe
                                                    src={selectedOrder.imageLocalisation}
                                                    width="100%"
                                                    height="500"
                                                    title="PDF Viewer"
                                                ></iframe>
                                            )}
                                        </p>
                                        <p><strong>Email:</strong> {selectedOrder.email}</p>
                                        <p><strong>N° Mobile:</strong> {selectedOrder.mobile}</p>
                                        {selectedOrder.idCardNumber !== null && (
                                            <>
                                                <p><strong>Numéro de la CNI:</strong> {selectedOrder.idCardNumber}</p>
                                                <p>
                                                    <strong>Carte Nationnale d'identité:</strong>
                                                    <div className="flex">
                                                        <div className="flex flex-col md:flex-row">
                                                            {selectedOrder.rectoCni && selectedOrder.rectoCni.endsWith('.jpg') || selectedOrder.rectoCni && selectedOrder.rectoCni.endsWith('.png') ? (
                                                                <img
                                                                    src={selectedOrder.rectoCni}
                                                                    alt="Image Recto de la CNI"
                                                                    className="w-full h-auto mt-2 rounded md:w-1/2"
                                                                    style={{ height: 250 }}
                                                                />
                                                            ) : (
                                                                <iframe
                                                                    src={selectedOrder.rectoCni}
                                                                    className="w-full md:w-1/2"
                                                                    height="300"
                                                                    title="PDF Viewer"
                                                                ></iframe>
                                                            )}

                                                            {selectedOrder.versoCni && selectedOrder.versoCni.endsWith('.jpg') || selectedOrder.versoCni && selectedOrder.versoCni.endsWith('.png') ? (
                                                                <img
                                                                    src={selectedOrder.versoCni}
                                                                    alt="Image Verso de la CNI"
                                                                    className="w-full h-auto mt-2 rounded md:w-1/2"
                                                                    style={{ height: 250 }}
                                                                />
                                                            ) : (
                                                                <iframe
                                                                    src={selectedOrder.versoCni}
                                                                    className="w-full md:w-1/2"
                                                                    height="300"
                                                                    title="PDF Viewer"
                                                                ></iframe>
                                                            )}
                                                        </div>
                                                    </div>
                                                </p>
                                            </>
                                        )}
                                        {selectedOrder.idPassportNumber !== null && (
                                            <>
                                                <p><strong>Numéro du passeport:</strong> {selectedOrder.idPassportNumber}</p>
                                                <p>
                                                    <strong>Passeport:</strong>
                                                    {selectedOrder.idPasseportFront && selectedOrder.idPasseportFront.endsWith('.jpg') || selectedOrder.idPasseportFront && selectedOrder.idPasseportFront.endsWith('.png') ? (
                                                        <img
                                                            src={selectedOrder.idPasseportFront}
                                                            alt="Image Recto du passeport"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <iframe
                                                            src={selectedOrder.idPasseportFront}
                                                            width="100%"
                                                            height="300"
                                                            title="PDF Viewer"
                                                        ></iframe>
                                                    )}
                                                </p>
                                            </>
                                        )}
                                        {selectedOrder.idResidencePermitNumber && (
                                            <>
                                                <p><strong>Numéro dr la carte de séjour:</strong> {selectedOrder.idResidencePermitNumber}</p>
                                                <p>
                                                    <strong>Carte de séjour:</strong>
                                                    <div className="flex">
                                                        {selectedOrder.idResidencePermitFront && selectedOrder.idResidencePermitFront.endsWith('.jpg') || selectedOrder.idResidencePermitFront && selectedOrder.idResidencePermitFront.endsWith('.png') ? (
                                                            <img
                                                                src={selectedOrder.idResidencePermitFront}
                                                                alt="Image Recto de la carte de séjour"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 250, width: '50%' }}
                                                            />
                                                        ) : (
                                                            <iframe
                                                                src={selectedOrder.idResidencePermitFront}
                                                                width="100%"
                                                                height="300"
                                                                title="PDF Viewer"
                                                            ></iframe>
                                                        )}
                                                    </div>
                                                </p>
                                            </>
                                        )}
                                        {selectedOrder.commerceRegNumber && (
                                            <>
                                                <p><strong>N°Régistre Commerce :</strong> {selectedOrder.commerceRegNumber}</p>
                                                <p>
                                                    <strong>Image du registre de commerce:</strong>
                                                    <div className="flex">
                                                        {selectedOrder.rectoCommerceReg && selectedOrder.rectoCommerceReg.endsWith('.jpg') || selectedOrder.rectoCommerceReg && selectedOrder.rectoCommerceReg.endsWith('.png') ? (
                                                            <img
                                                                src={selectedOrder.rectoCommerceReg}
                                                                alt="Image du registre de commerce"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 'auto', width: '100%' }}
                                                            />
                                                        ) : (
                                                            <iframe
                                                                src={selectedOrder.rectoCommerceReg}
                                                                width="100%"
                                                                height="500"
                                                                title="PDF Viewer"
                                                            ></iframe>
                                                        )}
                                                    </div>
                                                </p>
                                            </>
                                        )}

                                        <h3 className="text-2xl font-bold mt-2">TYPE D’OPERATION</h3>
                                        <p><strong>Formule d’abonnement choisie:</strong> {selectedOrder.offerBlueMaxSelected}</p>
                                    </div>
                                </>
                            )}

                            {selectedPreStudyOrder && !isDetailsVisible && (
                                <>
                                    <div className="mt-4 border p-4">
                                        <h3 className="text-3xl font-bold mb-2 text-center">PRE-ETUDE PRESTATAIRE</h3>
                                        <p><strong>Nom du prestataire :</strong> {selectedPreStudyOrder.name}</p>
                                        <p><strong>Tel :</strong> {selectedPreStudyOrder.telephoneEntity}</p>
                                        <p><strong>Email :</strong> {selectedPreStudyOrder.emailEntity}</p>
                                        {
                                            details && (
                                                <>
                                                    <p><strong>Zone d’intervention :</strong> {details.interventionZone}</p>
                                                    <p><strong>Agence Commerciale :</strong> {details.name}</p>
                                                </>
                                            )
                                        }
                                        <p><strong>Référence du FDT :</strong> {selectedPreStudyOrder.fdtRef}</p>
                                        <p><strong>Référence du FAT :</strong> {selectedPreStudyOrder.fatRef}</p>
                                        <p><strong>Distance FAT-Client (m) :</strong> {selectedPreStudyOrder.dFatCustomer}</p>
                                        {selectedPreStudyOrder.nbPoToInstall && <p><strong>Nombre de poteau à installer :</strong> {selectedPreStudyOrder.nbPoToInstall}</p>}
                                    </div>
                                    <div className="mt-4 border p-4">
                                        <h3 className="text-3xl font-bold mb-2 text-center">RESULTAT ET OBSERVATIONS DE LA PRE-ETUDE</h3>
                                        <p><strong>Statut de la validation par le technicien du prestataire:</strong> {selectedPreStudyOrder.result}</p>
                                        <p><strong>Observations fait par le technicien du prestataire:</strong> {selectedPreStudyOrder.preEtudeObs}</p>
                                    </div>
                                    {
                                        (selectedPreStudyOrder.validation !== '' || selectedPreStudyOrder.etudeObs !== '') && (
                                            <div className="mt-4 border p-4">
                                                <h3 className="text-3xl font-bold mb-2 mt-2 text-center">VALIDATION ET OBSERVATIONS DE L’ETUDE</h3>
                                                {selectedPreStudyOrder.validation !== '' && (
                                                    <p><strong>Statut de la validation par le technicien de CAMTEL:</strong> {selectedPreStudyOrder.validation}</p>
                                                )}
                                                {selectedPreStudyOrder.etudeObs !== '' && (
                                                    <p><strong>Observations de l'étude du technicien de CAMTEL:</strong> {selectedPreStudyOrder.etudeObs}</p>
                                                )}
                                            </div>
                                        )
                                    }
                                </>
                            )}

                            {!isDetailsVisible && selectedActivationData && selectedActivationData.crmCustomerCode !== null && selectedActivationData.crmCustomerCode !== '' && (
                                <div className="mt-4 border p-4">
                                    <h3 className="text-2xl font-bold mt-2 mb-2 text-center ">ACTIVATION</h3>
                                    <p><strong>Date d'activation de la commande: </strong><FormatDate dateStr={selectedActivationData.activationCreatedAt} /></p>
                                    <p><strong>ID de la commande (CBS) :</strong> {selectedActivationData.cbsOrderId}</p>
                                    <p><strong>Code Client CRM :</strong> {selectedActivationData.crmCustomerCode}</p>
                                    <p><strong>Compte de facturation :</strong> {selectedActivationData.billingAccount}</p>
                                    <p><strong>Numéro de Telephone :</strong> {selectedActivationData.phoneNumberActivated}</p>
                                    <p><strong>Numéro ONT :</strong> {selectedActivationData.ontNumber}</p>
                                    <p><strong>Référence paiement :</strong> {selectedActivationData.paymentRef}</p>
                                    <p><strong>Date de paiement : </strong> <FormatDate dateStr={selectedActivationData.paymentDate} /></p>
                                    <p><strong>Numéro STB/IP Stick :</strong> {selectedActivationData.stbIpStickNumber}</p>
                                </div>
                            )}

                            {!isDetailsVisible && selectedInstalationData && selectedInstalationData.idInstallation !== null && (
                                <div className="mt-4 border p-4">
                                    <h3 className="text-3xl font-bold mb-2 mt-2 text-center">INSTALLATION</h3>
                                    <p><strong>Date d'installation effective: </strong><FormatDate dateStr={selectedInstalationData.installationCreatedAt} /></p>
                                    <p><strong>Nombre de Balancelle :</strong> {selectedInstalationData.nbBalancel}</p>
                                    <p><strong>Métrage câble entrée FAT :</strong> {selectedInstalationData.cableMeterEntryFat}</p>
                                    <p><strong>Longueur Câble utilisée (m) :</strong> {selectedInstalationData.usedCableLong}</p>
                                    <p><strong>Nombre d’ATB :</strong> {selectedInstalationData.atbNumber}</p>
                                    <p><strong>Nombre Crochet d’Arrêt :</strong> {selectedInstalationData.nbCrochetArret}</p>
                                    <p><strong>Métrage câble chez l’abonné :</strong> {selectedInstalationData.clientCableMeter}</p>
                                    <p><strong>Nombre de poteau armé installé :</strong> {selectedInstalationData.nbArmedInstalledPoto}</p>
                                    <p><strong>Numéro ONT :</strong> {selectedInstalationData.ontNumber}</p>
                                    <p><strong>Nombre de STB/ Stick :</strong> {selectedInstalationData.stbStickNumber}</p>
                                    <p><strong>Nombre de TB :</strong> {selectedInstalationData.tbNumber}</p>
                                    <p><strong>Nombre de Jarretière :</strong> {selectedInstalationData.nbGarter}</p>
                                    <p><strong>Nombre de Smoove :</strong> {selectedInstalationData.nbSmoove}</p>
                                </div>
                            )}

                            {editingOrder && isEditVisible && (
                                <div className="mt-4 border p-4">
                                    <div className="flex justify-end">
                                        <div>
                                            <button
                                                className="close-button bg-yellow-500 hover:bg-yellow-700 p-2 text-white"
                                                onClick={() => setIsEditVisible(false)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>

                                    <h2 className="text-4xl font-bold mb-4 mt-4 text-center">Modifier la commande</h2>
                                    <form enctype="multipart/form-data">
                                        <div className="mb-2">
                                            <div>
                                                <strong>Photo du client :</strong>
                                                {displayCustomerPhoto ? (
                                                    <img
                                                        src={displayCustomerPhoto}
                                                        alt="Photo du client"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 200, width: 200 }}
                                                    />
                                                ) : (
                                                    <img
                                                        src={editedCustomerPhoto}
                                                        alt="Photo du client"
                                                        className="block w-32 h-auto mt-2 rounded"
                                                        style={{ height: 200, width: 200 }}
                                                    />
                                                )}
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/jpeg, image/jpg, image/png"
                                                id="customerPhoto"
                                                name="customerPhoto"
                                                onChange={handleFileChange}
                                                className="rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedNameOrSocialName" className="block text-gray-700 font-bold">
                                                Nom et Prénoms / Raison Sociale
                                            </label>
                                            <input
                                                type="text"
                                                id="editedNameOrSocialName"
                                                name="editedNameOrSocialName"
                                                value={editedNameOrSocialName}
                                                onChange={(e) => setEditedNameOrSocialName(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div>
                                            <div className="mb-2">
                                                <label htmlFor="editedCustomerType" className="block text-gray-700 font-bold">
                                                    Type de Client
                                                </label>
                                                <select
                                                    id="editedCustomerType"
                                                    name="editedCustomerType"
                                                    className="border rounded w-full py-2 px-3"
                                                    value={editedCustomerType}
                                                    onChange={(e) => { setEditedCustomerType(e.target.value); }}
                                                >
                                                    <option value="">Selectionnez le type de client</option>
                                                    <option value="Particulier">Particulier</option>
                                                    <option value="Entreprise">Entreprise</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <div>
                                                <label htmlFor="editedRegion" className="block text-gray-700 font-bold">
                                                    Région
                                                </label>
                                            </div>
                                            <select
                                                id="region"
                                                name="region"
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                value={editedRegion}
                                                onChange={handleSelectChange}
                                            >
                                                <option value="">Choisissez la region</option>
                                                <option value="EN">Extrême-Nord</option>
                                                <option value="NO">Nord</option>
                                                <option value="AD">Adamaoua</option>
                                                <option value="CE">Centre</option>
                                                <option value="LT">Littoral</option>
                                                <option value="SU">Sud</option>
                                                <option value="NW">Nord-Ouest</option>
                                                <option value="SW">Sud-Ouest</option>
                                                <option value="ES">Est</option>
                                            </select>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedCity" className="block text-gray-700 font-bold">
                                                Ville
                                            </label>
                                            <input
                                                type="email"
                                                id="editedCity"
                                                name="editedCity"
                                                value={editedCity}
                                                onChange={(e) => setEditedCity(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedLocalisation" className="block text-gray-700 font-bold">
                                                Localisation Précise
                                            </label>
                                            <input
                                                type="text"
                                                id="editedLocalisation"
                                                name="editedLocalisation"
                                                value={editedLocalisation}
                                                onChange={(e) => setEditedLocalisation(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedLieuDit" className="block text-gray-700 font-bold">
                                                Lieu-dit
                                            </label>
                                            <input
                                                type="text"
                                                id="editedLieuDit"
                                                name="editedLieuDit"
                                                value={editedLieuDit}
                                                onChange={(e) => setEditedLieuDit(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedCustomerGpsCoordinates" className="block text-gray-700 font-bold">
                                                Coordonnées GPS
                                            </label>
                                            <input
                                                type="text"
                                                id="editedCustomerGpsCoordinates"
                                                name="editedCustomerGpsCoordinates"
                                                value={editedCustomerGpsCoordinates}
                                                onChange={(e) => setEditedCustomerGpsCoordinates(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedImageLocalisation" className="block text-gray-700 font-bold">
                                                Plan de localisation
                                            </label>
                                            <div className="mb-2">
                                                <div>
                                                    {displayImageLocalisation ? (
                                                        <img
                                                            src={displayImageLocalisation}
                                                            alt="Plan de localisation"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 500, width: '100%' }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {editedImageLocalisation && editedImageLocalisation.endsWith('.jpg') || editedImageLocalisation && editedImageLocalisation.endsWith('.png') ? (
                                                                <img
                                                                    src={editedImageLocalisation}
                                                                    alt="Plan de localisation"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: 500, width: '100%' }}
                                                                />
                                                            ) : (
                                                                <iframe
                                                                    src={editedImageLocalisation}
                                                                    width="100%"
                                                                    height="500"
                                                                    title="PDF Viewer"
                                                                ></iframe>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <div className="flex m-2">
                                                    <strong className="whitespace-nowrap my-2">Modifier le plan de localisation:</strong>
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        id="preciseLocationFile"
                                                        name="preciseLocationFile"
                                                        onChange={handleFileChangeLocalisation}
                                                        className="rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedEmail" className="block text-gray-700 font-bold">
                                                Email
                                            </label>
                                            <input
                                                type="text"
                                                id="editedEmail"
                                                name="editedEmail"
                                                value={editedEmail}
                                                onChange={(e) => setEditedEmail(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedMobile" className="block text-gray-700 font-bold">
                                                N° Mobile
                                            </label>
                                            <input
                                                type="text"
                                                id="editedMobile"
                                                name="editedMobile"
                                                value={editedMobile}
                                                onChange={(e) => setEditedMobile(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        {editedIdCardNumber && (
                                            <>
                                                <div className="mb-2">
                                                    <label htmlFor="editedIdCardNumber" className="block text-gray-700 font-bold">
                                                        Numéro de la CNI
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="editedIdCardNumber"
                                                        name="editedIdCardNumber"
                                                        value={editedIdCardNumber}
                                                        onChange={(e) => setEditedIdCardNumber(e.target.value)}
                                                        className="border rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label htmlFor="cni" className="block text-gray-700 font-bold">
                                                        Carte Nationnale d'identité (Recto & Verso)
                                                    </label>
                                                    <div className="flex">
                                                        {displayRectoCni ? (
                                                            <img
                                                                src={displayRectoCni}
                                                                alt="Image Recto de la CNI"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 250, width: '50%' }}
                                                            />
                                                        ) : (
                                                            <>
                                                                {editedRectoCni && editedRectoCni.endsWith('.jpg') || editedRectoCni && editedRectoCni.endsWith('.png') ? (
                                                                    <img
                                                                        src={editedRectoCni}
                                                                        alt="Image Recto de la CNI"
                                                                        className="block w-32 h-auto mt-2 rounded"
                                                                        style={{ height: 250, width: '50%' }}
                                                                    />
                                                                ) : (
                                                                    <iframe
                                                                        src={editedRectoCni}
                                                                        width="50%"
                                                                        height="300"
                                                                        title="PDF Viewer"
                                                                    ></iframe>
                                                                )}
                                                            </>
                                                        )}

                                                        {displayVersoCni ? (
                                                            <img
                                                                src={displayVersoCni}
                                                                alt="Image Verso de la CNI"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 250, width: '50%' }}
                                                            />
                                                        ) : (
                                                            <>
                                                                {editedVersoCni && editedVersoCni.endsWith('.jpg') || editedVersoCni && editedVersoCni.endsWith('.png') ? (
                                                                    <img
                                                                        src={editedVersoCni}
                                                                        alt="Image Verso de la CNI"
                                                                        className="block w-32 h-auto mt-2 rounded"
                                                                        style={{ height: 250, width: '50%' }}
                                                                    />
                                                                ) : (
                                                                    <iframe
                                                                        src={editedVersoCni}
                                                                        width="50%"
                                                                        height="300"
                                                                        title="PDF Viewer"
                                                                    ></iframe>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <strong className="whitespace-nowrap my-2">Modifier le recto du CNI:</strong>
                                                    <input
                                                        type="file"
                                                        accept=".jpg, .jpeg, .png, .pdf"
                                                        id="idCardFront"
                                                        name="idCardFront"
                                                        onChange={handleFileChangeRectoCni}
                                                        className="rounded w-full py-2 px-3"
                                                    />
                                                </div>

                                                <div className="flex">
                                                    <strong className="whitespace-nowrap my-2">Modifier le verso du CNI:</strong>
                                                    <input
                                                        type="file"
                                                        accept=".jpg, .jpeg, .png, .pdf"
                                                        id="idCardBack"
                                                        name="idCardBack"
                                                        onChange={handleFileChangeVersoCni}
                                                        className="rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                            </>
                                        )}
                                        {editedIdPassportNumber && (
                                            <>
                                                <div className="mb-2">
                                                    <label htmlFor="editedIdPassportNumber" className="block text-gray-700 font-bold">
                                                        Numéro du passeport
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="editedIdPassportNumber"
                                                        name="editedIdPassportNumber"
                                                        value={editedIdPassportNumber}
                                                        onChange={(e) => setEditedIdPassportNumber(e.target.value)}
                                                        className="border rounded w-full py-2 px-3"
                                                    />
                                                    <p className="mt-2 bold">Passeport:</p>
                                                    {displayIdPassportFront ? (
                                                        <img
                                                            src={displayIdPassportFront}
                                                            alt="Image Recto du passeport"
                                                            className="block w-32 h-auto mt-2 rounded"
                                                            style={{ height: 250, width: '50%' }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {editedIdPassportFront && editedIdPassportFront.endsWith('.jpg') || editedIdPassportFront && editedIdPassportFront.endsWith('.png') ? (
                                                                <img
                                                                    src={editedIdPassportFront}
                                                                    alt="Image Recto du passeport"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: 250, width: '50%' }}
                                                                />
                                                            ) : (
                                                                <iframe
                                                                    src={editedIdPassportFront}
                                                                    width="100%"
                                                                    height="300"
                                                                    title="PDF Viewer"
                                                                ></iframe>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className="flex m-2">
                                                        <strong className="whitespace-nowrap my-2">Modifier le passeport :</strong>
                                                        <input
                                                            type="file"
                                                            accept="image/jpeg, image/jpg, image/png"
                                                            id="idPasseportFront"
                                                            name="idPasseportFront"
                                                            onChange={handleFileChangePassport}
                                                            className="rounded w-full py-2 px-3"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {editedIdResidencePermitNumber && (
                                            <>
                                                <div className="mb-2">
                                                    <label htmlFor="editedIdResidencePermitNumber" className="block text-gray-700 font-bold">
                                                        Numéro de la carte de séjour
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="editedIdResidencePermitNumber"
                                                        name="editedIdResidencePermitNumber"
                                                        value={editedIdResidencePermitNumber}
                                                        onChange={(e) => setEditedIdResidencePermitNumber(e.target.value)}
                                                        className="border rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                                <div>
                                                    <strong>Carte de séjour:</strong>
                                                    <div className="flex">
                                                        {displayIdResidencePermitFront ? (
                                                            <img
                                                                src={displayIdResidencePermitFront}
                                                                alt="Image Recto de la carte de séjour"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 250, width: '50%' }}
                                                            />
                                                        ) : (
                                                            <>
                                                                {editedIdResidencePermitFront && editedIdResidencePermitFront.endsWith('.jpg') || editedIdResidencePermitFront && editedIdResidencePermitFront.endsWith('.png') ? (
                                                                    <img
                                                                        src={editedIdResidencePermitFront}
                                                                        alt="Image Recto de la carte de séjour"
                                                                        className="block w-32 h-auto mt-2 rounded"
                                                                        style={{ height: 250, width: '50%' }}
                                                                    />
                                                                ) : (
                                                                    <iframe
                                                                        src={editedIdResidencePermitFront}
                                                                        width="100%"
                                                                        height="300"
                                                                        title="PDF Viewer"
                                                                    ></iframe>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="flex m-2">
                                                        <strong className="whitespace-nowrap my-2">Modifier la carte de séjour :</strong>
                                                        <input
                                                            type="file"
                                                            accept="image/jpeg, image/jpg, image/png"
                                                            id="idResidencePermitFront"
                                                            name="idResidencePermitFront"
                                                            onChange={handleFileChangeIdResidencePermit}
                                                            className="rounded w-full py-2 px-3"
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {editedCommerceRegNumber && (
                                            <>
                                                <div className="mb-2">
                                                    <label htmlFor="editedCommerceRegNumber" className="block text-gray-700 font-bold">
                                                        N° Régistre Commerce
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="editedCommerceRegNumber"
                                                        name="editedCommerceRegNumber"
                                                        value={editedCommerceRegNumber}
                                                        onChange={(e) => setEditedCommerceRegNumber(e.target.value)}
                                                        className="border rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label htmlFor="editedRectoCommerceReg" className="block text-gray-700 font-bold">
                                                        Image du registre de commerce
                                                    </label>
                                                    <div className="flex">
                                                        {displayRecotCommerceReg ? (
                                                            <img
                                                                src={displayRecotCommerceReg}
                                                                alt="Image du registre de commerce"
                                                                className="block w-32 h-auto mt-2 rounded"
                                                                style={{ height: 'auto', width: '100%' }}
                                                            />
                                                        ) : (
                                                            <>
                                                                {editedRectoCommerceReg && editedRectoCommerceReg.endsWith('.jpg') || editedRectoCommerceReg && editedRectoCommerceReg.endsWith('.png') ? (
                                                                    <img
                                                                        src={editedRectoCommerceReg}
                                                                        alt="Image du registre de commerce"
                                                                        className="block w-32 h-auto mt-2 rounded"
                                                                        style={{ height: 'auto', width: '100%' }}
                                                                    />
                                                                ) : (
                                                                    <iframe
                                                                        src={editedRectoCommerceReg}
                                                                        width="100%"
                                                                        height="500"
                                                                        title="PDF Viewer"
                                                                    ></iframe>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex m-2">
                                                    <strong className="whitespace-nowrap my-2">Modifier l'image du registre de commerce :</strong>
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg, image/jpg, image/png"
                                                        id="commerceRegistryFront"
                                                        name="commerceRegistryFront"
                                                        onChange={(e) => handleFileChangeReg}
                                                        className="rounded w-full py-2 px-3"
                                                    />
                                                </div>
                                            </>
                                        )}

                                        <div className="mb-2">
                                            <label htmlFor="editedOffer" className="block text-gray-700 font-bold mt-2">
                                                Formule d’abonnement choisie
                                            </label>

                                            <select
                                                id="editedOffer"
                                                name="editedOffer"
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                value={editedOffer}
                                                onChange={handleSelectChangeOffer}
                                            >
                                                <option value="">Sélectionnez une offre pour modifier</option>
                                                <option value="Blue Max S">Blue Max S</option>
                                                <option value="Blue Max M">Blue Max M</option>
                                                <option value="Blue Max L">Blue Max L</option>
                                                <option value="Blue Max XL">Blue Max XL</option>
                                            </select>
                                        </div>

                                        <button
                                            type="button"
                                            onClick={handleSubmit}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Enregistrer
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </>
                )
            }
        </>
    );
}

export default SuperOrder;
