import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../apiConfig';
import Header from '../admin/components/Header';
import Sidebar from '../admin/components/Sidebar';

const FeedbackList = () => {
    const [loading, setLoading] = useState(false);
    const [feedbacks, setFeedbacks] = useState([]);
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showRanking, setShowRanking] = useState(false);
    const [technicianRankings, setTechnicianRankings] = useState([]);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    useEffect(() => {
        if (token) {
            const fetchFeedbacks = async () => {
                setLoading(true);

                try {
                    const response = await axios.get(`${BASE_URL}/api/feedback/list`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setFeedbacks(response.data.feedbacks);
                    setLoading(false);
                } catch (error) {
                    console.error('Erreur lors de la récupération des feedbacks:', error);
                    setLoading(false);
                }
            };

            fetchFeedbacks();
        }
    }, [token]);

    const fetchTechnicianRankings = async () => {
        if (token) {
            try {
                const response = await axios.get(`${BASE_URL}/api/feedback/ranking`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setTechnicianRankings(response.data.rankings);
            } catch (error) {
                console.error('Erreur lors de la récupération du classement des techniciens :', error);
            }
        }
    };

    const handleDetailsClick = (feedback) => {
        setSelectedFeedback(feedback);
    };

    const handleBackClick = () => {
        setSelectedFeedback(null);
    };

    const handleRankingClick = () => {
        fetchTechnicianRankings();
        setShowRanking(true);
    };

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                        <img
                            src={require('../../assets/img/icons/gif/loading-points.webp')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div>
                ) : (
                    <>
                        <Header />
                        <Sidebar setHideText={setHideText} hideText={hideText} />

                        <div className={`container mx-auto ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                            <div className="container mx-auto p-4">
                                {!selectedFeedback && !showRanking ? (
                                    <>
                                        <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des Feedbacks clients</h1>
                                        <div className="flex justify-end">
                                            <button
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
                                                onClick={handleRankingClick}
                                            >
                                                Afficher le classement des techniciens
                                            </button>
                                        </div>
                                        <div className="overflow-x-auto">
                                            <table className="table-auto w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="px-2 py-2">Identifiant de souscription</th>
                                                        <th className="px-2 py-2">Nom du client</th>
                                                        <th className="px-2 py-2">Nom du Technicien</th>
                                                        <th className="px-2 py-2">Étoiles</th>
                                                        <th className="px-4 py-2">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {feedbacks.map((feedback) => (
                                                        <tr key={feedback.id} className="border-b hover:bg-gray-100">
                                                            <td className="py-3 px-4 text-center">{feedback.idSubscription + feedback.idSheet}</td>
                                                            <td className="py-3 px-4 text-center">{feedback.nameOrSocialName}</td>
                                                            <td className="py-3 px-4 text-center">{feedback.nom + ' ' + feedback.prenom}</td>
                                                            <td className="py-3 px-4 text-center">{'⭐'.repeat(feedback.rating)}</td>
                                                            <td className="py-3 px-4 text-center">
                                                                <button
                                                                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 m-1 rounded"
                                                                    onClick={() => handleDetailsClick(feedback)}
                                                                >
                                                                    Détails
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                ) : showRanking ? (
                                    <>
                                        <div className="flex justify-end">
                                            <button
                                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
                                                onClick={() => setShowRanking(false)}
                                            >
                                                <span className="close-icon text-white">✖</span> Retour
                                            </button>
                                        </div>
                                        <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Classement des Techniciens</h1>
                                        <div className="overflow-x-auto">
                                            <table className="table-auto w-full">
                                                <thead>
                                                    <tr>
                                                        <th className="px-2 py-2">Entité</th>
                                                        <th className="px-2 py-2">Nom du Technicien</th>
                                                        <th className="px-2 py-2">Total des Étoiles</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {technicianRankings.map((ranking) => (
                                                        <tr key={ranking.idTechnician} className="border-b hover:bg-gray-100">
                                                            <td className="py-3 px-4 text-center">{ranking.userEntity}</td>
                                                            <td className="py-3 px-4 text-center">{ranking.nom + ' ' + ranking.prenom}</td>
                                                            <td className="py-3 px-4 text-center">{ranking.totalStars}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                ) : (
                                    <div className="mt-6 p-4 bg-gray-100 rounded-lg shadow-md">
                                        <div className="flex justify-end">
                                            <div>
                                                <button
                                                    className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded float-right"
                                                    onClick={handleBackClick}
                                                >
                                                    <span className="close-icon text-white">✖</span> Fermer
                                                </button>
                                            </div>
                                        </div>
                                        <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Détails du Feedback</h1>
                                        <p><strong>Nom du Client:</strong> {selectedFeedback.nameOrSocialName}</p>
                                        <p><strong>Nom du Technicien:</strong> {selectedFeedback.nom + ' ' + selectedFeedback.prenom}</p>
                                        <p><strong>Nombre d'Étoiles:</strong> {selectedFeedback.rating}</p>
                                        <p><strong>Le technicien vous a-t-il demandé de l'argent au moment de l'installation ?</strong> {selectedFeedback.askedForMoney == 1 ? 'Oui' : 'Non' || 'Aucun commentaire.'}</p>
                                        <p><strong>Plus de détails</strong> {selectedFeedback.details || 'Aucun commentaire.'}</p>
                                        <p><strong>Votre expérience avec l'application FiberConnect a-t-elle été satisfaisante et a-t-elle facilité votre quotidien ?</strong> {selectedFeedback.appExperience === 'yes' ? 'Oui' : 'Non' || 'Aucun commentaire.'}</p>
                                        <p><strong>Quelles améliorations aimeriez-vous voir sur FiberConnect ?</strong> {selectedFeedback.improvements || 'Aucun commentaire.'}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default FeedbackList;