import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../admin/components/Sidebar";
import Header from "../admin/components/Header";
import { BASE_URL } from "../../apiConfig";
import FormatDate from "../admin/components/FormatDate";
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import '../../style.css';

const SuperOrder = () => {
    const [allSheetData, setAllSheetData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const [loading, setLoading] = useState(false);

    // SUPER ADMIN CAMTEL
    useEffect(() => {
        if (token && localStorage.getItem('userRole') === 'super_admin') {
            const fetchOrders = async () => {
                setLoading(true);

                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/superadmin/`,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const allSheetData = Object.values(response.data);
                            setFilteredData(allSheetData);
                            setAllSheetData(allSheetData);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [token]);

    useEffect(() => {
        filterData();
    }, [searchTerm, selectedStatus]);

    const searchCustomer = (e) => {
        setSearchTerm(e.target.value);
    };

    // const handleStatusChange = (e) => {
    //     setSelectedStatus(e.target.value);
    // };

    const handleShowOrderDetails = (orderId) => {
        window.open(`/order-details/${orderId}`, '_blank');
    };

    const filterData = () => {
        let filtered = allSheetData;

        if (searchTerm) {
            filtered = filtered.filter(sheet =>
                sheet.preStudyOrder.some(order =>
                    order.nameOrSocialName?.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
        // const filtered = allSheetData.filter(sheet => {
        //     const hasInstallationData = sheet.installationData && sheet.installationData.length > 0;

        //     return sheet.installationData.some(order => {
        //         // Filtre par nom ou raison sociale (recherché) et statut sélectionné
        //         // const matchesSearchTerm = order.nameOrSocialName?.toLowerCase().includes(searchTerm.toLowerCase());
        //         const matchesStatus = (
        //             (order.result === selectedStatus) || (!hasInstallationData && selectedStatus === "Pré-étude non réalisée")
        //         );

        //         // return matchesSearchTerm && matchesStatus;
        //         return  matchesStatus;
        //     });
        // });
        setFilteredData(filtered);
    };

    const handleDelete = (order) => {
        // setLoading(true);

        if (token && window.confirm('Êtes-vous sûr de vouloir supprimer cette commande ? Elle disparaîtra définitivement après cette action.')) {
            axios.delete(`${BASE_URL}/api/orders/` + order.idSheet + '?name=' + order.nameOrSocialName + '&email=' + order.email + '&idSuperAdmin=' + localStorage.getItem('userId') + '&mobile=' + order.mobile + '&sheetCreatedAt=' + order.sheetCreatedAt, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    // setLoading(false);
                    if (response.data && response.data.errorMessage) {
                        alert(response.data.errorMessage);
                    } else {
                        setFilteredData((prevData) =>
                            prevData.map((sheetData) => ({
                                ...sheetData,
                                preStudyOrder: sheetData.preStudyOrder?.filter((o) => o.idSheet !== order.idSheet),
                            })).filter((sheetData) =>
                                sheetData.preStudyOrder?.length > 0 || sheetData.installationData?.length > 0
                            )
                        );
                        alert('Commande supprimée avec succès!');
                    }
                })
                .catch((error) => {
                    alert('Erreur lors de la suppression de la commande.');
                    console.error("Erreur lors de la suppression de la commande : ", error);
                    // setLoading(false);
                });
        }
        // else {
        //     setLoading(false);
        // }
    }

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    // DOWNLOAD ORDERS
    const handleDownload = () => {
        setLoading(true);

        if (token) {
            axios.get(`${BASE_URL}/api/orders/download/orders/by/super/admin`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    let data = response.data;

                    data = data.map(item => ({
                        subscription_ID: item.idSubscription + item.idSheet,
                        photo_du_client: item.customerPhoto,
                        nom_du_client: item.nameOrSocialName,
                        type_de_client: item.customerType,
                        region: item.region,
                        ville: item.city,
                        localisation: item.localisation,
                        lieuDit: item.lieuDit,
                        coordonnees_gps_du_client: item.customerGpsCoordinates,
                        email: item.email,
                        numero_telephone_du_client: item.mobile,
                        numero_cni_du_client: item.idCardNumber,
                        numero_passeport_du_client: item.idPassportNumber,
                        numero_carte_de_sejour_du_client: item.idResidencePermitNumber,
                        image_recto_cni_du_client: item.rectoCni,
                        image_verso_cni_du_client: item.versoCni,
                        image_passeport_du_client: item.idPasseportFront,
                        image_carte_de_sejour_du_client: item.idResidencePermitFront,
                        numero_registre_de_commerce: item.commerceRegNumber,
                        image_registre_de_commerce: item.rectoCommerceReg,
                        offre_choisie: item.offerBlueMaxSelected,
                        date_de_souscription: item.sheetCreatedAt,
                        date_realisation_pre_etude: item.preEtudeCreatedAt,
                        nom_du_prestataire: item.nameEntity,
                        numero_telephone_prestataire: item.telephoneEntity,
                        email_prestataire: item.emailEntity,
                        reference_fdt: item.fdtRef,
                        reference_fat: item.fatRef,
                        distance_fat_client: item.dFatCustomer,
                        distance_fat_client: item.dFatCustomer,
                        nb_poteau_a_installer: item.nbPoToInstall,
                        nb_poteau_a_installer: item.nbPoToInstall,
                        statut_pre_etude: item.result,
                        observations_pre_etude: item.preEtudeObs,
                        date_realisation_etude: item.preEtudeUpdateByTechCamtelAt,
                        statut_etude: item.validation,
                        observations_etude: item.etudeObs,
                        crm_customer_code: item.crmCustomerCode,
                        numero_facturation_client: item.billingAccount,
                        numero_ligne_client: item.phoneNumberActivated,
                        reference_de_paiement: item.paymentRef,
                        date_de_paiement_client: item.paymentDate,
                        date_installation: item.installationCreatedAt,
                        nombre_balancelle: item.nbBalancel,
                        metrage_cable_entree_fat: item.cableMeterEntryFat,
                        nb_poteau_arme_installe: item.nbArmedInstalledPoto,
                        numero_ONT: item.ontNumber,
                        numero_de_STB_Stick: item.stbStickNumber,
                        numero_de_TB: item.tbNumber,
                        numero_de_jarretiere: item.nbGarter,
                        numero_de_smoove: item.nbSmoove,
                    }));

                    const worksheet = XLSX.utils.json_to_sheet(data);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Feuille1');

                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                    const url = window.URL.createObjectURL(dataBlob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'fiberconnect_orders.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    setLoading(false);
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des données ou de la création du fichier Excel :', error);
                    alert('Erreur lors de la récupération des données ou de la création du fichier Excel ');

                    setLoading(false);
                });
        }
    };

    // const relaunchPendingOrders = async (e) => {
    //     e.preventDefault();

    //     const userConfirmed = window.confirm("Êtes-vous sûr de vouloir relancer les commandes en attente de survey ?");

    //     if (token && userConfirmed) {
    //         setLoading(true);

    //         try {
    //             const response = await axios.post(`${BASE_URL}/api/orders/relaunch/pending`, {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${token}`
    //                 },
    //             });

    //             alert(response.data.message);
    //         } catch (error) {
    //             console.error('Erreur lors de la relance :', error);
    //             setLoading(false);
    //         }
    //     } else {
    //         console.log('Action annulée par l\'utilisateur.');
    //     }
    // };

    // const relaunchUnPaidOrders = async (e) => {
    //     e.preventDefault();

    //     const userConfirmed = window.confirm("Êtes-vous sûr de vouloir relancer les commandes en attente de paiement ?");

    //     if (token && userConfirmed) {
    //         setLoading(true);

    //         try {
    //             const response = await axios.post(`${BASE_URL}/api/orders/relaunch/unpaid`, {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${token}`
    //                 },
    //             });

    //             alert(response.data.message);
    //         } catch (error) {
    //             console.error('Erreur lors de la relance :', error);
    //             setLoading(false);
    //         }
    //     } else {
    //         console.log('Action annulée par l\'utilisateur.');
    //     }
    // }

    // const relaunchUnFavorableOrders = async (e) => {
    //     e.preventDefault();

    //     const userConfirmed = window.confirm("Êtes-vous sûr de vouloir relancer les commandes défavorables ?");

    //     if (token && userConfirmed) {
    //         setLoading(true);

    //         try {
    //             const response = await axios.post(`${BASE_URL}/api/orders/relaunch/unfavorable`, {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${token}`
    //                 },
    //             });

    //             alert(response.data.message);
    //         } catch (error) {
    //             console.error('Erreur lors de la relance :', error);
    //             setLoading(false);
    //         }
    //     } else {
    //         console.log('Action annulée par l\'utilisateur.');
    //     }
    // }

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../../assets/img/icons/gif/loading-points.webp')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <>
                        <Header />

                        <Sidebar setHideText={setHideText} hideText={hideText} />

                        <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[75%] 2xl:w-[73%]'}`}>

                            <div>
                                <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des commandes</h1>

                                <div className="flex justify-end">
                                    <div className="flex flex-col md:flex-row">
                                        <Link to="/super-sheet">
                                            <button
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-4"
                                            >
                                                Prospecter un client
                                            </button>
                                        </Link>

                                        <button
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 ml-2 mb-4"
                                            onClick={handleDownload}>Télécharger (Excel)
                                        </button>
                                    </div>
                                </div>

                                {/* <div className="flex justify-end">
                                        <div className="flex flex-col md:flex-row">
                                            <button
                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 ml-2 mb-4"
                                                onClick={relaunchPendingOrders}
                                            >
                                                Rélancer les commandes non traitées
                                            </button>

                                            <button
                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 ml-2 mb-4"
                                                onClick={relaunchUnPaidOrders}
                                            >
                                                Rélancer les commandes non payées
                                            </button>

                                            <button
                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 ml-2 mb-4"
                                                onClick={relaunchUnFavorableOrders}
                                            >
                                                Rélancer les commandes défavorables
                                            </button>
                                        </div>
                                    </div> */}

                                <div className="sm:w-[100%] md:w-[100%] lg:w-[50%] xl:w-[50%] 2xl:w-[50%] float-right">
                                    <label className="relative block">
                                        <span className="sr-only">Search</span>
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-search">
                                                <circle cx="11" cy="11" r="8" />
                                                <path d="m21 21-4.3-4.3" />
                                            </svg>
                                        </span>
                                        <input
                                            className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                            placeholder="Rechercher un client par son nom"
                                            type="text"
                                            value={searchTerm}
                                            onChange={searchCustomer}
                                        />
                                    </label>
                                </div>

                                {/* <div>
                                        <select
                                            className="block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                                            value={selectedStatus}
                                            onChange={handleStatusChange}
                                        >
                                            <option value="">Tous les statuts</option>
                                            <option value="Pré-étude non réalisée">Pré-étude non réalisée</option>
                                            <option value="Concluante">Concluante</option>
                                            <option value="Non concluante">Non concluante</option>
                                        </select>
                                    </div> */}

                                <div className="w-full mt-12 shadow-sm overflow-x-auto">
                                    <table className="w-full table-auto">
                                        <thead className="w-full">
                                            <tr>
                                                <th className="px-2 py-2 text-center">ID de la souscription</th>
                                                <th className="px-2 py-2 text-center">Nom du partenaire</th>
                                                <th className="px-2 py-2 text-center">Nom du client</th>
                                                <th className="px-2 py-2 text-center">Statut de la pré-étude</th>
                                                <th className="px-2 py-2 text-center">Statut de l'étude</th>
                                                <th className="px-2 py-2 text-center">Statut du paiement</th>
                                                <th className="px-2 py-2 text-center">Statut de l'activation</th>
                                                <th className="px-2 py-2 text-center">Statut de l'installation</th>
                                                <th className="px-4 py-2 text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="w-full text-gray-600 divide-y">
                                            {Array.isArray(filteredData) && filteredData.length > 0 ? (
                                                filteredData.map((sheetData) => (
                                                    <tr key={sheetData.idSheet} className="border-b hover:bg-gray-100">
                                                        {Array.isArray(sheetData.preStudyOrder) && sheetData.preStudyOrder.length > 0 ? (
                                                            sheetData.preStudyOrder.map((order) => (
                                                                <>
                                                                    <td className="gap-x-3 py-2 px-2 whitespace-nowrap lg:px-6">
                                                                        <div>{order.idSubscription + order.idSheet}</div>
                                                                    </td>
                                                                    <td className="px-2 py-2 text-center lg:px-6">
                                                                        {order.name === '' || order.name === null ? 'Client autoprospecté' : order.name}
                                                                    </td>
                                                                    <td className="px-2 py-2 lg:px-6 text-center">{order.nameOrSocialName}</td>
                                                                </>
                                                            ))
                                                        ) : (
                                                            <p>Aucune donnée de preStudyOrder disponible</p>
                                                        )}

                                                        {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                            sheetData.installationData.map((installation) => (
                                                                <>
                                                                    <td className="px-2 lg:px-6 py-2 text-center">{installation.result === '' || installation.result === null ? 'Pré-étude non réalisée' : installation.result}</td>
                                                                    <td className="px-2 lg:px-6 py-2 text-center">
                                                                        {installation.validation === '' || installation.validation === null ? 'Étude non réalisée' : installation.validation}
                                                                    </td>
                                                                </>
                                                            ))
                                                        ) : (
                                                            <>
                                                                <td className="px-2 py-2 lg:px-6 text-center">Pré-étude non réalisée</td>
                                                                <td className="px-2 py-2 lg:px-6 text-center">Étude non réalisée</td>
                                                            </>
                                                        )}

                                                        {Array.isArray(sheetData.paymentData) && sheetData.paymentData.length > 0 ? (
                                                            sheetData.paymentData.map((payment) => (
                                                                <td className="px-2 lg:px-6 py-2 text-center">
                                                                    {
                                                                        sheetData.activationData && sheetData.activationData.length > 0 && sheetData.activationData[0].idActivation
                                                                            ? 'Commande payée'
                                                                            : payment.status === 'SUCCESSFULL' ? 'Commande payée' : 'Commande non payée'
                                                                    }
                                                                </td>
                                                            ))
                                                        ) : (
                                                            <td className="px-2 py-2 lg:px-6 text-center">Commande non payée</td>
                                                        )}

                                                        {Array.isArray(sheetData.activationData) && sheetData.activationData.length > 0 ? (
                                                            sheetData.activationData.map((activation) => (
                                                                <td className="px-2 py-2 lg:px-6 text-center">
                                                                    {activation.idActivation === '' || activation.idActivation === null ? 'Service non activé' : 'Service activé'}
                                                                </td>
                                                            ))
                                                        ) : (
                                                            <td className="px-2 py-2 lg:px-6 text-center">Service non activé</td>
                                                        )}

                                                        {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                            sheetData.installationData.map((installation) => (
                                                                <td className="px-2 py-2 lg:px-6 text-center">
                                                                    {installation.idTechPartner !== null ? 'Installé' : 'Non installé'}
                                                                </td>
                                                            ))
                                                        ) : (
                                                            <td className="px-2 py-2 lg:px-6 text-center">Non installé</td>
                                                        )}

                                                        <td className="lg:px-6 text-center p-1">
                                                            <div className="flex items-center justify-center">
                                                                {Array.isArray(sheetData.preStudyOrder) && sheetData.preStudyOrder.length > 0 ? (
                                                                    sheetData.preStudyOrder.map((order) => (
                                                                        <>
                                                                            <button
                                                                                onClick={() => handleShowOrderDetails(order.idSheet)}
                                                                                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold m-1 py-1 px-2"
                                                                            >
                                                                                Détails
                                                                            </button>

                                                                            <button
                                                                                onClick={() => handleDelete(order)}
                                                                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 m-1"
                                                                            >
                                                                                Supprimer
                                                                            </button>
                                                                        </>
                                                                    ))
                                                                ) : (
                                                                    null
                                                                )}

                                                                {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                                    sheetData.installationData.map((installation) => (
                                                                        <div className="">
                                                                            {installation.idInstallation !== null && installation.idInstallation !== '' && (
                                                                                <Link
                                                                                    to={`/fiche/${installation.idSheet}`}
                                                                                    className="bg-green-500 hover:bg-green-700 text-white font-bold m-1 py-2 px-2 whitespace-nowrap"
                                                                                >
                                                                                    Prévisualiser la fiche
                                                                                </Link>
                                                                            )}
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    null
                                                                )}

                                                                {Array.isArray(sheetData.preStudyOrder) && sheetData.preStudyOrder.length > 0 && (
                                                                    sheetData.preStudyOrder.map((order) => (
                                                                        <Link
                                                                            to={`/contract/${order.idSheet}`}
                                                                            className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-1 px-2 m-3 whitespace-nowrap"
                                                                        >
                                                                            Visualiser le contrat
                                                                        </Link>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <td colSpan={9} className="text-center">Aucune commande disponible</td>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </>
                )
            }
        </>
    );
}

export default SuperOrder;