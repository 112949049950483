// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-hidden {
    width: 60px;
}

.text-hidden ul li a span {
    display: none;
}

.text-hidden ul li {
    margin-left: -4px;
}

.text-hidden ul li .icon {
    margin-left: -3px;
}

.small-image {
    width: 50px;
}

.small-avatar {
    height: 25px;
}

.main-container {
    margin-left: 60px;
    width: 96%;
}

@media (max-width: 640px) {
    .main-container {
        margin-left: 0;
        width: 100%;
    }
}

.font-medium li:hover a {
    color: white;
    background: rgb(38, 181, 238);
}

.font-medium li:hover .flex .text-gray-500 {
    color: white;
}

@keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
}

.fade-in-out {
    animation: fadeInOut 2s infinite;
}
`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI;QACI,cAAc;QACd,WAAW;IACf;AACJ;;AAEA;IACI,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW,UAAU,EAAE;IACvB,MAAM,UAAU,EAAE;AACtB;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".text-hidden {\n    width: 60px;\n}\n\n.text-hidden ul li a span {\n    display: none;\n}\n\n.text-hidden ul li {\n    margin-left: -4px;\n}\n\n.text-hidden ul li .icon {\n    margin-left: -3px;\n}\n\n.small-image {\n    width: 50px;\n}\n\n.small-avatar {\n    height: 25px;\n}\n\n.main-container {\n    margin-left: 60px;\n    width: 96%;\n}\n\n@media (max-width: 640px) {\n    .main-container {\n        margin-left: 0;\n        width: 100%;\n    }\n}\n\n.font-medium li:hover a {\n    color: white;\n    background: rgb(38, 181, 238);\n}\n\n.font-medium li:hover .flex .text-gray-500 {\n    color: white;\n}\n\n@keyframes fadeInOut {\n    0%, 100% { opacity: 0; }\n    50% { opacity: 1; }\n}\n\n.fade-in-out {\n    animation: fadeInOut 2s infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
