import React from "react";
import Navbar from "../components/Navbar";
import bgImage from '../assets/img/bg/power.png';
import Footer from "../components/Footer";

const Home = () => {
    return (
        <>
            <Navbar />

            {/* Image en arrière-plan */}
            <div
                className="bg-cover bg-center h-screen"
                style={{ backgroundImage: `url(${bgImage})` }}
            >
                {/* Contenu de la page
                <div className="h-full flex items-center justify-center text-white text-4xl font-bold">
                    Bienvenue sur la page d'accueil
                </div> */}
            </div>

            <Footer/>
        </>
    )
}

export default Home;