import { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import { useMap } from 'react-leaflet';

const LeafletRoutingMachine = ({ start, end }) => {
    const map = useMap();

    useEffect(() => {
        L.Routing.control({
            waypoints: [
                L.latLng(start[0], start[1]),
                L.latLng(end[0], end[1])
            ],
            fitSelectedRoutes: false,
            lineOptions: {
                styles: [
                    {
                        color: 'blue',
                        weight: 4,
                        opacity: 0.5,
                    }
                ]
            },
            routeWhileDragging: false,
            show: false
        }).addTo(map);        
    })

    return null;
}

export default LeafletRoutingMachine;