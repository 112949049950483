import React, { useState, useEffect } from 'react';

const CountDownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = new Date(targetDate) - new Date();
        let timeLeft = {};

        if (difference !== 0) {
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            timeLeft = {
                days,
                hours,
                minutes,
                seconds
            };
        } else {
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    const { days, hours, minutes, seconds } = timeLeft;
    const isPast = new Date(targetDate) < new Date();

    return (
        <div style={{ color: isPast ? 'red' : 'green', fontWeight: 'bold' }}>
            {days === -1 && isPast 
                ? `- ${Math.abs(hours)} heures ${Math.abs(minutes)} minutes ${Math.abs(seconds)} secondes`
                : `${days} jours ${hours} heures ${minutes} minutes ${seconds} secondes`}
        </div>
    );
};

export default CountDownTimer;