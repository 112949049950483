import React, { useState, useEffect } from 'react'

const Navbar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            {window.innerWidth <= 750 ? (
                <header className="bg-gray-200 p-4">
                    <div className="flex items-center justify-between">
                        {/* <h1 className="text-2xl font-bold">FIBER CONNECT</h1> */}
                        <img src={require('../assets/img/logo/logo-camtel.png')} />
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="block sm:hidden"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className={`w-6 h-6 ${isOpen ? 'transform rotate-180' : ''}`}
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                    {isOpen && (
                        <div className="mt-2">
                            <a href="/sheet" className="block text-cyan-800 hover:text-cyan-600 py-2">
                                Faire une demande
                            </a>
                        </div>
                    )}
                </header>
            ) : (
                <nav className="bg-white w-full border-b md:border-0 md:static">
                    <div className="items-center px-4 max-w-screen mx-auto md:flex md:px-8">
                        <div className="flex-1 items-center justify-between py-3 md:py-5 md:block">
                            <a href="javascript:void(0)">
                                <img
                                    src={require('../assets/img/icons/favicon/favicon-camtel.png')}
                                    width={120}
                                    height={50}
                                    alt="Float UI logo"
                                />
                            </a>
                        </div>
                        <div className="hidden md:inline-block">
                            <a href="/sheet" className="py-3 px-4 text-white bg-indigo-600 hover:bg-indigo-700 rounded-md shadow">
                                Faire une demande
                            </a>
                        </div>
                    </div>
                </nav>
            )}
        </>

    )
}



export default Navbar;