import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    localStorage.removeItem('userEntity');
    localStorage.removeItem('userRole');
    localStorage.removeItem('token');
    localStorage.removeItem('userInfos');
    navigate('/login');
  };

  return (
    <header className="bg-gray-200 p-4 sm:block md:block lg:hidden xl:hidden 2xl:hidden">
      <div className="flex items-center justify-between">
        <img src={require('../../../assets/img/logo/new-camtel-logo-certified.png')} width={120} />
        <button
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className={`w-6 h-6 ${isOpen ? 'transform rotate-180' : ''}`}
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="mt-2">
          <a href="/admin" className="block text-gray-800 hover:text-gray-600 py-2">
            Accueil
          </a>
          {localStorage.getItem('userRole') === 'super_admin' && (
            <>
              <a href="/agency" className="block text-gray-800 hover:text-gray-600 py-2">
                Agences
              </a>
              <a href="/partner" className="block text-gray-800 hover:text-gray-600 py-2">
                Partenaires
              </a>
              <a href="/super-order" className="block text-gray-800 hover:text-gray-600 py-2">
                Commandes
              </a>
              <a href="/payment" className="block text-gray-800 hover:text-gray-600 py-2">
                Paiement
              </a>
              <a href="/appointment" className="block text-gray-800 hover:text-gray-600 py-2">
                RDV
              </a>
              <a href="/feedback-list" className="block text-gray-800 hover:text-gray-600 py-2">
                Feedback
              </a>
            </>
          )}
          {localStorage.getItem('userRole') === 'admin_camtel' && (
            <>
              <a href="/profile" className="block text-gray-800 hover:text-gray-600 py-2">
                Profil
              </a>
              <a href="/partner" className="block text-gray-800 hover:text-gray-600 py-2">
                Partenaires
              </a>
            </>
          )}
          {localStorage.getItem('userRole') === 'admin_partner' && (
            <>
              <a href="/profile" className="block text-gray-800 hover:text-gray-600 py-2">
                Profil
              </a>
            </>
          )}
          {localStorage.getItem('userRole') === 'admin_camtel' || localStorage.getItem('userRole') !== 'admin_partner' || localStorage.getItem('userRole') !== 'commercial_partner' && (
            <a href="/order" className="block text-gray-800 hover:text-gray-600 py-2">
              Commandes
            </a>
          )}
          {localStorage.getItem('userRole') === 'commercial_partner' && (
            <>
              <a href="/order" className="block text-gray-800 hover:text-gray-600 py-2">
                Commandes
              </a>
            </>
          )}
          {localStorage.getItem('userRole') === 'commercial_camtel' && (
            <>
              <a href="/activation" className="block text-gray-800 hover:text-gray-600 py-2">
                Commandes
              </a>
            </>
          )}
          {localStorage.getItem('userRole') === 'technicien_camtel' && (
            <>
              <a href="/pre-study-order" className="block text-gray-800 hover:text-gray-600 py-2">
                Commandes
              </a>
            </>
          )}
          {localStorage.getItem('userRole') === 'technicien_partner' && (
            <>
              <a href="/installation" className="block text-gray-800 hover:text-gray-600 py-2">
                Installation
              </a>
            </>
          )}
          <a href="/help" className="block text-gray-800 hover:text-gray-600 py-2">
            Aide
          </a>
          <a href="/settings" className="block text-gray-800 hover:text-gray-600 py-2">
            Paramètres
          </a>
          <button onClick={handleLogout} className='mt-2'>Déconnexion</button>
        </div>
      )}
    </header>
  );
};

export default Header;