import React, { useState } from 'react';
import WhatsAppButton from '../components/WhatsAppButton';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const [showModal, setShowModal] = useState(false);
    const [showGpsModal, setShowGpsModal] = useState(false);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);
    const navigate = useNavigate();

    const handleRequestClick = () => {
        setShowModal(true);
    };

    const handleFirstQuestionResponse = (response) => {
        if (response === 'yes') {
            navigate('/sheet');
        } else {
            setShowModal(false);
            setShowGpsModal(true);
        }
    };

    const handleGpsQuestionResponse = (response) => {
        if (response === 'yes') {
            navigate('/sheet');
        } else {
            setShowGpsModal(false);
            setShowThankYouMessage(true);
        }
    };

    return (
        <main className="w-full flex">
            <div
                className="relative flex-1 hidden items-center justify-center h-screen bg-gray-900 lg:flex bg-contain bg-no-repeat bg-center"
                style={{ backgroundImage: `url(${require('../assets/img/bg/animiertes-gif-von-online-umwandeln-de.gif')})` }}
            ></div>
            <div className="flex-1 flex items-center justify-center h-screen">
                <div className="w-full space-y-8 px-4 bg-white text-gray-600 sm:px-0">
                    <div className="w-full text-center">
                        <img
                            src={require('../assets/img/icons/favicon/favicon-camtel.png')}
                            width={220}
                            className="mx-auto mb-5"
                        />

                        <div className="mt-5 space-y-2">
                            <h3 className="text-gray-800 text-1xl font-bold sm:text-3xl text-center">
                                Bienvenue à la plateforme de souscription en ligne de nos offres fibre optique.
                            </h3>
                        </div>

                        <div className="mt-10 mb-10 flex flex-col md:flex-row justify-center items-center">
                            <button
                                onClick={handleRequestClick}
                                className="px-7 py-4 mb-4 md:mb-0 text-white duration-150 bg-sky-500 rounded-full hover:bg-sky-600 active:bg-sky-700"
                            >
                                Faire une demande
                            </button>
                            <a
                                href="/follow-up"
                                className="px-7 py-4 mt-4 md:mt-0 ml-0 md:ml-2 text-white duration-150 bg-sky-500 rounded-full hover:bg-sky-600 active:bg-sky-700"
                            >
                                Suivre ma demande
                            </a>
                        </div>

                        <div className="mt-5 space-y-2">
                            <h3 className="text-gray-800 text-1xl font-bold sm:text-3xl text-center">
                                Welcome to the online platform for subscription to our fibre optic offers.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4 text-center">
                            Êtes-vous actuellement sur le lieu où vous souhaitez faire installer la Fibre Optique ?
                        </h2>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                onClick={() => handleFirstQuestionResponse('yes')}
                            >
                                Oui
                            </button>
                            <button
                                className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                onClick={() => handleFirstQuestionResponse('no')}
                            >
                                Non
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showGpsModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4 text-center">
                            Vu que vous n'êtes pas sur place, pouvez-vous obtenir vos coordonnées GPS via Google Maps ou en les sélectionnant sur la carte interactive du formulaire d'abonnement ?
                        </h2>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                onClick={() => handleGpsQuestionResponse('yes')}
                            >
                                Oui
                            </button>
                            <button
                                className="px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                                onClick={() => handleGpsQuestionResponse('no')}
                            >
                                Non
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showThankYouMessage && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/3">
                        <h2 className="text-lg font-bold mb-4 text-center text-gray-700">
                            Merci pour l'intérêt que vous portez aux services Fiber To The Home (FTTH) de CAMTEL.
                        </h2>
                        <p className="text-center text-gray-600">
                            Nous vous invitons à faire votre souscription une fois que vous serez sur le lieu où vous souhaitez faire installer la Fibre Optique.
                        </p>
                        <div className="mt-4 flex justify-center">
                            <button
                                className="px-6 py-2 bg-sky-500 text-white rounded-lg hover:bg-sky-600"
                                onClick={() => setShowThankYouMessage(false)}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <WhatsAppButton />
        </main>
    );
}

export default Index;
