import { useMapEvents } from "react-leaflet";

const LocationFinder = ({ onMapClick }) => {
    useMapEvents({
        click(e) {
            onMapClick(e.latlng);
        },
    });

    return null;
};

export default LocationFinder;