import React, { useState, useEffect, useMemo, useCallback } from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMap, useMapEvents } from 'react-leaflet';
import '../../location.css';
import { Icon } from 'leaflet';
import LeafletRoutingMachine from '../../components/LeafletRoutingMachine';
import axios from 'axios';
import { BASE_URL } from '../../apiConfig';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const AdminLocation = () => {
    const { id } = useParams();

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [slotsCache, setSlotsCache] = useState({});

    const [loading, setLoading] = useState(false);
    const [markers, setMarkers] = useState([]);

    const [closestMarker, setClosestMarker] = useState(null);
    const [closestMarkerErrorMessage, setClosestMarkerErrorMessage] = useState(null);
    const [wrongGpsCoordinates, setWrongGpsCoordinates] = useState(null);
    const [undifinedGpsCoords, setUndifinedGpsCoords] = useState(null);
    const [successMessage, setSuccesMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [endSuccessMessage, setEndSuccessMessage] = useState(false);

    const [subscriptionDate, setSubscriptionDate] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [customerEmail, setCustomerEmail] = useState(null);
    const [offer, setOffer] = useState(null);
    const [idSubscription, setIdSubscription] = useState('');
    const [processInit, setProcessInit] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const [latitudeFat, setLatitudeFat] = useState(null);
    const [longitudeFat, setLongitudeFat] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (localStorage.getItem('techId')) {
                try {
                    const res = await axios.get(`${BASE_URL}/api/appointment/${localStorage.getItem('techId')}`);
                    setAppointments(res.data.appointments);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        fetchData();
    }, [localStorage.getItem('techId')]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setSelectedTime(null);
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };

    const getBookedHoursForDate = useCallback((date) => {
        return appointments
            .filter(app => {
                const appDate = new Date(app.appointmentDateTime);
                return appDate.getFullYear() === date.getFullYear() &&
                    appDate.getMonth() === date.getMonth() &&
                    appDate.getDate() === date.getDate();
            })
            .map(app => new Date(app.appointmentDateTime).getHours());
    }, [appointments]);

    const isDateFullyBooked = (date) => {
        const bookedHours = getBookedHoursForDate(date);
        const allHours = [9, 10, 11, 12, 13, 14];
        return allHours.every(hour => bookedHours.includes(hour));
    };

    const generateTimeSlots = useCallback((date) => {
        if (slotsCache[date.toDateString()]) {
            return slotsCache[date.toDateString()];
        }

        const bookedHours = getBookedHoursForDate(date);
        const slots = [];
        for (let hour = 9; hour <= 14; hour++) {
            if (!bookedHours.includes(hour) &&
                (hour === 9 ? !bookedHours.includes(hour + 1) :
                    hour === 10 ? !bookedHours.includes(hour - 1) && !bookedHours.includes(hour + 1) :
                        hour === 11 ? !bookedHours.includes(hour - 1) && !bookedHours.includes(hour + 1) :
                            hour === 12 ? !bookedHours.includes(hour - 1) && !bookedHours.includes(hour + 1) :
                                hour === 13 ? !bookedHours.includes(hour - 1) && !bookedHours.includes(hour + 1) :
                                    !bookedHours.includes(hour - 1))) {
                slots.push(`${hour}:00`);
            }
        }

        setSlotsCache(prevCache => ({
            ...prevCache,
            [date.toDateString()]: slots
        }));

        return slots;
    }, [getBookedHoursForDate, slotsCache]);

    const availableTimeSlots = useMemo(() => {
        if (selectedDate) {
            return generateTimeSlots(selectedDate);
        }
        return [];
    }, [selectedDate, generateTimeSlots]);

    const isWeekday = (date) => {
        if (!date) return false;

        const day = date.getDay();
        const today = new Date();
        let daysToAdd = 3;
        while (daysToAdd > 0) {
            today.setDate(today.getDate() + 1);
            if (today.getDay() !== 0 && today.getDay() !== 6) {
                daysToAdd--;
            }
        }
        return day !== 0 && day !== 6 && date >= today;
    };

    const filterDate = (date) => {
        return isWeekday(date) && !isDateFullyBooked(date) && generateTimeSlots(date).length > 0;
    };

    useEffect(() => {
        if (id) {
            const fetchOrder = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/orders/pdf/${id}`,
                        headers: {}
                    };

                    const response = await axios.request(config);

                    if (response.data && response.data.sheet && response.data.sheet.length > 0) {
                        const sheet = response.data.sheet[0];

                        if (sheet.idSheet) {
                            setIdSubscription(sheet.idSheet);

                            let idSubscription = sheet.idSubscription;
                            if (idSubscription.endsWith('-')) {
                                idSubscription = idSubscription.slice(0, -1);
                            }
                            setSubscriptionDate(idSubscription);
                            setCustomerName(sheet.nameOrSocialName);
                            setCustomerEmail(sheet.email);

                            if (sheet.customerGpsCoordinates) {
                                const [lat, lon] = sheet.customerGpsCoordinates.split(',');

                                const regex = /^\d+\.\d+$/;

                                if (regex.test(lat) && regex.test(lon)) {
                                    setLatitude(parseFloat(lat));
                                    setLongitude(parseFloat(lon));
                                } else {
                                    setUndifinedGpsCoords('Les coordonnées GPS fournies semblent incorrectes. Merci de contacter notre service client via WhatsApp au 620 10 50 50 pour assistance.');
                                }
                            }

                            setOffer(sheet.offerBlueMaxSelected);
                        } else {
                            alert('Commande inexistante.');
                        }
                    } else {
                        alert('Commande inexistante.');
                    }

                    const preStudyData = await axios.get(`${BASE_URL}/api/prestudy/single/${id}`);
                    if (preStudyData.data && preStudyData.data.preStudyOrder && preStudyData.data.preStudyOrder.length > 0) {
                        const preStudyOrder = preStudyData.data.preStudyOrder[0];

                        if (preStudyOrder.fatGpsCoordinates) {
                            const [fatLat, fatLon] = preStudyOrder.fatGpsCoordinates.split(',');
                            setLatitudeFat(parseFloat(fatLat));
                            setLongitudeFat(parseFloat(fatLon));
                        } else {
                            console.log('Données de pré-étude inexistantes.');
                        }
                    } else {
                        console.log('Données de pré-étude inexistantes.');
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération de la commande: ', error);
                }
            };

            fetchOrder();
        }
    }, [id]);

    const getDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371;
        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d;
    }

    const deg2rad = (deg) => {
        return deg * (Math.PI / 180);
    }

    useEffect(() => {
        const fetchFats = async () => {

            if (latitude && longitude) {
                const regex = /^-?\d+\.\d+,-?\d+\.\d+$/;

                if (regex.test(`${latitude},${longitude}`)) {
                    try {
                        let config = {
                            method: 'get',
                            maxBodyLength: Infinity,
                            url: `${BASE_URL}/api/fat?lat=${latitude}&lon=${longitude}`,
                            headers: {}
                        };

                        const response = await axios.request(config);

                        if (response.data && Object.values(response.data).some(arr => arr.length > 0)) {
                            const newMarkerss = Object.values(response.data).flat().map(item => ({
                                geocode: [item.LATITUDE, item.LONGITUDE],
                                popUp: `${item.CENTRAL} ${item.QUARTIER} ${item.LOCALISATION}, Taux de saturation: ${item.SATURATION * 100}%, Ports disponibles: ${item.LIBRE}`,
                                central: [item.CENTRAL],
                                fdtRef: [item.FDT],
                                fatRef: [item.FAT],
                                olt: [item.OLT],
                                fsp: [item.FSP],
                                nbFreePorts: [item.LIBRE],
                                saturationPercent: [item.SATURATION],
                            }));

                            setMarkers(newMarkerss);

                            if (newMarkerss.length > 0) {
                                let closestMarker = null;
                                let minDistance = Infinity;

                                newMarkerss.forEach(marker => {
                                    if (latitude && longitude) {
                                        const distance = getDistance(latitude, longitude, marker.geocode[0], marker.geocode[1]);
                                        if (distance < minDistance && marker.nbFreePorts[0] > 0) {
                                            minDistance = distance;
                                            closestMarker = marker;
                                        }
                                    }
                                });

                                if (closestMarker) {
                                    const distance = getDistance(latitude, longitude, closestMarker.geocode[0], closestMarker.geocode[1]);

                                    let preEtudeObs = '';
                                    let result = '';
                                    let etudeObs = ''
                                    let validation = ''

                                    if (distance <= 0.3) {
                                        setLoading(false);

                                        preEtudeObs = `La distance entre le client et la fat est de ${Math.round(distance * 1000)} mètre (s), dont favorable. Bien plus, le nombre de port disponible est de ${closestMarker.nbFreePorts[0]} avec comme taux de saturation de ${closestMarker.saturationPercent[0] * 100}%.`;
                                        result = 'Concluante';
                                        etudeObs = 'RAS';
                                        validation = 'Favorable';

                                        setSuccesMessage('Félicitations! Vous êtes éligible à un abonnement à la fibre. Vous pouvez payer directement les frais d\'installation.');
                                    } else {
                                        setLoading(false);

                                        preEtudeObs = `Distance client-FAT: ${Math.round(distance * 1000)}, Nombre de ports disponible: ${closestMarker.nbFreePorts[0]}, Taux de saturation: ${closestMarker.saturationPercent[0] * 100}%.`;
                                        result = 'Non concluante';
                                        etudeObs = 'RAS';
                                        validation = 'Défavorable';

                                        setErrorMessage('Cher(e) prospect(e), votre localisation semble inéligible à date pour une installation de notre service FIBER TO THE HOME. Nous vous contacterons sous 48H après vérifications supplémentatires pour confirmation. Dans l\'attente d\'actions correctives, vous pouvez souscrire à nos offres mobiles.');
                                    }

                                    const postData = async () => {
                                        try {
                                            const response = await axios.get(`${BASE_URL}/api/connection?central=${closestMarker.central[0]}`);
                                            const data = response.data.entities[0];

                                            if (data && data.idPartner) {
                                                localStorage.setItem('idPartner', data.idPartner);
                                            }

                                            const res = await axios.get(`${BASE_URL}/api/user/${data.idPartner}`);
                                            let userData;
                                            if (res.data && res.data.techs) {
                                                userData = res.data.techs;
                                            }

                                            async function fetchInstallationData(techs) {
                                                if (techs.length === 0) {
                                                    return;
                                                }

                                                let minElements = Infinity;
                                                let minTech = null;
                                                let latestAppointment = null;

                                                for (let tech of techs) {
                                                    try {
                                                        const response = await axios.get(`${BASE_URL}/api/appointment/${tech.id}`);

                                                        const currentMonthAppointments = response.data.appointments.filter(appointment => {
                                                            const appointmentDate = new Date(appointment.appointmentDateTime);
                                                            const now = new Date();
                                                            return appointmentDate.getMonth() === now.getMonth() && appointmentDate.getFullYear() === now.getFullYear();
                                                        });

                                                        if (currentMonthAppointments.length === 0) {
                                                            localStorage.setItem('techId', tech.id);
                                                            localStorage.setItem('idPartner', tech.idEntity);
                                                        } else {
                                                            const currentLatestAppointment = currentMonthAppointments.reduce((latest, appointment) => {
                                                                const latestDate = new Date(latest.appointmentDateTime);
                                                                const currentAppointmentDate = new Date(appointment.appointmentDateTime);
                                                                return currentAppointmentDate > latestDate ? appointment : latest;
                                                            }, currentMonthAppointments[0]);

                                                            if (currentMonthAppointments.length < minElements) {
                                                                minElements = currentMonthAppointments.length;
                                                                minTech = tech;
                                                                latestAppointment = currentLatestAppointment;

                                                                localStorage.setItem('latestAppointment', JSON.stringify(latestAppointment));
                                                            }
                                                        }

                                                        if (latestAppointment) {
                                                            const appointmentDate = new Date(latestAppointment.appointmentDateTime);

                                                            const localDate = appointmentDate.toLocaleString();

                                                            const endDateHour = new Date(latestAppointment.appointmentDateTime);
                                                            endDateHour.setHours(endDateHour.getHours() + 1);

                                                            localStorage.setItem('startAppointmentDate', localDate);

                                                            localStorage.setItem('techId', minTech.id);
                                                            localStorage.setItem('idPartner', minTech.idEntity);
                                                        } else {
                                                            const todayDate = new Date();
                                                            const localDate = todayDate.toLocaleString();

                                                            localStorage.setItem('startAppointmentDate', localDate);
                                                            localStorage.setItem('techId', tech.id);
                                                            localStorage.setItem('idPartner', tech.idEntity);
                                                        }
                                                    } catch (error) {
                                                        console.error(`Erreur lors de la récupération des données d'installation pour ${tech.nom}:`, error);
                                                    }
                                                }
                                            }

                                            await fetchInstallationData(userData);

                                            let techId = 0;
                                            if (localStorage.getItem('techId')) {
                                                techId = localStorage.getItem('techId');
                                            }

                                            await axios.post(`${BASE_URL}/api/prestudy/bypass`, {
                                                "techId": techId,
                                                "idEntity": data.idPartner,
                                                "nameEntity": data.namePartner,
                                                "customerEmail": customerEmail,
                                                "customerName": customerName,
                                                "offer": offer,
                                                "idSheet": idSubscription,
                                                "fdtRef": closestMarker.fdtRef[0],
                                                "fatRef": closestMarker.fatRef[0],
                                                "dFatCustomer": Math.round(distance * 1000),
                                                "oltSfp": closestMarker.olt[0] + '-' + closestMarker.fsp[0],
                                                "fatGpsCoordinates": closestMarker.geocode[0] + ',' + closestMarker.geocode[1],
                                                "customerGpsCoordinates": latitude + ',' + longitude,
                                                "preEtudeObs": preEtudeObs,
                                                "result": result,
                                                "etudeObs": etudeObs,
                                                "validation": validation
                                            });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }

                                    postData();
                                }

                                setClosestMarker(closestMarker);
                            } else {
                                const preEtudeObs = `Zone non desservie.`;
                                const result = 'Non concluante';
                                const etudeObs = `Zone non desservie.`;
                                const validation = 'Défavorable';

                                await axios.post(`${BASE_URL}/api/prestudy/bypass`, {
                                    "techId": 0,
                                    "idEntity": 0,
                                    "nameEntity": 'NULL',
                                    "customerEmail": customerEmail,
                                    "customerName": customerName,
                                    "offer": offer,
                                    "idSheet": idSubscription,
                                    "fdtRef": 'NULL',
                                    "fatRef": 'NULL',
                                    "dFatCustomer": 'NULL',
                                    "oltSfp": 'NULL',
                                    "fatGpsCoordinates": 'NULL',
                                    "customerGpsCoordinates": latitude + ',' + longitude,
                                    "preEtudeObs": preEtudeObs,
                                    "result": result,
                                    "etudeObs": etudeObs,
                                    "validation": validation
                                });

                                setClosestMarkerErrorMessage('Cher(e) prospect(e), votre localisation semble inéligible à date pour une installation de notre service FIBER TO THE HOME. Nous vous contacterons sous 48H après vérifications supplémentatires pour confirmation. Dans l\'attente d\'actions correctives, vous pouvez souscrire à nos offres mobiles.');
                                console.log('NO FATs 1');
                                setLoading(false);
                            }
                        } else {
                            const preEtudeObs = `Zone non desservie.`;
                            const result = 'Non concluante';
                            const etudeObs = `Zone non desservie.`;
                            const validation = 'Défavorable';

                            await axios.post(`${BASE_URL}/api/prestudy/bypass`, {
                                "techId": 0,
                                "idEntity": 0,
                                "nameEntity": 'NULL',
                                "customerEmail": customerEmail,
                                "customerName": customerName,
                                "offer": offer,
                                "idSheet": idSubscription,
                                "fdtRef": 'NULL',
                                "fatRef": 'NULL',
                                "dFatCustomer": 'NULL',
                                "oltSfp": 'NULL',
                                "fatGpsCoordinates": 'NULL',
                                "customerGpsCoordinates": latitude + ',' + longitude,
                                "preEtudeObs": preEtudeObs,
                                "result": result,
                                "etudeObs": etudeObs,
                                "validation": validation
                            });

                            setClosestMarkerErrorMessage('Cher(e) prospect(e), votre localisation semble inéligible à date pour une installation de notre service FIBER TO THE HOME. Nous vous contacterons sous 48H après vérifications supplémentatires pour confirmation. Dans l\'attente d\'actions correctives, vous pouvez souscrire à nos offres mobiles.');
                            setLoading(false);
                        }
                    } catch (error) {
                        console.error('Erreur lors de la récupération des données des FATs', error);
                        setLoading(false);
                    }
                } else {
                    console.log('Incorrect Gps coords');
                    setWrongGpsCoordinates('Les coordonnées GPS fournies semblent incorrectes. Merci de contacter notre service client via WhatsApp au 620 10 50 50 pour assistance.');
                    setLoading(false);
                }
            }
        };

        fetchFats();
    }, [latitude, longitude]);

    const customIcon = new Icon({
        iconUrl: require('../../assets/img/icons/map/epingle.png'),
        iconSize: [38, 38]
    });

    const customHomeIcon = new Icon({
        iconUrl: require('../../assets/img/icons/map/location.png'),
        iconSize: [38, 38]
    });

    const closeModal = () => {
        setIsOpen(false);
    }

    const useWindowWidth = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWindowWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowWidth;
    }

    const windowWidth = useWindowWidth();
    const modalWidth = windowWidth < 480 ? '90%' : windowWidth < 768 ? '90%' : '50%';

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: modalWidth,
            height: '95%',
            border: '1px solid #ccc',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
        },
        overlay: {
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
        },
    };

    const CustomModal = ({ isOpen, closeModal }) => {

        const [formData, setFormData] = useState({
            idSubscription: subscriptionDate,
            idSheet: idSubscription,
            nameOrSocialName: customerName,
            installationAmount: '50000',
            subscriberMsisdn: ''
        });

        const handleChangeNumber = (e) => {
            const value = e.target.value;
            setFormData({ ...formData, subscriberMsisdn: value });
        };

        let operator = null;
        const isValidPhoneNumber = (phoneNumber) => {
            const orangeRegex = /^(655|656|657|658|659|69[0-9]{1}|659[6-9])/;
            const mtnRegex = /^(650|651|652|653|654|67[0-9]{1}|680|681|682|683)/;

            if (orangeRegex.test(phoneNumber)) {
                operator = 'orange';
                return /^(655|656|657|658|659|69[0-9]{1}|659[6-9])/.test(phoneNumber);
            } else if (mtnRegex.test(phoneNumber)) {
                operator = 'MTN';
                return /^(650|651|652|653|654|67[0-9]{1}|680|681|682|683)/.test(phoneNumber);
            } else {
                return null;
            }
        };

        const handleFormSubmit = async (e) => {
            e.preventDefault();

            if (!formData.subscriberMsisdn) {
                alert('Veuillez remplir tous les champs.');
                return;
            }

            const isValid = isValidPhoneNumber(formData.subscriberMsisdn);

            if (!isValid) {
                alert('Numéro de téléphone non valide. Veuillez entrer un numéro de téléphone valide.');
                return;
            }

            setLoading(true);

            try {
                const response = await axios.post(`${BASE_URL}/api/payment/makepayment`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.data && response.data.error) {
                    alert(response.data.error);
                    setLoading(false);
                } else {
                    let payToken;
                    if (operator === 'orange') {
                        payToken = response.data.data.data.payToken;
                    } else if (operator === 'MTN') {
                        payToken = response.data.xReferenceId;
                    } else {
                        alert('Veuillez entrer un numéro valide (Orange ou MTN).');
                    }

                    async function pollTransactionStatus(payToken) {

                        setLoading(true);

                        try {
                            const transUpdateResponse = await axios.get(`${BASE_URL}/api/payment/transupdate/${idSubscription}/${payToken}/${customerEmail}/${customerName}/${offer}?paymentMethod=${operator}`);
                            let transactionStatus;

                            if (operator === 'orange') {
                                transactionStatus = transUpdateResponse.data.data.data.status;
                            } else if (operator === 'MTN') {
                                transactionStatus = transUpdateResponse.data.data.status;
                            } else {
                                alert('Veuillez entrer un numéro valide (Orange ou MTN).');
                            }

                            if (transactionStatus === 'PENDING') {
                                setProcessInit(true);
                                await new Promise(resolve => setTimeout(resolve, 15000));
                                await pollTransactionStatus(payToken);
                            } else if (transactionStatus === 'SUCCESSFULL' || transactionStatus === 'SUCCESSFUL') {
                                setLoading(false);
                                setProcessInit(false);
                                setEndSuccessMessage(true);

                                await axios.post(`${BASE_URL}/api/fat/decrement/${latitudeFat}/${longitudeFat}`);
                            } else if (transactionStatus === 'CANCELLED') {
                                setLoading(false);
                                setProcessInit(false);
                                alert('Transaction annulée. Nous vous prions de reprendre le processus de paiement pour finaliser votre procédure.')
                            } else if (transactionStatus === 'FAILED') {
                                setLoading(false);
                                setProcessInit(false);
                                alert('Votre transaction a échoué. Nous vous prions de réessayer plus tard afin de finaliser votre procédure.');
                            } else {
                                setLoading(false);
                                setProcessInit(false);
                                alert('Pour de raison inconnue, votre transaction n\'a pas abouti. Nous vous prions de réessayer plus tard afin de finaliser votre procédure.')
                            }
                        } catch (error) {
                            setLoading(false);
                            console.error('Erreur lors de la requête de mise à jour de la transaction :', error);
                        }
                    }

                    if (payToken) {
                        pollTransactionStatus(payToken);
                    }

                    setIsOpen(false);
                    setLoading(false);
                    alert('Veuillez valider la transaction en tapant votre code après avoir avoir reçu le message de CAMTEL WEBPAYMENT. Bien plus, nous vous prions de patienter quelques instants en restant sur cette page pour la finalisation de la transaction.')
                    setProcessInit(true);
                }
            } catch (error) {
                console.error('Erreur lors de la soumission du formulaire :', error);
                alert('Echec de la transaction. Veuillez vérifier que votre solde est d\'au moins 50 000 FCFA (cinquante mille francs CFA) pour couvrir les frais d\'installation, puis réessayer plus tard.');
                setLoading(false);
            }

            setFormData({
                subscriberMsisdn: ''
            });
        };

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customModalStyles}
                contentLabel="Détails du paiement"
            >
                <button onClick={() => setIsOpen(false)} style={{ background: '#ddd' }} className='float-right p-2 rounded-full'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg></button>

                <div className='font-montserrat w-full flex flex-col items-center'>
                    <h1 className="text-center text-4xl font-semibold mb-5">Détails du paiement</h1>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="subscriptionId">
                            Identifiant de souscription <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="subscriptionId"
                            type="text"
                            placeholder="Identifiant de la souscription"
                            name="subscriptionId"
                            value={`${subscriptionDate}-${idSubscription}`}
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="customerName">
                            Nom du client / Raison Sociale <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="customerName"
                            type="text"
                            placeholder="Nom du client / Raison Sociale"
                            name="customerName"
                            value={customerName}
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="offerSelected">
                            Offre Blue Max choisie <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="offerSelected"
                            type="text"
                            placeholder=" Offre Blue Max choisie"
                            name="offerSelected"
                            value={offer}
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="installationAmount">
                            Frais d'installation (Franc CFA) <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="installationAmount"
                            type="text"
                            placeholder="Montant (Franc CFA)"
                            name="installationAmount"
                            value='50 000'
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5 '>
                        <div className='relative'>
                            <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="subscriberMsisdn">
                                Numéro de téléphone du payeur <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                            </label>
                            <div className="flex items-center mt-2">
                                <input
                                    className="z-0 shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-10"
                                    id="subscriberMsisdn"
                                    type="tel"
                                    placeholder="Numéro de téléphone du payeur"
                                    name="subscriberMsisdn"
                                    value={formData.subscriberMsisdn}
                                    onChange={handleChangeNumber}
                                    maxLength={9}
                                />
                                {/^(655|656|657|658|659|69[0-9]{1}|659[6-9])/.test(formData.subscriberMsisdn) ? (
                                    <img
                                        src={require('../../assets/img/logo/om-logo.jpeg')}
                                        alt="logo OM"
                                        className="absolute sm:w-3 md:w-10 lg:w-12 xl:w-12 2xl:w-12 right-0 z-1 h-5 sm:mr-3 md:mr-5 lg:mr-5 xl:mr-5 2xl:mr-5 focus:outline-none"
                                    />
                                ) : /^(650|651|652|653|654|67[0-9]{1}|680|681|682|683)/.test(formData.subscriberMsisdn) ? (
                                    <img
                                        src={require('../../assets/img/logo/momo-logo.png')}
                                        alt="logo MoMo"
                                        className="absolute sm:w-3 md:w-10 lg:w-12 xl:w-12 2xl:w-12 right-0 z-1 h-5 sm:mr-3 md:mr-5 lg:mr-5 xl:mr-5 2xl:mr-5 focus:outline-none"
                                    />
                                ) : null}
                                {(formData.subscriberMsisdn !== '') && ((/^(655|656|657|658|659|69[0-9]{1}|659[6-9]|650|651|652|653|654|67[0-9]{1}|680|681|682|683)/.test(formData.subscriberMsisdn) === false)) && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute sm:w-3 md:w-10 lg:w-12 xl:w-12 2xl:w-12 right-0 z-1 h-4 sm:mr-2 md:mr-2 lg:mr-2 xl:mr-2 2xl:mr-5 focus:outline-none"
                                        viewBox="0 0 20 20"
                                        fill="red"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zM9 6v2h2V6H9zm0 4v4h2v-4H9z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                )}
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={handleFormSubmit}
                        className="bg-sky-500 hover:bg-sky-700 text-lg text-white py-3 px-8 mt-3 rounded-lg"
                    >
                        Payer
                    </button>
                </div>
            </Modal>
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true)

        try {
            const config = {
                method: 'post',
                url: `${BASE_URL}/api/payment/check-payment-availability/${subscriptionDate}-/${subscriptionDate}-${idSubscription}/${idSubscription}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.request(config);

            if (response.data && response.data.message) {
                alert(response.data.message);

                setLoading(false);
            } else if (response.data && response.data.successMessage) {
                setIsOpen(true);
                setLoading(false);
            } else {
                alert('Une erreur inconnue est survenue lors du traitement de cette requête. Veuillez vérifier votre connexion internet et réesayer plus tard.');
                setLoading(false);
            }

            e.target.reset();
        } catch (error) {
            console.error("Erreur  : ", error);
            setLoading(false);
        }
    }

    const navigate = useNavigate();

    const handleRDVSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (!selectedDate || !selectedTime) {
            alert('Aucune date ou heure sélectionnée');
            return;
        }

        const year = selectedDate.getFullYear();
        const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
        const day = String(selectedDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        const dateOfRdv = `${formattedDate} ${selectedTime}:00`;

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const idPartner = localStorage.getItem('idPartner');
            const techId = localStorage.getItem('techId');

            const res = await axios.get(`${BASE_URL}/api/payment/${idSubscription}`, config);
            let payToken;
            if (res.data && res.data.payment[0].payToken) {
                payToken = res.data.payment[0].payToken;
            }

            const data = {
                appointmentDateTime: dateOfRdv,
                idSheet: idSubscription,
                idEntity: idPartner,
                idPartnerTechnician: techId,
                returnedPayToken: payToken
            };

            const response = await axios.post(`${BASE_URL}/api/appointment`, data, config);

            const showAlert = (message) => {
                return new Promise((resolve) => {
                    alert(message);
                    resolve();
                });
            };

            if (response.data && response.data.message) {
                localStorage.removeItem('idPartner');
                localStorage.removeItem('techId');

                await showAlert(response.data.message);

                setLoading(false);

                navigate(`/receipt/${payToken}`);
            } else if (response.data && response.data.error) {
                alert(response.data.error);
            } else {
                alert('Une erreur inconnue est survenue lors du traitement de cette requête. Veuillez vérifier votre connexion internet et réesayer plus tard.');
                setLoading(false);
            }
        } catch (error) {
            console.error("Erreur  : ", error);
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../../assets/img/icons/gif/loading-points.webp')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    <div className='mt-4'>
                        <Link
                            to={`/order`}
                            className="bottom-0 right-0 m-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Revenir à la page des commandes
                        </Link>
                    </div>

                    <div>
                        {!endSuccessMessage ? (
                            <div>
                                {loading ? (
                                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                                        <img
                                            src={require('../../assets/img/icons/gif/loading-points.webp')}
                                            alt='Loader'
                                            className="mx-auto"
                                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        {processInit ? (
                                            <div className='h-screen flex items-center justify-center'>
                                                <div>
                                                    <div className='text-center text-5xl font-bold'>Transaction initiée. Veuillez patienter et ne fermez surtout pas cette page s'il vous plait...</div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {undifinedGpsCoords ? (
                                                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                                                        <h3 className="text-text-500 text-2xl font-bold sm:text-4xl text-center">{undifinedGpsCoords}</h3>
                                                    </div>
                                                ) : latitude && longitude ? (
                                                    wrongGpsCoordinates ? (
                                                        <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                                                            <h3 className="text-text-500 text-2xl font-bold sm:text-4xl text-center">{wrongGpsCoordinates}</h3>
                                                        </div>
                                                    ) : (
                                                        closestMarker ? (
                                                            <div className='flex flex-col justify-center items-center'>
                                                                <div className="text-center my-4">
                                                                    {successMessage ? (
                                                                        <p className="text-green-500 font-bold">{successMessage}</p>
                                                                    ) : (
                                                                        <p className="text-black-500 font-bold">{errorMessage}</p>
                                                                    )}
                                                                </div>
                                                                <MapContainer center={[latitude, longitude]} zoom={17}>
                                                                    <TileLayer
                                                                        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                    />
                                                                    <Marker position={[latitude, longitude]} icon={customHomeIcon}>
                                                                        <Popup>
                                                                            <h2>Mon Domicile</h2>
                                                                        </Popup>
                                                                    </Marker>
                                                                    <Circle center={[latitude, longitude]} radius={300} />
                                                                    {/* {markers.map(marker => (
                                                                        <Marker position={marker.geocode} icon={customIcon}>
                                                                            <Popup>
                                                                                <h2>{marker.popUp}</h2>
                                                                            </Popup>
                                                                        </Marker>
                                                                    ))} */}
                                                                    <LeafletRoutingMachine start={[latitude, longitude]} end={closestMarker.geocode} />
                                                                </MapContainer>
                                                                {successMessage ? (
                                                                    <>
                                                                        <form
                                                                            onSubmit={handleSubmit}
                                                                        >
                                                                            <div>
                                                                                <input
                                                                                    type="hidden"
                                                                                    id='idSubscription'
                                                                                    name='idSubscription'
                                                                                    value={idSubscription}
                                                                                    hidden
                                                                                />
                                                                            </div>
                                                                            <div className='flex items-center'>
                                                                                <button
                                                                                    className="bottom-0 right-0 m-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                                                                >
                                                                                    Procéder au paiement
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                        <CustomModal
                                                                            isOpen={isOpen}
                                                                            closeModal={closeModal}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <div className='flex items-center'>
                                                                        <a
                                                                            href='https://camtel.cm'
                                                                            className="bottom-0 right-0 mt-2 mb-3 bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded"
                                                                        >
                                                                            Voir nos offres
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            closestMarkerErrorMessage && (
                                                                <div className='flex flex-col justify-center items-center'>
                                                                    <div className="text-center my-4">
                                                                        <p className="text-black-500 font-bold">{closestMarkerErrorMessage}</p>
                                                                    </div>
                                                                    <MapContainer center={[latitude, longitude]} zoom={17}>
                                                                        <TileLayer
                                                                            attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                        />
                                                                        <Marker position={[latitude, longitude]} icon={customHomeIcon}>
                                                                            <Popup>
                                                                                <h2>Mon Domicile</h2>
                                                                            </Popup>
                                                                        </Marker>
                                                                        <Circle center={[latitude, longitude]} radius={300} />
                                                                    </MapContainer>
                                                                    <div className='flex items-center'>
                                                                        <a
                                                                            href='https://camtel.cm'
                                                                            className="bottom-0 right-0 mt-2 mb-3 bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded"
                                                                        >
                                                                            Voir nos offres
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ))
                                                ) : (
                                                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                                                        <img
                                                            src={require('../../assets/img/icons/gif/loading-points.webp')}
                                                            alt='Loader'
                                                            className="mx-auto"
                                                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <main className="w-full flex">
                                <div
                                    className="relative flex-1 hidden items-center justify-center h-screen bg-gray-900 lg:flex bg-contain bg-no-repeat bg-center"
                                    style={{ backgroundImage: `url(${require('../../assets/img/bg/img-compiled.gif')})` }}
                                ></div>
                                <div className="flex-1 flex items-center justify-center h-screen">
                                    <div className="w-full space-y-8 px-4 bg-white text-gray-600 sm:px-0">
                                        <div className="w-full text-center">
                                            <img src={require('../../assets/img/icons/favicon/favicon-camtel.png')} width={220} className="mx-auto mb-5" />

                                            <div className="space-y-2">
                                                <h3 className="text-gray-800 text-2xl font-bold sm:text-4xl text-center">Merci d'avoir payé votre souscription!</h3>
                                            </div>
                                            <form onSubmit={handleRDVSubmit} className="bg-white px-8 pt-6 pb-8 mb-4">
                                                <div className="mb-2">
                                                    <label className="block text-gray-700 font-bold mb-2" htmlFor="date">
                                                        Quel jour souhaiteriez-vous être installé ?
                                                    </label>
                                                    <DatePicker
                                                        placeholderText='Date du RDV ici'
                                                        selected={selectedDate}
                                                        onChange={handleDateChange}
                                                        filterDate={filterDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    />
                                                </div>
                                                {selectedDate && (
                                                    <div className="mb-2 mt-4">
                                                        <label className="block text-gray-700 font-bold mb-2" htmlFor="time">
                                                            A quelle heure souhaiteriez-vous être installée ?
                                                        </label>
                                                        <div className="grid grid-cols-3 gap-4">
                                                            {availableTimeSlots.map((time, index) => (
                                                                <button
                                                                    key={index}
                                                                    type="button"
                                                                    onClick={() => handleTimeChange(time)}
                                                                    className={`bg-sky-200 hover:bg-sky-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline} ${selectedTime === time ? 'bg-sky-700 font-bold' : 'bg-sky-200'}`}
                                                                >
                                                                    {time}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="flex items-center justify-center">
                                                    <button
                                                        type="submit"
                                                        className="bottom-0 right-0 mb-3 bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded"
                                                    >
                                                        Réserver
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        )}
                    </div>
                </>
            )
            }
        </>
    );
}

export default AdminLocation;
