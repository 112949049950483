import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../apiConfig';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import WhatsAppButton from '../components/WhatsAppButton';

const PaySubscription = () => {
    const { id } = useParams();

    const [idSheet, setIdSheet] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [cbsOrderId, setCbsOrderId] = useState(null);
    const [accountId, setAccountId] = useState(null);
    const [customerEmail, setCustomerEmail] = useState(null);
    const [offer, setOffer] = useState(null);
    const [agencyId, setAgencyId] = useState('0');
    const [agencyName, setAgencyName] = useState('0');
    const [idSubscription, setIdSubscription] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [processInit, setProcessInit] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            navigate(`/location/${id}`);
        } else {
            navigate('/');
        }
    }, [id, navigate]);

    const closeModal = () => {
        setIsOpen(false);
    };

    const customModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: '95%',
            border: '1px solid #ccc',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            // paddingTop: '10px',
            borderRadius: '10px',
        },
        overlay: {
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
        },
    };

    const CustomModal = ({ isOpen, closeModal }) => {

        const [formData, setFormData] = useState({
            idSubscription: idSubscription,
            idSheet: idSheet,
            cbsOrderId: cbsOrderId,
            accountId: accountId,
            nameOrSocialName: customerName,
            // installationAmount: '50000',
            installationAmount: '1',
            agencyId: agencyId,
            agencyName: agencyName,
            subscriberMsisdn: ''
        });

        const handleChangeNumber = (e) => {
            const value = e.target.value;
            setFormData({ ...formData, subscriberMsisdn: value });
        };

        const isValidPhoneNumber = (phoneNumber) => {
            return /^(655|656|657|658|69[0-9]{2}|659[6-9])/.test(phoneNumber);
        };

        const handleFormSubmit = async (e) => {
            e.preventDefault();

            if (!formData.subscriberMsisdn) {
                alert('Veuillez remplir tous les champs.');
                return;
            }

            if (!isValidPhoneNumber(formData.subscriberMsisdn)) {
                alert('Numéro de téléphone non valide. Veuillez entrer un numéro de téléphone valide.');
                return;
            }

            setLoading(true);

            try {
                const response = await axios.post(`${BASE_URL}/api/payment/makepayment`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const payToken = response.data.data.data.payToken;

                async function pollTransactionStatus(payToken) {

                    setLoading(true);

                    try {
                        const transUpdateResponse = await axios.get(`${BASE_URL}/api/payment/transupdate/${payToken}/${customerEmail}/${customerName}/${offer}`);
                        const transactionStatus = transUpdateResponse.data.data.data.status;

                        if (transactionStatus === 'PENDING') {
                            setProcessInit(false);
                            setLoading(true);

                            await new Promise(resolve => setTimeout(resolve, 15000));
                            await pollTransactionStatus(payToken);
                        } else if (transactionStatus === 'SUCCESSFULL') {
                            setLoading(false);
                            setProcessInit(false);
                            alert('Paiement effectuée avec succès. Veuillez consulter votre boite mail pour plus détails relatif à votre installation.')
                            setError('Paiement effectuée avec succès. Veuillez consulter votre boite mail pour plus détails relatif à votre installation.')
                            navigate(`/receipt/${cbsOrderId}`);
                        } else if (transactionStatus === 'CANCELLED') {
                            setLoading(false);
                            setProcessInit(false);
                            alert('Transaction annulée. Nous vous prions de reprendre le processus de paiement pour finaliser votre procédure.')
                            setError('Transaction annulée. Nous vous prions de reprendre le processus de paiement pour finaliser votre procédure.')
                        } else if (transactionStatus === 'FAILED') {
                            setLoading(false);
                            setProcessInit(false);
                            alert('Votre transaction a échoué. Nous vous prions de réessayer plus tard afin de finaliser votre procédure.');
                            setError('Votre transaction a échoué. Nous vous prions de réessayer plus tard afin de finaliser votre procédure.');
                        } else {
                            setLoading(false);
                            setProcessInit(false);
                            alert('Pour de raison inconnue, votre transaction n\'a pas abouti. Nous vous prions de réessayer plus tard afin de finaliser votre procédure.')
                            setError('Pour de raison inconnue, votre transaction n\'a pas abouti. Nous vous prions de réessayer plus tard afin de finaliser votre procédure.')
                        }
                    } catch (error) {
                        setLoading(false);
                        console.error('Erreur lors de la requête de mise à jour de la transaction :', error);
                    }
                }

                if (payToken) {
                    pollTransactionStatus(payToken);
                }

                setIsOpen(false);
                setLoading(false);
                alert('Veuillez valider la transaction en tapant votre code après avoir avoir reçu le message de CAMTEL WEBPAYMENT. Bien plus, nous vous prions de patienter quelques instants en restant sur cette page pour la finalisation de la transaction.')
                setError('Veuillez valider la transaction en tapant votre code après avoir avoir reçu le message de CAMTEL WEBPAYMENT. Bien plus, nous vous prions de patienter quelques instants en restant sur cette page pour la finalisation de la transaction.')
                // setLoading(true);
                setProcessInit(true);
                setError();

            } catch (error) {
                console.error('Erreur lors de la soumission du formulaire :', error);
                setLoading(false);
            }

            setFormData({
                subscriberMsisdn: ''
            });
        };

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customModalStyles}
                contentLabel="Détails du paiement"
            >
                <button onClick={() => setIsOpen(false)} style={{ background: '#ddd' }} className='float-right p-2 rounded-full'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width={1.5} stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg></button>

                <div className='font-montserrat w-full flex flex-col items-center'>
                    <h1 className="text-center text-4xl font-semibold mb-5">Détails du paiement</h1>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="subscriotionId">
                            Identifiant de souscription <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="subscriotionId"
                            type="text"
                            placeholder="Identifiant de la souscription"
                            name="subscriotionId"
                            value={idSubscription}
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="customerName">
                            Nom du client / Raison Sociale <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="customerName"
                            type="text"
                            placeholder="Nom du client / Raison Sociale"
                            name="customerName"
                            value={customerName}
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="cbsOrderId">
                            ID de la commande (CBS) <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="cbsOrderId"
                            type="text"
                            placeholder="ID de la commande (CBS)"
                            name="cbsOrderId"
                            value={cbsOrderId}
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="accountId">
                            Numéro de compte client / Account customer ID <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="accountId"
                            type="text"
                            placeholder="Numéro de compte client / Account customer ID"
                            name="accountId"
                            value={accountId}
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="offerSelected">
                            Offre Blue Max choisie <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="offerSelected"
                            type="text"
                            placeholder=" Offre Blue Max choisie"
                            name="offerSelected"
                            value={offer}
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5'>
                        <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="installationAmount">
                            Frais d'installation (Franc CFA) <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                        </label>
                        <input
                            className="shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="installationAmount"
                            type="text"
                            placeholder="Montant (Franc CFA)"
                            name="installationAmount"
                            value='50 000'
                            readOnly
                        />
                    </div>

                    <div className='w-full mb-5 '>
                        <div className='relative'>
                            <label className="block text-gray-700 text-lg font-medium mb-2" htmlFor="subscriberMsisdn">
                                Numéro OM de téléphone du payeur <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                            </label>
                            <div className="flex items-center mt-2">
                                <input
                                    className="z-0 shadow appearance-none border rounded-lg w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline pr-10"
                                    id="subscriberMsisdn"
                                    type="tel"
                                    placeholder="Numéro de téléphone du payeur"
                                    name="subscriberMsisdn"
                                    value={formData.subscriberMsisdn}
                                    onChange={handleChangeNumber}
                                    maxLength={9}
                                />
                                {/^(655|656|657|658|69[0-9]{2}|659[6-9])/.test(formData.subscriberMsisdn) && (
                                    <img
                                        src={require('../assets/img/logo/om-logo.jpeg')}
                                        alt="logo OM"
                                        className="absolute sm:w-3 md:w-10 lg:w-12 xl:w-12 2xl:w-12 right-0 z-1 h-4 sm:mr-3 md:mr-5 lg:mr-5 xl:mr-5 2xl:mr-5 focus:outline-none"
                                    />
                                )}
                                {(formData.subscriberMsisdn !== '') && (/^(655|656|657|658|69[0-9]{2}|659[6-9])/.test(formData.subscriberMsisdn) === false) && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="absolute sm:w-3 md:w-10 lg:w-12 xl:w-12 2xl:w-12 right-0 z-1 h-4 sm:mr-2 md:mr-2 lg:mr-2 xl:mr-2 2xl:mr-5 focus:outline-none"
                                        viewBox="0 0 20 20"
                                        fill="red"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zM9 6v2h2V6H9zm0 4v4h2v-4H9z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                )}
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={handleFormSubmit}
                        className="bg-sky-500 hover:bg-sky-700 text-lg text-white py-3 px-8 mt-3 rounded-lg"
                    >
                        Procéder au paiement
                    </button>
                </div>
            </Modal>
        );
    };

    const handleChange = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');

        if (value.length === 6 && value.charAt(5) !== '-') {
            value = value.slice(0, 6) + '-' + value.slice(6);
        } else if (value.length > 6) {
            value = value.slice(0, 6) + '-' + value.slice(6);
        }

        setIdSubscription(value);
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const regex = /^\d{6}-\d+$/;

        if (!regex.test(idSubscription)) {
            setError("Veuillez entrer un identifiant de souscription valide.");
            return;
        }

        const [subscriptionDate, subscriptionId] = idSubscription.split('-');

        setIdSheet(subscriptionId);

        try {
            const config = {
                method: 'post',
                url: `${BASE_URL}/api/payment/check-payment-availability/${subscriptionDate}-/${subscriptionDate}-${subscriptionId}/${subscriptionId}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.request(config);

            if (response.data && response.data.error) {
                setError(response.data.error);
            } else if (response.data && response.data.message) {
                setError(response.data.message);
            } else if (response.data && response.data.successMessage) {
                setError('');
                setIsOpen(true);
                setCustomerName(response.data.order[0].nameOrSocialName);
                setCustomerEmail(response.data.order[0].email);
                setCbsOrderId(response.data.order[0].cbsOrderId);
                setAccountId(response.data.order[0].accountId);

                const res = await axios.get(`${BASE_URL}/api/agency/name/` + response.data.order[0].idEntity);
                setAgencyId(res.data.agency[0].idEntity);
                setAgencyName(res.data.agency[0].name);

                setOffer(response.data.order[0].offerBlueMaxSelected);
            } else {
                setError('Une erreur inconnue est survenue lors du traitement de cette requête. Veuillez vérifier votre connexion internet et réesayer plus tard.');
            }

            e.target.reset();

            setLoading(false);
        } catch (error) {
            console.error("Erreur  : ", error);
            setLoading(false);
            // alert('Erreur !');
        }
    }

    return (
        <>
            {
                loading ? (
                    <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                        <img
                            src={require('../assets/img/icons/gif/loading-icon-animated.jpeg')}
                            alt='Loader'
                            className="mx-auto"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                        />
                    </div >
                ) : (
                    <main className="w-full flex">
                        {processInit ? (
                            <div className='h-screen flex items-center justify-center'>
                                <div>
                                    <div className='text-center text-5xl font-bold'>Transaction initiée. Veuillez patienter et ne fermez surtout pas cette page s'il vous plait...</div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div
                                    className="relative flex-1 hidden items-center justify-center h-screen bg-gray-900 lg:flex bg-contain bg-no-repeat bg-center"
                                    style={{ backgroundImage: `url(${require('../assets/img/bg/animiertes-gif-von-online-umwandeln-de.gif')})` }}
                                ></div>
                                <div className="flex-1 flex items-center justify-center h-screen">
                                    <div className="w-full space-y-8 px-4 bg-white text-gray-600 sm:px-0">
                                        <div className="w-full text-center">
                                            <img src={require('../assets/img/icons/favicon/favicon-camtel.png')} width={220} className="mx-auto mb-5" />

                                            <div className="mt-5 space-y-2">
                                                <h3 className="text-gray-800 text-1xl font-bold sm:text-3xl text-center">Vérification de la possibilité du paiement.</h3>
                                            </div>

                                            <div className="px-7 mt-5 mb-10 flex flex-col md:flex-row justify-center items-center">
                                                <form
                                                    onSubmit={handleSubmit}
                                                    className="space-y-3"
                                                >
                                                    <div>
                                                        <label className="font-medium">
                                                            Entrez l'identifiant de votre souscription (<span className='font-bold'>ID SUBSCRIPTION</span>)
                                                        </label>
                                                        <div className="relative">
                                                            <input
                                                                type="text"
                                                                id='idSubscription'
                                                                name='idSubscription'
                                                                required
                                                                placeholder='011223-294'
                                                                value={idSubscription}
                                                                onChange={handleChange}
                                                                className="w-full text-center mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg pr-10"
                                                            />
                                                            <button
                                                                onClick={() => setIdSubscription('')}
                                                                className="absolute right-3 top-4 focus:outline-none"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                                                            </button>
                                                        </div>

                                                    </div>
                                                    {error && <p className="text-red-500">{error}</p>}
                                                    <button
                                                        className="w-full px-4 py-2 text-white font-medium bg-sky-500 hover:bg-sky-600 active:bg-sky-600 rounded-lg duration-150"
                                                    >
                                                        Vérifier
                                                    </button>
                                                </form>
                                            </div>

                                            <CustomModal
                                                isOpen={isOpen}
                                                closeModal={closeModal}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </main>
                )}

            <WhatsAppButton />
        </>
    );
}

export default PaySubscription;