import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import { Icon, L } from 'leaflet';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import axios from 'axios';
import { BASE_URL } from '../../apiConfig';

const Fat = () => {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [fats, setFats] = useState([]);

    const [centrals, setCentrals] = useState([]);
    const [central, setCentral] = useState("");

    const [showUploadForm, setShowUploadForm] = useState(false);

    const [formData, setFormData] = useState({ idCentral: "", olt: "", fspOss: "", fsp: "", fdtOss: "", fdt: "", splitterOss: "", fatOss: "", fatOss: "", fat: "", latitude: "", longitude: "", neighborhood: "", localisation: "", capacity: "", occupation: "", free: "", saturation: "" });
    const [isAdding, setIsAdding] = useState(false);
    const [selectedFat, setSelectedFat] = useState(null);
    const [editingFat, setEditingFat] = useState(null);

    const [editedCentral, setEditedCentral] = useState("");
    const [editedOlt, setEditedOlt] = useState("");
    const [editedFspOss, setEditedFspOss] = useState("");
    const [editedFsp, setEditedFsp] = useState("");
    const [editedFdtOss, setEditedFdtOss] = useState("");
    const [editedFdt, setEditedFdt] = useState("");
    const [editedSplitterOss, setEditedSplitterOss] = useState("");
    const [editedFatOss, setEditedFatOss] = useState("");
    const [editedFat, setEditedFat] = useState("");
    const [editedLatitude, setEditedLatitude] = useState("");
    const [editedLongitude, setEditedLongitude] = useState("");
    const [editedNeighborhood, setEditedNeighborhood] = useState("");
    const [editedLocalisation, setEditedLocalisation] = useState("");
    const [editedCapacity, setEditedCapacity] = useState("");
    const [editedOccupation, setEditedOccupation] = useState("");
    const [editedFree, setEditedFree] = useState("");
    const [editedSaturation, setEditedSaturation] = useState("");

    const [isDetailsVisible, setIsDetailsVisible] = useState(true);
    const [isAddFatVisibile, setIsAddFatVisible] = useState(true);
    const [isEditFatVisible, setIsEditFatVisible] = useState(true);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "occupation") {
            const capacity = parseFloat(formData.capacity) || 0;
            const occupation = parseFloat(value);

            if (occupation > capacity) {
                alert("La valeur d'occupation ne peut pas dépasser la capacité.");
                return;
            }
        }

        setFormData({ ...formData, [name]: value });
    };

    function LocationMarker({ setFormData }) {
        useMapEvents({
            click(e) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    latitude: e.latlng.lat.toFixed(6),
                    longitude: e.latlng.lng.toFixed(6)
                }));
            }
        });
        return null;
    }

    function EditLocationMarker({ setEditedLatitude, setEditedLongitude }) {
        useMapEvents({
            click(e) {
                setEditedLatitude(e.latlng.lat.toFixed(6));
                setEditedLongitude(e.latlng.lng.toFixed(6));
            },
        });
        return null;
    }

    const customFatIcon = new Icon({
        iconUrl: require('../../assets/img/icons/map/epingle.png'),
        iconSize: [38, 38]
    });

    const getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        resolve({ latitude, longitude });
                    },
                    (error) => reject(error)
                );
            } else {
                reject(new Error("La géolocalisation n'est pas supportée par ce navigateur."));
            }
        });
    };

    const handleAddFatClick = async () => {
        const isUserAtLocation = window.confirm("Êtes-vous sur le lieu où se trouve le FAT ?");
        if (isUserAtLocation) {
            setLoading(true);
            try {
                const { latitude, longitude } = await getCurrentLocation();
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    latitude: latitude.toFixed(6),
                    longitude: longitude.toFixed(6),
                }));
                setLoading(false);
            } catch (error) {
                alert("Impossible de récupérer les coordonnées de votre emplacement.");
                setLoading(false);
            }
        }

        setIsAdding(true);
        setSelectedFat(false);
        setEditingFat(false);
        setIsAddFatVisible(false);
    };

    const handleDetails = (idFat) => {
        const fat = fats.find((fat) => fat.idFat === idFat);
        setSelectedFat(fat);
        setEditingFat(false);
        setIsAdding(false);

        setIsDetailsVisible(false);
    };

    useEffect(() => {
        calculateFreeAndSaturation();
    }, [formData.capacity, formData.occupation]);

    const calculateFreeAndSaturation = () => {
        const capacity = parseFloat(formData.capacity) || 0;
        const occupation = parseFloat(formData.occupation) || 0;

        const free = capacity - occupation;
        const saturation = capacity ? (occupation / capacity) * 100 : 0;

        setFormData(prevFormData => ({
            ...prevFormData,
            free: free,
            saturation: `${saturation.toFixed(2)}%`
        }));
    };

    useEffect(() => {
        calculateFreeAndSaturationWhileEdit();
    }, [editedCapacity, editedOccupation]);

    const calculateFreeAndSaturationWhileEdit = () => {
        const capacity = parseFloat(editedCapacity) || 0;
        const occupation = parseFloat(editedOccupation) || 0;

        const free = capacity - occupation;
        const saturation = capacity ? (occupation / capacity) * 100 : 0;

        setEditedFree(free);
        setEditedSaturation(`${saturation}%`);
    };

    const handleAdd = async () => {
        setLoading(true);

        if (
            !formData.olt ||
            !formData.fspOss ||
            !formData.fsp ||
            !formData.fdtOss ||
            !formData.fdt ||
            !formData.splitterOss ||
            !formData.fatOss ||
            !formData.fat ||
            !formData.latitude ||
            !formData.longitude ||
            !formData.neighborhood ||
            !formData.localisation ||
            !formData.capacity ||
            !formData.occupation ||
            !formData.free ||
            !formData.saturation
        ) {
            setLoading(false);
            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        const data = formData;

        try {
            const config = {
                method: 'post',
                url: `${BASE_URL}/api/fat/create`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: data
            };

            const response = await axios.request(config);
            console.log('response: ', response);

            setFormData({
                idCentral: "",
                olt: "",
                fspOss: "",
                fsp: "",
                fdtOss: "",
                fdt: "",
                splitterOss: "",
                fatOss: "",
                fatOss: "",
                fat: "",
                latitude: "",
                longitude: "",
                neighborhood: "",
                localisation: "",
                capacity: "",
                occupation: "",
                free: "",
                saturation: ""
            });

            setEditingFat(false);
            setIsAdding(false);
            setIsAddFatVisible(true);

            alert('FAT ajouté avec succès.');
            getFats();
        } catch (error) {
            console.error("Erreur lors de la création du FAT : ", error);
            setLoading(false);
            alert('Erreur lors de la création du FAT !');
        }
    };

    const handleEdit = (idFat) => {
        setSelectedFat(false);
        setIsAdding(false);

        const fat = fats.find((fat) => fat.ID === idFat);
        setEditingFat(fat);
        setEditedCentral(fat.CENTRAL);
        setEditedOlt(fat.OLT);
        setEditedFspOss(fat.FSP_OSS);
        setEditedFsp(fat.FSP);
        setEditedFdtOss(fat.FDT_OSS);
        setEditedFdt(fat.FDT);
        setEditedSplitterOss(fat.SPLITTER_OSS);
        setEditedFatOss(fat.FAT_OSS);
        setEditedFat(fat.FAT);
        setEditedLatitude(fat.LATITUDE);
        setEditedLongitude(fat.LONGITUDE);
        setEditedNeighborhood(fat.QUARTIER);
        setEditedLocalisation(fat.LOCALISATION);
        setEditedCapacity(fat.CAPACITE);
        setEditedOccupation(fat.OCCUPATION);
        setEditedFree(fat.LIBRE);
        setEditedSaturation(fat.SATURATION);

        setIsDetailsVisible(false);
        setIsEditFatVisible(false);
    };

    const handleSubmit = () => {
        // setLoading(true);

        // if (!editedOlt || !editedFspOss || !editedFsp || !editedFdtOss || !editedFdt || !editedSplitterOss || !editedFatOss || !editedFat || !editedLatitude || !editedLongitude || !editedNeighborhood || !editedLocalisation || !editedCapacity || !editedOccupation || !editedFree || !editedSaturation) {
        //     setLoading(false);
        //     alert("Veuillez remplir tous les champs obligatoires.");
        //     return;
        // }

        const updatedData = {
            ...editingFat,
            idCentral: editedCentral,
            olt: editedOlt,
            fspOss: editedFspOss,
            fsp: editedFsp,
            fdtOss: editedFdtOss,
            fdt: editedFdt,
            splitterOss: editedSplitterOss,
            fatOss: editedFatOss,
            fatOss: editedFatOss,
            fat: editedFat,
            latitude: editedLatitude,
            longitude: editedLongitude,
            neighborhood: editedNeighborhood,
            localisation: editedLocalisation,
            capacity: editedCapacity,
            occupation: editedOccupation,
            free: editedFree,
            saturation: editedSaturation
        };

        if (editedOccupation > editedCapacity) {
            alert("La valeur d'occupation ne peut pas dépasser la capacité.");
            setLoading(false);
        } else {
            // axios.patch(`${CAMTEL_MAPS_BASE_URL}/api/fat/edit/${editingFat.idFat}`, updatedData, {
            axios.patch(`${BASE_URL}/api/fat/excel/edit/${editingFat.ID}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then((response) => {
                    console.log('response: ', response);
                    setIsDetailsVisible(true);
                    setEditingFat(null);
                    setIsEditFatVisible(true);

                    alert('Fat modifié avec succès.');
                    // getFats();
                })
                .catch((error) => {
                    alert('Erreur lors de la mise à jour du FAT.');
                    console.error("Erreur lors de la mise à jour du FAT : ", error);
                    setLoading(false);
                });
        }
    };

    const getCentrals = async () => {
        setLoading(true);

        if (token) {
            try {
                // const response = await axios.get(`${CAMTEL_MAPS_BASE_URL}/api/central`, {
                const response = await axios.get(`${BASE_URL}/api/fat/excel/centrals`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setCentrals(response.data.centrals);
                setLoading(false);
            } catch (error) {
                if (error.response) {
                    console.error('Erreur de la réponse du serveur:', error.response.data);
                    console.error("Code d'erreur: ", error.response.status);
                } else if (error.request) {
                    console.error('Erreur de la requête:', error.request);
                } else {
                    console.error('Erreur:', error.message);
                }
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        getCentrals();
    }, [token]);

    // const handleFileChange = (e) => {
    //     setFile(e.target.files[0]);
    // };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && (selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || selectedFile.type === "application/vnd.ms-excel")) {
            setFile(selectedFile);
            setError(null);
        } else {
            setFile(null);
            setError("Veuillez sélectionner un fichier Excel valide (.xlsx ou .xls).");
        }
    };

    // const handleSubmitForm = async (e) => {
    //     e.preventDefault();
    //     if (!file) {
    //         setError('Veuillez sélectionner un fichier.');
    //         return;
    //     }

    //     setLoading(true);
    //     setError(null);
    //     setSuccess(null);

    //     const formData = new FormData();
    //     formData.append('file', file);

    //     try {
    //         const response = await axios.post(`${BASE_URL}/api/fat/excel`, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         setSuccess('Fichier uploadé avec succès.');
    //         console.log('Données du fichier Excel :', response.data.data);
    //     } catch (err) {
    //         setError(err.response?.data?.message || 'Erreur lors de l\'upload du fichier.');
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // SUPER ADMIN

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        if (!file) {
            setError('Veuillez sélectionner un fichier.');
            return;
        }

        setLoading(true);
        setError(null);
        setSuccess(null);

        const formData = new FormData();
        formData.append('file', file);

        const superAdminId = localStorage.getItem('userId');

        try {
            const response = await axios.post(`${BASE_URL}/api/fat/excel/${superAdminId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setSuccess('Fichier uploadé avec succès.');
            console.log('Données du fichier Excel :', response.data.data);
        } catch (err) {
            setError(err.response?.data?.message || 'Erreur lors de l\'upload du fichier.');
        } finally {
            setLoading(false);
        }
    };

    const getFats = async (centralName) => {
        setLoading(true);

        if (token && localStorage.getItem('userRole') === 'super_admin') {
            try {
                // const response = await axios.get(`${CAMTEL_MAPS_BASE_URL}/api/fat`, {
                const response = await axios.get(`${BASE_URL}/api/fat/excel?centralName=${centralName}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setFats(response.data.fats);
                setLoading(false);
            } catch (error) {
                if (error.response) {
                    console.error('Erreur de la réponse du serveur:', error.response.data);
                    console.error("Code d'erreur: ", error.response.status);
                } else if (error.request) {
                    console.error('Erreur de la requête:', error.request);
                } else {
                    console.error('Erreur:', error.message);
                }
                setLoading(false);
            }
        }
    };

    // INFRA ADMIN
    const getInfraAdminFats = async () => {
        const userEntity = JSON.parse(localStorage.getItem('userId'));

        if (token && localStorage.getItem('userRole') === 'infra_admin') {
            try {
                const response = await axios.get(`${BASE_URL}/api/connection/infra/admin/${userEntity}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const centrals = response.data.central;

                if (centrals && centrals.length > 0) {
                    const fatPromises = centrals.map((central) => {
                        return axios.get(`${BASE_URL}/api/fat/${central.idCentral}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }).then((res) => {
                            return res.data.fats;
                        });
                    });

                    const fatResults = await Promise.all(fatPromises);

                    const combinedFats = fatResults.flat();
                    setFats(combinedFats);
                }
            } catch (error) {
                if (error.response) {
                    console.error('Erreur de la réponse du serveur:', error.response.data);
                    console.error("Code d'erreur: ", error.response.status);
                } else if (error.request) {
                    console.error('Erreur de la requête:', error.request);
                } else {
                    console.error('Erreur:', error.message);
                }
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (central) {
            getFats(central);
        }
    }, [central]);

    useEffect(() => {
        // getFats();
        getInfraAdminFats();
    }, [token]);
    //     setLoading(true);

    //     const getFats = async () => {
    //         if (token && localStorage.getItem('userRole') === 'infra_admin') {
    //             const { central } = '';

    //             try {
    //                 const response = await axios.get(`${CAMTEL_MAPS_BASE_URL}/api/fat/${central}`, {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 });
    //                 setFats(response.data.fats);
    //                 setLoading(false);
    //             } catch (error) {
    //                 if (error.response) {
    //                     console.error('Erreur de la réponse du serveur:', error.response.data);
    //                     console.error("Code d'erreur: ", error.response.status);
    //                 } else if (error.request) {
    //                     console.error('Erreur de la requête:', error.request);
    //                 } else {
    //                     console.error('Erreur:', error.message);
    //                 }
    //                 setLoading(false);
    //             }
    //         }
    //     };

    //     getFats();
    // }, [token]);

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../../assets/img/icons/gif/loading-points.webp')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    <Header />

                    <Sidebar setHideText={setHideText} hideText={hideText} />

                    <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 flex justify-center ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                        <div className='w-full'>
                            {isDetailsVisible && isEditFatVisible && isAddFatVisibile && (
                                <div>
                                    <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des FATs</h1>


                                    <div className="flex justify-end">
                                        {!showUploadForm && (
                                            <button
                                                onClick={() => { setShowUploadForm(true) }}
                                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mb-4 mr-2"
                                            >
                                                Charger le fichier excel des FATs
                                            </button>
                                        )}
                                        {showUploadForm && (
                                            <button
                                                onClick={() => { setShowUploadForm(false); }}
                                                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 mb-4 mr-2"
                                            >
                                                Fermer
                                            </button>
                                        )}
                                        {/* <button
                                            onClick={handleAddFatClick}
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-4"
                                        >
                                            Ajouter un FAT
                                        </button> */}
                                    </div>

                                    {showUploadForm && (
                                        <div className="bg-white shadow-lg rounded-xl p-6 max-w-md mx-auto mt-6">
                                            <h2 className="text-xl font-bold text-gray-800 flex items-center gap-2 mb-4">
                                                📂 Uploader le fichier Excel des FATs
                                            </h2>

                                            <form onSubmit={handleSubmitForm} className="space-y-4">
                                                <label className="block">
                                                    <span className="text-gray-700 font-medium">Sélectionnez un fichier Excel :</span>
                                                    <input
                                                        type="file"
                                                        accept=".xlsx, .xls"
                                                        onChange={handleFileChange}
                                                        className="block w-full mt-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                    />
                                                </label>

                                                <button
                                                    type="submit"
                                                    disabled={loading}
                                                    className={`w-full text-white font-bold py-2 px-4 rounded-lg transition-all duration-300 ${loading ? "bg-gray-400 cursor-not-allowed" : "bg-sky-600 hover:bg-sky-700"
                                                        }`}
                                                >
                                                    {loading ? (
                                                        <div className="flex items-center justify-center">
                                                            <svg className="animate-spin h-5 w-5 mr-2 border-t-2 border-white rounded-full" viewBox="0 0 24 24"></svg>
                                                            Upload en cours...
                                                        </div>
                                                    ) : "📤 Uploader"}
                                                </button>
                                            </form>

                                            {error && <p className="mt-3 text-red-600 font-semibold">{error}</p>}
                                            {success && <p className="mt-3 text-green-600 font-semibold">{success}</p>}
                                        </div>
                                    )}

                                    {!showUploadForm && (
                                        <>
                                            <div className="mb-4">
                                                <label htmlFor="central" className="block text-gray-700 font-bold">
                                                    Sélectionnez un central :
                                                </label>
                                                <select
                                                    id="central"
                                                    name="central"
                                                    value={central}
                                                    // onChange={(e) => setCentral(e.target.value)}
                                                    onChange={(e) => {
                                                        const selectedCentral = e.target.value;
                                                        setCentral(selectedCentral);
                                                        if (selectedCentral) getFats(selectedCentral);
                                                    }}
                                                    className="border rounded w-full py-2 px-3"
                                                >
                                                    <option value="">Veuillez sélectionner un central</option>
                                                    {centrals.map((central, index) => (
                                                        <option key={index} value={central}>
                                                            {central}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {!central ? (
                                                <p className="text-center text-gray-600">Veuillez sélectionner un central pour afficher ses FATs.</p>
                                            ) : (
                                                <div className="w-full mt-12 shadow-sm overflow-x-auto">
                                                    <table className="w-full table-auto">
                                                        <thead className="w-full">
                                                            <tr>
                                                                <th className="px-2 py-2 text-center">Central</th>
                                                                <th className="px-2 py-2 text-center">Ville</th>
                                                                <th className="px-2 py-2 text-center">Localisation</th>
                                                                <th className="px-2 py-2 text-center">Latitude</th>
                                                                <th className="px-2 py-2 text-center">Longitude</th>
                                                                <th className="px-2 py-2 text-center">Quartier</th>
                                                                <th className="px-2 py-2 text-center">Capacité</th>
                                                                <th className="px-2 py-2 text-center">Occupation</th>
                                                                <th className="px-2 py-2 text-center">Libre</th>
                                                                <th className="px-2 py-2 text-center">Taux de saturation</th>
                                                                <th className="px-2 py-2 text-center">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="w-full text-gray-600 divide-y">
                                                            {Array.isArray(fats) && fats.length > 0 ? (
                                                                fats.map((fat) => (
                                                                    <tr key={fat.id} className="border-b hover:bg-gray-100">
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.CENTRAL}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.VILLE}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.LOCALISATION}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.LATITUDE}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.LONGITUDE}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.QUARTIER}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.CAPACITE}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.OCCUPATION}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.LIBRE}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">{fat.SATURATION}</td>
                                                                        <td className="px-2 py-2 lg:px-6 text-center">
                                                                            <button
                                                                                onClick={() => handleDetails(fat.idFat)}
                                                                                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold m-2 py-1 px-2 mr-2"
                                                                            >
                                                                                Détails
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="11" className="text-center">Aucun fat trouvé pour ce central.</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}

                            {selectedFat && !isDetailsVisible && (
                                <div className="mt-4">
                                    <div className="flex justify-end">
                                        {/* <button
                                            onClick={() => handleEdit(selectedFat.ID)}
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 m-1"
                                        >
                                            Modifier
                                        </button> */}
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 m-1 text-white"
                                            onClick={() => { setIsDetailsVisible(true); }}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                    <h2 className="text-3xl font-bold text-center m-2">Détails du FAT</h2>
                                    <div> <strong>Central:</strong> {selectedFat.CENTRAL} </div>
                                    <div> <strong>Région:</strong> {selectedFat.REGION}</div>
                                    <div><strong>Ville:</strong> {selectedFat.VILLE}</div>
                                    <div><strong>OLT:</strong> {selectedFat.OLT}</div>
                                    <div> <strong>FSP_OSS:</strong> {selectedFat.FSP_OSS}</div>
                                    <div><strong>FDT:</strong> {selectedFat.FDT}</div>
                                    <div><strong>SPLITTER_OSS:</strong> {selectedFat.SPLITTER_OSS}</div>
                                    <div><strong>FAT_OSS:</strong> {selectedFat.FAT_OSS}</div>
                                    <div><strong>FAT:</strong> {selectedFat.FAT}</div>
                                    <div><strong>Latitude:</strong> {selectedFat.LATITUDE}</div>
                                    <div><strong>Longitude:</strong> {selectedFat.LONGITUDE}</div>
                                    <div><strong>Quartier:</strong> {selectedFat.QUARTIER}</div>
                                    <div><strong>Localisation:</strong> {selectedFat.LOCALISATION}</div>
                                    <div><strong>Capacité:</strong> {selectedFat.CAPACITE}</div>
                                    <div><strong>Occupation:</strong> {selectedFat.OCCUPATION}</div>
                                    <div><strong>Libre:</strong> {selectedFat.LIBRE}</div>
                                    <div><strong>Taux de saturation:</strong> {selectedFat.SATURATION * 100}%</div>
                                </div>
                            )}

                            {isAdding && !isAddFatVisibile && (
                                <div className="mt-4">
                                    <div className="flex justify-end">
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                            onClick={() => { setIsAddFatVisible(true); setIsDetailsVisible(true); }}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                    <h2 className="text-3xl font-bold text-center mb-2">Ajouter un FAT</h2>
                                    <form>
                                        <div className="mb-2">
                                            <label htmlFor="central" className="block text-gray-700 font-bold">
                                                Central  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <select
                                                id="central"
                                                name="central"
                                                value={central}
                                                onChange={(event) => {
                                                    setFormData({ ...formData, idCentral: event.target.value });
                                                    setCentral(event.target.value);
                                                }}
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                                <option value=''>Sélectionnez un central</option>
                                                {centrals.map((central) => (
                                                    <option key={central.idCentral} value={central.idCentral}>{central.centralName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="olt" className="block text-gray-700 font-bold">
                                                OLT  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="olt"
                                                name="olt"
                                                value={formData.olt}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="fspOss" className="block text-gray-700 font-bold">
                                                FSP_OSS  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="fspOss"
                                                name="fspOss"
                                                value={formData.fspOss}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="fsp" className="block text-gray-700 font-bold">
                                                FSP  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="fsp"
                                                name="fsp"
                                                value={formData.fsp}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="fdtOss" className="block text-gray-700 font-bold">
                                                FDT_OSS  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="fdtOss"
                                                id="fdtOss"
                                                name="fdtOss"
                                                value={formData.fdtOss}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="fdt" className="block text-gray-700 font-bold">
                                                FAT  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="fdt"
                                                name="fdt"
                                                value={formData.fdt}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="splitterOss" className="block text-gray-700 font-bold">
                                                SPLITTER_OSS  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="splitterOss"
                                                name="splitterOss"
                                                value={formData.splitterOss}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="fatOss" className="block text-gray-700 font-bold">
                                                FAT_OSS  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="fatOss"
                                                name="fatOss"
                                                value={formData.fatOss}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="fat" className="block text-gray-700 font-bold">
                                                FAT  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="fat"
                                                name="fat"
                                                value={formData.fat}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="latitude" className="block text-gray-700 font-bold">
                                                Latitude  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="latitude"
                                                name="latitude"
                                                value={formData.latitude}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="longitude" className="block text-gray-700 font-bold">
                                                Longitude <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="longitude"
                                                name="longitude"
                                                value={formData.longitude}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        {formData.latitude && formData.longitude && (
                                            <div className="mb-4">
                                                <MapContainer
                                                    key={[formData.latitude, formData.longitude]}
                                                    center={[formData.latitude, formData.longitude]}
                                                    zoom={18}
                                                    style={{ height: 350, width: '100%', zIndex: 0 }}
                                                >
                                                    <TileLayer
                                                        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                    <Marker position={[formData.latitude, formData.longitude]} icon={customFatIcon}>
                                                        <Popup>
                                                            <h2>Position du FAT</h2>
                                                        </Popup>
                                                    </Marker>
                                                    <LocationMarker setFormData={setFormData} />
                                                </MapContainer>
                                            </div>
                                        )}
                                        <div className="mb-2">
                                            <label htmlFor="neighborhood" className="block text-gray-700 font-bold">
                                                Quartier  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="neighborhood"
                                                name="neighborhood"
                                                value={formData.neighborhood}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="localisation" className="block text-gray-700 font-bold">
                                                Localisation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="localisation"
                                                name="localisation"
                                                value={formData.localisation}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="capacity" className="block text-gray-700 font-bold">
                                                Capacité  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="capacity"
                                                name="capacity"
                                                value={formData.capacity}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="fatOss" className="block text-gray-700 font-bold">
                                                Occupation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="occupation"
                                                name="occupation"
                                                value={formData.occupation}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="free" className="block text-gray-700 font-bold">
                                                Libre  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="free"
                                                name="free"
                                                value={formData.free}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                                readOnly
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="saturation" className="block text-gray-700 font-bold">
                                                Saturation  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="saturation"
                                                name="saturation"
                                                value={formData.saturation}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                                readOnly
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            onClick={handleAdd}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Ajouter
                                        </button>
                                    </form>
                                </div>
                            )}

                            {editingFat && !isEditFatVisible && (
                                <div className="mt-4">
                                    <div className="flex justify-end">
                                        <button
                                            className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                            onClick={() => { setIsEditFatVisible(true); setIsDetailsVisible(true); }}
                                        >
                                            <span className="close-icon text-white">✖</span> Fermer
                                        </button>
                                    </div>
                                    <h2 className="text-3xl font-bold text-center mb-2">Modifier le FAT</h2>
                                    <form>
                                        <div className="mb-2">
                                            <label htmlFor="editedCentral" className="block text-gray-700 font-bold">
                                                Central  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <select
                                                id="editedCentral"
                                                name="editedCentral"
                                                value={editedCentral}
                                                onChange={(e) => setEditedCentral(e.target.value)}
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                                {centrals.map((central, index) => (
                                                    <option key={index} value={central}>{central}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedOlt" className="block text-gray-700 font-bold">
                                                OLT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedOlt"
                                                name="editedOlt"
                                                value={editedOlt}
                                                onChange={(e) => setEditedOlt(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedFspOss" className="block text-gray-700 font-bold">
                                                FSP_OSS <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedFspOss"
                                                name="editedFspOss"
                                                value={editedFspOss}
                                                onChange={(e) => setEditedFspOss(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedFsp" className="block text-gray-700 font-bold">
                                                FSP <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedFsp"
                                                name="editedFsp"
                                                value={editedFsp}
                                                onChange={(e) => setEditedFsp(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedFdtOss" className="block text-gray-700 font-bold">
                                                FDT_OSS <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedFdtOss"
                                                name="editedFdtOss"
                                                value={editedFdtOss}
                                                onChange={(e) => setEditedFdtOss(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedFdt" className="block text-gray-700 font-bold">
                                                FDT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedFdt"
                                                name="editedFdt"
                                                value={editedFdt}
                                                onChange={(e) => setEditedFdt(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedSplitterOss" className="block text-gray-700 font-bold">
                                                SPLITTER_OSS <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedSplitterOss"
                                                name="editedSplitterOss"
                                                value={editedSplitterOss}
                                                onChange={(e) => setEditedSplitterOss(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedFatOss" className="block text-gray-700 font-bold">
                                                FAT_OSS <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedFatOss"
                                                name="editedFatOss"
                                                value={editedFatOss}
                                                onChange={(e) => setEditedFatOss(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedFat" className="block text-gray-700 font-bold">
                                                FAT <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedFat"
                                                name="editedFat"
                                                value={editedFat}
                                                onChange={(e) => setEditedFat(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedLatitude" className="block text-gray-700 font-bold">
                                                Latitude <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedLatitude"
                                                name="editedLatitude"
                                                value={editedLatitude}
                                                onChange={(e) => setEditedLatitude(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedLongitude" className="block text-gray-700 font-bold">
                                                Longitude <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedLongitude"
                                                name="editedLongitude"
                                                value={editedLongitude}
                                                onChange={(e) => setEditedLongitude(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        {editedLatitude && editedLongitude && (
                                            <div className="mb-4">
                                                <MapContainer
                                                    key={[editedLatitude, editedLongitude]}
                                                    center={[editedLatitude, editedLongitude]}
                                                    zoom={18}
                                                    style={{ height: 350, width: '100%', zIndex: 0 }}
                                                >
                                                    <TileLayer
                                                        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                    <Marker position={[editedLatitude, editedLongitude]} icon={customFatIcon}>
                                                        <Popup>
                                                            <h2>Position du FAT</h2>
                                                        </Popup>
                                                    </Marker>
                                                    <EditLocationMarker setEditedLatitude={setEditedLatitude} setEditedLongitude={setEditedLongitude} />
                                                </MapContainer>
                                            </div>
                                        )}
                                        <div className="mb-2">
                                            <label htmlFor="editedNeighborhood" className="block text-gray-700 font-bold">
                                                Quartier <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedNeighborhood"
                                                name="editedNeighborhood"
                                                value={editedNeighborhood}
                                                onChange={(e) => setEditedNeighborhood(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedLocalisation" className="block text-gray-700 font-bold">
                                                Localisation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedLocalisation"
                                                name="editedLocalisation"
                                                value={editedLocalisation}
                                                onChange={(e) => setEditedLocalisation(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedCapacity" className="block text-gray-700 font-bold">
                                                Capacité <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedCapacity"
                                                name="editedCapacity"
                                                value={editedCapacity}
                                                onChange={(e) => setEditedCapacity(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedOccupation" className="block text-gray-700 font-bold">
                                                Occupation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedOccupation"
                                                name="editedOccupation"
                                                value={editedOccupation}
                                                onChange={(e) => setEditedOccupation(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedFree" className="block text-gray-700 font-bold">
                                                Libre <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedFree"
                                                name="editedFree"
                                                value={editedFree}
                                                onChange={(e) => setEditedFree(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                readOnly
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedSaturation" className="block text-gray-700 font-bold">
                                                Taux de saturation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedSaturation"
                                                name="editedSaturation"
                                                value={editedSaturation}
                                                onChange={(e) => setEditedSaturation(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                                readOnly
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            onClick={handleSubmit}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Enregistrer
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Fat;
