import React from "react";

const Register = () => {
    return (
        <h1 className="text-3xl font-bold underline text-center">
            Register
        </h1>
    )
}

export default Register;