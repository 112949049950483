import React, { useState, useEffect, useRef } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import SignatureCanvas from 'react-signature-canvas';

const Settings = () => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [token, setToken] = useState(null);
    const [formData, setFormData] = useState({
        email: localStorage.getItem('email'),
        role: localStorage.getItem('userRole'),
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [selectedTab, setSelectedTab] = useState("password");
    const [signatureData, setSignatureData] = useState([]);
    const sigCanvas = useRef({});

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        setLoading(true);

        if (token) {
            try {
                const response = await axios.patch(`${BASE_URL}/api/user/editpassword`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setLoading(false);

                if (response.data && response.data.message) {
                    alert(response.data.message);
                    navigate('/dashboard');
                } else {
                    setErrorMessage(response.data.error);
                }
            } catch (error) {
                console.error("Erreur de connexion :", error);
                setErrorMessage("Une erreur s\'est produite lors de la mise à jour du mot de passe.");
                setLoading(false);
            }
        }
    };

    const handleClearSignature = () => {
        sigCanvas.current.clear();
    };

    useEffect(() => {
        if (token) {
            const fetchSignature = async () => {
                setLoading(true);

                let idUser;

                if (localStorage.getItem('profileId') !== 'undefined') {
                    idUser = localStorage.getItem('profileId');
                    console.log('profileId: ', idUser);
                } else {
                    idUser = localStorage.getItem('userId');
                    console.log('userId: ', idUser);
                }

                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/user/signature/${idUser}?role=${localStorage.getItem('userRole')}`,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            setSignatureData(response.data.signature[0]);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                    setLoading(false);
                }
            };

            fetchSignature();
        }
    }, [token])

    const handleSaveSignature = async () => {
        setLoading(true);

        let idUser;

        if (localStorage.getItem('profileId') !== 'undefined') {
            idUser = localStorage.getItem('profileId');
            console.log('profileId: ', idUser);
        } else {
            idUser = localStorage.getItem('userId');
            console.log('userId: ', idUser);
        }

        const formData = {
            idUser: idUser,
            role: localStorage.getItem('userRole'),
            signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        }

        if (token) {
            try {
                const response = await axios.post(`${BASE_URL}/api/user/signature`, formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setLoading(false);

                if (response.data && response.data.message) {
                    alert(response.data.message);
                    setSignatureData(formData);
                } else {
                    setErrorMessage(response.data.error);
                }
            } catch (error) {
                console.error("Erreur de connexion :", error);
                setErrorMessage("Une erreur s\'est produite lors de l'enregistrement de la signature électronique.");
                setLoading(false);
            }
        }
    };

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center" >
                    <img
                        src={require('../../assets/img/icons/gif/loading-points.webp')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div >
            ) : (
                <>
                    <Header />

                    <Sidebar setHideText={setHideText} hideText={hideText} />

                    <div className={`container mx-auto p-6 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 flex justify-center ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                        <div className="w-full mt-20 max-w-lg bg-white shadow-md rounded-lg p-6">

                            <h3 className="text-gray-800 text-2xl font-bold text-center mb-6">Paramètres du compte</h3>

                            <div className="border-b mb-6">
                                <ul className="flex">
                                    <li
                                        className={`cursor-pointer py-2 px-4 ${selectedTab === "password" ? "border-b-2 border-sky-600 text-sky-600" : "text-gray-600"}`}
                                        onClick={() => setSelectedTab("password")}
                                    >
                                        Mot de passe
                                    </li>
                                    <li
                                        className={`cursor-pointer py-2 px-4 ${selectedTab === "signature" ? "border-b-2 border-sky-600 text-sky-600" : "text-gray-600"}`}
                                        onClick={() => setSelectedTab("signature")}
                                    >
                                        Signature
                                    </li>
                                </ul>
                            </div>

                            {selectedTab === "password" && (
                                <>
                                    <h4 className="text-gray-600 font-semibold text-center mb-3">Modification de votre mot de passe</h4>

                                    <form onSubmit={handlePasswordChange}>
                                        <div className="form-group mb-4">
                                            <label className="font-medium">Mot de passe actuel</label>
                                            <input
                                                type="password"
                                                value={formData.currentPassword}
                                                onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
                                                required
                                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                            />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="font-medium">Nouveau mot de passe</label>
                                            <input
                                                type="password"
                                                value={formData.newPassword}
                                                onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                                                required
                                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-cyan-600 shadow-sm rounded-lg"
                                            />
                                        </div>
                                        <div className="form-group mb-4">
                                            <label className="font-medium">Confirmer le nouveau mot de passe</label>
                                            <input
                                                type="password"
                                                value={formData.confirmPassword}
                                                onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                                                required
                                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-cyan-600 shadow-sm rounded-lg"
                                            />
                                        </div>

                                        <button type="submit" className="w-full py-2 px-4 bg-sky-500 text-white font-semibold rounded-md hover:bg-sky-700 transition duration-300">
                                            Valider
                                        </button>

                                        {errorMessage && (
                                            <div className="mt-3 text-red-500 text-center">{errorMessage}</div>
                                        )}
                                    </form>
                                </>
                            )}

                            {selectedTab === "signature" && (
                                <div className="signature-section flex flex-col items-center">
                                    <h4 className="text-gray-600 font-semibold mb-3">Signature numérique</h4>
                                    {signatureData ? (
                                        <div className="mt-4">
                                            <img src={signatureData.signature} alt="Signature" className="border border-gray-300 rounded-md mt-2" />
                                        </div>
                                    ) : (
                                        <>
                                            <SignatureCanvas
                                                ref={sigCanvas}
                                                penColor="black"
                                                canvasProps={{ className: "border border-gray-300 rounded-md w-full h-40", width: 360, height: 170 }}
                                            />
                                            <div className="flex mt-4 space-x-2">
                                                <button onClick={handleClearSignature} className="w-full py-2 px-4 bg-gray-500 text-white font-semibold rounded-md hover:bg-gray-600">
                                                    Effacer
                                                </button>
                                                <button onClick={handleSaveSignature} className="w-full py-2 px-4 bg-sky-600 text-white font-semibold rounded-md hover:bg-sky-700">
                                                    Enregistrer
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Settings;
