import React, { useState, useRef, useEffect } from "react";
import Header from "../admin/components/Header";
import Sidebar from "../admin/components/Sidebar";
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvent } from 'react-leaflet';
import { Icon, L } from 'leaflet';
import AvatarEditor from 'react-avatar-editor';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from "../../apiConfig";
import logoCamtel from '../../assets/img/icons/favicon/favicon-camtel.png';
import logosFtth from '../../assets/img/logo/logos-ftth.png';
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";
import Modal from 'react-modal';
import 'leaflet/dist/leaflet.css';
import LocationFinder from "../../components/LocationFinder";
import SignatureCanvas from 'react-signature-canvas';
import '../../style.css';

const PartnerSheet = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [capVal, setCapVal] = useState(null);
    const [photoPreview, setPhotoPreview] = useState([]);
    const [photoLocationPreview, setPhotoLocationPreview] = useState([]);
    const [pdfLocationPreview, setPdfLocationPreview] = useState(false);
    const [pdfLocationFile, setPdfLocationFile] = useState([]);
    const [photoRectoCniPreview, setPhotoRectoCniPreview] = useState(null);
    const [photoVersoCniPreview, setPhotoVersoCniPreview] = useState(null);
    const [photoPassportPreview, setPhotoPassportPreview] = useState(null);
    const [photoResidencePreview, setPhotoResidencePreview] = useState(null);
    const [photoRegComPreview, setPhotoRegComPreview] = useState([]);
    const [pdfRegComPreview, setPdfRegComPreview] = useState(false);
    const [pdfRegComFile, setPdfRegComFile] = useState([]);

    const [signature, setSignature] = useState(null);
    const sigCanvas = useRef({});

    const saveSignature = () => {
        const customerSignature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        setSignature(customerSignature);
        setFormData({ ...formData, signature: customerSignature });
    }

    const clearSignature = () => {
        sigCanvas.current.clear();
        setFormData({ ...formData, signature: null });
        setSignature(null)
    }

    function formatDateToJJMMAA() {
        const date = new Date();
        const jour = date.getDate().toString().padStart(2, '0');
        const mois = (date.getMonth() + 1).toString().padStart(2, '0');
        const annee = date.getFullYear().toString().slice(-2);

        return jour + mois + annee;
    }

    const dateJJMMAA = formatDateToJJMMAA(new Date());

    // PHOTO DU CLIENT
    const [customerPhotoImage, setCustomerPhotoImage] = useState('');
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [canvas, setCanvas] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // RECTO & VERSO CNI
    const [rectoPhotoCniImage, setRectoPhotoCniImage] = useState('');
    const [canvasRectoCni, setCanvasRectoCni] = useState('');
    const [versoPhotoCniImage, setVersoPhotoCniImage] = useState('');
    const [scaleRectoCni, setScaleRectoCni] = useState(1);
    const [rotateRectoCni, setRotateRectoCni] = useState(0);
    const [scaleVersoCni, setScaleVersoCni] = useState(1);
    const [rotateVersoCni, setRotateVersoCni] = useState(0);
    const [canvasVersoCni, setCanvasVersoCni] = useState('');
    const [modalCniIsOpen, setModalCniIsOpen] = useState(false);

    // PASSPORT
    const [passportPhotoImage, setPassportPhotoImage] = useState('');
    const [scalePassport, setScalePassport] = useState(1);
    const [rotatePassport, setRotatePassport] = useState(0);
    const [canvasPassport, setCanvasPassport] = useState('');
    const [modalPassportIsOpen, setModalPassportIsOpen] = useState(false);

    // CARTE DE SEJOUR
    const [residencePhotoImage, setResidencePhotoImage] = useState('');
    const [scaleResidence, setScaleResidence] = useState(1);
    const [rotateResidence, setRotateResidence] = useState(0);
    const [canvasResidence, setCanvasResidence] = useState('');
    const [modalResidenceIsOpen, setModalResidenceIsOpen] = useState(false);

    // CONDITIONS
    const [modalConditionsIsOpen, setModalConditionsIsOpen] = useState(false);

    const editorRef = useRef();
    const editorRefRectoCni = useRef();
    const editorRefVersoCni = useRef();
    const editorRefPassport = useRef();
    const editorRefResidence = useRef();

    // CUSTOMER PHOTO
    const handleScaleChange = (e) => {
        setScale(parseFloat(e.target.value));
    }

    const handleRotateChange = (e) => {
        setRotate(parseFloat(e.target.value));
    }

    // CNI
    const handleScaleChangeRectoCni = (e) => {
        setScaleRectoCni(parseFloat(e.target.value));
    }

    const handleRotateChangeRectoCni = (e) => {
        setRotateRectoCni(parseFloat(e.target.value));
    }

    const handleScaleChangeVersoCni = (e) => {
        setScaleVersoCni(parseFloat(e.target.value));
    }

    const handleRotateChangeVersoCni = (e) => {
        setRotateVersoCni(parseFloat(e.target.value));
    }

    // PASSEPORT
    const handleScaleChangePassport = (e) => {
        setScalePassport(parseFloat(e.target.value));
    }

    const handleRotateChangePassport = (e) => {
        setRotatePassport(parseFloat(e.target.value));
    }

    // CARTE DE SEJOUR
    const handleScaleChangeResidence = (e) => {
        setScaleResidence(parseFloat(e.target.value));
    }

    const handleRotateChangeResidence = (e) => {
        setRotateResidence(parseFloat(e.target.value));
    }

    const previewHandle = () => {
        const canvasImage = editorRef.current.getImage();

        canvasImage.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoPreview(previewURL);

            const originalFileName = "profile_pic.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['customerPhoto']: originalFile,
            }));
        });
        setCanvas(canvasImage);

        setModalIsOpen(false);
    }

    const previewHandleCni = () => {

        const canvasImageRecto = editorRefRectoCni.current.getImage();
        const canvasImageVerso = editorRefVersoCni.current.getImage();

        canvasImageRecto.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoRectoCniPreview(previewURL);

            const originalFileName = "recto_cni.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['idCardFront']: originalFile,
            }));
        });
        setCanvasRectoCni(canvasImageRecto);

        canvasImageVerso.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoVersoCniPreview(previewURL);

            const originalFileName = "verso_cni.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['idCardBack']: originalFile,
            }));
        });
        setCanvasVersoCni(canvasImageVerso);

        setModalCniIsOpen(false);
    }

    const previewHandlePassport = () => {
        const canvasImagePassport = editorRefPassport.current.getImage();

        canvasImagePassport.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoPassportPreview(previewURL);

            const originalFileName = "passport_pic.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['idPasseportFront']: originalFile,
            }));
        });
        setCanvasPassport(canvasImagePassport);

        setModalPassportIsOpen(false);
    }

    const previewHandleResidence = () => {
        const canvasImageResidence = editorRefResidence.current.getImage();

        canvasImageResidence.toBlob((blob) => {
            const previewURL = URL.createObjectURL(blob);
            setPhotoResidencePreview(previewURL);

            const originalFileName = "residence_pic.jpg";

            const originalFile = new File([blob], originalFileName, { type: 'image/jpeg' });

            setFormData((prevFormData) => ({
                ...prevFormData,
                ['idResidencePermitFront']: originalFile,
            }));
        });
        setCanvasResidence(canvasImageResidence);

        setModalResidenceIsOpen(false);
    }

    const acceptConditions = () => {
        setModalConditionsIsOpen(false);
    }

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    useEffect(() => {
        const getLocationCoords = async () => {
            setLoading(true);

            navigator.geolocation.getCurrentPosition(async position => {
                const { latitude, longitude } = position.coords;

                if (!latitude || !longitude) {
                    console.error("Coordonnées de géolocalisation invalides");
                    return;
                }

                setLatitude(latitude);
                setLongitude(longitude);

                const regionAbbreviations = {
                    'Extrême-Nord': 'EN',
                    'Nord': 'NO',
                    'Adamaoua': 'AD',
                    'Centre': 'CE',
                    'Littoral': 'LT',
                    'Sud': 'SU',
                    'Est': 'ES',
                    'Ouest': 'OU',
                    'Nord-Ouest': 'NW',
                    'Sud-Ouest': 'SW'
                };

                try {
                    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
                    const data = response.data;

                    const road = data.address.road;
                    const neighbourhood = data.address.suburb;
                    const city = data.address.city;
                    let region = data.address.state;

                    localStorage.setItem('city', city);
                    localStorage.setItem('preciseLocation', `${neighbourhood}, ${road}`);

                    const match = region.match(/Région de l'(.*)|Région du (.*)/);
                    if (match) {
                        region = match[1] || match[2];
                    }

                    const regionAbbreviation = regionAbbreviations[region];

                    setFormData({
                        ...formData,
                        region: regionAbbreviation,
                        customerGpsCoordinates: latitude + ',' + longitude,
                        city: localStorage.getItem('city'),
                        preciseLocation: localStorage.getItem('preciseLocation')
                    });

                } catch (error) {
                    console.error(error);
                    alert('Non disponibilité de l\'API de géolocalisation');
                }

                setLoading(false);
            },
                error => {
                    console.error("Erreur lors de la récupération de la position:", error);
                    alert("Impossible d'obtenir votre position. Veuillez vérifier vos paramètres de localisation.");
                    setLoading(false);
                }
            );
        }

        getLocationCoords();
    }, []);

    const handleMapClick = async (latlng) => {
        if (!latlng || !latlng.lat || !latlng.lng) {
            console.error("Coordonnées invalides:", latlng);
            return;
        }

        setLatitude(latlng.lat);
        setLongitude(latlng.lng);

        const regionAbbreviations = {
            'Extrême-Nord': 'EN',
            'Nord': 'NO',
            'Adamaoua': 'AD',
            'Centre': 'CE',
            'Littoral': 'LT',
            'Sud': 'SU',
            'Est': 'ES',
            'Ouest': 'OU',
            'Nord-Ouest': 'NW',
            'Sud-Ouest': 'SW'
        };

        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latlng.lat}&lon=${latlng.lng}`);
            const data = response.data;
            const road = data.address.road;
            const neighbourhood = data.address.suburb;
            const city = data.address.city;
            let region = data.address.state;

            const match = region.match(/Région de l'(.*)|Région du (.*)/);
            if (match) {
                region = match[1] || match[2];
            }

            const regionAbbreviation = regionAbbreviations[region];

            setFormData({
                ...formData,
                region: regionAbbreviation,
                city: city,
                preciseLocation: neighbourhood + ', ' + road,
                customerGpsCoordinates: latlng.lat + ',' + latlng.lng
            });
        } catch (error) {
            console.error(error);
        }
    }

    const [formData, setFormData] = useState({
        idEntity: localStorage.getItem('userId'),
        nameEntity: localStorage.getItem('userEntity'),
        idPartnerCommercial: localStorage.getItem('profileId'),
        customerPhoto: null,
        idSubscription: dateJJMMAA + "-",
        nameOrSocialName: "",
        customerType: "Particulier",
        region: "",
        city: localStorage.getItem('city'),
        preciseLocation: localStorage.getItem('preciseLocation'),
        preciseLocationFile: null,
        lieuDit: "",
        customerGpsCoordinates: latitude + ',' + longitude,
        email: "",
        mobile: "",
        idDoc: "CNI",
        idCardNumber: "",
        idCardFront: null,
        idCardBack: null,
        idPassportNumber: "",
        idResidencePermitNumber: "",
        idPasseportFront: null,
        idResidencePermitFront: null,
        regComNumber: "",
        commerceRegistryFront: null,
        offerBlueMaxSelected: "",
        isAutoSubscribe: 0
    });

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedOption, setSelectedOption] = useState(formData.region || "");

    const [formErrors, setFormErrors] = useState({});

    const [steps, setStep] = useState({
        stepsItems: ["Informations du client", "Choix de l'offre", "Récapitulatif"],
        currentStep: 1,
        formData: {
            idEntity: localStorage.getItem('userId'),
            nameEntity: localStorage.getItem('userEntity'),
            idPartnerCommercial: localStorage.getItem('profileId'),
            customerPhoto: null,
            idSubscription: dateJJMMAA + "-",
            nameOrSocialName: "",
            customerType: "Particulier",
            region: "",
            city: localStorage.getItem('city'),
            preciseLocation: localStorage.getItem('preciseLocation'),
            preciseLocationFile: null,
            lieuDit: "",
            customerGpsCoordinates: latitude + ',' + longitude,
            email: "",
            mobile: "",
            idDoc: "CNI",
            idCardNumber: "",
            idCardFront: null,
            idCardBack: null,
            idPassportNumber: "",
            idResidencePermitNumber: "",
            idPasseportFront: null,
            idResidencePermitFront: null,
            regComNumber: "",
            commerceRegistryFront: null,
            offerBlueMaxSelected: "",
            isAutoSubscribe: 0
        },
    });

    const regionCoordinates = {
        'EN': { latitude: '10.6033083', longitude: '14.3281625' },
        'NO': { latitude: '9.3070698', longitude: '13.3934527' },
        'AD': { latitude: '7.3211536', longitude: '7.3211536' },
        'CE': { latitude: '3.8689867', longitude: '11.5213344' },
        'LT': { latitude: '4.0429408', longitude: '9.706203' },
        'SU': { latitude: '2.919384', longitude: '11.151663' },
        'ES': { latitude: '4.5776155', longitude: '13.6843792' },
        'OU': { latitude: '5.4758844', longitude: '10.4217852' },
        'NW': { latitude: '5.9614117', longitude: '10.1516505' },
        'SW': { latitude: '4.1567895', longitude: '9.2315915' },
    };

    const handleSelectChange = async (e) => {
        const newValue = e.target.value;
        setSelectedOption(newValue);

        const coordinates = regionCoordinates[newValue];
        if (!coordinates || !coordinates.latitude || !coordinates.longitude) {
            console.error("Coordonnées non disponibles ou invalides pour cette région");
            return;
        }

        setLatitude(coordinates.latitude);
        setLongitude(coordinates.longitude);

        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinates.latitude}&lon=${coordinates.longitude}`);
            const data = response.data;
            const road = data.address.road;
            const neighbourhood = data.address.suburb;
            const city = data.address.city;

            setFormData({ ...formData, region: newValue, city: city, preciseLocation: neighbourhood + ', ' + road, customerGpsCoordinates: coordinates.latitude + ',' + coordinates.longitude });
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        setStep((prevState) => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: value,
            },
        }));

        if (name === 'customerGpsCoordinates') {
            const coordinates = value.split(',').map(coord => parseFloat(coord.trim()));

            if (coordinates.length === 2 && !isNaN(coordinates[0]) && !isNaN(coordinates[1])) {
                setLatitude(coordinates[0]);
                setLongitude(coordinates[1]);

                navigator.geolocation.getCurrentPosition(position => {

                    const regionAbbreviations = {
                        'Extrême-Nord': 'EN',
                        'Nord': 'NO',
                        'Adamaoua': 'AD',
                        'Centre': 'CE',
                        'Littoral': 'LT',
                        'Sud': 'SU',
                        'Est': 'ES',
                        'Ouest': 'OU',
                        'Nord-Ouest': 'NW',
                        'Sud-Ouest': 'SW'
                    };

                    fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinates[0]}&lon=${coordinates[1]}`)
                        .then(response => response.json())
                        .then(data => {
                            const road = data.address.road;
                            const neighbourhood = data.address.suburb;
                            const city = data.address.city;
                            let region = data.address.state;

                            const match = region.match(/Région de l'(.*)|Région du (.*)/);
                            if (match) {
                                region = match[1] || match[2];
                            }

                            const regionAbbreviation = regionAbbreviations[region];

                            setFormData({ ...formData, region: regionAbbreviation, city: city, preciseLocation: neighbourhood + ', ' + road, customerGpsCoordinates: coordinates[0] + ',' + coordinates[1] });
                        })
                        .catch(error => console.error(error));
                })
            } else {
                console.error("Coordonnées invalides:", value);
            }
        }

        if (formData.idDoc === 'CNI') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                idPassportNumber: '',
                idResidencePermitNumber: '',
                idPasseportFront: null,
                idResidencePermitFront: null,
                idResidencePermitFront: null
            }));
        } else if (formData.idDoc === 'Passeport') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                idCardNumber: '',
                idResidencePermitNumber: '',
                idCardFront: null,
                idCardBack: null,
                idResidencePermitFront: null
            }));
        } else {
            if (formData.idDoc === 'Carte de séjour') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    idCardNumber: '',
                    idPassportNumber: '',
                    idCardFront: null,
                    idCardBack: null,
                    idPasseportFront: null,
                }));
            }
        }
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setStep((prevState) => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                [name]: files[0],
            },
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));

        if (name === 'preciseLocationFile' && files.length > 0) {
            const file = files[0];
            const fileType = file.type;

            if (fileType.startsWith('image/')) {
                setPhotoLocationPreview(URL.createObjectURL(file));
            } else if (fileType === 'application/pdf') {
                setPdfLocationPreview(true);
                setPdfLocationFile(URL.createObjectURL(file));
            } else {
                console.error('Type de fichier non pris en charge.');
            }
        }

        if (name === 'idCardFront' && files.length > 0) {
            setPhotoRectoCniPreview(URL.createObjectURL(files[0]));
        }

        if (name === 'idCardBack' && files.length > 0) {
            setPhotoVersoCniPreview(URL.createObjectURL(files[0]));
        }

        if (name === 'idPasseportFront' && files.length > 0) {
            setPhotoPassportPreview(URL.createObjectURL(files[0]));
        }

        if (name === 'idResidencePermitFront' && files.length > 0) {
            setPhotoResidencePreview(URL.createObjectURL(files[0]));
        }

        if (name === 'commerceRegistryFront' && files.length > 0) {
            const file = files[0];
            const fileType = file.type;

            if (fileType.startsWith('image/')) {
                setPhotoRegComPreview(URL.createObjectURL(file));
            } else if (fileType === 'application/pdf') {
                setPdfRegComPreview(true);
                setPdfRegComFile(URL.createObjectURL(file));
            } else {
                console.error('Type de fichier non pris en charge.');
            }
        }
    };

    const handleSelectPlan = (planName) => {
        setSelectedPlan(planName);

        setFormData({ ...formData, offerBlueMaxSelected: planName });
    };

    const isValidGpsFormat = (gpsString) => {
        const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
        return regex.test(gpsString);
    };

    const nextStep = () => {
        const currentStepFields = getRequiredFieldsForStep(steps.currentStep);
        const errors = {};

        currentStepFields.forEach((fieldName) => {
            if (!steps.formData[fieldName]) {
                errors[fieldName] = "Ce champ est obligatoire.";
            }
        });

        if (steps.currentStep === 1 && !canvas) {
            errors.customerPhoto = "Veuillez sélectionner une photo du client.";
        }

        if (steps.currentStep === 1 && !steps.formData.preciseLocationFile) {
            errors.preciseLocationFile = "Veuillez sélectionner une image ou un fichier pdf du plan de localisation.";
        }

        if (steps.currentStep === 1) {
            if (!formData.customerGpsCoordinates || !isValidGpsFormat(formData.customerGpsCoordinates)) {
                errors.customerGpsCoordinates = "Les coordonnées GPS doivent être sous format LATITUDE, LONGITUDE. Exemple: 3.8622904131590636, 11.519637988898678";
            }
        }

        if (steps.formData.customerType === "Entreprise") {
            if (!steps.formData.regComNumber) {
                errors.regComNumber = "Le N° du Registre commerce est obligatoire.";
            }

            if (!steps.formData.commerceRegistryFront) {
                errors.commerceRegistryFront = "Veuillez sélectionner un fichier pour le régistre de commerce.";
            }
        }

        if (steps.currentStep === 1 && steps.formData.idDoc === "CNI") {
            if (!canvasRectoCni && !canvasVersoCni) {
                errors.idCardFront = "Veuillez sélectionner de fichiers pour le Recto et le Verso de la CNI.";
            }
        } else if (steps.currentStep === 1 && steps.formData.idDoc === "Passeport") {
            if (steps.currentStep === 1 && !canvasPassport) {
                errors.idPasseportFront = "Veuillez sélectionner un fichier pour le passeport.";
            }
        } else {
            if (steps.currentStep === 1 && !canvasResidence) {
                errors.idResidencePermitFront = "Veuillez sélectionner un fichier pour la carte de séjour.";
            }
        }

        if (steps.formData.idDoc === "CNI") {
            if (!steps.formData.idCardNumber) {
                errors.idCardNumber = "Le N° de la CNI est obligatoire.";
            }
        } else if (steps.formData.idDoc === "Passeport") {
            if (!steps.formData.idPassportNumber) {
                errors.idPassportNumber = "Le N° du passeport est obligatoire.";
            }
        } else {
            if (steps.formData.idDoc === "Carte de séjour") {
                if (!steps.formData.idResidencePermitNumber) {
                    errors.idResidencePermitNumber = "Le N° de la Carte de séjour est obligatoire.";
                }
            }
        }

        if (Object.keys(errors).length === 0) {
            setFormErrors({});

            if (steps.currentStep < steps.stepsItems.length) {
                setStep((prevState) => ({
                    ...prevState,
                    currentStep: prevState.currentStep + 1,
                }));
            }
        } else {
            setFormErrors(errors);
        }
    };

    const getRequiredFieldsForStep = (step) => {
        const commonFields = ["nameOrSocialName", "customerType", "city", "preciseLocation", "lieuDit", "email", "mobile"];
        const enterpriseFields = ["regComNumber"];

        switch (step) {
            case 1:
                return steps.formData.customerType === "Entreprise" ? [...commonFields, ...enterpriseFields] : commonFields;
            case 2:
                return [];
            default:
                return [];
        }
    };

    const prevStep = () => {
        if (steps.currentStep > 1) {
            setStep((prevState) => ({
                ...prevState,
                currentStep: prevState.currentStep - 1,
            }));
        }
    };

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true);

        try {
            const response = await axios.post(`${BASE_URL}/api/orders`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            const { insertedId } = response.data;

            localStorage.setItem('idSheet', insertedId);

            navigate(`/admin-location/${insertedId}`);
        } catch (error) {
            console.error('Une erreur s\'est produite lors de l\'envoi de la demande :', error);
            alert('Erreur lors de votre souscription. Veuillez vous assurer que toutes les informations obligatoires sont renseignées dans les deux étapes.');
        }
    };

    const plans = [
        {
            name: "Blue Max S",
            price: 25000,
            features: [
                "15 Mbps",
                "200 mins",
                "90 mins"
            ],
        },
        {
            name: "Blue Max M",
            price: 35000,
            features: [
                "25 Mbps",
                "300 mins",
                "120 mins"
            ],
        },
        {
            name: "Blue Max L",
            price: 50000,
            features: [
                "40 Mbps",
                "400 mins",
                "150 mins"
            ],
        },
        {
            name: "Blue Max XL",
            price: 60000,
            features: [
                "50 Mbps",
                "500 mins",
                "180 mins"
            ],
        },
    ];

    const customHomeIcon = new Icon({
        iconUrl: require('../../assets/img/icons/map/location.png'),
        iconSize: [38, 38]
    });

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../../assets/img/icons/gif/loading-points.webp')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                    {/* <h3 className="text-gray-800 text-5xl font-bold sm:text-5xl text-center text-sky-500 fade-in-out">Géolocalisation de votre position en cours...</h3> */}
                </div>
            ) : (
                <>
                    {isButtonDisabled ? (
                        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 flex">
                            <h3 className="text-gray-800 text-5xl font-bold sm:text-5xl text-center text-sky-500 fade-in-out">
                                Test d'éligibilité en cours. <br />
                                Veuillez patientez s'il vous plait...
                            </h3>
                        </div>
                    ) : (
                        <>
                            <Header />

                            <Sidebar setHideText={setHideText} hideText={hideText} />

                            <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 flex justify-center ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                                <div className="w-full">
                                    <h3 className='text-gray-800 text-3xl text-center mb-10 font-semibold sm:text-4xl mt-10'>
                                        Formulaire d’abonnement
                                    </h3>

                                    <form onSubmit={handleSubmit} enctype="multipart/form-data">
                                        <ul aria-label="Steps" className="items-center text-gray-600 font-medium md:flex">
                                            {steps.stepsItems.map((item, idx) => (
                                                <li
                                                    key={item}
                                                    aria-current={steps.currentStep === idx + 1 ? "step" : false}
                                                    className="flex gap-x-3 md:flex-col md:flex-1 md:gap-x-0"
                                                >
                                                    <div className="flex flex-col items-center md:flex-row md:flex-1">
                                                        <hr
                                                            className={`w-full border hidden md:block ${idx === 0 ? "border-none" : steps.currentStep >= idx + 1 ? "border-sky-600" : ""
                                                                }`}
                                                        />
                                                        <div
                                                            className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${steps.currentStep > idx + 1 ? "bg-sky-600 border-sky-600" : "" ||
                                                                steps.currentStep === idx + 1 ? "border-sky-600" : ""
                                                                }`}
                                                        >
                                                            <span className={`w-2.5 h-2.5 rounded-full bg-sky-600 ${steps.currentStep !== idx + 1 ? "hidden" : ""}`}></span>
                                                            {steps.currentStep > idx + 1 ? (
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                    className="w-5 h-5 text-white"
                                                                >
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                                </svg>
                                                            ) : ""}
                                                        </div>
                                                        <hr
                                                            className={`h-12 border md:w-full md:h-auto ${idx + 1 === steps.stepsItems.length ? "border-none" : "" ||
                                                                steps.currentStep > idx + 1 ? "border-sky-600" : ""}`}
                                                        />
                                                    </div>
                                                    <div className="h-8 flex justify-center items-center md:mt-3 md:h-auto">
                                                        <h3 className={`text-sm ${steps.currentStep === idx + 1 ? "text-sky-600" : ""}`}>
                                                            {item}
                                                        </h3>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>

                                        <div className="mt-4">

                                            {/* Photo du client */}
                                            <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                                    Photo du client <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>

                                                <button
                                                    type="button"
                                                    onClick={() => setModalIsOpen(true)}
                                                    className="bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded-full"
                                                >
                                                    Sélectionner votre photo ici
                                                </button>

                                                <Modal
                                                    isOpen={modalIsOpen}
                                                    onRequestClose={() => setModalIsOpen(false)}
                                                    contentLabel="File Upload Modal"
                                                >
                                                    <h2 className="text-3xl font-bold mb-4 uppercase text-center">Upload de la photo du client</h2>
                                                    <div className="flex flex-col items-center space-y-4">
                                                        {customerPhotoImage ? <AvatarEditor
                                                            ref={editorRef}
                                                            image={customerPhotoImage}
                                                            width={250}
                                                            height={250}
                                                            border={50}
                                                            borderRadius={500}
                                                            color={[135, 206, 250, 0.6]}
                                                            scale={scale}
                                                            rotate={rotate}
                                                        /> : ''}

                                                        {customerPhotoImage && (
                                                            <div className='flex flex-col items-center space-y-2'>
                                                                <div className='flex items-center space-y-2'>
                                                                    <div className="mx-2">
                                                                        <label htmlFor="scale" className="text-gray-600 font-bold mx-1">Zoom:</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            type="range"
                                                                            id="scale"
                                                                            min="0.1"
                                                                            max="10"
                                                                            step="0.1"
                                                                            value={scale}
                                                                            onChange={handleScaleChange}
                                                                            className="w-48"
                                                                        />
                                                                        <span className="text-gray-500 font-bold mx-1">{scale}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="flex items-center space-y-2">
                                                                    <div className="mx-2">
                                                                        <label htmlFor="rotate" className="text-gray-600 font-bold mx-1">Rotation:</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            type="range"
                                                                            id="rotate"
                                                                            min="0"
                                                                            max="360"
                                                                            step="1"
                                                                            value={rotate}
                                                                            onChange={handleRotateChange}
                                                                            className="w-48"
                                                                        />
                                                                        <span className="text-gray-500 font-bold mx-1">{rotate}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <input
                                                            type='file'
                                                            accept="image/jpeg, image/jpg, image/png"
                                                            name="customerPhoto"
                                                            onChange={e => {
                                                                setCustomerPhotoImage(e.target.files[0]);
                                                            }}
                                                            className="block py-2 px-4 w-full text-sm text-slate-500
                                                        file:mr-4 file:py-2 file:px-4
                                                        file:rounded-full file:border-0
                                                        file:text-sm file:font-semibold
                                                        file:bg-sky-50 file:text-sky-700
                                                        hover:file:bg-sky-100 cursor-pointer"
                                                        />
                                                        {customerPhotoImage && (
                                                            <button
                                                                type="button"
                                                                onClick={previewHandle}
                                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full"
                                                            >
                                                                Valider
                                                            </button>
                                                        )}

                                                        {canvas ? <canvas src={canvas} /> : ''}
                                                    </div>
                                                </Modal>

                                                {formErrors.customerPhoto && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.customerPhoto}</p>
                                                )}
                                            </div>

                                            {/* Noms et Prénoms / Raison sociale */}
                                            <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nameOrSocialName">
                                                    Noms et Prénoms / Raison sociale <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="nameOrSocialName"
                                                    type="text"
                                                    placeholder="Noms et Prénoms / Raison sociale"
                                                    name="nameOrSocialName"
                                                    value={steps.formData.nameOrSocialName}
                                                    onChange={handleChange}
                                                />
                                                {formErrors.nameOrSocialName && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.nameOrSocialName}</p>
                                                )}
                                            </div>
                                        </div>

                                        {/* Type de client (Particulier ou entreprise) */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                Type de client <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <div className="flex">
                                                <label className="mr-4">
                                                    <input
                                                        type="radio"
                                                        name="customerType"
                                                        value="Particulier"
                                                        checked={steps.formData.customerType === "Particulier"}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="ml-2">Particulier</span>
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="customerType"
                                                        value="Entreprise"
                                                        checked={steps.formData.customerType === "Entreprise"}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="ml-2">Entreprise</span>
                                                </label>
                                            </div>
                                            {formErrors.customerType && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.customerType}</p>
                                            )}
                                        </div>

                                        {/* Région */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                Région <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <select
                                                id="region"
                                                name="region"
                                                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                value={formData.region ? formData.region : selectedOption}
                                                onChange={handleSelectChange}
                                            >
                                                <option value="">Choisissez la region</option>
                                                <option value="EN">Extrême-Nord</option>
                                                <option value="NO">Nord</option>
                                                <option value="AD">Adamaoua</option>
                                                <option value="CE">Centre</option>
                                                <option value="LT">Littoral</option>
                                                <option value="OU">Ouest</option>
                                                <option value="SU">Sud</option>
                                                <option value="NW">Nord-Ouest</option>
                                                <option value="SW">Sud-Ouest</option>
                                                <option value="ES">Est</option>
                                            </select>
                                            {formErrors.region && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.region}</p>
                                            )}
                                        </div>

                                        {/* Ville */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                Ville <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="block appearance-none w-full border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:shadow-outline"
                                                type="text"
                                                name="city"
                                                placeholder="Ville"
                                                value={formData.city}
                                                onChange={handleChange}
                                            />
                                            {formErrors.city && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.city}</p>
                                            )}
                                        </div>

                                        {/* Localisation précise */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="preciseLocation">
                                                Quartier <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="preciseLocation"
                                                type="text"
                                                placeholder="Localisation précise"
                                                name="preciseLocation"
                                                value={formData.preciseLocation}
                                                onChange={handleChange}
                                            />
                                            {formErrors.preciseLocation && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.preciseLocation}</p>
                                            )}
                                        </div>

                                        {/* Plan de localisation */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                Image du plan de localisation <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="file"
                                                accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                name="preciseLocationFile"
                                                onChange={handleFileChange}
                                                className="block w-full text-sm text-slate-500
                                            file:mr-4 file:py-2 file:px-4
                                            file:rounded-full file:border-0
                                            file:text-sm file:font-semibold
                                            file:bg-sky-50 file:text-sky-700
                                            hover:file:bg-sky-100 cursor-pointer"
                                            />
                                            {formErrors.preciseLocationFile && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.preciseLocationFile}</p>
                                            )}
                                        </div>

                                        {/* Lieu-dit */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lieuDit">
                                                Lieu-dit <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="lieuDit"
                                                type="text"
                                                placeholder="Lieu-dit"
                                                name="lieuDit"
                                                value={steps.formData.lieuDit}
                                                onChange={handleChange}
                                            />
                                            {formErrors.lieuDit && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.lieuDit}</p>
                                            )}
                                        </div>

                                        {/* Coordonnées GPS du client */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerGpsCoordinates">
                                                Coordonnées GPS du client <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="customerGpsCoordinates"
                                                type="text"
                                                placeholder="Coordonnées GPS du client"
                                                name="customerGpsCoordinates"
                                                value={formData.customerGpsCoordinates ? formData.customerGpsCoordinates : steps.formData.customerGpsCoordinates}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        {latitude && longitude && (
                                            <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`} style={{ zIndex: 0 }}>
                                                <p className="mb-2" style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>NB:</span> Les coordonnées renseignées seront celles considérées lors de l'installation. Donc, rassurez-vous que le plan ci-dessous correspond à votre domicile. Sinon, veuillez modifier manuellement vos coordonées géographiques dans le champ précédent "Coordonnées GPS du client"</p>
                                                <MapContainer
                                                    key={[latitude, longitude]}
                                                    center={[latitude, longitude]}
                                                    zoom={17}
                                                    style={{ height: 350, width: '100%', zIndex: 0 }}
                                                >
                                                    <LocationFinder onMapClick={handleMapClick} />
                                                    <TileLayer
                                                        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                    <Marker position={[latitude, longitude]} icon={customHomeIcon}>
                                                        <Popup>
                                                            <h2>Mon Domicile</h2>
                                                        </Popup>
                                                    </Marker>
                                                </MapContainer>
                                            </div>
                                        )}

                                        {/* Adresse mail */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                                Adresse mail <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="email"
                                                type="email"
                                                placeholder="Adresse mail"
                                                name="email"
                                                value={steps.formData.email}
                                                onChange={handleChange}
                                            />
                                            {formErrors.email && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.email}</p>
                                            )}
                                        </div>

                                        {/* N° mobile */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobile">
                                                N° mobile <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                id="mobile"
                                                type="tel"
                                                placeholder="N° mobile"
                                                name="mobile"
                                                value={steps.formData.mobile}
                                                onChange={handleChange}
                                            />
                                            {formErrors.mobile && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.mobile}</p>
                                            )}
                                        </div>

                                        {/* Type de document d'dentification */}
                                        <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                Document d'identification <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <div className="flex">
                                                <label className="mr-4">
                                                    <input
                                                        type="radio"
                                                        name="idDoc"
                                                        value="CNI"
                                                        checked={steps.formData.idDoc === "CNI"}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="ml-2">CNI</span>
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="idDoc"
                                                        value="Passeport"
                                                        checked={steps.formData.idDoc === "Passeport"}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="ml-2">Passeport</span>
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="idDoc"
                                                        value="Carte de séjour"
                                                        checked={steps.formData.idDoc === "Carte de séjour"}
                                                        onChange={handleChange}
                                                        className="ml-2"
                                                    />
                                                    <span className="ml-2">Carte de séjour</span>
                                                </label>
                                            </div>
                                            {formErrors.idDoc && (
                                                <p className="text-red-500 text-sm mt-1">{formErrors.idDoc}</p>
                                            )}
                                        </div>

                                        {/* Champs de formulaire en fonction du type de document d'identification */}
                                        {steps.formData.idDoc === "CNI" && (
                                            <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="idCardNumber">
                                                    N° CNI <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="idCardNumber"
                                                    type="text"
                                                    placeholder="N° CNI"
                                                    name="idCardNumber"
                                                    value={steps.formData.idCardNumber}
                                                    onChange={handleChange}
                                                />
                                                {formErrors.idCardNumber && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.idCardNumber}</p>
                                                )}

                                                <div className="py-2">
                                                    <button
                                                        type="button"
                                                        onClick={() => setModalCniIsOpen(true)}
                                                        className="bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded-full"
                                                    >
                                                        Sélectionner les images du recto et verso de la CNI ici
                                                    </button>

                                                    <Modal
                                                        isOpen={modalCniIsOpen}
                                                        onRequestClose={() => setModalCniIsOpen(false)}
                                                        contentLabel="File Upload Modal"
                                                    >
                                                        <h2 className="text-3xl font-bold mb-4 uppercase text-center">Upload de la CNI</h2>
                                                        <div className='mb-4'>
                                                            <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                                                                Recto du CNI <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept="image/jpeg, image/jpg, image/png"
                                                                name="idCardFront"
                                                                onChange={e => {
                                                                    setRectoPhotoCniImage(e.target.files[0]);
                                                                }}
                                                                className="block w-full text-sm text-slate-500
                                                            file:mr-4 file:py-2 file:px-4
                                                            file:rounded-full file:border-0
                                                            file:text-sm file:font-semibold
                                                            file:bg-sky-50 file:text-sky-700
                                                            hover:file:bg-sky-100 cursor-pointer"
                                                            />
                                                        </div>

                                                        <div className='mb-4'>
                                                            <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                                                                Verso du CNI <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                            </label>
                                                            <input
                                                                type="file"
                                                                accept="image/jpeg, image/jpg, image/png"
                                                                name="idCardBack"
                                                                onChange={e => {
                                                                    setVersoPhotoCniImage(e.target.files[0]);
                                                                }}
                                                                className="block w-full text-sm text-slate-500
                                                            file:mr-4 file:py-2 file:px-4
                                                            file:rounded-full file:border-0
                                                            file:text-sm file:font-semibold
                                                            file:bg-sky-50 file:text-sky-700
                                                            hover:file:bg-sky-100 cursor-pointer"
                                                            />
                                                        </div>

                                                        <div className="flex flex-col items-center space-y-4">
                                                            <div className="flex flex-col items-center">
                                                                {rectoPhotoCniImage ? (
                                                                    <div className="border border-gray-300">
                                                                        <div>
                                                                            <AvatarEditor
                                                                                ref={editorRefRectoCni}
                                                                                image={rectoPhotoCniImage}
                                                                                width={270}
                                                                                height={150}
                                                                                border={10}
                                                                                color={[135, 206, 250, 0.6]}
                                                                                scale={scaleRectoCni}
                                                                                rotate={rotateRectoCni}
                                                                            />
                                                                        </div>

                                                                        <div className='flex flex-col items-center space-y-2'>
                                                                            <div className='flex items-center space-y-2'>
                                                                                <div className="my-2">
                                                                                    <label htmlFor="scale" className="text-gray-600 font-bold mx-1">Zoom:</label>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        type="range"
                                                                                        id="scale"
                                                                                        min="0.1"
                                                                                        max="10"
                                                                                        step="0.1"
                                                                                        value={scaleRectoCni}
                                                                                        onChange={handleScaleChangeRectoCni}
                                                                                        className="w-48"
                                                                                    />
                                                                                    <span className="text-gray-500 font-bold mx-1">{scaleRectoCni}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="flex items-center space-y-2">
                                                                                <div>
                                                                                    <label htmlFor="rotate" className="text-gray-600 font-bold mx-1">Rotation:</label>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        type="range"
                                                                                        id="rotate"
                                                                                        min="0"
                                                                                        max="360"
                                                                                        step="1"
                                                                                        value={rotateRectoCni}
                                                                                        onChange={handleRotateChangeRectoCni}
                                                                                        className="w-48"
                                                                                    />
                                                                                    <span className="text-gray-500 font-bold mx-1">{rotateRectoCni}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : ''}

                                                                {versoPhotoCniImage ? (
                                                                    <div className="border border-gray-300">
                                                                        <div>
                                                                            <AvatarEditor
                                                                                ref={editorRefVersoCni}
                                                                                image={versoPhotoCniImage}
                                                                                width={270}
                                                                                height={150}
                                                                                border={10}
                                                                                color={[135, 206, 250, 0.6]}
                                                                                scale={scaleVersoCni}
                                                                                rotate={rotateVersoCni}
                                                                            />
                                                                        </div>

                                                                        <div className='flex flex-col items-center space-y-2'>
                                                                            <div className='flex items-center space-y-2'>
                                                                                <div className="my-2">
                                                                                    <label htmlFor="scale" className="text-gray-600 font-bold mx-1">Zoom:</label>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        type="range"
                                                                                        id="scale"
                                                                                        min="0.1"
                                                                                        max="10"
                                                                                        step="0.1"
                                                                                        value={scaleVersoCni}
                                                                                        onChange={handleScaleChangeVersoCni}
                                                                                        className="w-48"
                                                                                    />
                                                                                    <span className="text-gray-500 font-bold mx-1">{scaleVersoCni}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className="flex items-center space-y-2">
                                                                                <div>
                                                                                    <label htmlFor="rotate" className="text-gray-600 font-bold mx-1">Rotation:</label>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        type="range"
                                                                                        id="rotate"
                                                                                        min="0"
                                                                                        max="360"
                                                                                        step="1"
                                                                                        value={rotateVersoCni}
                                                                                        onChange={handleRotateChangeVersoCni}
                                                                                        className="w-48"
                                                                                    />
                                                                                    <span className="text-gray-500 font-bold mx-1">{rotateVersoCni}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : ''}
                                                            </div>

                                                            {rectoPhotoCniImage && versoPhotoCniImage && (
                                                                <button
                                                                    type="button"
                                                                    onClick={previewHandleCni}
                                                                    className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full"
                                                                >
                                                                    Valider
                                                                </button>
                                                            )}

                                                            {canvasRectoCni ? <canvas src={canvasRectoCni} /> : ''}
                                                            {canvasVersoCni ? <canvas src={canvasVersoCni} /> : ''}
                                                        </div>
                                                    </Modal>
                                                </div>
                                                {formErrors.idCardFront && formErrors.idCardBack && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.idCardFront}</p>
                                                )}
                                            </div>
                                        )}

                                        {steps.formData.idDoc === "Passeport" && (
                                            <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="idPassportNumber">
                                                    N° Passeport <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="idPassportNumber"
                                                    type="text"
                                                    placeholder="N° Passeport"
                                                    name="idPassportNumber"
                                                    value={steps.formData.idPassportNumber}
                                                    onChange={handleChange}
                                                />
                                                {formErrors.idPassportNumber && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.idPassportNumber}</p>
                                                )}

                                                <label className="block text-gray-700 text-sm font-bold mb-2 mt-2">
                                                    Image du Passeport
                                                </label>
                                                <button
                                                    type="button"
                                                    onClick={() => setModalPassportIsOpen(true)}
                                                    className="bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded-full"
                                                >
                                                    Sélectionner votre passeport ici
                                                </button>

                                                <Modal
                                                    isOpen={modalPassportIsOpen}
                                                    onRequestClose={() => setModalPassportIsOpen(false)}
                                                    contentLabel="File Upload Modal"
                                                >
                                                    <h2 className="text-3xl font-bold mb-4 uppercase text-center">Upload de la Carte du passeport</h2>
                                                    <div className="flex flex-col items-center space-y-4">
                                                        {passportPhotoImage ? <AvatarEditor
                                                            ref={editorRefPassport}
                                                            image={passportPhotoImage}
                                                            width={270}
                                                            height={150}
                                                            border={10}
                                                            color={[135, 206, 250, 0.6]}
                                                            scale={scalePassport}
                                                            rotate={rotatePassport}
                                                        /> : ''}

                                                        {passportPhotoImage && (
                                                            <div className='flex flex-col items-center space-y-2'>
                                                                <div className='flex items-center space-y-2'>
                                                                    <div className="mx-2">
                                                                        <label htmlFor="scale" className="text-gray-600 font-bold mx-1">Zoom:</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            type="range"
                                                                            id="scale"
                                                                            min="0.1"
                                                                            max="10"
                                                                            step="0.1"
                                                                            value={scale}
                                                                            onChange={handleScaleChangePassport}
                                                                            className="w-48"
                                                                        />
                                                                        <span className="text-gray-500 font-bold mx-1">{scalePassport}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="flex items-center space-y-2">
                                                                    <div className="mx-2">
                                                                        <label htmlFor="rotate" className="text-gray-600 font-bold mx-1">Rotation:</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            type="range"
                                                                            id="rotate"
                                                                            min="0"
                                                                            max="360"
                                                                            step="1"
                                                                            value={rotatePassport}
                                                                            onChange={handleRotateChangePassport}
                                                                            className="w-48"
                                                                        />
                                                                        <span className="text-gray-500 font-bold mx-1">{rotatePassport}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <input
                                                            type='file'
                                                            accept="image/jpeg, image/jpg, image/png"
                                                            name="idPasseportFront"
                                                            onChange={e => {
                                                                setPassportPhotoImage(e.target.files[0]);
                                                            }}
                                                            className="block py-2 px-4 w-full text-sm text-slate-500
                                                        file:mr-4 file:py-2 file:px-4
                                                        file:rounded-full file:border-0
                                                        file:text-sm file:font-semibold
                                                        file:bg-sky-50 file:text-sky-700
                                                        hover:file:bg-sky-100 cursor-pointer"
                                                        />
                                                        {passportPhotoImage && (
                                                            <button
                                                                type="button"
                                                                onClick={previewHandlePassport}
                                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full"
                                                            >
                                                                Valider
                                                            </button>
                                                        )}

                                                        {canvasPassport ? <canvas src={canvasPassport} /> : ''}
                                                    </div>
                                                </Modal>

                                                {formErrors.idPasseportFront && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.idPasseportFront}</p>
                                                )}
                                            </div>
                                        )}

                                        {steps.formData.idDoc === "Carte de séjour" && (
                                            <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="idResidencePermitNumber">
                                                    N° Carte de séjour <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>
                                                <input
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="idResidencePermitNumber"
                                                    type="text"
                                                    placeholder="N° Carte de séjour"
                                                    name="idResidencePermitNumber"
                                                    value={steps.formData.idResidencePermitNumber}
                                                    onChange={handleChange}
                                                />
                                                {formErrors.idResidencePermitNumber && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.idResidencePermitNumber}</p>
                                                )}

                                                <label className="block text-gray-700 text-sm font-bold mb-2 mt-4">
                                                    Image de la carte de séjour <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                </label>
                                                <button
                                                    type="button"
                                                    onClick={() => setModalResidenceIsOpen(true)}
                                                    className="bg-sky-500 hover:bg-sky-700 text-white py-2 px-4 rounded-full"
                                                >
                                                    Sélectionner votre carte de séjour ici
                                                </button>

                                                <Modal
                                                    isOpen={modalResidenceIsOpen}
                                                    onRequestClose={() => setModalResidenceIsOpen(false)}
                                                    contentLabel="File Upload Modal"
                                                >
                                                    <h2 className="text-3xl font-bold mb-4 uppercase text-center">Upload de la Carte de résience</h2>
                                                    <div className="flex flex-col items-center space-y-4">
                                                        {residencePhotoImage ? <AvatarEditor
                                                            ref={editorRefResidence}
                                                            image={residencePhotoImage}
                                                            width={270}
                                                            height={150}
                                                            border={10}
                                                            color={[135, 206, 250, 0.6]}
                                                            scale={scaleResidence}
                                                            rotate={rotateResidence}
                                                        /> : ''}

                                                        {residencePhotoImage && (
                                                            <div className='flex flex-col items-center space-y-2'>
                                                                <div className='flex items-center space-y-2'>
                                                                    <div className="mx-2">
                                                                        <label htmlFor="scale" className="text-gray-600 font-bold mx-1">Zoom:</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            type="range"
                                                                            id="scale"
                                                                            min="0.1"
                                                                            max="10"
                                                                            step="0.1"
                                                                            value={scale}
                                                                            onChange={handleScaleChangeResidence}
                                                                            className="w-48"
                                                                        />
                                                                        <span className="text-gray-500 font-bold mx-1">{scaleResidence}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="flex items-center space-y-2">
                                                                    <div className="mx-2">
                                                                        <label htmlFor="rotate" className="text-gray-600 font-bold mx-1">Rotation:</label>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            type="range"
                                                                            id="rotate"
                                                                            min="0"
                                                                            max="360"
                                                                            step="1"
                                                                            value={rotateResidence}
                                                                            onChange={handleRotateChangeResidence}
                                                                            className="w-48"
                                                                        />
                                                                        <span className="text-gray-500 font-bold mx-1">{rotateResidence}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <input
                                                            type='file'
                                                            accept="image/jpeg, image/jpg, image/png"
                                                            name="idResidencePermitFront"
                                                            onChange={e => {
                                                                setResidencePhotoImage(e.target.files[0]);
                                                            }}
                                                            className="block py-2 px-4 w-full text-sm text-slate-500
                                                        file:mr-4 file:py-2 file:px-4
                                                        file:rounded-full file:border-0
                                                        file:text-sm file:font-semibold
                                                        file:bg-sky-50 file:text-sky-700
                                                        hover:file:bg-sky-100 cursor-pointer"
                                                        />
                                                        {residencePhotoImage && (
                                                            <button
                                                                type="button"
                                                                onClick={previewHandleResidence}
                                                                className="bg-sky-500 hover:bg-sky-700 text-white font-bold py-2 px-4 rounded-full"
                                                            >
                                                                Valider
                                                            </button>
                                                        )}

                                                        {canvasResidence ? <canvas src={canvasResidence} /> : ''}
                                                    </div>
                                                </Modal>

                                                {formErrors.idResidencePermitFront && (
                                                    <p className="text-red-500 text-sm mt-1">{formErrors.idResidencePermitFront}</p>
                                                )}
                                            </div>
                                        )}

                                        {steps.formData.customerType === "Entreprise" && (
                                            <>
                                                <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="regComNumber">
                                                        N° Registre commerce <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                    </label>
                                                    <input
                                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="regComNumber"
                                                        type="text"
                                                        placeholder="N° Registre commerce"
                                                        name="regComNumber"
                                                        value={steps.formData.regComNumber}
                                                        onChange={handleChange}
                                                    />
                                                    {formErrors.regComNumber && (
                                                        <p className="text-red-500 text-sm mt-1">{formErrors.regComNumber}</p>
                                                    )}
                                                </div>
                                                {/* Recto du Registre de commerce (si entreprise */}
                                                <div className={`mb-4 ${steps.currentStep === 1 ? "" : "hidden"}`}>
                                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                                        Image du Registre commerce
                                                    </label>
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg, image/jpg, image/png, application/pdf"
                                                        name="commerceRegistryFront"
                                                        onChange={handleFileChange}
                                                        className="block w-full text-sm text-slate-500
                                                    file:mr-4 file:py-2 file:px-4
                                                    file:rounded-full file:border-0
                                                    file:text-sm file:font-semibold
                                                    file:bg-sky-50 file:text-sky-700
                                                    hover:file:bg-sky-100 cursor-pointer"
                                                    />
                                                    {formErrors.commerceRegistryFront && (
                                                        <p className="text-red-500 text-sm mt-1">{formErrors.commerceRegistryFront}</p>
                                                    )}
                                                </div>
                                            </>
                                        )}

                                        {/* ETAPE 2 */}
                                        <section className={`py-2 ${steps.currentStep === 2 ? "" : "hidden"}`}>
                                            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                                                <div className='relative max-w-xl mx-auto sm:text-center'>
                                                    <h3 className='text-gray-800 text-3xl font-semibold sm:text-4xl'>
                                                        Formule d’abonnement <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                                    </h3>
                                                </div>
                                                <div className='mt-16 space-y-6 justify-center gap-6 sm:grid sm:grid-cols-2 sm:space-y-0 lg:grid-cols-3'>
                                                    {
                                                        plans.map((item, idx) => (
                                                            <div key={idx} className='relative flex-1 flex items-stretch flex-col p-8 rounded-xl border-2'>
                                                                <div>
                                                                    <span className='text-sky-600 font-medium'>
                                                                        {item.name}
                                                                    </span>
                                                                    <div className='mt-4 text-gray-800 text-3xl font-semibold'>
                                                                        {item.price} FCFA<span className="text-xl text-gray-600 font-normal">/mois</span>
                                                                    </div>
                                                                </div>
                                                                <ul className='py-8 space-y-3'>
                                                                    <li key={idx} className='flex items-center gap-5'>
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            className='h-5 w-5 text-sky-600'
                                                                            viewBox='0 0 20 20'
                                                                            fill='currentColor'>
                                                                            <path
                                                                                fill-rule='evenodd'
                                                                                d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                                                                clip-rule='evenodd'></path>
                                                                        </svg>
                                                                        Frais d'installation: 50.000 FCFA
                                                                    </li>
                                                                    {
                                                                        item.features.map((featureItem, idx) => (
                                                                            <li key={idx} className='flex items-center gap-5'>
                                                                                <svg
                                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                                    className='h-5 w-5 text-sky-600'
                                                                                    viewBox='0 0 20 20'
                                                                                    fill='currentColor'>
                                                                                    <path
                                                                                        fill-rule='evenodd'
                                                                                        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                                                                                        clip-rule='evenodd'></path>
                                                                                </svg>
                                                                                {featureItem}
                                                                            </li>
                                                                        ))
                                                                    }
                                                                </ul>
                                                                <div className="flex-1 flex items-end">
                                                                    <button
                                                                        onClick={() => handleSelectPlan(item.name)}
                                                                        className={`px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white ${selectedPlan === item.name ? 'bg-sky-600' : 'bg-gray-400'
                                                                            } hover:bg-sky-500 active:bg-sky-700`}
                                                                        disabled={selectedPlan === item.name}
                                                                    >
                                                                        {selectedPlan === item.name ? 'Choisi' : 'Sélectionner'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                                <p className="mt-7 text-center">
                                                    En signant ci-dessous et cliquant sur Suivant, vous acceptez nos <a href="#" onClick={() => setModalConditionsIsOpen(true)} className="underline text-sky-600">conditions générales d'abonnement aux services</a>.

                                                    <Modal
                                                        isOpen={modalConditionsIsOpen}
                                                        onRequestClose={() => setModalConditionsIsOpen(false)}
                                                    >
                                                        <h2 className="font-montserrat text-3xl font-bold mb-4 uppercase text-center">CONDITIONS GENERALES D’ABONNEMENT AUX SERVICES</h2>
                                                        <div className="font-montserrat flex flex-col space-y-4">
                                                            <p className="text-justify text-xs">
                                                                (1) : Le formulaire de demande d’abonnement et les conditions particulières d’abonnement au service font partie intégrante du contrat de service entre CAMTEL et le Client.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                (2) : Les prix des services dont définis dans le catalogue des produits et services de la Business Unit Fixe de CAMTEL
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                (3) : Je (le Client) déclare avoir pris parfaite et entière connaissance des conditions particulières d’abonnement aux services BLUE MAX de CAMTEL et les avoir acceptées sans réserve et que les informations me concernant susmentionnées sont exactes.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 1 : OBJET</h1>
                                                            <p className="text-justify text-xs">
                                                                1.1. Les présentes ont pour objet de définir les conditions et modalités d’abonnement au service BLUE MAX fourni par CAMTEL.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                1.2. Les présentes ainsi que le formulaire de demande d’abonnement forment le contrat de service entre CAMTEL et le Client tel que ce dernier est précisément identifié sur le formulaire précité.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                1.3. Le formulaire de demande d’abonnement signé par CAMTEL et le Client, formalise l’accord intervenu entre les Parties et recueille à cet effet les informations indispensables permettant à CAMTEL de mettre le service à la disposition du Client.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 2 : DUREE ET DATE D’EFFET</h1>
                                                            <p className="text-justify text-xs">
                                                                2.1. Le contrat d’abonnement au service BLUE MAX prend effet à compter de la date de sa signature par le Client et CAMTEL.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                2.2. L’abonnement est souscrit pour une durée minimale fixée dans le formulaire de demande d’abonnement.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                2.3. A l’expiration de sa durée minimale, l’abonnement est renouvelable par tacite reconduction chaque 06 (mois), sauf résiliation dans les conditions prévues à l’article 16 ci-dessous.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 3 : GARANTIES</h1>
                                                            <p className="text-justify text-xs">
                                                                Lors de la souscription de l’abonnement au service BLUE MAX, CAMTEL est en droit d’exiger du Client le versement d’une caution qui demeurera solidaire de des engagements souscrits par le Client pendant toute la durée de l’abonnement.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 4 : DESCRIPTION DU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                Le service BLUE MAX Double-Play offre au client le service de téléphonie fixe vers les réseaux domestiques et internationaux, et le service internet illimité au débit variable, en fonction de l’option choisie à savoir :
                                                            </p>
                                                            <table className="table-auto w-full">
                                                                <tr>
                                                                    <th>Taille</th><th>Vitesse Max</th><th>Données internet</th><th>Minutes d’appels vers fixe</th><th>Minutes d’appels vers mobile</th><th>Prix mensuel (F CFA)</th>
                                                                </tr>
                                                                <tr><td className="border text-center">S</td><td className="border text-center">15 Mbps</td><td className="border text-center">Illimitées</td><td className="border text-center">200 mins</td><td className="border text-center">90 mins</td><td className="border text-center">25 000</td></tr>
                                                                <tr><td className="border text-center">M</td><td className="border text-center">25 Mbps</td><td className="border text-center">Illimitées</td><td className="border text-center">300 mins</td><td className="border text-center">120 mins</td><td className="border text-center">35 000</td></tr>
                                                                <tr><td className="border text-center">L</td><td className="border text-center">40 Mbps</td><td className="border text-center">Illimitées</td><td className="border text-center">400 mins</td><td className="border text-center">150 mins</td><td className="border text-center">50 000</td></tr>
                                                                <tr><td className="border text-center">XL</td><td className="border text-center">50 Mbps</td><td className="border text-center">Illimitées</td><td className="border text-center">500 mins</td><td className="border text-center">180 mins</td><td className="border text-center">60 000</td></tr>
                                                            </table>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 5 : CONDITIONS D’ACCES AU SERVICE/DATE D'ACTIVATION DU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                5.1. L’accès au service BLUE MAX est réservé au Client se trouvant dans une localité accessible et desservie par le réseau cuivre ADSL et/ou le réseau fibre optique de CAMTEL.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 6 : OBLIGATIONS DES PARTIES</h1>
                                                            <p className="text-justify text-xs">
                                                                6.1. Le Client s’engage à:
                                                                <ul className="list-disc ml-7">
                                                                    <li>régler les factures correspondant au Service souscrit dans les délais fixés dans le contrat par CAMTEL ;</li>
                                                                    <li>utiliser le Service conformément aux termes et conditions du contrat d’abonnement et à la réglementation en vigueur ;</li>
                                                                    <li>s’interdire d’effectuer toute modification d’équipements terminaux, propriété exclusive de CAMTEL, susceptible d’affecter la fourniture du Service ; </li>
                                                                    <li>Ne pas utiliser abusivement les services de CAMTEL;</li>
                                                                    <li>Informer CAMTEL sans délai en cas de changement de son adresse de facturation.</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                6.2. CAMTEL s’engage à :
                                                                <ul className="list-disc ml-7">
                                                                    <li>mettre en œuvre tous les moyens nécessaires au fonctionnement régulier du Service ;</li>
                                                                    <li>garantir les équipements terminaux fournis au Client dans les conditions et modalités définies dans le catalogue des produits et services BLUE MAX ;</li>
                                                                    <li>assurer la maintenance curative du Service et procéder à la relève des dérangements dans un délai maximum 48 heures à compter de la date de notification par le Client à CAMTEL desdits dérangements à l’exception des cas suivants :
                                                                        <ul>
                                                                            <li>installation de l’équipement terminal non effectuée par CAMTEL ;</li>
                                                                            <li>L’équipement terminal ne peut être économiquement entretenu ;</li>
                                                                            <li>Non disponibilité des outils et des composantes pour effectuer l’entretien de l’équipement terminal.</li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 7 - RESPONSABILITE/LIMITE DE RESPONSABILITE</h1>
                                                            <p className="text-justify text-xs">
                                                                7.1. La responsabilité de CAMTEL ne saurait être engagée dans les cas suivants :
                                                                <ul className="list-disc ml-7">
                                                                    <li>Manquement du Client à l'une de ses obligations contractuelles ;</li>
                                                                    <li>Défaillance momentanée du réseau de télécommunications de CAMTEL altérant la qualité du service notamment en cas de travaux d’entretien, de renforcement ou d’extension des installations dudit réseau ;</li>
                                                                    <li>Dommages indirects et/ou immatériels tels que pertes d’exploitation, de profits, de clientèle ou pertes financières du Client;</li>
                                                                    <li>Force majeure.</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                7.2 CAMTEL se réserve la faculté de modifier à tout moment, sans notification préalable, la composition des options proposées. Dans ce cas, le client aura la faculté de mettre fin sans frais à son contrat d’abonnement dans les quinze jours qui suivent par lettre recommandée avec accusé de réception, dépassé ce délai les dispositions prévues par l’article 16 ci-dessous s’appliqueront.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 8 - FRAIS D’ACCES AU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                8.1 Les frais d’accès au Service comportant les frais d’installation et ceux d’abonnement sont fixés dans le catalogue des prix des produits et services BLUE MAX de CAMTEL.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                8.2 Le Client reconnaît avoir pris entière et parfaite connaissance des frais en vigueur correspondant au Service tels que fixés dans le catalogue des prix des produits et services BLUE MAX de CAMTEL. Toute modification ultérieure desdits frais s’applique au Client à compter de la date de son entrée en vigueur.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                8.3 Les frais d’installation pour bénéficier du service BLUE MAX incombent au client. Ils sont non remboursables.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 9 : FACTURATION ET RECOUVREMENT</h1>
                                                            <p className="text-justify text-xs">
                                                                9.1. Les frais de prépaiement sont facturés au Client et réglés au moment de la signature du formulaire de demande d’abonnement.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                9.2. La facture correspondant au Service est payable à la date limite fixée par CAMTEL.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                9.3. CAMTEL se réserve le droit, durant la validité du contrat, d’émettre des factures intermédiaires dans les cas suivants :
                                                                <ul className="list-disc ml-7">
                                                                    <li>incident dans le paiement de la facture</li>
                                                                    <li>résiliation du contrat.</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                9.4. En cas de non-paiement total ou partiel d’une facture à la date limite fixée par CAMTEL, CAMTEL se réserve le droit d’exiger le paiement des pénalités de retard d’un montant équivalent au tiers de la facture.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                9.5. Le non-paiement du montant de la facture à la date limite fixée par CAMTEL donne lieu au report de ce montant sur la facture du mois suivant celui au titre duquel est due la facture non payée.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 10 : CHANGEMENT DE FORMULES DU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                10.1. Le client peut demander le changement de la formule du Service choisie initialement moyennant la signature d’un nouveau formulaire de demande et le paiement des frais y afférents.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 11 : SUSPENSION DU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                11.1 Le Client peut demander la suspension du service BLUE MAX dans les conditions et modalités fixées dans le catalogue des prix des produits et services du Fixe.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                11.2. CAMTEL est en droit de suspendre :
                                                                <ul className="list-disc ml-7">
                                                                    <li>L’accès au Service en cas du manquement du Client à l’une de ses obligations contractuelles ;</li>
                                                                    <li>non- paiement ou non- respect de toutes autres obligations contractuelles en dehors du paiement ;</li>
                                                                    <li>non –paiement de sa facture.</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 12 : RETABLISSEMENT DU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                CAMTEL procède, dans un délai n’excédant pas 07 (jours) à compter de la date de suspension du service, au rétablissement du service sous réserve du règlement par le Client, pendant ledit délai, des sommes dues à CAMTEL.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 13 : TRANSFERT/CHANGEMENT DE NUMERO D’APPEL OU DE NOM</h1>
                                                            <p className="text-justify text-xs">
                                                                Les demandes de transfert de la ligne téléphonique et/ou de Changement de numéro d’appel ou de nom du Client acceptées par CAMTEL, donnent lieu à la signature d’un nouveau formulaire de demande et au paiement des frais correspondants.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 14: RECLAMATION</h1>
                                                            <p className="text-justify text-xs">
                                                                14.1. En cas de préjudice subi du fait de CAMTEL ou titre de l'exécution des présentes, le Client peut adresser une réclamation aux services de CAMTEL désignés sur la facture.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                14.2. La réclamation relative à la facture est recevable dans un délai maximum de 05 (cinq) jours à compter de la date de réception de ladite facture.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 15 : RESILIATION</h1>
                                                            <p className="text-justify text-xs font-bold">15.1. Résiliation sur demande du Client</p>
                                                            <p className="text-justify text-xs">
                                                                15.1.1. Après expiration de la durée minimale visée au 2.2 ci-dessus, le Client peut à tout moment résilier le contrat sous réserve d’un préavis de trente (30) jours adressé à CAMTEL par lettre recommandée avec accusé de réception.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                15.1.2. Dans le cas où la résiliation serait demandée avant la date de mise à disposition du service, le Client n’est pas en droit de réclamer le remboursement des frais d’installation.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                15.1.3. En cas de résiliation avant terme du contrat, le Client demeure redevable des sommes correspondantes aux frais d’abonnement restant à courir pour la durée minimale du contrat.
                                                            </p>
                                                            <p className="text-justify text-xs font-bold">15.2. Résiliation par CAMTEL</p>
                                                            <p className="text-justify text-xs">
                                                                -	CAMTEL est en droit de résilier le présent contrat, après mise en demeure à la charge du Client, restée sans effet pendant un délai de 15 jours et sans préjudice des poursuites judiciaires qui pourraient être exercées pour assurer le recouvrement des impayés, dans les cas suivants :
                                                                <ul className="list-disc ml-7">
                                                                    <li>manquement du Client à l’une de ses obligations contractuelles ;</li>
                                                                    <li>non-rétablissement du service dans les conditions visées à l’article 12 ;</li>
                                                                    <li>persistance de non-paiement des factures ;</li>
                                                                    <li>force majeure.</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs font-bold">15.3. Effets de la Résiliation</p>
                                                            <p className="text-justify text-xs">
                                                                La résiliation du contrat d’abonnement aux services BLUE MAX entraîne ipso facto le retrait des équipements d’installation déployés.
                                                            </p>
                                                        </div>
                                                        <div className="font-montserrat my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 17 : COMPETENCE JURIDICTIONNELLE</h1>
                                                            <p className="text-justify text-xs">
                                                                Tout litige né de l’exécution ou de l’interprétation du présent contrat sera porté, à défaut d’accord amiable, devant le tribunal du lieu de localisation de l’Agence Commerciale.
                                                            </p>
                                                        </div>
                                                        <button type="button" onClick={acceptConditions} className="font-montserrat bg-sky-500 hover:bg-sky-600 text-white px-7 py-2 float-right bold rounded-full">
                                                            Fermer
                                                        </button>
                                                    </Modal>
                                                </p>

                                                <div className="mt-3">
                                                    <h2 className="mb-3 text-center font-bold text-2xl">Signature du client dans la case ci-dessous:</h2>
                                                    <div className="border-2 border-gray-700">
                                                        <SignatureCanvas
                                                            penColor='black'
                                                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                                            ref={sigCanvas}
                                                            onEnd={saveSignature}
                                                        />
                                                    </div>
                                                    <div className="p-2 text-center cursor-pointer font-bold text-red-500" onClick={clearSignature}>Effacer la signature</div>
                                                </div>

                                                <div className="flex items-center justify-center mt-5 py-2">
                                                    <div>
                                                        <ReCAPTCHA
                                                            sitekey={config.REACT_APP_RECAPTCHA_SITEKEY}
                                                            onChange={(val) => setCapVal(val)}
                                                        />
                                                    </div>
                                                </div>

                                                <p className="mt-7 text-center">
                                                    En cliquant sur Suivant, le client accepte nos <a href="#" onClick={() => setModalConditionsIsOpen(true)} className="underline text-sky-600">conditions générales d'abonnement aux services</a>.

                                                    <Modal
                                                        isOpen={modalConditionsIsOpen}
                                                        onRequestClose={() => setModalConditionsIsOpen(false)}
                                                    >
                                                        <h2 className="text-3xl font-bold mb-4 uppercase text-center">CONDITIONS GENERALES D’ABONNEMENT AUX SERVICES</h2>
                                                        <div className="flex flex-col space-y-4">
                                                            <p className="text-justify text-xs">
                                                                (1) : Le formulaire de demande d’abonnement et les conditions générales d’abonnement au service font partie intégrante du Contrat de service entre CAMTEL et le Client. En cas de souscription aux services CAMTEL Business Corporate +, CB VSAT et Camtel Business VPN, un Service Level Agreement (SLA) est inclus au contrat/ The subscription request form and the general subscription conditions to the service form an integral part of the Service Agreement between CAMTEL and the Customer. In case of a subscription to CB Corporate +, VPN and VSAT offers, a Service Level Agreement (SLA) will be integrated in the contract.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                (2) Les formules de services sont définies dans les catalogues des produits et services CAMTEL/ The service formulas are defined in the CAMTEL products and services catalogs.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                (3) : Je (le Client) déclare avoir pris parfaite et entière connaissance des conditions générales d’abonnement aux services de CAMTEL et les avoir acceptées sans réserve et que les informations me concernant susmentionnées sont exactes/I (the Client) declare to have taken perfect and complete knowledge of the general conditions of subscription to the services of CAMTEL and to have accepted them without reserve and that the information concerning me mentioned above are exact
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 1 : OBJET ET DOCUMENTS CONTRACTUELS</h1>
                                                            <p className="text-justify text-xs">
                                                                1.1. Les présentes ont pour objet de définir les conditions et modalités d’abonnement au service fourni par CAMTEL
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                1.2. Les présentes ainsi que le formulaire de demande d’abonnement forment le contrat de service entre CAMTEL et le Client tel que ce dernier est précisément identifié sur le formulaire précité.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                1.3. Le formulaire de demande d’abonnement signé par CAMTEL et le Client, formalise l’accord intervenu entre les Parties et recueille à cet effet les informations indispensables permettant à CAMTEL de mettre le service à la disposition du Client.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 2 : DUREE ET DATE D’EFFET</h1>
                                                            <p className="text-justify text-xs">
                                                                2.1. Le contrat d’abonnement au service prend effet à compter
                                                                de la date de sa signature par le Client et CAMTEL.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                2.2. L’abonnement est souscrit pour une durée de 12 mois
                                                                consécutifs.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                2.3. A l’expiration de sa durée minimale, l’abonnement est renouvelable par tacite reconduction de mois en mois, sauf résiliation dans les conditions prévues à l’article 16 ci-dessous.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 3 : GARANTIES</h1>
                                                            <p className="text-justify text-xs">
                                                                Lors de la souscription de l’abonnement au service, CAMTEL est en droit d’exiger du Client le versement d’une caution qui demeurera solidaire des engagements souscrits par le Client pendant toute la durée de l’abonnement.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 4 : DESCRIPTION DES SERVICES</h1>
                                                            <p className="text-justify text-xs">
                                                                4.1 Le service MBOA offre au client au minimum un package double-play, incluant le service de téléphonie fixe et le service internet illimité au débit variable, asymétrique en montée et descente, en fonction de l’option choisie. Le service MBOA offre au maximum un package Quadruple-Play, incluant la téléphonie fixe, l’internet illimité, la télévision, et des abonnements mobiles.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                4.2 Les services CAMTEL Business Pro et Corporate offrent au client le service internet illimité au débit variable en fonction de l’option choisie, asymétrique en montée et en descente, et une adresse IP fixe.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                4.3 Le service CAMTEL Business Corporate + offre au client le service internet illimité au débit variable en fonction de l’option de l’option choisie, symétrique en montée et en descente, et une adresse IP fixe.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                4.4 Le service CAMTEL Business VPN offre au client le service d’interconnexion de sites distants de manière physique ou virtuelle, via le nuage IP MPLS.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                4.5 Le service CAMTEL Business VSAT offre au client le service voix et data via les services satellitaires (service requérant l’installation préalable d’une antenne satellitaire).
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                4.6 Les services « autres » intègrent la ligne téléphonique fixe simple, les flottes de téléphonie mobile TOLi, ainsi que les solutions de VSAT.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 5 : CONDITIONS D’ACCES AU SERVICE/DATE D'ACTIVATION DU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                5.1. L’accès au service est réservé au Client se trouvant dans une localité accessible et desservie par le réseau d’accès filaire de CAMTEL.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 6 : OBLIGATIONS DES PARTIES</h1>
                                                            <p className="text-justify text-xs">
                                                                6.1. Le Client s’engage à:
                                                                <ul className="list-disc ml-7">
                                                                    <li>utiliser le Service conformément aux termes et conditions du contrat d’abonnement et à la réglementation en vigueur</li>
                                                                    <li>s'interdire d'effectuer toute modification des équipements terminaux, propriété exclusive de CAMTEL, susceptible d'affecter la fourniture du Service</li>
                                                                    <li>régler les factures correspondant au Service dans les délais fixés par CAMTEL</li>
                                                                    <li>informer CAMTEL sans délai en cas de changement de son adresse de facturation</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                6.2. CAMTEL s’engage à :
                                                                <ul className="list-disc ml-7">
                                                                    <li>s’assurer de l’installation effective des services sollicitées par le client</li>
                                                                    <li>mettre en œuvre tous les moyens nécessaires au fonctionnement régulier du Service</li>
                                                                    <li>garantir les équipements terminaux fournis au Client dans les conditions et modalités définies dans les catalogues des produits et services</li>
                                                                    <li>assurer la maintenance du Service et procéder à la relève des dérangements à compter de la date de notification par le Client à CAMTEL desdits dérangements</li>
                                                                    <li>se conformer aux dispositions arrêtées le cas échéant dans le SLA convenu entre le CAMTEL et le Client (disponible pour les services CB Corporate Plus, CB VSAT et CB VPN).</li>
                                                                </ul>
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 7 - RESPONSABILITE/LIMITE DE RESPONSABILITE</h1>
                                                            <p className="text-justify text-xs">
                                                                7.1. La responsabilité de CAMTEL ne saurait être engagée dans les cas suivants:
                                                                <ul className="list-disc ml-7">
                                                                    <li>Manquement du Client à l'une de ses obligations contractuelles</li>
                                                                    <li>Défaillance momentanée du réseau de télécommunications de CAMTEL altérant la qualité du service notamment en cas de travaux d'entretien, de renforcement ou d'extension des installations dudit réseau</li>
                                                                    <li>Dommages indirects et/ou immatériels tels que pertes d’exploitation, de profits, de clientèle ou pertes financières du Client.</li>
                                                                    <li>Force majeure (notamment et sans s'y limiter : les grèves et manifestations bloquant les voies de communication ; les accidents de la circulation, les guerres et les actes de terrorisme, les catastrophes naturelles telles que les inondations, les tempêtes, les ouragans, etc.).</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                7.2 CAMTEL se réserve la faculté de modifier à tout moment, sans notification préalable, la composition des options proposées. Dans ce cas, le client aura la faculté de mettre fin sans frais à son contrat d’abonnement dans les quinze jours qui suivent par lettre recommandée avec accusé de réception, dépassé ce délai les dispositions prévues par l’article 16 cidessous s’appliqueront.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 8 - FRAIS D’ACCES AU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                8.1 Les frais d’accès au Service comportant les frais d’installation, d’équipements et d’abonnement sont fixés dans le catalogue des prix des produits et services de CAMTEL, ou dans l’offre personnalisée adressée au client.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                8.2 Le Client reconnaît avoir pris entière et parfaite connaissance des frais en vigueur correspondant au Service tels que fixés dans le catalogue des prix des produits et services de CAMTEL. Toute modification ultérieure desdits frais s’applique au Client à compter de la date de son entrée en vigueur.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 9 : FACTURATION ET RECOUVREMENT</h1>
                                                            <p className="text-justify text-xs">
                                                                9.1. Les frais de prépaiement sont facturés au Client et réglés au moment de la signature du formulaire de demande d’abonnement.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                9.2. La facture correspondant au Service est payable à la date limite fixée par CAMTEL.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                9.3. CAMTEL se réserve le droit, durant la validité du contrat, d’émettre des factures intermédiaires dans les cas suivants:
                                                                <ul className="list-disc ml-7">
                                                                    <li>incident dans le paiement de la facture</li>
                                                                    <li>résiliation du contrat.</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                9.4. En cas de non-paiement total ou partiel d’une facture à la date limite fixée par CAMTEL, CAMTEL se réserve le droit d’exiger le paiement des pénalités de retard d’un montant équivalent à une fois et demie le taux légal des intérêts en matière civile et commerciale.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                9.5. Le non-paiement du montant de la facture à la date limite fixée par CAMTEL donne lieu à la suspension immédiate du service et au report du montant en question sur la facture du mois suivant.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                9.6. Le Client ne peut en aucun cas invoquer la non réception de la facture pour justifier le retard de paiement de l’échéance correspondante. Un duplicata de la facture émise par CAMTEL, au titre de la dernière échéance mise en recouvrement, est mis à sa disposition par mail ou peut être demandé à l’agence commerciale de rattachement de son abonnement.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 10 : CHANGEMENT DE FORMULES DU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                10.1. Le Client peut demander le changement de la formule du Service choisie initialement moyennant la signature d’un nouveau formulaire de demande et le paiement des frais y afférents.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 11 : SUSPENSION DU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                11.1 Le Client peut demander la suspension du service dans les conditions et modalités fixées dans le catalogue des prix des produits et services
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                11.2. CAMTEL est en droit de suspendre :
                                                                <ul className="list-disc ml-7">
                                                                    <li>l’accès au Service en cas du manquement du Client à l’une de ses obligations contractuelles</li>
                                                                    <li>tout ou partie des services fournis au Client au titre d’abonnements souscrits aux services de télécommunications de CAMTEL en cas de non-paiement, à la date limite fixée par CAMTEL, de l’une des factures correspondantes auxdits services</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                11.3. Le Client est mis en demeure dès qu’il ne paie pas à la date limite de paiement.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 12 : RETABLISSEMENT DU SERVICE</h1>
                                                            <p className="text-justify text-xs">
                                                                CAMTEL procède, dans un délai n’excédant pas un (01) mois à compter de la date de suspension du service, au rétablissement du service sous réserve du règlement par le Client, pendant ledit délai, des sommes dues à CAMTEL.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 13 : MODIFICATION</h1>
                                                            <p className="text-justify text-xs">
                                                                CAMTEL est en droit de modifier les conditions générales d’abonnement sous réserve d’en informer préalablement le Client par tout moyen de communication
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 14 : TRANSFERT/CHANGEMENT DE NUMERO D’APPEL OU DE NOM</h1>
                                                            <p className="text-justify text-xs">
                                                                Les demandes de transfert de la ligne téléphonique et/ou de Changement de numéro d’appel ou de nom du Client acceptées par CAMTEL, donnent lieu à la signature d’un nouveau formulaire de demande et au paiement des frais correspondants.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 15: RECLAMATION</h1>
                                                            <p className="text-justify text-xs">
                                                                15.1. En cas de préjudice subi du fait de CAMTEL au titre de l’exécution des présentes, le Client peut adresser une réclamation aux services de CAMTEL désignés sur la facture.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                15.2. La réclamation relative à la facture est recevable dans un délai maximum de dix (10) jours à compter de la date de réception de ladite facture.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 16 : RESILIATION</h1>
                                                            <p className="text-justify text-xs font-bold">16.1. Résiliation sur demande du Client</p>
                                                            <p className="text-justify text-xs">
                                                                16.1.1. Après expiration de la durée minimale visée au 2.2 cidessus, le Client peut à tout moment résilier le contrat sous réserve d’un préavis de trente (30) jours adressé à CAMTEL par lettre recommandée avec accusé de réception.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                16.1.2. Dans le cas où la résiliation serait demandée avant la date de mise à disposition du service, le Client n’est pas en droit de réclamer le remboursement des frais d’installation.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                16.1.3. En cas de résiliation avant terme du contrat, le Client demeure redevable des sommes correspondant aux frais d’abonnement restant à courir pour la durée minimale du contrat.
                                                            </p>
                                                            <p className="text-justify text-xs">
                                                                16.1.4. En cas de non-respect des engagements pris par CAMTEL à l’article 6 du présent contrat, sur une période consécutive de 02 mois consécutifs, le client peut résilier sans frais ledit contrat par lettre recommandée avec accusé de réception.
                                                            </p>
                                                            <p className="text-justify text-xs font-bold">16.2. Résiliation par CAMTEL</p>
                                                            <p className="text-justify text-xs">
                                                                CAMTEL est en droit de résilier le présent contrat, après mise en demeure, à la charge du Client, restée sans effet pendant un délai de 15 jours et sans préjudice des poursuites judiciaires qui pourraient être exercées pour assurer le recouvrement des impayés, dans les cas suivants:
                                                                <ul className="list-disc ml-7">
                                                                    <li>manquement du Client à l’une de ses obligations contractuelles</li>
                                                                    <li>non-rétablissement du service dans les conditions visées à l’article 12</li>
                                                                    <li> persistance de non-paiement des factures</li>
                                                                    <li> force majeure.</li>
                                                                </ul>
                                                            </p>
                                                            <p className="text-justify text-xs font-bold">16.3. Effets de la Résiliation</p>
                                                            <p className="text-justify text-xs">
                                                                La résiliation du contrat d’abonnement aux services entraîne ipso facto le retrait des équipements d’installation déployés.
                                                            </p>
                                                        </div>
                                                        <div className="my-3">
                                                            <h1 className="mb-2 text-center bg-sky-500 text-white">ARTICLE 17 : COMPETENCE JURIDICTIONNELLE</h1>
                                                            <p className="text-justify text-xs">
                                                                Tout litige né de l’exécution ou de l’interprétation du présent contrat sera porté, à défaut d’accord amiable, devant le tribunal compétent.
                                                            </p>
                                                        </div>
                                                        <button type="button" onClick={acceptConditions} className="bg-sky-500 hover:bg-sky-600 text-white px-7 py-2 float-right bold rounded-full">
                                                            Fermer
                                                        </button>
                                                    </Modal>
                                                </p>
                                            </div>
                                        </section>

                                        {/* ETAPE 3 */}
                                        <section className={`py-14 ${steps.currentStep === 3 ? "" : "hidden"}`}>
                                            <div className="mb-4 mt-4 flex items-center justify-between">
                                                <div className="w-28 h-23 mr-4 ">
                                                    <img src={logoCamtel} alt="Logo CAMTEL" className="w-full h-full" />
                                                </div>

                                                <div className="w-full flex flex-col items-center">
                                                    <div className="w-100 h-10 mb-2">
                                                        <img src={logosFtth} alt="icônes FTTH" className="w-full h-full" style={{ width: 250, height: 60 }} />
                                                    </div>
                                                    <h2 className="text-sky-400 text-1xl mt-4 font-bold sm:text-4xl text-center whitespace-nowrap">
                                                        Formulaire d’abonnement
                                                    </h2>
                                                </div>

                                                <div className="flex items-center mb-2">
                                                    <div className="w-24 h-20 ml-4">
                                                        {photoPreview && (
                                                            <div>
                                                                <img
                                                                    src={photoPreview}
                                                                    alt="Photo du client"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: '100%', width: '100%' }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <p><strong>Noms et Prénoms / Raison sociale: </strong> {steps.formData.nameOrSocialName}</p>
                                            <p><strong>Type de client: </strong> {steps.formData.customerType}</p>
                                            <p><strong>Région: </strong>
                                                {
                                                    selectedOption || formData.region === 'CE' ? 'Centre' :
                                                        selectedOption || formData.region === 'LT' ? 'Littoral' :
                                                            selectedOption || formData.region === 'NO' ? 'Nord' :
                                                                selectedOption || formData.region === 'EN' ? 'Extrême-Nord' :
                                                                    selectedOption || formData.region === 'AD' ? 'Adamaoua' :
                                                                        selectedOption || formData.region === 'ES' ? 'Est' :
                                                                            selectedOption || formData.region === 'SU' ? 'SUD' :
                                                                                selectedOption || formData.region === 'OU' ? 'Ouest' :
                                                                                    selectedOption || formData.region === 'NW' ? 'Nord-Ouest' :
                                                                                        selectedOption || formData.region === 'SW' ? 'Sud-Ouest' : null
                                                }
                                            </p>
                                            <p><strong>Ville: </strong> {formData.city}</p>
                                            <p><strong>Quartier: </strong> {formData.preciseLocation}</p>
                                            <p><strong>Lieu-dit: </strong> {steps.formData.lieuDit}</p>
                                            <p><strong>Coordonnées GPS du client: </strong> {formData.customerGpsCoordinates}</p>
                                            <p>
                                                <strong>Plan de localisation: </strong>
                                                <div className="h-full">
                                                    {photoLocationPreview && (
                                                        <div>
                                                            {pdfLocationPreview ? (
                                                                <iframe
                                                                    src={pdfLocationFile}
                                                                    className="h-screen w-full"
                                                                    title="PDF Viewer"
                                                                ></iframe>
                                                            ) : (
                                                                <img
                                                                    src={photoLocationPreview}
                                                                    alt="Image du plan de localisation"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: 'auto', width: 'auto' }}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </p>
                                            <p><strong>Adresse mail: </strong> {steps.formData.email}</p>
                                            <p><strong> N° de téléphone mobile: </strong> {steps.formData.mobile}</p>
                                            <p><strong>Document d'identification: </strong> {steps.formData.idDoc}</p>
                                            {formData.idCardNumber && (
                                                <>
                                                    <p><strong>Numéro de la CNI: </strong> {steps.formData.idCardNumber}</p>
                                                    <div>
                                                        {photoRectoCniPreview && photoVersoCniPreview && (
                                                            <div className="flex">
                                                                <img
                                                                    src={photoRectoCniPreview}
                                                                    alt="Recto de la CNI"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: '100%', width: '50%' }}
                                                                />
                                                                <img
                                                                    src={photoVersoCniPreview}
                                                                    alt="Verso de la CNI"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: '100%', width: '50%' }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {formData.idPassportNumber && (
                                                <>
                                                    <p><strong>Numéro du passeport: </strong> {formData.idPassportNumber}</p>
                                                    <div>
                                                        {photoPassportPreview && (
                                                            <div className="flex">
                                                                <img
                                                                    src={photoPassportPreview}
                                                                    alt="Passeport"
                                                                    className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: '100%', width: '100%' }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {formData.idResidencePermitNumber && (
                                                <>
                                                    <p><strong>Numéro de la carte de séjour: </strong> {steps.formData.idResidencePermitNumber}</p>
                                                    <div>
                                                        {photoResidencePreview && (
                                                            <div className="flex">
                                                                <img
                                                                    src={photoResidencePreview}
                                                                    alt="Carte de séjour"
                                                                    // className="block w-32 h-auto mt-2 rounded"
                                                                    style={{ height: '100%', width: '100%' }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {steps.formData.regComNumber && (
                                                <div>
                                                    <p><strong>N° du régistre de commerce: </strong> {steps.formData.regComNumber}</p>
                                                    <div className="h-full">
                                                        {photoRegComPreview && (
                                                            <div>
                                                                {pdfRegComPreview ? (
                                                                    <iframe
                                                                        src={pdfRegComFile}
                                                                        className="h-screen w-full"
                                                                        title="PDF Viewer"
                                                                    ></iframe>
                                                                ) : (
                                                                    <img
                                                                        src={photoRegComPreview}
                                                                        alt="Photo du registre de commerce"
                                                                        className="block w-32 h-auto mt-2 rounded"
                                                                        style={{ height: 'auto', width: 'auto' }}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <p><strong>Offre Blue Max choisie: </strong> {selectedPlan}</p>
                                            <div>
                                                <p><strong>Signature: </strong></p>
                                                <p>
                                                    <img src={formData.signature} />
                                                </p>
                                            </div>
                                        </section>

                                        <div className="mt-4 flex justify-end">
                                            {steps.currentStep > 1 && (
                                                <button type="button" onClick={prevStep} className="bg-sky-500 hover:bg-sky-600 text-white px-4 py-2 rounded-full">
                                                    Précédent
                                                </button>
                                            )}

                                            {steps.currentStep < steps.stepsItems.length && (
                                                <button
                                                    type="button" onClick={nextStep}
                                                    disabled={steps.currentStep === 2 && (!selectedPlan || !capVal || !signature)}
                                                    className={`px-4 py-2 ml-2 rounded-full ${steps.currentStep === 2 && (!selectedPlan || !capVal || !signature) ? 'bg-gray-300 cursor-not-allowed' : 'bg-sky-500 hover:bg-sky-600 text-white'}`}
                                                >
                                                    Suivant
                                                </button>
                                            )}

                                            {steps.currentStep === 3 && (
                                                <button
                                                    type="submit"
                                                    className={`bg-sky-500 hover:bg-sky-600 text-white px-4 py-2 ml-2 rounded-full ${selectedPlan ? "" : "opacity-50 cursor-not-allowed"}`}
                                                    disabled={!selectedPlan || isButtonDisabled}
                                                >
                                                    Valider
                                                </button>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default PartnerSheet;