import React from 'react';
import { Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton = () => {
    const phoneNumber = '+237620105050';

    const openWhatsApp = () => {
        const url = `https://wa.me/${phoneNumber}`;
        window.open(url, '_blank');
    };

    return (
        <Fab
            color="primary"
            aria-label="whatsapp"
            onClick={openWhatsApp}
            style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                backgroundColor: '#25D366',
                color: '#fff',
                zIndex: 1000,
            }}
        >
            <WhatsAppIcon />
        </Fab>
    );
};

export default WhatsAppButton;
