import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { usePDF } from 'react-to-pdf';
import logoCamtel from '../../assets/img/icons/favicon/favicon-camtel.png';
import logosFtth from '../../assets/img/logo/logos-ftth.png';
import { BASE_URL } from '../../apiConfig';
import FormatDate from './components/FormatDate';

const Contract = () => {
    const { id } = useParams();

    const [orders, setOrders] = useState([]);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const { toPDF, targetRef } = usePDF({ filename: `Contrat_d_abonnement_Services_CAMTEL_${id}.pdf` });

    useEffect(() => {
        if (token) {
            const fetchOrders = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/installation/final/order/` + id,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            const allSheetData = Object.values(response.data);
                            setOrders(allSheetData);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchOrders();
        }
    }, [token]);

    const [signatures, setSignatures] = useState(null);

    useEffect(() => {
        if (token) {
            const fetchSignatures = async () => {
                try {
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${BASE_URL}/api/installation/signature/` + id,
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    };

                    axios.request(config)
                        .then((response) => {
                            setSignatures(response.data.signatures[0]);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } catch (error) {
                    console.error("Une erreur s'est produite :", error);
                }
            };

            fetchSignatures();
        }
    }, [token])

    return (
        <>
            <div className='flex float-right'>
                {/* {localStorage.getItem('userRole') === 'super_admin' || localStorage.getItem('userRole') === 'admin_camtel' && ( */}
                <button
                    className='m-4 mr-14 px-10 py-2 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-lg shadow-md'
                    onClick={() => toPDF()}
                >
                    Télécharger le contrat
                </button>
                {/* )} */}
            </div>

            <br /><br /><br />

            <div ref={targetRef}>
                <div className='fiche pl-10 pr-10 m-3 pt-3 pb-3' style={{ fontSize: 14 }}>

                    <div className="mb-5 mt-2 flex items-center justify-between">
                        <div className="w-28 h-23 mr-4 ">
                            <img src={logoCamtel} alt="Logo CAMTEL" className="w-full h-full" />
                        </div>

                        <div className="w-full flex flex-col items-center">
                            <div className="w-100 h-10 mb-1">
                                <img src={logosFtth} alt="icônes FTTH" className="w-full h-full" style={{ width: 250, height: 60 }} />
                            </div>
                            <h2 className="text-sky-500 text-2xl mt-4 mb-4 font-bold sm:text-4xl text-center whitespace-nowrap">
                                Formulaire de demande d'abonnement
                            </h2>
                        </div>

                        <div className="flex items-center mb-2">
                            {Array.isArray(orders) && orders.length > 0 ? (
                                orders.map((sheetData, sheetIndex) => (
                                    <div className="w-20 h-20 ml-4">
                                        <img src={orders[0].ordersData[0].customerPhoto} alt="Photo client" className="w-full h-full rounded-full" />
                                    </div>
                                ))
                            ) : (null)}
                        </div>
                    </div>
                    <div className="border border-black mb-4">
                        <div className="bg-sky-500 text-2xl font-bold border-b border-black text-center text-white pb-4">INFORMATIONS CLIENT</div>
                        <div className='font-montserrat py-2 px-2 border-b border-black'>
                            {
                                Array.isArray(orders) && orders.length > 0 ? (
                                    orders.map((sheetData) => (
                                        <div key={sheetData.id}>
                                            {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                sheetData.ordersData.map((order) => (
                                                    <>
                                                        <div className="mb-2">N° ID Souscription: <span className='pl-20 pr-20 border-b border-black' style={{ paddingBottom: 6 }}>{order.idSubscription}{order.idSheet}</span></div>
                                                        <div className="mb-2">Nom et Prénoms / Raison Sociale: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.nameOrSocialName}</span></div>
                                                        <div className="mb-2 flex">
                                                            <div className="w-1/2 pr-2">Région:  <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>
                                                                {
                                                                    order.region === 'CE' ? 'Centre' :
                                                                        order.region === 'LT' ? 'Littoral' :
                                                                            order.region === 'NO' ? 'Nord' :
                                                                                order.region === 'EN' ? 'Extrême-Nord' :
                                                                                    order.region === 'AD' ? 'Adamaoua' :
                                                                                        order.region === 'ES' ? 'Est' :
                                                                                            order.region === 'SU' ? 'SUD' :
                                                                                                order.region === 'OU' ? 'Ouest' :
                                                                                                    order.region === 'NW' ? 'Nord-Ouest' :
                                                                                                        'Sud-Ouest'
                                                                }
                                                            </span></div>
                                                            <div className="w-1/2 pl-2">Ville: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.city}</span></div>
                                                        </div>
                                                        <div className="mb-2">Type de Client: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.customerType}</span></div>
                                                        <div className="mb-2">Localisation Précise: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.localisation}</span></div>
                                                        <div className="mb-2">Adresse mail: <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.email}</span></div>
                                                        <div className="mb-2">N° Mobile: {order.mobile !== null && <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.mobile}</span>}</div>
                                                        {order.idCardNumber !== null && (
                                                            <div className="mb-2">N°CNI (+ Espace Upload Recto Verso): <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.idCardNumber}</span></div>
                                                        )}
                                                        {order.idPassportNumber !== null && (
                                                            <div className="mb-2">N°Passeport : <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.idPassportNumber}</span></div>
                                                        )}
                                                        {order.idResidencePermitNumber !== null && (
                                                            <div className="mb-2">N° Carte de séjour : <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.idResidencePermitNumber}</span></div>
                                                        )}
                                                        {order.commerceRegNumber !== null || order.commerceRegNumber !== '' && (
                                                            <div className="mb-2">N°Régistre Commerce (si Entreprise): <span className='pl-36 pr-36 border-b border-black' style={{ paddingBottom: 6 }}>{order.commerceRegNumber}</span></div>
                                                        )}
                                                    </>
                                                ))
                                            ) : (
                                                null
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    null
                                )
                            }
                        </div>
                        <div className="bg-sky-500 font-bold border-b border-black text-center" style={{ paddingBottom: 10 }}>TYPE D’OPERATION</div>
                        <div className="flex">
                            <div className="w-3/5 border-r border-b">
                                <p className="bg-gray-300 font-bold text-lg text-center" style={{ paddingBottom: 4 }}>Formule d’abonnement</p>
                                <table className="table-fixed w-full">
                                    <thead>
                                        <tr>
                                            <th className="w-1/6 border-t border-r border-black" style={{ paddingBottom: 4 }}></th>
                                            <th className="w-1/6 border border-black" style={{ paddingBottom: 4 }}>Vitesse(*)</th>
                                            <th className="w-1/6 border border-black" style={{ paddingBottom: 4 }}>Mins Fixes</th>
                                            <th className="w-1/6 border border-black" style={{ paddingBottom: 4 }}>Mins Mobile</th>
                                            <th className="w-1/6 border border-black" style={{ paddingBottom: 4 }}>Prix</th>
                                            <th className="w-1/6 border-t border-black" style={{ paddingBottom: 4 }}>Offre Choisie</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        <tr>
                                            <th className="border-t border-r border-black" style={{ paddingBottom: 6 }}>Blue Max S</th>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>10 Mbps</td>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>200 mins</td>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>90 mins</td>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>25 000</td>

                                            {
                                                Array.isArray(orders) && orders.length > 0 ? (
                                                    orders.map((sheetData) => (
                                                        <td className="border-t border-black text-center" style={{ paddingBottom: 6 }}>
                                                            {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                                sheetData.ordersData.map((order) => (
                                                                    <>
                                                                        {
                                                                            order.offerBlueMaxSelected === 'Blue Max S' ? '✅' : ''
                                                                        }
                                                                    </>
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </td>
                                                    ))
                                                ) : (
                                                    null
                                                )
                                            }
                                        </tr>
                                        <tr>
                                            <th className="border-t border-r border-black" style={{ paddingBottom: 6 }}>Blue Max M</th>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>16 Mbps</td>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>300 mins</td>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>120 mins</td>
                                            <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>35 000</td>
                                            {
                                                Array.isArray(orders) && orders.length > 0 ? (
                                                    orders.map((sheetData) => (
                                                        <td className="border-t border-black text-center" style={{ paddingBottom: 6 }}>
                                                            {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                                sheetData.ordersData.map((order) => (
                                                                    <span>
                                                                        {
                                                                            order.offerBlueMaxSelected === 'Blue Max M' ? '✅' : ''
                                                                        }
                                                                    </span>
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </td>
                                                    ))
                                                ) : (
                                                    null
                                                )
                                            }
                                        </tr>
                                        <tr>
                                            <th className="border-t border-r border-black" style={{ paddingBottom: 6 }}>Blue Max L</th>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>25 Mbps</td>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>400 mins</td>
                                            <td className="border border-black" style={{ paddingBottom: 6 }}>150 mins</td>
                                            <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>50 000</td>

                                            {
                                                Array.isArray(orders) && orders.length > 0 ? (
                                                    orders.map((sheetData) => (
                                                        <td className="border-t border-black text-center" style={{ paddingBottom: 6 }}>
                                                            {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                                sheetData.ordersData.map((order) => (
                                                                    <>
                                                                        {
                                                                            order.offerBlueMaxSelected === 'Blue Max L' ? '✅' : ''
                                                                        }
                                                                    </>
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </td>
                                                    ))
                                                ) : (
                                                    null
                                                )
                                            }
                                        </tr>
                                        <tr>
                                            <th className="border-t border-r border-black" style={{ paddingBottom: 6 }}>Blue Max XL</th>
                                            <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>35 Mbps</td>
                                            <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>500 mins</td>
                                            <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>180 mins</td>
                                            <td className="border-t border-r border-black" style={{ paddingBottom: 6 }}>60 000</td>
                                            {
                                                Array.isArray(orders) && orders.length > 0 ? (
                                                    orders.map((sheetData) => (
                                                        <td className="border-t border-black text-center" style={{ paddingBottom: 6 }}>
                                                            {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                                sheetData.ordersData.map((order) => (
                                                                    <>
                                                                        {
                                                                            order.offerBlueMaxSelected === 'Blue Max XL' ? '✅' : ''
                                                                        }
                                                                    </>
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </td>
                                                    ))
                                                ) : (
                                                    null
                                                )
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="w-2/5 border-l border-black">
                                <div className="bg-gray-300 font-bold text-lg text-center border-b border-black" style={{ paddingBottom: 6 }}>Signature du client</div>
                                {signatures && signatures.customerSignature && (
                                    <img src={signatures.customerSignature} key={signatures.customerSignature} />
                                )}
                            </div>
                        </div>
                        <div className="bg-white shadow-md rounded-lg">
                            <div className="bg-sky-500 font-bold border-t border-black text-center" style={{ paddingBottom: 10 }}>PRE-ETUDE PRESTATAIRE</div>
                            <table className="table-auto w-full">
                                <tbody>
                                    {Array.isArray(orders) && orders.length > 0 ? (
                                        orders.map((sheetData, sheetIndex) => (
                                            <>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Nom du prestataire: {order.nameEntity}</td>
                                                                <td className="border border-black" style={{ paddingBottom: 6 }}>Référence du FDT: {order.fdtRef}</td>
                                                                <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Port FAT: {order.portFat}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Tel : {order.telephoneEntity}</td>
                                                                <td className="border border-black" style={{ paddingBottom: 6 }}>Référence du FAT : {order.fatRef}</td>
                                                                <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Coordonnée GPS du FAT : {order.fatGpsCoordinates}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Email : {order.emailEntity}</td>
                                                                <td className="border border-black" style={{ paddingBottom: 6 }}>Distance FAT-Client (m) : {order.dFatCustomer}</td>
                                                                <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Coordonnée GPS Client : {order.customerGpsCoordinates}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Zone d’intervention : {order.interventionZone}</td>
                                                                <td className="border border-b-0 border-black" style={{ paddingBottom: 6 }}>OLT - F/S/P : {order.oltSfp}</td>
                                                                <td className="border border-r-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Nombre de poteau à installer : {order.nbPoToInstall}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <>
                                            {null}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <table className="table-auto w-full">
                            <tbody>
                                <tr>
                                    <td className='w-1/2 border border-l-0 border-b-0 border-black'>
                                        <div className="bg-sky-500 font-bold border-b border-t-0 border-l-0 border-l-0 border-black text-center" style={{ paddingBottom: 10 }}>
                                            RESULTAT PRE-ETUDE PRESTATAIRE
                                        </div>
                                        {
                                            Array.isArray(orders) && orders.length > 0 ? (
                                                orders.map((sheetData) => (
                                                    <div key={sheetData.id} className='text-center'>
                                                        {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                            sheetData.ordersData.map((order) => (
                                                                <>
                                                                    <label className="inline-flex items-center" style={{ paddingBottom: 6 }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            value="Concluante"
                                                                            checked={order.result === 'Concluante'}
                                                                        />
                                                                        <span className="ml-2">Concluante</span>
                                                                    </label>

                                                                    <label className="inline-flex items-center ml-2" style={{ paddingBottom: 6 }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            value="Non concluante"
                                                                            checked={order.result === 'Non concluante'}
                                                                        />
                                                                        <span className="ml-2">Non concluante</span>
                                                                    </label>
                                                                </>
                                                            ))
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                null
                                            )
                                        }
                                    </td>
                                    <td className='w-1/2 border border-r-0 border-black'>
                                        <div className="bg-sky-500 font-bold border-b border-t-0 border-black text-center" style={{ paddingBottom: 10 }}>
                                            VALIDATION DE L’ETUDE
                                        </div>
                                        {
                                            Array.isArray(orders) && orders.length > 0 ? (
                                                orders.map((sheetData) => (
                                                    <div key={sheetData.id} className='text-center'>
                                                        {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                            sheetData.ordersData.map((order) => (
                                                                <>
                                                                    <label className="inline-flex items-center" style={{ paddingBottom: 6 }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            value="Favorable"
                                                                            checked={order.validation === 'Favorable'}
                                                                        />
                                                                        <span className="ml-2">Favorable</span>
                                                                    </label>
                                                                    <label className="inline-flex items-center ml-2" style={{ paddingBottom: 6 }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            value="Défavorable"
                                                                            checked={order.validation === 'Défavorable'}
                                                                        />
                                                                        <span className="ml-2">Défavorable</span>
                                                                    </label>
                                                                </>
                                                            ))
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                null
                                            )
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className='w-1/2 border border-l-0 border-b-0 border-black'>
                                        <div className="bg-sky-500 font-bold border-b border-black text-center" style={{ paddingBottom: 10 }}>RESULTAT PRE-ETUDE PRESTATAIRE</div>
                                        {
                                            Array.isArray(orders) && orders.length > 0 ? (
                                                orders.map((sheetData) => (
                                                    <>
                                                        {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                            sheetData.ordersData.map((order) => (
                                                                <div className='text-center h-32' style={{ paddingBottom: 6 }}>{order.preEtudeObs === '' ? '---' : order.preEtudeObs}</div>
                                                            ))
                                                        ) : (
                                                            null
                                                        )}
                                                    </>
                                                ))
                                            ) : (
                                                null
                                            )
                                        }
                                    </td>
                                    <td className='w-1/2 border border-r-0 border-b-0 border-black'>
                                        <div className="bg-sky-500 font-bold border-b border-black text-center" style={{ paddingBottom: 10 }}>OBSERVATIONS DE L’ETUDE</div>
                                        {
                                            Array.isArray(orders) && orders.length > 0 ? (
                                                orders.map((sheetData) => (
                                                    <div>
                                                        {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                            sheetData.ordersData.map((order) => (
                                                                <div className='text-center p-1 h-32' style={{ paddingBottom: 6 }}>{order.etudeObs === '' ? '---' : order.etudeObs}</div>
                                                            ))
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                null
                                            )
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="bg-white shadow-md rounded-lg">
                            <div className="bg-sky-500 font-bold border-t border-black text-center" style={{ paddingBottom: 10 }}>ACTIVATION DU SERVICE</div>
                            <table className="table-auto w-full">
                                <tbody>
                                    {Array.isArray(orders) && orders.length > 0 ? (
                                        orders.map((sheetData, sheetIndex) => (
                                            <>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                        sheetData.installationData.map((installation, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Nom du conseiller : {installation.nom} {installation.prenom} </td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-black" style={{ paddingBottom: 6 }}>Code Client : {order.crmCustomerCode}</td>
                                                                <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Référence paiement : {order.paymentRef}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Tel : {orders[0].installationData[0].mobile} </td>
                                                                <td className="border border-black" style={{ paddingBottom: 6 }}>Compte de facturation : {order.billingAccount}</td>
                                                                <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Date de paiement : {order.paymentDate}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Email : {orders[0].installationData[0].email} </td>
                                                                <td className="border border-black" style={{ paddingBottom: 6 }}>Numéro de Telephone : {order.phoneNumberActivated}</td>
                                                                <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Numéro STB/IP Stick : {order.stbIpStickNumber}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                        sheetData.ordersData.map((order, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Agence Commerciale : {order.bindedEntity}</td>
                                                                <td className="border border-b-0 border-black" style={{ paddingBottom: 6 }}>Numéro ONT : {order.ontNumber}</td>
                                                                <td className="border border-r-0 border-b-0 border-black" style={{ paddingBottom: 6 }}></td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <>
                                            {null}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="bg-white shadow-md rounded-lg">
                            <div className="bg-sky-500 font-bold border-t border-black text-center" style={{ paddingBottom: 10 }}>INSTALLATION PRESTATAIRE</div>
                            <table className="table-auto w-full">
                                <tbody>
                                    {Array.isArray(orders) && orders.length > 0 ? (
                                        orders.map((sheetData, sheetIndex) => (
                                            <>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                        sheetData.installationData.map((installation, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Nombre de Balancelle : {installation.nbBalancel}</td>
                                                                <td className="border border-black" style={{ paddingBottom: 6 }}>Nombre Crochet d’Arrêt : {installation.nbCrochetArret}</td>
                                                                <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Nombre de STB/ Stick : {installation.stbStickNumber}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                        sheetData.installationData.map((installation, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Métrage câble entrée FAT : {installation.cableMeterEntryFat}</td>
                                                                <td className="border border-black" style={{ paddingBottom: 6 }}>Métrage câble chez l’abonné : {installation.clientCableMeter}</td>
                                                                <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Nombre de TB : {installation.tbNumber}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                        sheetData.installationData.map((installation, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-black" style={{ paddingBottom: 6 }}>Longueur Câble utilisée (m) : {installation.usedCableLong}</td>
                                                                <td className="border border-black" style={{ paddingBottom: 6 }}>Nombre de poteau armé installé : {installation.nbArmedInstalledPoto}</td>
                                                                <td className="border border-r-0 border-black" style={{ paddingBottom: 6 }}>Nombre de Jarretière : {installation.nbGarter}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                                <tr key={sheetIndex}>
                                                    {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                        sheetData.installationData.map((installation, orderIndex) => (
                                                            <React.Fragment key={orderIndex}>
                                                                <td className="border border-l-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Nombre d’ATB : {installation.atbNumber}</td>
                                                                <td className="border border-b-0 border-black" style={{ paddingBottom: 6 }}>Numéro ONT : {installation.ontNumber}</td>
                                                                <td className="border border-r-0 border-b-0 border-black" style={{ paddingBottom: 6 }}>Nombre de Smoove {installation.nbSmoove}</td>
                                                            </React.Fragment>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <>
                                            {null}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {/* INTERLOCUTEUR EN CHARGE */}
                        <div className="bg-white shadow-md rounded-lg">
                            <div className="bg-sky-500 font-bold text-lg text-center border-b border-black" style={{ paddingBottom: 10 }}>INTERLOCUTEUR EN CHARGE</div>
                            {Array.isArray(orders) && orders.length > 0 ? (
                                orders.map((sheetData, sheetIndex) => (
                                    <>
                                        <div key={sheetIndex}>
                                            <div className="flex text-center border-b border-black h-36">
                                                {Array.isArray(sheetData.commercialPartnerInfos) && sheetData.commercialPartnerInfos.length > 0 ? (
                                                    sheetData.commercialPartnerInfos.map((commercialPartnerInfo, orderIndex) => (
                                                        <div className="border-r border-black flex-1">
                                                            <div className="font-bold">Prospecteur</div>
                                                            <div style={{ paddingBottom: 6 }}>Nom /Tél : </div> <div> {commercialPartnerInfo.nom} {commercialPartnerInfo.prenom} / {commercialPartnerInfo.mobile} </div>
                                                            {Array.isArray(sheetData.signatureComPartner) && sheetData.signatureComPartner.length > 0 ? (
                                                                sheetData.signatureComPartner.map((comaPrtnerSignature, orderIndex) => (
                                                                    <div>
                                                                        <img src={comaPrtnerSignature.signature} key={comaPrtnerSignature.signature} />
                                                                    </div>
                                                                ))) : null
                                                            }
                                                        </div>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                                {Array.isArray(sheetData.techPartnerInfos) && sheetData.techPartnerInfos.length > 0 ? (
                                                    sheetData.techPartnerInfos.map((techPartnerInfo, orderIndex) => (
                                                        <div className="border-r border-black flex-1">
                                                            <div className="font-bold">Technicien Installateur</div>
                                                            <div style={{ paddingBottom: 6 }}>Nom /Tél : </div> <div> {techPartnerInfo.nom} {techPartnerInfo.prenom} / {techPartnerInfo.mobile} </div>
                                                            <div>
                                                                {Array.isArray(sheetData.signatureTechnicianPartner) && sheetData.signatureTechnicianPartner.length > 0 ? (
                                                                    sheetData.signatureTechnicianPartner.map((techPartnerSignature, orderIndex) => (
                                                                        <div key={orderIndex}>
                                                                            <img src={techPartnerSignature.signature} alt={`Signature ${orderIndex}`} />
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    signatures && signatures.techPartnerSignature ? (
                                                                        <img src={signatures.techPartnerSignature} alt="Signature" />
                                                                    ) : null
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : null}
                                                {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                    sheetData.installationData.map((installation, orderIndex) => (

                                                        <div className="border-r border-black flex-1">
                                                            <div className="font-bold">Conseiller Client</div>
                                                            <div style={{ paddingBottom: 6 }}>Nom /Tél : </div> <div> {installation.nom} {installation.prenom} / {installation.mobile} </div>
                                                            {Array.isArray(sheetData.signatureComCamtel) && sheetData.signatureComCamtel.length > 0 ? (
                                                                sheetData.signatureComCamtel.map((comCamtelSignature, orderIndex) => (
                                                                    <div>
                                                                        <img src={comCamtelSignature.signature} key={comCamtelSignature.signature} />
                                                                    </div>
                                                                ))) : null
                                                            }
                                                        </div>

                                                    ))
                                                ) : (
                                                    null
                                                )}
                                                <div className="border-r border-black flex-1">
                                                    <div className="font-bold">Chef d’Agence</div>
                                                </div>
                                                {Array.isArray(sheetData.techCamtelInfos) && sheetData.techCamtelInfos.length > 0 ? (
                                                    sheetData.techCamtelInfos.map((techCamtelInfo, orderIndex) => (
                                                        <div className='flex-1'>
                                                            <div className="font-bold">Technicien CAMTEL</div>
                                                            {techCamtelInfo.nom && techCamtelInfo.prenom && (<><div style={{ paddingBottom: 6 }}>Nom /Tél : </div> <div> {techCamtelInfo.nom} {techCamtelInfo.prenom} / {techCamtelInfo.mobile} </div></>)}
                                                            {Array.isArray(sheetData.signatureTechCamtel) && sheetData.signatureTechCamtel.length > 0 ? (
                                                                sheetData.signatureTechCamtel.map((techCamtelSignature, orderIndex) => (
                                                                    <div>
                                                                        <img src={techCamtelSignature.signature} key={techCamtelSignature.signature} />
                                                                    </div>
                                                                ))) : null
                                                            }
                                                        </div>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ))
                            ) : (
                                <>
                                    {null}
                                </>
                            )}
                        </div>
                        {/* EVALUATION DU CLIENT */}
                        <div className="bg-white shadow-md rounded-lg">
                            <div className="bg-gray-300 font-bold text-lg mb-1 text-center border-b border-black" style={{ paddingBottom: 6 }}>EVALUATION DU CLIENT</div>
                            <div className="flex text-center border-b border-black h-28">
                                <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                    <p className='text-5xl pb-2'>😡</p>
                                    <p style={{ paddingBottom: 1, paddingTop: 1 }}>Très insatisfait</p>
                                </div>
                                <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                    <p className='text-5xl pb-2'>😢</p>
                                    <p style={{ paddingBottom: 1, paddingTop: 1 }}>Insatisfait/déçu</p>
                                </div>
                                <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                    <p className='text-5xl pb-2'>😐</p>
                                    <p style={{ paddingBottom: 1, paddingTop: 1 }}>Indifférent</p>
                                </div>
                                <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                    <p className='text-5xl pb-2'>😊</p>
                                    <p style={{ paddingBottom: 1, paddingTop: 1 }}>Satisfait</p>
                                </div>
                                <div className="w-1/5" style={{ paddingBottom: 6 }}>
                                    <p className='text-5xl pb-2'>😁</p>
                                    <p style={{ paddingBottom: 1, paddingTop: 1 }}>Très satisfait</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white shadow-md rounded-lg">
                            <div className="text-center">
                                {Array.isArray(orders) && orders.length > 0 ? (
                                    orders.map((sheetData, sheetIndex) => (
                                        <>
                                            <div className="flex text-center border-b border-black h-28">
                                                {Array.isArray(sheetData.ordersData) && sheetData.ordersData.length > 0 ? (
                                                    sheetData.ordersData.map((orderData, orderIndex) => (
                                                        <>
                                                            <div className="pb-8 border-r border-black flex-1">
                                                                <strong>Date de souscription</strong>
                                                                <p><FormatDate dateStr={orderData.sheetCreatedAt} /></p>
                                                            </div>
                                                            <div className="pb-8 border-r border-black flex-1">
                                                                <strong>Date de Pré-étude</strong>
                                                                <p><FormatDate dateStr={orderData.preEtudeCreatedAt} /></p>
                                                            </div>
                                                            <div className="pb-8 border-r border-black flex-1">
                                                                <strong>Date d’Etude</strong>
                                                                <p>
                                                                    {orderData.preEtudeUpdateByTechCamtelAt ? (
                                                                        <FormatDate dateStr={orderData.preEtudeUpdateByTechCamtelAt} />
                                                                    ) : (
                                                                        <FormatDate dateStr={orderData.preEtudeCreatedAt} />
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="pb-8 border-r border-black flex-1">
                                                                <strong>Date de paiement</strong>
                                                                <p><FormatDate dateStr={orderData.paymentDate === null ? '' : orderData.paymentDate} /></p>
                                                            </div>
                                                            {Array.isArray(sheetData.installationData) && sheetData.installationData.length > 0 ? (
                                                                sheetData.installationData.map((iData, installationIndex) => (
                                                                    <div className="flex-1 pb-8">
                                                                        <strong>Date d’Installation</strong>
                                                                        <p><FormatDate dateStr={iData.installationCreatedAt} /></p>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </>
                                                    ))
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                        </>
                                    ))
                                ) : (
                                    <>
                                        {null}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='p-10 mt-5'>
                    <h2 className="font-montserrat text-3xl font-bold mb-4 uppercase text-center">CONDITIONS GENERALES D’ABONNEMENT AUX SERVICES</h2>
                    <div className="font-montserrat flex flex-col space-y-4">
                        <p className="text-justify text-xs">
                            (1) : Le formulaire de demande d’abonnement et les conditions particulières d’abonnement au service font partie intégrante du contrat de service entre CAMTEL et le Client.
                        </p>
                        <p className="text-justify text-xs">
                            (2) : Les prix des services dont définis dans le catalogue des produits et services de la Business Unit Fixe de CAMTEL
                        </p>
                        <p className="text-justify text-xs">
                            (3) : Je (le Client) déclare avoir pris parfaite et entière connaissance des conditions particulières d’abonnement aux services BLUE MAX de CAMTEL et les avoir acceptées sans réserve et que les informations me concernant susmentionnées sont exactes.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 1 : OBJET</h1>
                        <p className="text-justify text-xs">
                            1.1. Les présentes ont pour objet de définir les conditions et modalités d’abonnement au service BLUE MAX fourni par CAMTEL.
                        </p>
                        <p className="text-justify text-xs">
                            1.2. Les présentes ainsi que le formulaire de demande d’abonnement forment le contrat de service entre CAMTEL et le Client tel que ce dernier est précisément identifié sur le formulaire précité.
                        </p>
                        <p className="text-justify text-xs">
                            1.3. Le formulaire de demande d’abonnement signé par CAMTEL et le Client, formalise l’accord intervenu entre les Parties et recueille à cet effet les informations indispensables permettant à CAMTEL de mettre le service à la disposition du Client.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 2 : DUREE ET DATE D’EFFET</h1>
                        <p className="text-justify text-xs">
                            2.1. Le contrat d’abonnement au service BLUE MAX prend effet à compter de la date de sa signature par le Client et CAMTEL.
                        </p>
                        <p className="text-justify text-xs">
                            2.2. L’abonnement est souscrit pour une durée minimale fixée dans le formulaire de demande d’abonnement.
                        </p>
                        <p className="text-justify text-xs">
                            2.3. A l’expiration de sa durée minimale, l’abonnement est renouvelable par tacite reconduction chaque 06 (mois), sauf résiliation dans les conditions prévues à l’article 16 ci-dessous.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 3 : GARANTIES</h1>
                        <p className="text-justify text-xs">
                            Lors de la souscription de l’abonnement au service BLUE MAX, CAMTEL est en droit d’exiger du Client le versement d’une caution qui demeurera solidaire de des engagements souscrits par le Client pendant toute la durée de l’abonnement.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 4 : DESCRIPTION DU SERVICE</h1>
                        <p className="text-justify text-xs">
                            Le service BLUE MAX Double-Play offre au client le service de téléphonie fixe vers les réseaux domestiques et internationaux, et le service internet illimité au débit variable, en fonction de l’option choisie à savoir :
                        </p>
                        <table className="table-auto w-full">
                            <tr>
                                <th>Taille</th><th>Vitesse Max</th><th>Données internet</th><th>Minutes d’appels vers fixe</th><th>Minutes d’appels vers mobile</th><th>Prix mensuel (F CFA)</th>
                            </tr>
                            <tr><td className="border text-center">S</td><td className="border text-center">15 Mbps</td><td className="border text-center">Illimitées</td><td className="border text-center">200 mins</td><td className="border text-center">90 mins</td><td className="border text-center">25 000</td></tr>
                            <tr><td className="border text-center">M</td><td className="border text-center">25 Mbps</td><td className="border text-center">Illimitées</td><td className="border text-center">300 mins</td><td className="border text-center">120 mins</td><td className="border text-center">35 000</td></tr>
                            <tr><td className="border text-center">L</td><td className="border text-center">40 Mbps</td><td className="border text-center">Illimitées</td><td className="border text-center">400 mins</td><td className="border text-center">150 mins</td><td className="border text-center">50 000</td></tr>
                            <tr><td className="border text-center">XL</td><td className="border text-center">50 Mbps</td><td className="border text-center">Illimitées</td><td className="border text-center">500 mins</td><td className="border text-center">180 mins</td><td className="border text-center">60 000</td></tr>
                        </table>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 5 : CONDITIONS D’ACCES AU SERVICE/DATE D'ACTIVATION DU SERVICE</h1>
                        <p className="text-justify text-xs">
                            5.1. L’accès au service BLUE MAX est réservé au Client se trouvant dans une localité accessible et desservie par le réseau cuivre ADSL et/ou le réseau fibre optique de CAMTEL.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 6 : OBLIGATIONS DES PARTIES</h1>
                        <p className="text-justify text-xs">
                            6.1. Le Client s’engage à:
                            <ul className="list-disc ml-7">
                                <li>régler les factures correspondant au Service souscrit dans les délais fixés dans le contrat par CAMTEL ;</li>
                                <li>utiliser le Service conformément aux termes et conditions du contrat d’abonnement et à la réglementation en vigueur ;</li>
                                <li>s’interdire d’effectuer toute modification d’équipements terminaux, propriété exclusive de CAMTEL, susceptible d’affecter la fourniture du Service ; </li>
                                <li>Ne pas utiliser abusivement les services de CAMTEL;</li>
                                <li>Informer CAMTEL sans délai en cas de changement de son adresse de facturation.</li>
                            </ul>
                        </p>
                        <p className="text-justify text-xs">
                            6.2. CAMTEL s’engage à :
                            <ul className="list-disc ml-7">
                                <li>mettre en œuvre tous les moyens nécessaires au fonctionnement régulier du Service ;</li>
                                <li>garantir les équipements terminaux fournis au Client dans les conditions et modalités définies dans le catalogue des produits et services BLUE MAX ;</li>
                                <li>assurer la maintenance curative du Service et procéder à la relève des dérangements dans un délai maximum 48 heures à compter de la date de notification par le Client à CAMTEL desdits dérangements à l’exception des cas suivants :
                                    <ul>
                                        <li>installation de l’équipement terminal non effectuée par CAMTEL ;</li>
                                        <li>L’équipement terminal ne peut être économiquement entretenu ;</li>
                                        <li>Non disponibilité des outils et des composantes pour effectuer l’entretien de l’équipement terminal.</li>
                                    </ul>
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 7 - RESPONSABILITE/LIMITE DE RESPONSABILITE</h1>
                        <p className="text-justify text-xs">
                            7.1. La responsabilité de CAMTEL ne saurait être engagée dans les cas suivants :
                            <ul className="list-disc ml-7">
                                <li>Manquement du Client à l'une de ses obligations contractuelles ;</li>
                                <li>Défaillance momentanée du réseau de télécommunications de CAMTEL altérant la qualité du service notamment en cas de travaux d’entretien, de renforcement ou d’extension des installations dudit réseau ;</li>
                                <li>Dommages indirects et/ou immatériels tels que pertes d’exploitation, de profits, de clientèle ou pertes financières du Client;</li>
                                <li>Force majeure.</li>
                            </ul>
                        </p>
                        <p className="text-justify text-xs">
                            7.2 CAMTEL se réserve la faculté de modifier à tout moment, sans notification préalable, la composition des options proposées. Dans ce cas, le client aura la faculté de mettre fin sans frais à son contrat d’abonnement dans les quinze jours qui suivent par lettre recommandée avec accusé de réception, dépassé ce délai les dispositions prévues par l’article 16 ci-dessous s’appliqueront.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 8 - FRAIS D’ACCES AU SERVICE</h1>
                        <p className="text-justify text-xs">
                            8.1 Les frais d’accès au Service comportant les frais d’installation et ceux d’abonnement sont fixés dans le catalogue des prix des produits et services BLUE MAX de CAMTEL.
                        </p>
                        <p className="text-justify text-xs">
                            8.2 Le Client reconnaît avoir pris entière et parfaite connaissance des frais en vigueur correspondant au Service tels que fixés dans le catalogue des prix des produits et services BLUE MAX de CAMTEL. Toute modification ultérieure desdits frais s’applique au Client à compter de la date de son entrée en vigueur.
                        </p>
                        <p className="text-justify text-xs">
                            8.3 Les frais d’installation pour bénéficier du service BLUE MAX incombent au client. Ils sont non remboursables.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 9 : FACTURATION ET RECOUVREMENT</h1>
                        <p className="text-justify text-xs">
                            9.1. Les frais de prépaiement sont facturés au Client et réglés au moment de la signature du formulaire de demande d’abonnement.
                        </p>
                        <p className="text-justify text-xs">
                            9.2. La facture correspondant au Service est payable à la date limite fixée par CAMTEL.
                        </p>
                        <p className="text-justify text-xs">
                            9.3. CAMTEL se réserve le droit, durant la validité du contrat, d’émettre des factures intermédiaires dans les cas suivants :
                            <ul className="list-disc ml-7">
                                <li>incident dans le paiement de la facture</li>
                                <li>résiliation du contrat.</li>
                            </ul>
                        </p>
                        <p className="text-justify text-xs">
                            9.4. En cas de non-paiement total ou partiel d’une facture à la date limite fixée par CAMTEL, CAMTEL se réserve le droit d’exiger le paiement des pénalités de retard d’un montant équivalent au tiers de la facture.
                        </p>
                        <p className="text-justify text-xs">
                            9.5. Le non-paiement du montant de la facture à la date limite fixée par CAMTEL donne lieu au report de ce montant sur la facture du mois suivant celui au titre duquel est due la facture non payée.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 10 : CHANGEMENT DE FORMULES DU SERVICE</h1>
                        <p className="text-justify text-xs">
                            10.1. Le client peut demander le changement de la formule du Service choisie initialement moyennant la signature d’un nouveau formulaire de demande et le paiement des frais y afférents.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 11 : SUSPENSION DU SERVICE</h1>
                        <p className="text-justify text-xs">
                            11.1 Le Client peut demander la suspension du service BLUE MAX dans les conditions et modalités fixées dans le catalogue des prix des produits et services du Fixe.
                        </p>
                        <p className="text-justify text-xs">
                            11.2. CAMTEL est en droit de suspendre :
                            <ul className="list-disc ml-7">
                                <li>L’accès au Service en cas du manquement du Client à l’une de ses obligations contractuelles ;</li>
                                <li>non- paiement ou non- respect de toutes autres obligations contractuelles en dehors du paiement ;</li>
                                <li>non –paiement de sa facture.</li>
                            </ul>
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 12 : RETABLISSEMENT DU SERVICE</h1>
                        <p className="text-justify text-xs">
                            CAMTEL procède, dans un délai n’excédant pas 07 (jours) à compter de la date de suspension du service, au rétablissement du service sous réserve du règlement par le Client, pendant ledit délai, des sommes dues à CAMTEL.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 13 : TRANSFERT/CHANGEMENT DE NUMERO D’APPEL OU DE NOM</h1>
                        <p className="text-justify text-xs">
                            Les demandes de transfert de la ligne téléphonique et/ou de Changement de numéro d’appel ou de nom du Client acceptées par CAMTEL, donnent lieu à la signature d’un nouveau formulaire de demande et au paiement des frais correspondants.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 14: RECLAMATION</h1>
                        <p className="text-justify text-xs">
                            14.1. En cas de préjudice subi du fait de CAMTEL ou titre de l'exécution des présentes, le Client peut adresser une réclamation aux services de CAMTEL désignés sur la facture.
                        </p>
                        <p className="text-justify text-xs">
                            14.2. La réclamation relative à la facture est recevable dans un délai maximum de 05 (cinq) jours à compter de la date de réception de ladite facture.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 15 : RESILIATION</h1>
                        <p className="text-justify text-xs font-bold">15.1. Résiliation sur demande du Client</p>
                        <p className="text-justify text-xs">
                            15.1.1. Après expiration de la durée minimale visée au 2.2 ci-dessus, le Client peut à tout moment résilier le contrat sous réserve d’un préavis de trente (30) jours adressé à CAMTEL par lettre recommandée avec accusé de réception.
                        </p>
                        <p className="text-justify text-xs">
                            15.1.2. Dans le cas où la résiliation serait demandée avant la date de mise à disposition du service, le Client n’est pas en droit de réclamer le remboursement des frais d’installation.
                        </p>
                        <p className="text-justify text-xs">
                            15.1.3. En cas de résiliation avant terme du contrat, le Client demeure redevable des sommes correspondantes aux frais d’abonnement restant à courir pour la durée minimale du contrat.
                        </p>
                        <p className="text-justify text-xs font-bold">15.2. Résiliation par CAMTEL</p>
                        <p className="text-justify text-xs">
                            -	CAMTEL est en droit de résilier le présent contrat, après mise en demeure à la charge du Client, restée sans effet pendant un délai de 15 jours et sans préjudice des poursuites judiciaires qui pourraient être exercées pour assurer le recouvrement des impayés, dans les cas suivants :
                            <ul className="list-disc ml-7">
                                <li>manquement du Client à l’une de ses obligations contractuelles ;</li>
                                <li>non-rétablissement du service dans les conditions visées à l’article 12 ;</li>
                                <li>persistance de non-paiement des factures ;</li>
                                <li>force majeure.</li>
                            </ul>
                        </p>
                        <p className="text-justify text-xs font-bold">15.3. Effets de la Résiliation</p>
                        <p className="text-justify text-xs">
                            La résiliation du contrat d’abonnement aux services BLUE MAX entraîne ipso facto le retrait des équipements d’installation déployés.
                        </p>
                    </div>
                    <div className="font-montserrat my-3">
                        <h1 className="mb-2 text-center bg-sky-500 text-white" style={{ paddingBottom: 6 }}>ARTICLE 17 : COMPETENCE JURIDICTIONNELLE</h1>
                        <p className="text-justify text-xs">
                            Tout litige né de l’exécution ou de l’interprétation du présent contrat sera porté, à défaut d’accord amiable, devant le tribunal du lieu de localisation de l’Agence Commerciale.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contract;