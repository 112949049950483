import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from '../admin/components/Header';
import Sidebar from '../admin/components/Sidebar';
import { BASE_URL } from '../../apiConfig';
import '../../style.css';

const Agency = () => {
    const [entities, setEntities] = useState([]);
    const [formData, setFormData] = useState({ name: "", emailEntity: "", password: "camtel", role: "admin_camtel", telephoneEntity: "", idBindedEntity: 0, interventionZone: "" });
    const [isAdding, setIsAdding] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [editingEntity, setEditingEntity] = useState(null);

    const [editedName, setEditedName] = useState("");
    const [editedEmail, setEditedEmail] = useState("");
    const [editedTelephoneEntity, setEditedTelephoneEntity] = useState("");
    const [editedInterventionZone, setEditedInterventionZone] = useState("");

    const [isDetailsVisible, setIsDetailsVisible] = useState(true);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [isFormEditVisible, setIsFormEditVisible] = useState(true);

    const [loading, setLoading] = useState(false);

    const [token, setToken] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        if (!token) {
            navigate('/login');
        }

        setToken(token);
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDetails = (id) => {
        const entity = entities.find((entity) => entity.idEntity === id);
        setSelectedEntity(entity);
        setEditingEntity(false);
        setIsAdding(false);
        setIsDetailsVisible(false);
    };

    const handleAdd = async () => {
        setLoading(true);

        if (
            !formData.name ||
            !formData.email ||
            !formData.telephoneEntity ||
            !formData.interventionZone
        ) {
            setLoading(false);
            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        const data = formData;

        if (token) {
            try {
                const config = {
                    method: 'post',
                    url: `${BASE_URL}/api/agency`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    data: data
                };

                const response = await axios.request(config);

                setFormData({
                    name: "",
                    emailEntity: "",
                    password: "camtel",
                    role: "admin_camtel",
                    telephoneEntity: "",
                    idBindedEntity: 0,
                    interventionZone: ""
                });

                setEntities([...entities, response.data]);
                setEditingEntity(false);
                setIsAdding(false);
                setLoading(false);

                setIsFormVisible(true);
            } catch (error) {
                console.error("Erreur lors de la création de l'agence : ", error);
                setLoading(false);
                alert('Une erreur est survenue lors de la création de l\'agence !');
            }
        }
    };

    const handleEdit = (id) => {
        // setSelectedEntity(false);
        setIsAdding(false);
        // setIsDetailsVisible(true);
        setIsFormEditVisible(false);

        const entity = entities.find((entity) => entity.idEntity === id);
        setEditingEntity(entity);
        setEditedName(entity.name);
        setEditedEmail(entity.emailEntity);
        setEditedTelephoneEntity(entity.telephoneEntity);
        setEditedInterventionZone(entity.interventionZone);
    };

    const handleSubmit = () => {
        setLoading(true);

        if (!editedName || !editedEmail || !editedTelephoneEntity || !editedInterventionZone) {
            setLoading(false);
            alert("Veuillez remplir tous les champs obligatoires.");
            return;
        }

        const updatedData = {
            ...editingEntity,
            name: editedName,
            email: editedEmail,
            telephoneEntity: editedTelephoneEntity,
            interventionZone: editedInterventionZone
        };

        if (token) {
            axios.put(`${BASE_URL}/api/agency/${editingEntity.idEntity}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    setLoading(false);
                    setIsFormEditVisible(true);
                    setIsDetailsVisible(true);
                    alert('Agence modifiée avec succès.');
                })
                .catch((error) => {
                    setLoading(false);
                    alert('Erreur lors de la modification de l\'agence.');
                    console.error("Erreur lors de la mise à jour de l'entité : ", error);
                });
        }
    };

    const handleDelete = (id) => {
        setLoading(true);

        if (token) {
            if (window.confirm('Êtes-vous sûr de vouloir désactiver cette agence ? Vous ne pourrez plus l\'asigner à un partenaire dans le futur après cette action.')) {
                axios.patch(`${BASE_URL}/api/agency/${id}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((response) => {
                        setSelectedEntity(null);
                        setIsDetailsVisible(true);
                        setLoading(false);
                        alert('Agence désactivée avec succès.');
                    })
                    .catch((error) => {
                        setLoading(false);
                        alert('Erreur lors de la désactivation de l\'agence.');
                        console.error("Erreur lors de la suppression de l'entité : ", error);
                    });
            }
        }

        setLoading(false);
    };

    const handleReActivate = (id) => {
        setLoading(true);

        if (token) {
            if (window.confirm('Êtes-vous sûr de vouloir réactiver cette agence ? Vous pourrez désormais l\'assigner à un partenaire après cette action.')) {
                axios.patch(`${BASE_URL}/api/agency/reactivate/${id}`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((response) => {
                        setLoading(false);
                        alert('Agence réactivée avec succès.');
                    })
                    .catch((error) => {
                        setLoading(false);
                        alert('Erreur lors de la désactivation de l\'agence.');
                        console.error("Erreur lors de la suppression de l'entité : ", error);
                    });
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        if (token) {
            const fetchData = async () => {
                try {
                    const response = await axios.get(`${BASE_URL}/api/agency/`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    setEntities(response.data.entities);
                } catch (error) {
                    console.error("Erreur lors de la récupération des agences :", error);
                }
            };

            fetchData();
        }
    }, [formData, selectedEntity, editingEntity, handleEdit, handleSubmit, handleDelete, handleReActivate, token]);

    const [hideText, setHideText] = useState(
        localStorage.getItem('sidebarHidden') === 'true'
    );

    const resetPassword = (userId) => {
        setLoading(true);

        const userRole = 'admin_camtel';
        const superAdminId = localStorage.getItem('userId');

        if (token && window.confirm('Êtes-vous sûr de vouloir réinitialiser le mot de passe de l\'administrateur de cette entité ?')) {

            axios.patch(`${BASE_URL}/api/user/reset-password-by-superadmin/${userId}/${superAdminId}`, { userRole }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    if (response && response.data && response.data.message) {
                        alert(response.data.message);
                    } else {
                        alert(response.data.error);
                    }
                })
                .catch((error) => {
                    console.error("Erreur lors de la réinitialisation du mot de passe de l'administrateur de cette entité : ", error);
                    setLoading(false);
                });
        }

        setLoading(false);
    }

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh' }} className="flex items-center justify-center">
                    <img
                        src={require('../../assets/img/icons/gif/loading-icon-animated.jpeg')}
                        alt='Loader'
                        className="mx-auto"
                        style={{ maxWidth: '300px', maxHeight: '300px' }}
                    />
                </div>
            ) : (
                <>
                    <Header />

                    <Sidebar setHideText={setHideText} hideText={hideText} />

                    <div className={`container mx-auto p-4 ml-0 sm:ml-0 md:ml-0 lg:ml-80 xl:ml-80 2xl:ml-80 flex justify-center ${hideText ? 'main-container' : 'sm:w-[99%] md:w-[99%] lg:w-[73%] xl:w-[73%] 2xl:w-[73%]'}`}>
                        <div className='w-full'>
                            {isDetailsVisible && isFormVisible && isFormEditVisible && (
                                <>
                                    <h1 className="text-4xl font-bold mb-4 mt-4 text-center">Liste des agences</h1>

                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => { setIsAdding(true); setSelectedEntity(false); setEditingEntity(false); setIsFormVisible(false); }}
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mb-4"
                                        >
                                            Ajouter une agence
                                        </button>
                                    </div>

                                    <div className="mt-3 overflow-x-auto">
                                        <table className="w-full table-auto">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2">Nom</th>
                                                    <th className="px-4 py-2">Email</th>
                                                    <th className="px-4 py-2">Téléphone</th>
                                                    <th className="px-4 py-2">Zone d'intervention</th>
                                                    <th className="px-4 py-2">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-gray-600 divide-y">
                                                {Array.isArray(entities) && entities.length > 0 ? (
                                                    entities.map((entity) => (
                                                        <tr key={entity.idEntity} className={entity.visible === 0 ? "text-red-500 border-b hover:bg-gray-100" : "border-b hover:bg-gray-100"}>
                                                            <td className="px-4 py-2">{entity.name}</td>
                                                            <td className="px-4 py-2">{entity.emailEntity}</td>
                                                            <td className="px-4 py-2">{entity.telephoneEntity}</td>
                                                            <td className="px-4 py-2">{entity.interventionZone}</td>
                                                            <td className="px-4 py-2 text-center">
                                                                {entity.visible === 1 ?
                                                                    <button
                                                                        onClick={() => handleDetails(entity.idEntity)}
                                                                        className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 m-1"
                                                                    >
                                                                        Détails
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        onClick={() => handleReActivate(entity.idEntity)}
                                                                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 m-1"
                                                                    >
                                                                        Réactiver
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5" className="text-center">Aucune agence trouvée.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}

                            {selectedEntity && !isDetailsVisible && (
                                <div className="mt-4">
                                    <div className='flex justify-end'>
                                        <div>
                                            <a
                                                href={`/profiles/${selectedEntity.idEntity}/${encodeURIComponent(selectedEntity.name)}/${selectedEntity.idBindedEntity}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="bg-green-500 hover:bg-green-700 text-white font-bold m-1 p-2"
                                            >
                                                Profils utilisateurs
                                            </a>
                                            <button
                                                onClick={() => { setIsDetailsVisible(true); handleEdit(selectedEntity.idEntity); }}
                                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold m-1 p-2"
                                            >
                                                Modifier
                                            </button>
                                            <button
                                                onClick={() => resetPassword(selectedEntity.idEntity)}
                                                className="bg-orange-500 hover:bg-orange-700 text-white font-bold p-2 m-1"
                                            >
                                                Réinialiser
                                            </button>
                                            <button
                                                onClick={() => handleDelete(selectedEntity.idEntity)}
                                                className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold m-1 p-2"
                                            >
                                                Désactiver
                                            </button>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 m-1 p-2 text-white"
                                                onClick={() => setIsDetailsVisible(true)}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h2 className="text-4xl font-bold mb-4 mt-4 text-center">Détails de l'agence</h2>
                                    <div>
                                        <div><strong>Nom:</strong> {selectedEntity.name}</div>
                                    </div>
                                    <div>
                                        <strong>Email:</strong> {selectedEntity.emailEntity}
                                    </div>
                                    <div>
                                        <strong>Téléphone:</strong> {selectedEntity.telephoneEntity}
                                    </div>
                                    <div>
                                        <strong>Zone d'intervention:</strong> {selectedEntity.interventionZone}
                                    </div>
                                </div>
                            )}

                            {isAdding && !isFormVisible && (
                                <div className="mt-4">
                                    <div className='flex justify-end'>
                                        <div>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 p-2 text-white"
                                                onClick={() => { setIsFormVisible(true); }}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h2 className="text-4xl font-bold mb-4 mt-4 text-center">Ajouter une agence</h2>
                                    <form>
                                        <div className="mb-2">
                                            <label htmlFor="name" className="block text-gray-700 font-bold">
                                                Dénomination de l'agence <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                                reqqu
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="email" className="block text-gray-700 font-bold">
                                                Email <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="telephoneEntity" className="block text-gray-700 font-bold">
                                                Téléphone <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="telephoneEntity"
                                                name="telephoneEntity"
                                                value={formData.telephoneEntity}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="interventionZone" className="block text-gray-700 font-bold">
                                                Zone d'intervention <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="interventionZone"
                                                name="interventionZone"
                                                value={formData.interventionZone}
                                                onChange={handleChange}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            onClick={handleAdd}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Ajouter
                                        </button>
                                    </form>
                                </div>
                            )}

                            {editingEntity && !isFormEditVisible && (
                                <div className="mt-4">
                                    <div className='flex justify-end'>
                                        <div>
                                            <button
                                                className="close-button bg-red-500 hover:bg-red-700 m-1 p-2 text-white"
                                                onClick={() => { setIsDetailsVisible(true); setIsFormEditVisible(true); }}
                                            >
                                                <span className="close-icon text-white">✖</span> Fermer
                                            </button>
                                        </div>
                                    </div>
                                    <h2 className="text-3xl font-bold text-center m-2">Modifier l'agence</h2>
                                    <form>
                                        <div className="mb-2">
                                            <label htmlFor="editedName" className="block text-gray-700 font-bold">
                                                Nom  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedName"
                                                name="editedName"
                                                value={editedName}
                                                onChange={(e) => setEditedName(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedEmail" className="block text-gray-700 font-bold">
                                                Email  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                id="editedEmail"
                                                name="editedEmail"
                                                value={editedEmail}
                                                onChange={(e) => setEditedEmail(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedTelephoneEntity" className="block text-gray-700 font-bold">
                                                Téléphone  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedTelephoneEntity"
                                                name="editedTelephoneEntity"
                                                value={editedTelephoneEntity}
                                                onChange={(e) => setEditedTelephoneEntity(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="editedInterventionZone" className="block text-gray-700 font-bold">
                                                Zone d'intervention <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="editedInterventionZone"
                                                name="editedInterventionZone"
                                                value={editedInterventionZone}
                                                onChange={(e) => setEditedInterventionZone(e.target.value)}
                                                className="border rounded w-full py-2 px-3"
                                            />
                                        </div>

                                        <button
                                            type="button"
                                            onClick={handleSubmit}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
                                        >
                                            Enregistrer
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default Agency;